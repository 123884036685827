import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { CareerInsightsProcessingStatus, selectors, actions } from 'builder/modules/careerProfile'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FloatingModalContainer } from 'builder/components/JobSearchHelper'
import ModalOverlay from 'builder/components/ModalOverlay'
import { selectors as eventSelectors } from 'builder/modules/events'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { FloatingModal } from 'builder/components/HelperModal'
import { Icon20 } from 'builder/components/Icon'
import Button, { ButtonTheme } from 'builder/components/Button'
import { useHelpScout, useIsScrolled } from 'builder/components/FloatingCIOHelperButton'
import {
  careerInsightsFailedImageUrl,
  careerInsightsPendingImageUrl,
  careerInsightsReadyImageUrl,
  careerInsightsRequiredImageUrl,
} from '../../images'
import {
  FloatingButtonText,
  FloatingButtonTextContainer,
  CloseModalButton,
  FloatingButtonContainer,
  ModalImage,
  ModalBody,
  ModalTextContainer,
  ModalTitle,
  ModalDescription,
  ModalButtonsContainer,
  ModalContent,
  ModalSpinner,
  FloatingButtonSpinner,
  InsightsImage,
  InsightsReadyBadgeImage,
} from './styles'
import careerInsightsImageUrl from './images/career-insights.svg'
import careerInsightsReadyBadgeImageUrl from './images/insights-ready-badge.svg'

export const CareerProfileFloatingHelper: FC<{ isResumeEditor?: boolean }> = ({
  isResumeEditor,
}) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isHovering, setIsHovering] = useState(false)
  const [isHelperOpen, setIsHelperOpen] = useState(false)
  const careerInsightsStatus = useTypedSelector(selectors.careerInsightsData)?.status
  const helperAnimation = useTypedSelector(eventSelectors.helperAnimation)
  const isCareerInsightsRequired = useTypedSelector(selectors.isCareerAssessmentRequired)
  const isCareerInsightsPageUnseen = useTypedSelector(store =>
    selectors.isPageMarkedAsNew(store, 'insights'),
  )
  const isCareerInsightsPending = careerInsightsStatus === CareerInsightsProcessingStatus.pending
  const isCareerInsightsReady =
    careerInsightsStatus === CareerInsightsProcessingStatus.success && isCareerInsightsPageUnseen
  const isCareerInsightsFailed = careerInsightsStatus === CareerInsightsProcessingStatus.failure
  const shouldShowHelper =
    isCareerInsightsRequired ||
    isCareerInsightsPending ||
    isCareerInsightsFailed ||
    isCareerInsightsReady
  const { isScrolled } = useIsScrolled()
  const { isHelpScoutPresent } = useHelpScout(isResumeEditor)
  const statusModalType = useTypedSelector(selectors.careerInsightsStatusModalType)

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const toggleIsHelperOpen = () => {
    if (!isHelperOpen) {
      let status = 'ready'
      if (isCareerInsightsRequired) {
        status = 'promo'
      } else if (isCareerInsightsFailed) {
        status = 'failed'
      } else if (isCareerInsightsPending) {
        status = 'in progress'
      }
      trackInternalEvent('assessment_corner_click', { status })
    }

    setIsHelperOpen(value => !value)
  }

  const onRetakeAssessment = () => {
    toggleIsHelperOpen()
    dispatch(actions.setInitialChatState())
    navigate(`/career-profile/chat`)
  }

  const onRetry = () => {
    dispatch(actions.postChatbotAnswers())
    toggleIsHelperOpen()
  }

  // we should close helper if status modal is opened
  useEffect(() => {
    if (statusModalType && isHelperOpen) {
      setIsHelperOpen(false)
    }
  }, [statusModalType, isHelperOpen])

  return shouldShowHelper ? (
    <>
      <FloatingButtonContainer
        onMouseOver={handleMouseOver}
        isHovering={isHovering}
        onMouseOut={handleMouseOut}
        isScrolled={isScrolled}
        resumeEditor={isResumeEditor}
        isHelpScoutPresent={isHelpScoutPresent}
        isHelperOpen={isHelperOpen}
        onClick={toggleIsHelperOpen}
        currentWidgetName=""
      >
        <FloatingButtonTextContainer>
          {isCareerInsightsRequired ? (
            <>
              <InsightsImage src={careerInsightsImageUrl} />
              <FloatingButtonText>
                {i18n.t(`builder.career_profile.floating_helper.explore_personal_insights`)}
              </FloatingButtonText>
            </>
          ) : isCareerInsightsPending ? (
            <>
              <FloatingButtonSpinner />
              <FloatingButtonText>
                {i18n.t(`builder.career_profile.status_modal.processing.title`)}
              </FloatingButtonText>
            </>
          ) : isCareerInsightsReady ? (
            <>
              <InsightsImage src={careerInsightsImageUrl} />
              <InsightsReadyBadgeImage src={careerInsightsReadyBadgeImageUrl} />
              <FloatingButtonText>
                {i18n.t(`builder.career_profile.status_modal.success.title`)}
              </FloatingButtonText>
            </>
          ) : isCareerInsightsFailed ? (
            <>
              <InsightsImage src={careerInsightsImageUrl} />
              <FloatingButtonText>
                {i18n.t(`builder.career_profile.status_modal.failure.title`)}
              </FloatingButtonText>
            </>
          ) : null}
        </FloatingButtonTextContainer>
      </FloatingButtonContainer>

      <FloatingModalContainer
        modalAsCentre={false}
        isOverlay={isHelperOpen}
        isHelper={isHelperOpen}
        helperAnimation={helperAnimation}
      >
        {isHelperOpen && isPhone && <ModalOverlay onClick={toggleIsHelperOpen} />}
        <FloatingModal>
          <ModalContent>
            {isCareerInsightsRequired ? (
              <>
                <ModalImage src={careerInsightsRequiredImageUrl} />
                <ModalBody>
                  <ModalTextContainer>
                    <ModalTitle>
                      {i18n.t(`builder.career_profile.floating_helper.insights.title`)}
                    </ModalTitle>
                    <ModalDescription>
                      {i18n.t(`builder.career_profile.floating_helper.insights.subtitle`)}
                    </ModalDescription>
                  </ModalTextContainer>
                  <ModalButtonsContainer>
                    <Link to="/career-profile/chat">
                      <Button theme={ButtonTheme.alternative} size="small">
                        {i18n.t(`builder.career_profile.floating_helper.learn_more`)}
                      </Button>
                    </Link>
                  </ModalButtonsContainer>
                </ModalBody>
              </>
            ) : isCareerInsightsPending ? (
              <>
                <ModalImage src={careerInsightsPendingImageUrl} />
                <ModalBody>
                  <ModalTextContainer>
                    <ModalTitle>
                      <ModalSpinner />
                      {i18n.t(`builder.career_profile.floating_helper.procession.title`)}
                    </ModalTitle>
                    <ModalDescription
                      dangerouslySetInnerHTML={{
                        __html: i18n.t(
                          `builder.career_profile.floating_helper.procession.subtitle`,
                        ),
                      }}
                    />
                  </ModalTextContainer>
                </ModalBody>
              </>
            ) : isCareerInsightsReady ? (
              <>
                <ModalImage src={careerInsightsReadyImageUrl} />
                <ModalBody>
                  <ModalTextContainer>
                    <ModalTitle>
                      {i18n.t(`builder.career_profile.status_modal.success.title`)}
                    </ModalTitle>
                    <ModalDescription>
                      {i18n.t(`builder.career_profile.status_modal.success.subtitle`)}
                    </ModalDescription>
                  </ModalTextContainer>
                  <ModalButtonsContainer>
                    <Link to="/career-profile/insights">
                      <Button theme={ButtonTheme.alternative} size="small">
                        {i18n.t(`builder.career_profile.status_modal.view_results`)}
                      </Button>
                    </Link>
                  </ModalButtonsContainer>
                </ModalBody>
              </>
            ) : isCareerInsightsFailed ? (
              <>
                <ModalImage src={careerInsightsFailedImageUrl} />
                <ModalBody>
                  <ModalTextContainer>
                    <ModalTitle>
                      {i18n.t(`builder.career_profile.status_modal.failure.title`)}
                    </ModalTitle>
                    <ModalDescription>
                      {i18n.t(`builder.career_profile.status_modal.failure.subtitle`)}
                    </ModalDescription>
                  </ModalTextContainer>
                  <ModalButtonsContainer>
                    <Button theme={ButtonTheme.ghost} size="small" onClick={onRetakeAssessment}>
                      {i18n.t(`builder.career_profile.status_modal.retake_assessment`)}
                    </Button>
                    <Button theme={ButtonTheme.alternative} size="small" onClick={onRetry}>
                      {i18n.t(`builder.career_profile.status_modal.try_again`)}
                    </Button>
                  </ModalButtonsContainer>
                </ModalBody>
              </>
            ) : null}
          </ModalContent>
          <CloseModalButton onClick={toggleIsHelperOpen}>
            <Icon20.Close />
          </CloseModalButton>
        </FloatingModal>
      </FloatingModalContainer>
    </>
  ) : null
}
