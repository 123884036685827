import styled from 'styled-components'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'

export const Container = styled.div`
  width: 100%;
  max-width: 416px;
  height: auto;

  ${Media.Tablet`
    max-width: 400px;
  `};

  ${Media.Phone`
    max-width: 333px;
  `};
`

export const Title = styled.div`
  ${Typography.M};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  text-align: center;
  margin-bottom: 12px;
`

export const Text = styled.div`
  ${Typography.Body};
  color: ${Colors.Neutral50};
  text-align: center;
  margin-bottom: 24px;
`

export const FormContainer = styled.form``

export const FormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;

  ${Media.Phone`
    margin-top: 32px;
  `};

  & ${Button}:last-child {
    margin-left: auto;
  }
`
