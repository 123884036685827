import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import { Button } from 'builder/components/Button'
import Media from 'builder/styles/media'

export const Container = styled.div`
  width: 352px;
  padding: 24px;
  border-radius: 8px;
  z-index: 5;
  background-color: ${Colors.White};
  ${Media.Phone`
    width: 95%;
    padding: 20px;
    max-width: 500px;
  `}
`

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  ${Media.Phone`
    margin-bottom: 24px;
  `}
`

export const Title = styled.div`
  /* Mobile/S • DemiBold */
  ${Typography.S}
  ${FontWeights.DemiBold}
  color: ${Colors.Neutral90};
  width: 260px;
`

export const CloseIcon = styled(Icon20.Close)`
  background-color: ${Colors.Neutral10};
  border-radius: 32px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`

export const Cancel = styled(Button)`
  margin-right: 8px;
`

export const Save = styled(Button)``
