import countriesArray from 'builderStatic/js/components/config/countriesArray'
import { AutoApplyForm, Profile, WorkingTypes } from 'builder/modules/autoApply/types'
import { ConfigScopesEnum } from 'builder/modules/init'
import { store } from 'builder/modules/store'

import { SOCIAL_OPTIONS } from '../constants'

const countries = countriesArray
  .sort((a, b) => (b.priority || 0) - (a.priority || 0))
  .map(c => ({ value: c.id, label: c.name }))

export const mapCareerResponse = (response: Profile): AutoApplyForm => {
  const state = store.getState()
  const features = state.application.configs[ConfigScopesEnum.app]?.features
  const careerProfileShortVersion = features?.careerProfileShortVersion

  if (
    (!response.coursesAttributes || response.coursesAttributes?.length === 0) &&
    !careerProfileShortVersion
  ) {
    response.coursesAttributes = [
      {
        course: '',
        dateFrom: '',
        dateUntil: '',
        institution: '',
      },
    ]
  }

  if (
    (!response.socialLinksAttributes || response.socialLinksAttributes.length === 0) &&
    !careerProfileShortVersion
  ) {
    response.socialLinksAttributes = [
      {
        link: '',
        title: SOCIAL_OPTIONS[0].id,
      },
    ]
  }

  if (!response.jobPreferenceAttributes) {
    response.jobPreferenceAttributes = {
      currentSalary: undefined,
      currentCurrency: 'USD',
      currentSalaryPlan: 'annually',
      expectedSalary: undefined,
      expectedCurrency: 'USD',
      expectedSalaryPlan: 'annually',
      expectedWorkingTypes: !careerProfileShortVersion ? [WorkingTypes.office] : [],
      willingToRelocate: false,
      willingToRelocateAtYourOwnCost: false,
      expectedStartWork: 'immediate',
      openForBusinessTrips: !careerProfileShortVersion ? false : undefined,
      fullyVaccinatedAgreementAgainstCovid: !careerProfileShortVersion ? false : undefined,
      hasRestrictionLimiting: !careerProfileShortVersion ? false : undefined,
      openToRemoteOpportunities: !careerProfileShortVersion ? false : undefined,
    }
  }

  if (!response.jobPreferenceAttributes.openToRemoteOpportunities) {
    response.jobPreferenceAttributes.openToRemoteOpportunities = false
  }

  if (!response.jobPreferenceAttributes.willingToRelocate) {
    response.jobPreferenceAttributes.willingToRelocate = false
  }

  if (!response.jobPreferenceAttributes?.expectedStartWork) {
    response.jobPreferenceAttributes.expectedStartWork = 'one_month'
  }

  if (!response.jobPreferenceAttributes?.expectedWorkingTypes && !careerProfileShortVersion) {
    response.jobPreferenceAttributes.expectedWorkingTypes = [WorkingTypes.office]
  }

  if (response.countriesAuthorizedToWorkIn?.length) {
    const resCountries = response.countriesAuthorizedToWorkIn as unknown as string[]
    response.countriesAuthorizedToWorkIn = countries.filter(item =>
      resCountries.includes(item.value),
    )
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { resumeUploadedAt, profilePicture, userType, ...rest } = response

  return rest
}
