import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import {
  AlternativeWideFeatureSlotImage,
  CTAContainer,
  CTADescription,
  CTAPrimary,
  CTAPrimaryContainer,
  CTATopLabel,
  Close,
  CloseButtonContainer,
  CloseIcon,
  FeatureSlot,
  FeatureSlotDescription,
  FeatureSlotTitle,
  GetStartedDescription,
  GetStartedFeature,
  GetStartedFeatureTitle,
  GetStartedFeatures,
  GetStartedFeaturesContainer,
  GetStartedHeaderContainer,
  GetStartedTextContainer,
  GetStartedTitle,
  ModalImageSection,
  ModalImageSectionContent,
  ModalSection,
  NarrowFeatureSlotsContainer,
  NathImage,
  NathImageContainer,
  Overlay,
  RootModalContainer,
  TopBlueBadgeContainer,
  TopBlueBadgeText,
  WideFeatureSlot,
  WideFeatureSlotImage,
  WideFeatureSlotTextContainer,
} from './styles'
import { useResumeDistributionGetStartedModal } from './useResumeDistributionGetStartedModal'
import ALTERNATIVE_TARGET from './assets/alternative-target.png'
import NATH_IMAGE from './assets/nath.png'

export const ResumeDistributionGetStartedModal = () => {
  const { i18n } = useI18n()
  const { isOpen, handleModalClose, redirectToAutoApply } = useResumeDistributionGetStartedModal()
  const appConfig = useConfig()
  const engagementModalResult = appConfig?.features.engagementModal
  const resultIsControl = engagementModalResult === 'control' || engagementModalResult === false
  const resultIsNath = engagementModalResult === 'nath'
  const resultIsHitesh = engagementModalResult === 'hitesh'

  useEffect(() => {
    if (isOpen) {
      trackInternalEvent('show_promo_modal', { type: 'total_job_solution' })
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <Overlay>
      <RootModalContainer $isControl={resultIsControl} $isNath={resultIsNath}>
        <CloseButtonContainer>
          <Close onClick={handleModalClose}>
            <CloseIcon />
          </Close>
        </CloseButtonContainer>
        {resultIsNath && (
          <NathImageContainer>
            <NathImage src={NATH_IMAGE} />
          </NathImageContainer>
        )}
        <ModalSection
          $isControl={resultIsControl}
          $maxWidth={resultIsHitesh ? 394 : 498}
          $isNath={resultIsNath}
        >
          <GetStartedTextContainer>
            <GetStartedHeaderContainer $isNath={resultIsNath}>
              <GetStartedTitle
                $maxWidth={resultIsHitesh ? 270 : undefined}
                $isControl={resultIsControl}
                $isNath={resultIsNath}
              >
                {i18n.t(
                  `builder.resume_distribution.get_started_modal.title.${engagementModalResult}`,
                )}
              </GetStartedTitle>
              {engagementModalResult !== 'hitesh' && (
                <GetStartedDescription $isControl={resultIsControl} $isNath={resultIsNath}>
                  {i18n.t(
                    `builder.resume_distribution.get_started_modal.description.${engagementModalResult}`,
                  )}
                </GetStartedDescription>
              )}
            </GetStartedHeaderContainer>
            <GetStartedFeaturesContainer $isNath={resultIsNath}>
              <GetStartedFeatureTitle>
                {i18n.t(
                  `builder.resume_distribution.get_started_modal.features_title.${engagementModalResult}`,
                )}
              </GetStartedFeatureTitle>
              {(resultIsControl || engagementModalResult === 'byron') && (
                <GetStartedFeatures>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      'builder.resume_distribution.get_started_modal.features.weekly_jobs_shortlist',
                    )}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      'builder.resume_distribution.get_started_modal.features.job_specilists',
                    )}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t('builder.resume_distribution.get_started_modal.features.send_resume')}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      'builder.resume_distribution.get_started_modal.features.interview_simulation',
                    )}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      'builder.resume_distribution.get_started_modal.features.job_offer_analysis',
                    )}
                  </GetStartedFeature>
                </GetStartedFeatures>
              )}
              {(resultIsHitesh || resultIsNath) && (
                <GetStartedFeatures>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.features.${engagementModalResult}.one`,
                    )}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.features.${engagementModalResult}.two`,
                    )}
                  </GetStartedFeature>
                  <GetStartedFeature $isNath={resultIsNath}>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.features.${engagementModalResult}.three`,
                    )}
                  </GetStartedFeature>
                </GetStartedFeatures>
              )}
            </GetStartedFeaturesContainer>
          </GetStartedTextContainer>
          <CTAContainer $isNath={resultIsNath}>
            {resultIsControl && (
              <CTATopLabel>
                {i18n.t('builder.resume_distribution.get_started_modal.premium_membership')}
              </CTATopLabel>
            )}
            <CTAPrimaryContainer>
              <CTAPrimary onClick={redirectToAutoApply}>
                {i18n.t(
                  `builder.resume_distribution.get_started_modal.get_started_cta.${engagementModalResult}`,
                )}
              </CTAPrimary>
              {engagementModalResult === 'byron' && (
                <CTADescription>
                  {i18n.t(
                    `builder.resume_distribution.get_started_modal.cta_description.${engagementModalResult}`,
                  )}
                </CTADescription>
              )}
            </CTAPrimaryContainer>
          </CTAContainer>
        </ModalSection>
        {engagementModalResult !== 'nath' && (
          <ModalImageSection $isControl={resultIsControl}>
            <ModalImageSectionContent $isControl={resultIsControl}>
              {resultIsControl && (
                <TopBlueBadgeContainer>
                  <TopBlueBadgeText>
                    {i18n.t('builder.resume_distribution.get_started_modal.blue_badge_text')}
                  </TopBlueBadgeText>
                </TopBlueBadgeContainer>
              )}
              <WideFeatureSlot $isControl={resultIsControl} $isHitesh={resultIsHitesh}>
                <WideFeatureSlotTextContainer>
                  <FeatureSlotTitle>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.first.title.${engagementModalResult}`,
                    )}
                  </FeatureSlotTitle>
                  <FeatureSlotDescription
                    $maxWidth={resultIsControl ? 253 : resultIsHitesh ? 168 : 196}
                    $maxWidthTablet={resultIsControl ? 259 : resultIsHitesh ? 190 : 239}
                    $maxWidthPhone={resultIsControl ? 275 : resultIsHitesh ? 117 : 139}
                  >
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.first.description.${engagementModalResult}`,
                    )}
                  </FeatureSlotDescription>
                </WideFeatureSlotTextContainer>
                {resultIsControl ? (
                  <WideFeatureSlotImage />
                ) : (
                  <AlternativeWideFeatureSlotImage src={ALTERNATIVE_TARGET} />
                )}
              </WideFeatureSlot>
              <NarrowFeatureSlotsContainer $isControl={resultIsControl}>
                <FeatureSlot
                  variant="indigo"
                  useBiggerPadding={!resultIsControl}
                  isControl={resultIsControl}
                  isHitesh={resultIsHitesh}
                >
                  <FeatureSlotTitle>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.second.title.${engagementModalResult}`,
                    )}
                  </FeatureSlotTitle>
                  <FeatureSlotDescription
                    $maxWidth={resultIsControl ? 156 : resultIsHitesh ? 150 : 143}
                    $maxWidthTablet={resultIsControl ? 188 : 192}
                    $maxWidthPhone={resultIsControl ? undefined : resultIsHitesh ? 138 : 236}
                  >
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.second.description.${engagementModalResult}`,
                    )}
                  </FeatureSlotDescription>
                </FeatureSlot>
                <FeatureSlot
                  variant="green"
                  useBiggerPadding={!resultIsControl}
                  isControl={resultIsControl}
                  isHitesh={resultIsHitesh}
                >
                  <FeatureSlotTitle>
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.third.title.${engagementModalResult}`,
                    )}
                  </FeatureSlotTitle>
                  <FeatureSlotDescription
                    $maxWidth={resultIsControl ? 153 : 143}
                    $maxWidthTablet={resultIsControl ? 134 : 192}
                    $maxWidthPhone={resultIsControl ? undefined : resultIsHitesh ? 108 : 216}
                  >
                    {i18n.t(
                      `builder.resume_distribution.get_started_modal.feature_slots.third.description.${engagementModalResult}`,
                    )}
                  </FeatureSlotDescription>
                </FeatureSlot>
              </NarrowFeatureSlotsContainer>
            </ModalImageSectionContent>
          </ModalImageSection>
        )}
      </RootModalContainer>
    </Overlay>
  )
}
