import styled, { css } from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { Icon20 } from 'builder/components/Icon'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  align-items: center;

  ${Media.Phone`
    max-width: 145px;
  `}
`

export const Section = styled.div`
  display: flex;
  gap: 8px;
`

export const Progress = styled.div`
  width: 24px;
`

export const ProgressConatiner = styled.div<{ percentage: number }>`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ percentage }) => css`
    background: conic-gradient(
      ${Colors.Blue50} 0%,
      ${Colors.Blue50} ${percentage}%,
      ${Colors.Blue20} ${percentage}%,
      ${Colors.Blue20} 100%
    );
  `}
`

export const ProgressBar = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${Colors.Neutral5};
`

export const TextSection = styled.div``
export const Header = styled.div`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const SubHeader = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  display: flex;
`

export const CompleteIcon = styled(Icon20.StatusOffer)`
  color: #25b869;
  margin-top: 2px;
`

export const Tooltip = styled(TooltipQuestion)`
  ${Media.Phone`
    & div > svg {
      color: ${Colors.Neutral90};
      right: -16px;
      left: auto;
    }
  `}
`
