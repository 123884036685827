import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { i18n } from 'builder/utils/i18n'
import {
  selectors as resumeDistributionSelectors,
  actions as resumeDistributionActions,
  AutoSuggestItem,
} from 'builder/modules/resumeDistribution'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import * as SimpleForm from 'builder/components/SimpleForm'
import { ERDSteps } from '../../types'
import { Location } from '../Forms/Location/Location'
import { Container, Title, Text } from './styles'

const TRANSLATION = 'builder.resume_distribution.landing.form_location_detail'

interface IProps {
  setActiveStep: (step: ERDSteps) => void
}

const LocationForm = ({ setActiveStep }: IProps) => {
  const dispatch = useDispatch()
  const { getHost } = useWebsiteHost()

  const formState = useTypedSelector(resumeDistributionSelectors.locationDetailsFields)
  const form = useForm({ defaultValues: formState })

  const setFormValue = (field: string, value?: AutoSuggestItem) => {
    dispatch(resumeDistributionActions.setLocationDetailsForm({ [field]: value }))
  }

  const onNextStep = (payload: typeof formState) => {
    dispatch(resumeDistributionActions.setLocationDetailsForm(payload))
    setActiveStep(ERDSteps.FORM_STEP_3)
  }

  const onBackClick = () => {
    setActiveStep(ERDSteps.FORM_STEP_1)
  }

  useEffect(() => {
    trackInternalEvent('rd_current_location', { website_host: getHost })
  }, [getHost])

  return (
    <Container>
      <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
      <Text>{i18n.t(`${TRANSLATION}.text`)}</Text>

      <SimpleForm.Form
        submitButtonText={i18n.t(`${TRANSLATION}.next`)}
        onSubmit={form.handleSubmit(onNextStep)}
        onBackClick={onBackClick}
        autoComplete="off"
      >
        <Location form={form} setStateFromField={setFormValue} />
      </SimpleForm.Form>
    </Container>
  )
}

export default LocationForm
