import { Component } from 'react'
import PropTypes from 'prop-types'
import { formatDate } from 'builder/utils/formatDate'
import { i18n as I18n } from 'builder/utils/i18n'
import { trackInternalEvent } from '@rio/tracking'
import premiumAccUrl from 'images/builder/account/account_premium_new.svg'
import crownUrl from 'images/builder/account/CrownColorFullIcon.svg'
import crownDottedUrl from 'images/builder/account/CrownColorDottedIcon.svg'
import premiumPlusCrown from 'images/builder/account/PremiumPlusCrown.svg'
import freeAccUrl from 'images/builder/account/account_free_new.svg'
import premiumAccUrlOld from 'images/builder/account/account_premium.svg'
import freeAccUrlOld from 'images/builder/account/account_free.svg'
import { withConfig } from 'builder/components/Config'

import {
  SectionTitle,
  ChangePlanButton,
  SectionInnerTitle,
  SectionBody,
  Section,
  SectionInnerWithHeader,
  SectionContent,
  SectionText,
  Button,
  RedButton,
  TitleImageNew,
  PostPremiumButton,
  CrownIcon,
  ManagePlanButton,
  ActionLink,
} from './styles'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'

class SubscriptionSection extends Component {
  static propTypes = {
    billingStatus: PropTypes.string,
    billingInfo: PropTypes.object,
    config: PropTypes.object.isRequired,
    account: PropTypes.object,
    isSevenDaysLeft: PropTypes.bool,
    hasPremiumPlusFeatures: PropTypes.bool,
    hasPremiumFeatures: PropTypes.bool,
  }

  static defaultProps = {
    billingStatus: '',
    billingInfo: {},
    account: {},
    isSevenDaysLeft: false,
    hasPremiumPlusFeatures: false,
  }

  calculateDifferenceInDays = () => {
    const { billingInfo } = this.props
    if (!billingInfo?.premiumEndsAt) {
      return 0
    }
    const premiumEndsAt = new Date(billingInfo?.premiumEndsAt)
    const presentDate = new Date()

    const timeDifference = premiumEndsAt - presentDate

    let differenceInDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000))
    differenceInDays = Math.max(differenceInDays, 0)

    return differenceInDays + 1
  }

  checkPremiumConditions(billingInfo, calculateDifferenceInDays) {
    const isSevenDaysLeft = billingInfo.premiumEndsAt && calculateDifferenceInDays() <= 7
    const isPostPremium = billingInfo.userType === 'post_premium'

    return { isSevenDaysLeft, isPostPremium }
  }

  get messageText() {
    const { billingStatus, billingInfo, hasPremiumPlusFeatures } = this.props

    const { isSevenDaysLeft, isPostPremium } = this.checkPremiumConditions(
      billingInfo,
      this.calculateDifferenceInDays,
    )

    if (isSevenDaysLeft && billingStatus !== 'subscription') {
      return I18n.t('builder.account.seven_premium_days_text', {
        when: this.formatDate(billingInfo.premiumEndsAt),
      })
    }

    if (isPostPremium && billingStatus !== 'subscription') {
      return I18n.t('builder.account.renew_premium_text')
    }

    if (hasPremiumPlusFeatures && billingStatus === 'subscription') {
      return I18n.t('builder.account.premium_plus_text', {
        when: this.formatDate(billingInfo.nextPaymentAt),
      })
    }

    return (
      {
        premium: I18n.t('builder.account.premium_no_subscription_text', {
          when: this.formatDate(billingInfo.premiumEndsAt),
        }),
        subscription:
          billingInfo.onTrial && !hasPremiumPlusFeatures
            ? I18n.t('builder.account.trial_subscription_text', {
                when: this.formatDate(billingInfo.nextPaymentAt),
                trial_days: billingInfo.trialDays,
              })
            : I18n.t('builder.account.monthly_subscription_text', {
                when: this.formatDate(billingInfo.nextPaymentAt),
              }),
        cancelled: I18n.t('builder.account.premium_subscription_canceled_text', {
          when: this.formatDate(billingInfo.premiumEndsAt),
        }),
        free: I18n.t('builder.account.free_account_text'),
      }[billingStatus] || ''
    )
  }

  get messageTitle() {
    const { billingStatus, billingInfo } = this.props

    const { isSevenDaysLeft, isPostPremium } = this.checkPremiumConditions(
      billingInfo,
      this.calculateDifferenceInDays,
    )
    if (isSevenDaysLeft && billingStatus !== 'subscription') {
      return I18n.t('builder.account.seven_premium_days', {
        days: this.calculateDifferenceInDays(),
      })
    }
    if (isPostPremium && billingStatus !== 'subscription') {
      return I18n.t('builder.account.renew_premium_title')
    }
    if (billingStatus === 'free') {
      return I18n.t('builder.account.free_account')
    }
    return I18n.t('builder.account.premium_account')
  }

  formatDate(date) {
    return date ? formatDate(date, undefined, {}, true) : ''
  }

  handleUpgradeClick = () => {
    const { billingInfo } = this.props
    const { isSevenDaysLeft } = this.checkPremiumConditions(
      billingInfo,
      this.calculateDifferenceInDays,
    )

    goToBillingPlanPage({ upgradeUrl: this.props.billingInfo.upgradeUrl })

    if (isSevenDaysLeft) {
      trackInternalEvent('click_keep_premium', { label: 'account_settings' })
    } else {
      trackInternalEvent('click_upgrade')
    }
  }

  handleManageClick = () => {
    goToBillingPlanPage({ upgradeUrl: this.props.billingInfo.upgradeUrl })
    trackInternalEvent('click_manage_sub')
  }

  render() {
    const { billingStatus, billingInfo, hasPremiumPlusFeatures, config, hasPremiumFeatures } =
      this.props

    // A user can only cancel his plan when his subscription is active.
    const canCancel = billingStatus === 'subscription'

    // A user can upgrade their account, only when the current plan is free
    const isFreeUser = billingInfo.userType === 'free'

    const { isSevenDaysLeft, isPostPremium } = this.checkPremiumConditions(
      billingInfo,
      this.calculateDifferenceInDays,
    )

    const isResumeDistributionFeatureEnabled = config?.features.resumeDistribution

    const isNotSubscribedUser =
      (isSevenDaysLeft || isPostPremium) && billingStatus !== 'subscription'

    const getImageUrl = () => {
      const { billingStatus, hasPremiumPlusFeatures, config } = this.props
      if (
        (isSevenDaysLeft && billingStatus !== 'subscription') ||
        (!billingInfo.recurringAmount && hasPremiumPlusFeatures)
      ) {
        return crownUrl
      }
      if (isPostPremium && billingStatus !== 'subscription') {
        return crownDottedUrl
      }
      if (hasPremiumPlusFeatures) {
        return premiumPlusCrown
      }

      const subCancellationV4 = config?.features.subCancellationV4 || false
      if (!subCancellationV4) {
        return billingStatus !== 'free' ? premiumAccUrlOld : freeAccUrlOld
      }

      return billingStatus !== 'free' ? premiumAccUrl : freeAccUrl
    }

    const renderPlanAction = () => {
      return (
        <ChangePlanButton isSevenDaysLeft={isNotSubscribedUser}>
          {isNotSubscribedUser && (
            <PostPremiumButton onClick={this.handleUpgradeClick}>
              <CrownIcon />
              {isSevenDaysLeft
                ? I18n.t('builder.account.keep_premium')
                : I18n.t('builder.account.renew_premium')}
            </PostPremiumButton>
          )}
          {isFreeUser && (
            <ActionLink>
              <Button onClick={this.handleUpgradeClick}>
                {I18n.t('builder.account.upgrade_to_premium')}
              </Button>
            </ActionLink>
          )}
          {(!isNotSubscribedUser || !isSevenDaysLeft) &&
            !isFreeUser &&
            isResumeDistributionFeatureEnabled &&
            hasPremiumFeatures &&
            !hasPremiumPlusFeatures && (
              <ActionLink>
                <ManagePlanButton onClick={this.handleManageClick}>
                  {I18n.t('builder.account.manage_subscription')}
                </ManagePlanButton>
              </ActionLink>
            )}
          {canCancel && (
            <ActionLink>
              <RedButton
                as="a"
                href="/app/account/cancel-subscription"
                onClick={() => trackInternalEvent('visit_subscription_cancellation')}
              >
                {I18n.t('builder.account.cancel_subscription')}
              </RedButton>
            </ActionLink>
          )}
        </ChangePlanButton>
      )
    }

    return (
      <Section>
        <SectionTitle>
          {hasPremiumPlusFeatures
            ? I18n.t('builder.account.your_subscription')
            : I18n.t('builder.account.subscription')}
        </SectionTitle>
        <SectionInnerWithHeader>
          <SectionContent isSevenDaysLeft={isNotSubscribedUser}>
            <TitleImageNew
              isSevenDaysLeft={isNotSubscribedUser}
              src={getImageUrl()}
              alt={this.messageText}
            />
            <SectionBody>
              <SectionInnerTitle isSevenDaysLeft={isNotSubscribedUser}>
                {this.messageTitle}
              </SectionInnerTitle>
              <SectionText isSevenDaysLeft={isSevenDaysLeft}>{this.messageText}</SectionText>
            </SectionBody>
          </SectionContent>
          {renderPlanAction()}
        </SectionInnerWithHeader>
      </Section>
    )
  }
}

export default withConfig(SubscriptionSection)
