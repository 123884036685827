import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { Interview } from 'builder/modules/interview/types'
import { useUser } from 'builder/hooks/useUser'
import { selectors, actions } from 'builder/modules/interview/interviewModule'

import { PerformanceLoggerWithMainDsn as PerformanceLogger } from 'builder/services/PerformanceLogger'
import { useComponentWillMount } from 'builder/hooks/useComponentWillMount'
import { RestartModal } from '../DashboardView/Modals/RestartModal/RestartModal'
import { useInterviews } from '../../hooks/useInterviews'
import { useInterviewAnswer } from '../../hooks/useInterviewAnswer'
import { useInterview } from '../../hooks/useInterview'
import { Sidebar } from './components/Sidebar/Sidebar'
import { VideoPlayer } from './components/VideoPlayer/VideoPlayer'
import { Container } from './styles'
import { InterviewHistoryDialog } from './components/InterviewHistoryDialog/InterviewHistoryDialog'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedback`

export const FeedbackView = () => {
  const user = useUser()
  const hasPremiumFeatures = !!user?.hasPremiumFeatures
  const interviewId = useSelector(selectors.interviewId)
  const navigate = useNavigate()
  const { data: interview } = useInterview({ interviewId })
  const { data: allInterviews } = useInterviews(interview?.data_api_job_title_id)
  const questions = useMemo(() => (interview ? interview.questions : []), [interview])
  const [selectedInterview, setSelectedInterview] = useState<Interview>()
  const [showInterviewHistoryDialog, setShowInterviewHistoryDialog] = useState(false)
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0)

  const dispatch = useDispatch()

  const selectedQuestion = useMemo(
    () => questions[selectedQuestionIndex],
    [questions, selectedQuestionIndex],
  )
  const filteredInterviews = useMemo(
    () =>
      allInterviews?.filter(
        interview =>
          selectedQuestionIndex < interview.questions.length &&
          selectedQuestion &&
          interview.questions[selectedQuestionIndex].content === selectedQuestion.content &&
          interview.questions.some(question => question.answer),
      ),
    [allInterviews, interview, selectedQuestionIndex, selectedQuestion],
  )

  const { data: answer } = useInterviewAnswer(
    selectedInterview ? undefined : selectedQuestion?.id,
    true,
  )

  const [showSidebar, setShowSidebar] = useState(false)
  const handleNextQuestion = () => {
    setSelectedQuestionIndex((prevQuestionIndex: number) =>
      questions.length - 1 === prevQuestionIndex ? prevQuestionIndex : prevQuestionIndex + 1,
    )
  }

  const stopPerformance = useCallback(() => {
    PerformanceLogger.finish()
  }, [])

  useComponentWillMount(() => {
    PerformanceLogger.listen({
      name: 'interview-feedback-page',
    })
  })

  useEffect(() => {
    if (!interviewId) {
      stopPerformance()
      navigate('/interview-preparation/dashboard')
      return
    }
    dispatch(actions.stopVideoStream())
    dispatch(actions.stopAudioStream())
    trackInternalEvent('view_interview_report', { label: 'interview_prep' })

    return () => {
      stopPerformance()
    }
  }, [])

  const handlePrevQuestion = () => {
    setSelectedQuestionIndex(prevQuestionIndex =>
      prevQuestionIndex === 0 ? prevQuestionIndex : prevQuestionIndex - 1,
    )
  }

  return (
    <Container>
      <VideoPlayer
        stopPerformance={stopPerformance}
        toggleSidebar={() => setShowSidebar(prevShowSidebar => !prevShowSidebar)}
        selectedQuestionIndex={selectedQuestionIndex}
        setSelectedQuestionIndex={setSelectedQuestionIndex}
        onNextQuestion={handleNextQuestion}
        onPrevQuestion={handlePrevQuestion}
        answer={answer}
        showInterviewHistoryDialog={() => setShowInterviewHistoryDialog(true)}
        hasPremiumFeatures={hasPremiumFeatures}
        interview={interview}
        questions={questions}
        selectedQuestion={selectedQuestion}
      />
      <Sidebar
        showSidebar={showSidebar}
        questions={questions}
        selectedQuestionIndex={selectedQuestionIndex}
        selectedQuestion={selectedQuestion}
        onClose={() => setShowSidebar(false)}
        onNextQuestion={handleNextQuestion}
        onPrevQuestion={handlePrevQuestion}
        answer={answer}
      />
      {showInterviewHistoryDialog && hasPremiumFeatures && (
        <InterviewHistoryDialog
          open={showInterviewHistoryDialog}
          interviews={filteredInterviews}
          onRestartInterview={interview => setSelectedInterview(interview)}
          onClose={() => setShowInterviewHistoryDialog(false)}
        />
      )}

      {selectedInterview && interview && (
        <RestartModal onClose={() => setSelectedInterview(undefined)} interview={interview} />
      )}
    </Container>
  )
}
