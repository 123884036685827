import { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useI18n } from 'builder/hooks/useI18n'
import { Icon24 } from 'builder/components/Icon'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import Field from './Field'
import Button from 'builder/components/Button'
import { useFields } from 'builder/hooks/useFields'
import { EXTENSION_URL } from 'builder/modules/jobTracking'
import { CompanyAutosuggest } from 'builder/components/CompanyAutosuggest'
import { useConfig } from 'builder/hooks/useConfig'
import { trackInternalEvent } from '@rio/tracking'
import {
  Container,
  MobileClose,
  DesktopClose,
  Fields,
  FieldBar,
  Company,
  CompanyLogo,
  CompanyLabel,
  CompanyInput,
  Panel,
  DeleteButton,
  DeleteButtonIcon,
  Status,
  StatusIcon,
  StatusChevron,
  SaveButton,
  ExtensionPanel,
  ExtensionPanelContent,
  ExtensionPanelIcon,
  ExtensionPanelText,
  ExtensionPanelTitle,
  ExtensionPanelSubtitle,
  ExtensionPanelButtons,
  ExtensionPanelCloseButton,
  IrrelevantBox,
  IrrelevantBoxTitle,
  IrrelevantBoxSubtitle,
  TickIcon,
  IrrelevantButton,
  IrrelevantDeleteButton,
  Actions,
} from './styles'

const EditModal = ({ job, columnNames, onSubmit, onDelete, onClose, canInstallExtension }) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const config = useConfig()

  const [relevant, setRelevant] = useState(false)

  const {
    id,
    company,
    title,
    salary,
    link,
    location,
    description,
    notes,
    status,
    logo,
    isIrrelevant,
  } = job
  const extensionCanceledKey = 'EXTENSION_CANCELED'

  const [showExtensionPanel, setExtensionPanelVisibility] = useState(false)
  const [fields, handleUpdate] = useFields({
    company,
    logo,
    title,
    salary,
    link,
    location,
    description,
    notes,
    status,
    isIrrelevant,
  })

  const handleSuggestionSelect = (e, { suggestion }) => {
    for (const name in suggestion) {
      const target = { name, value: suggestion[name] }
      handleUpdate({ target })
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (relevant) {
      trackInternalEvent('mark_job_relevant')
      onSubmit({ isIrrelevant: false, id })
    } else {
      onSubmit({ ...fields, id })
    }
    onClose()
  }

  const handleCloseExtensionPanelClick = () => {
    setExtensionPanelVisibility(false)
    localStorage.setItem(extensionCanceledKey, true)
  }

  useEffect(() => {
    const isExtensionPanelCanceled = !!localStorage.getItem(extensionCanceledKey)
    const isExtensionPanelVisible = !isExtensionPanelCanceled && canInstallExtension
    setExtensionPanelVisibility(isExtensionPanelVisible)
  }, [canInstallExtension])

  useEffect(() => {
    job.isIrrelevant && trackInternalEvent('view_irrelevantjob_notification')
  }, [job.isIrrelevant])

  const Close = isPhone ? MobileClose : DesktopClose
  const CloseIcon = isPhone ? Icon24.Close : Icon24.CloseLarge

  const isSuperApp = config.features.superApp
  const isExtensionVisible = !isSuperApp && showExtensionPanel

  const handleUpdateNote = () => {
    trackInternalEvent('update_note')
  }

  return (
    <ModalOverlay
      overlayFadeDuration={isPhone ? 0 : 150}
      contentSlideDuration={isPhone ? 0 : 150}
      fullScreen={isPhone}
      onClose={onClose}
    >
      <Container onSubmit={handleSubmit}>
        {isExtensionVisible && (
          <ExtensionPanel>
            <ExtensionPanelContent>
              <ExtensionPanelIcon />
              <ExtensionPanelText>
                <ExtensionPanelTitle>
                  {i18n.t('builder.job_tracking.edit_modal.extension_panel.title')}
                </ExtensionPanelTitle>
                <ExtensionPanelSubtitle>
                  {i18n.t('builder.job_tracking.edit_modal.extension_panel.subtitle')}
                </ExtensionPanelSubtitle>
              </ExtensionPanelText>
            </ExtensionPanelContent>

            <ExtensionPanelButtons>
              <ExtensionPanelCloseButton onClick={handleCloseExtensionPanelClick}>
                {i18n.t('builder.job_tracking.edit_modal.extension_panel.close')}
              </ExtensionPanelCloseButton>
              <Button as="a" target="_blank" href={EXTENSION_URL} size="small">
                {i18n.t('builder.job_tracking.edit_modal.extension_panel.install')}
              </Button>
            </ExtensionPanelButtons>
          </ExtensionPanel>
        )}

        <Close onClick={onClose}>
          <CloseIcon />
        </Close>

        <Fields>
          <Company>
            <CompanyLogo size={48} alt={fields.company} src={fields.logo} />
            <CompanyLabel>{i18n.t('builder.job_tracking.edit_modal.company_label')}</CompanyLabel>

            <CompanyAutosuggest
              value={fields.company}
              onChange={handleUpdate}
              onSuggestionSelected={handleSuggestionSelect}
            >
              {inputProps => (
                <Fragment>
                  <CompanyInput
                    {...inputProps}
                    placeholder={i18n.t('builder.job_tracking.edit_modal.company_placeholder')}
                  />
                  <FieldBar />
                </Fragment>
              )}
            </CompanyAutosuggest>
          </Company>

          {job.isIrrelevant && (
            <IrrelevantBox>
              <IrrelevantBoxTitle>Is this job still relevant?</IrrelevantBoxTitle>
              <IrrelevantBoxSubtitle>
                The job hasn&apos;t been updated for quite some time. This job might be outdated and
                no longer relevant.
              </IrrelevantBoxSubtitle>
              <Actions>
                <IrrelevantButton onClick={() => setRelevant(true)}>
                  <TickIcon /> Still Relevant
                </IrrelevantButton>

                <IrrelevantDeleteButton onClick={() => onDelete(id)}>Delete</IrrelevantDeleteButton>
              </Actions>
            </IrrelevantBox>
          )}

          <Field name="title" value={fields.title} onChange={handleUpdate} />
          <Field name="salary" value={fields.salary} onChange={handleUpdate} />
          <Field name="link" value={fields.link} onChange={handleUpdate} />
          <Field name="location" value={fields.location} onChange={handleUpdate} />

          <Field multiline name="description" value={fields.description} onChange={handleUpdate} />
          <Field
            multiline
            name="notes"
            value={fields.notes}
            onChange={handleUpdate}
            onBlur={handleUpdateNote}
          />
        </Fields>

        <Panel>
          <DeleteButton onClick={() => onDelete(id)}>
            <DeleteButtonIcon />
            {i18n.t('builder.job_tracking.edit_modal.delete')}
          </DeleteButton>

          <Status>
            <StatusIcon status={fields.status} />
            {i18n.t(`builder.job_tracking.columns.${fields.status}`)}
            <StatusChevron />

            <select name="status" value={fields.status} onChange={handleUpdate}>
              {columnNames.map(name => (
                <option key={name} value={name}>
                  {i18n.t(`builder.job_tracking.columns.${name}`)}
                </option>
              ))}
            </select>
          </Status>

          <SaveButton>{i18n.t('builder.job_tracking.edit_modal.submit')}</SaveButton>
        </Panel>
      </Container>
    </ModalOverlay>
  )
}

EditModal.propTypes = {
  onMove: PropTypes.func,
  job: PropTypes.object,
  columnNames: PropTypes.array,
  canInstallExtension: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

EditModal.defaultProps = {
  job: {},
  columnNames: [],
}

export default EditModal
