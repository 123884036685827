import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon24 } from 'builder/components/Icon'

export const Banner = styled.div<{ animate: boolean }>(({ animate }) => {
  return css`
    width: 100%;
    height: 100%;
    background: ${Colors.Indigo10};
    display: flex;
    border-radius: 6px;
    margin-bottom: 48px;
    cursor: pointer;
    align-items: center;

    &:hover {
      background: ${Colors.Indigo110};
    }

    ${animate &&
    css`
      opacity: 0;
      transition: opacity 300ms ease-in;
      &:hover {
        background: white;
      }
    `}
    ${Media.Tablet`
        margin-bottom: 32px;
      `}
  `
})

export const ArrowIcon = styled(Icon24.ChevronRight)`
  fill: ${Colors.Neutral40};
  margin: auto;
  margin-right: 16px;
  width: 24px;
  height: 24px;

  ${Banner}:hover & {
    fill: ${Colors.Indigo50};
  }

  ${Media.Phone`
    margin-right: 11.5px;
  `}
`
export const ContentWrapper = styled.div``

export const BannerImage = styled.img`
  width: 125px;
  height: 86px;
  margin: 7px 9px 3px 6px;
  ${Media.Phone`
    width: 108px;
    height: 75px;
    margin: 0px 8.5px 0px 7px;
  `}
`

export const BannerTitle = styled.p`
  ${Typography.Body};
  ${FontWeights.DemiBold};
  color: ${Colors.Indigo90};
  margin-top: 16px;

  ${Banner}:hover & {
    color: ${Colors.Indigo70};
  }

  ${Media.Phone`
    margin-top: 12px;
  `}
`

export const BannerContent = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  margin-top: 4px;
  margin-bottom: 16px;
  ${Banner}:hover & {
    color: ${Colors.Indigo50};
  }

  ${Media.Phone`
      margin-bottom: 18px;
    `}
`
