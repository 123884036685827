import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { Icon24, Icon20 } from 'builder/components/Icon'
import Avatar from 'builder/components/Avatar'
import Button from 'builder/components/Button'
import StatusIconComponent from 'builder/components/JobTrackingStatusIcon'

export const Container = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 544px;
  max-height: calc(100vh - 64px);
  background-color: ${Colors.White};
  border-radius: 6px;
  z-index: 999;

  ${Media.Phone`
    height: 100%;
    max-width: none;
    max-height: none;
    border-radius: 0;
  `};
`

export const Close = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
`

export const DesktopClose = styled(Close)`
  top: 0;
  right: 0;
  padding: 16px;
  color: ${Colors.Neutral40};
  transition: color 0.15s;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const MobileClose = styled(Close)`
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${Colors.Neutral30};
  color: ${Colors.White};
`

export const Fields = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${Media.Phone`
    padding: 20px;
    margin-bottom: auto;
  `};
`

export const FieldContainer = styled.label`
  position: relative;
  width: ${props => (props.multiline ? '100%' : 'calc(50% - 12px)')};
  margin-bottom: 16px;

  ${Media.Phone`
    width: 100%;
  `};

  &:last-child {
    margin-bottom: 0;
  }
`

export const FieldLabel = styled.span`
  position: absolute;
  top: 8px;
  left: 0;
  transform-origin: 0 0;
  transform: ${props => (props.collapsed ? 'translateY(-8px) scale(0.74)' : 'none')};
  color: ${Colors.Neutral50};
  pointer-events: none;
  transition: transform 0.15s;
  will-change: transform;
`

export const FieldInputBase = styled.input`
  display: block;
  width: 100%;
  margin: 0;
  padding: 16px 32px 8px 0;
  border: none;
  background: none;
  color: ${Colors.Neutral90};
  caret-color: ${Colors.Blue60};
  resize: none;

  &::placeholder {
    color: ${Colors.Neutral50};
    opacity: 1;
  }
`

export const FieldInput = styled(FieldInputBase)`
  opacity: 0;
  transition: opacity 0.15s;

  textarea& {
    min-height: 89px;
  }

  ${props =>
    props.visible &&
    css`
      opacity: 1;
    `};
`

export const FieldBar = styled.div`
  height: 1px;
  background-color: ${Colors.Neutral15};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: ${Colors.Blue50};
    transform-origin: 20% 0;
    transform: scale(0);
    opacity: 0;
    transition: opacity 0.15s, transform 0.15s 0.15s;
  }

  input:focus + &:after {
    transform: scale(1);
    opacity: 1;
    transition: opacity 0.15s, transform 0.15s;
  }
`

export const FieldIconContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  color: ${Colors.Neutral40};

  a& {
    color: ${Colors.Blue50};
    transition: color 0.15s;
  }

  a&:hover {
    color: ${Colors.Blue70};
  }

  & svg {
    display: block;
  }
`

export const Company = styled(FieldContainer)`
  width: 100%;
`

export const CompanyLogo = styled(Avatar)`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

export const CompanyLabel = styled.div`
  position: absolute;
  top: 4px;
  left: 64px;
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
  pointer-events: none;
`

export const CompanyInput = styled(FieldInputBase)`
  padding: 20px 40px 24px 64px;
  ${Typography.S};

  ${Media.Phone`
    padding-bottom: 20px;
  `};
`

export const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 16px 24px;
  border-radius: 0 0 6px 6px;
  background-color: ${Colors.Neutral10};

  ${Media.Phone`
    padding: 20px;
  `};
`

export const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: ${Colors.Neutral60};
  }
`

export const Status = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  ${Typography.Caption};
  font-weight: 600;
  transition: color 0.15s;
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue50};
  }

  & select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    outline: none;
    cursor: pointer;
  }
`

export const StatusIcon = styled(StatusIconComponent)`
  margin-right: 8px;
`

export const StatusChevron = styled(Icon20.ChevronDown)`
  color: ${Colors.Neutral40};
  transition: color 0.15s;

  ${Status}:hover & {
    color: ${Colors.Blue50};
  }
`

export const DeleteButtonIcon = styled(Icon24.Trash)`
  margin-right: 4px;
`

export const SaveButton = styled(Button).attrs({ type: 'submit', size: 'small' })`
  margin-left: 20px;
`

export const ExtensionPanel = styled.div`
  position: absolute;
  width: 100%;
  padding: 16px;
  left: 0;
  top: -96px;
  background-color: ${Colors.Neutral100};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;

  ${Media.Phone`
    display: none;
  `};
`

export const ExtensionPanelContent = styled.div`
  display: flex;
  align-items: center;
`

export const ExtensionPanelIcon = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 16px;
  background-image: url(${require('images/builder/job-tracker/chrome.png')});
  background-size: contain;
`

export const ExtensionPanelText = styled.div``

export const ExtensionPanelTitle = styled.div`
  ${Typography.Caption};
  font-weight: 600;
  color: ${Colors.White};
`

export const ExtensionPanelSubtitle = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  max-width: 240px;
`

export const ExtensionPanelButtons = styled.div`
  display: flex;
  align-items: center;
`

export const ExtensionPanelCloseButton = styled(Button).attrs({
  theme: 'ghost',
  size: 'small',
  type: 'button',
})`
  margin-right: 20px;
  border: 1px solid ${Colors.Blue50};
  color: ${Colors.Blue50};
  box-shadow: none;

  &:hover {
    border-color: ${Colors.Blue70};
    box-shadow: none;
    color: ${Colors.Blue70};
  }
`

export const IrrelevantBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  max-width: 544px;
  height: 192px;
  margin: 15px 0;
  background-color: ${Colors.Neutral5};
  border: 1px solid ${Colors.Neutral10};
  border-radius: 6px;
  z-index: 999;
`

export const IrrelevantBoxTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  color: #000000;
`

export const IrrelevantBoxSubtitle = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral70};
`

export const TickIcon = styled(Icon20.Tick)`
  width: 20px;
  height: 20px;
`

export const Actions = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 20px;
  > :not(:last-child) {
    margin-right: 8px;
  }
  ${Media.Tablet`
    flex-direction: column;
    > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  `}
`

export const IrrelevantButton = styled(Button)`
  ${FontWeights.Medium};
  ${Typography.Caption};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px 8px 16px;
  gap: 4px;
  width: 144px;
  height: 36px;
  background: ${Colors.Blue50};
  border-radius: 4px;
`

export const IrrelevantDeleteButton = styled(Button)`
  ${FontWeights.Medium};
  ${Typography.Caption};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  width: 82px;
  height: 36px;
  background: ${Colors.Red10};
  color: ${Colors.Red50};
  border-radius: 4px;
  &:hover {
    background: ${Colors.Red50};
    color: ${Colors.White};
  }
`
