import React from 'react'
import { useDispatch } from 'react-redux'
import { actions, ConfigScopesEnum } from 'builder/modules/init'
import { useConfig } from 'builder/hooks/useConfig'

type ConfigScopeProps = {
  children: React.ReactElement
  scope: ConfigScopesEnum
}

export const ConfigScope = ({ scope, children }: ConfigScopeProps) => {
  const config = useConfig(scope)
  const dispatch = useDispatch()
  const isReady = !!config

  React.useEffect(() => {
    if (!isReady) {
      dispatch(actions.fetchConfig({ scope }))
    }
  }, [dispatch, isReady, scope])

  return config ? children : null
}
