import { useCallback, useState } from 'react'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { UserDocumentType } from '@rio/types'
import { useI18n } from 'builder/hooks/useI18n'
import { actions, typeToName } from 'builder/modules/panel'
import {
  DocumentCreateContainer,
  DocumentPreviewContainer,
  DocumentContent,
  DocumentNote,
  DocumentCreatePreview,
  DocumentCreateButton,
  DocumentCreateTitle,
} from './styles'
import { CreateDocumentPopup } from './CreateDocumentPopup/CreateDocumentPopup'

type Ref = React.ForwardedRef<HTMLDivElement>

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  type: UserDocumentType
}

export const CreateDocument = React.forwardRef(({ type, ...rest }: Props, ref: Ref) => {
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const [popupOpen, setPopupOpen] = useState(false)
  const isTypeResume = type === 'resume'

  const handleCreate = useCallback(() => {
    dispatch(actions.createDocument({ type }))
    trackMarketingEvent('Dashboard', `Create ${typeToName(type)}`)
    trackInternalEvent(`create_${type}`)
  }, [dispatch, type])

  const handleClick = useCallback(() => {
    if (isTypeResume) {
      setPopupOpen(prevState => !prevState)
    } else {
      handleCreate()
    }
  }, [isTypeResume, setPopupOpen, handleCreate])

  if (popupOpen) {
    trackInternalEvent('click_new_resume_card')
  }

  return (
    <>
      <DocumentCreateContainer
        onClick={handleClick}
        {...rest}
        ref={ref}
        isClicked={isTypeResume ? popupOpen : false}
      >
        <DocumentPreviewContainer>
          <DocumentCreatePreview>
            <DocumentCreateButton />
          </DocumentCreatePreview>
        </DocumentPreviewContainer>

        <DocumentContent>
          <DocumentCreateTitle>
            {i18n.t(`builder.panel.documents.new.${type}.title`)}
          </DocumentCreateTitle>

          <DocumentNote>{i18n.t(`builder.panel.documents.new.${type}.text`)}</DocumentNote>
        </DocumentContent>
      </DocumentCreateContainer>
      {popupOpen && <CreateDocumentPopup type="resume" />}
    </>
  )
})
