import { memo } from 'react'
import { Skeleton } from '../JobSearchResult/styles'
import { Container, Content, Title, Company, Position, Metas } from './styles'

export const JobItemSkeleton = memo(() => {
  return (
    <Container isActive={false}>
      <Skeleton width={48} height={48} />
      <Content>
        <Title>
          <Company>
            <Skeleton width={100} height={10} />
          </Company>
          <Position>
            <Skeleton width={230} height={14} marginTop={12} />
          </Position>
        </Title>
        <Metas id="metas">
          <Skeleton width={100} height={30} />
          <Skeleton width={100} height={30} />
          <Skeleton width={100} height={30} />
        </Metas>
      </Content>
    </Container>
  )
})
