import { stringifyUrl } from 'query-string'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import { ConfigScopesEnum } from 'builder/modules/init'

import { BillingItemList } from './BillingItemList'
import {
  BillingFormContainer,
  CTAPrimary,
  Disclaimer,
  PaymentMethodIconsContainer,
  PaymentMethodsContainer,
  TopBadgeText,
  TopBadgeTextContainer,
  WeAcceptLabel,
} from './styles'
import { BillingItemData } from './BillingItem'

type Props = {
  plans: Array<BillingItemData>
  ctaLabel: string
  disclaimer: string
  topBadgeText?: string
  afterCTAClick?: () => void
  shouldShowTopBadge: boolean
  isPaidUser: boolean
}

export const BillingForm = ({
  plans,
  ctaLabel,
  disclaimer,
  isPaidUser,
  topBadgeText,
  shouldShowTopBadge,
}: Props) => {
  const { i18n } = useI18n()
  const config = useConfig()
  const countryConfig = useConfig(ConfigScopesEnum.country)

  const redirectToPayments = () => {
    trackInternalEvent('credits_exceeded')
    trackInternalEvent('show_upsell_payment_page')

    const plansPageUrl = stringifyUrl({
      url: '/app/billing/payment',
      query: {
        plan:
          isPaidUser && config?.features.superApp
            ? '20_auto_appy_credits_promo'
            : '20_credits_autoapply',
        on_success_redirect: '/app/job-tracking?show_credit_modal=true',
      },
    })

    window.location.assign(plansPageUrl)
  }

  return (
    <BillingFormContainer>
      {shouldShowTopBadge && topBadgeText && (
        <TopBadgeTextContainer>
          <TopBadgeText>{topBadgeText}</TopBadgeText>
        </TopBadgeTextContainer>
      )}

      <BillingItemList items={plans} />

      <CTAPrimary onClick={redirectToPayments}>{ctaLabel}</CTAPrimary>

      <PaymentMethodsContainer>
        <WeAcceptLabel>
          {i18n.t('builder.resume_distribution.upsell_modal.we_accept')}
        </WeAcceptLabel>
        <PaymentMethodIconsContainer>
          {countryConfig?.paymentMethods?.map((method, index) => (
            <img key={index} src={method.iconUrl} alt={method.title} title={method.title} />
          ))}
        </PaymentMethodIconsContainer>
      </PaymentMethodsContainer>

      <Disclaimer>*{disclaimer}</Disclaimer>
    </BillingFormContainer>
  )
}
