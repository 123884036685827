import { useCallback, useRef } from 'react'
import { PerformanceLoggerWithMainDsn as PerformanceLogger } from 'builder/services/PerformanceLogger'

interface Config {
  enabled: boolean
  metricKey: string
  operation?: string
  tags: any
  successCallBack?: () => void
}

export const usePerformanceOnce = ({
  metricKey,
  operation,
  tags,
  successCallBack,
  enabled = false,
}: Config) => {
  const onceRef = useRef({ request: false, response: false })

  const registerOncePerformance = useCallback(() => {
    const once = onceRef.current
    if (once.request || !enabled) {
      return
    }
    onceRef.current = { request: true, response: false }
    PerformanceLogger.operationFull({
      key: metricKey,
      op: operation || metricKey,
      tags,
    })
  }, [])

  const stopRegisterPerformance = useCallback(async () => {
    const once = onceRef.current

    if (!once.request || once.response || !enabled) {
      return
    }

    onceRef.current = { request: true, response: true }

    PerformanceLogger.operationEnd(metricKey)
    successCallBack && successCallBack()
  }, [])
  return { registerOncePerformance, stopRegisterPerformance }
}
