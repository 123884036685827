import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Button, { CircleButton } from 'builder/components/Button'
import { fadeIn } from 'builder/styles/keyframes'

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 680px;
  max-height: 620px;
  padding-top: 48px;
  background-color: ${Colors.Neutral10};
  text-align: center;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${Media.Phone`
    border-radius: 0;
    max-width: 100%;
    padding-top: 77px;
    height: 100%;
    max-height: 100%;
  `};
`

export const Title = styled.div`
  ${Typography.S};
  font-weight: 600;
  margin-bottom: 12px;
  flex-shrink: 0;

  ${Media.Phone`
    margin: 0 52px 12px;
  `}
`

export const Text = styled.div`
  ${Typography.Body};
  max-width: 400px;
  margin: 0 auto;
  flex-shrink: 0;

  ${Media.Phone`
    margin: 0 20px;
    max-width: 100%;
  `}
`

export const DownloadButton = styled(Button)`
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin: 0 auto;
  transform: translateX(-50%);
  z-index: 1;

  ${Media.Phone`
    ${Typography.Caption};
    padding: 8px 12px;
    bottom: 72px;
  `}
`

export const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  color: ${Colors.Neutral30};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${Colors.Neutral40};
  }

  ${Media.Phone`
    width: 32px;
    height: 32px;
    top: 20px;
    padding: 0;
    right: 20px;
    border-radius: 50%;
    color: ${Colors.White};
    background-color: ${Colors.Neutral30};

    &:hover {
      color: ${Colors.White};
      background-color: ${Colors.Neutral40};
    }
  `}
`

export const SliderContent = styled.div`
  height: 464px;
  width: 100%;
  animation: ${fadeIn} 0.3s ease;
  animation-fill-mode: forwards;

  ${Media.Phone`
    height: auto;
  `}

  & .swiper-container {
    position: relative;
    overflow: visible;
  }

  & .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;
  }
`

export const SliderPlaceholder = styled.div`
  height: 464px;
  width: 100%;

  ${Media.Phone`
    height: auto;
  `}
`

export const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const TemplatesButton = styled(CircleButton)`
  position: absolute;
  z-index: 2;
  top: calc(50% + 24px);

  ${Media.Phone`
    display: none;
  `}
`

export const TemplatesLeftButton = styled(TemplatesButton).attrs({
  direction: 'left',
})`
  left: calc(50% - 210px - 24px);

  ${Media.Tablet`
    left: 24px;
  `}
`

export const TemplatesRightButton = styled(TemplatesButton)`
  right: calc(50% - 210px - 24px);

  ${Media.Tablet`
    right: 24px;
  `}
`

export const TemplatePreview = styled.div`
  width: 420px;
  height: 588px;
  border-radius: 6px;
  position: relative;
  margin: 0 20px;
  background: ${Colors.Neutral15} url(${require('images/builder/resume-editor/resume-loader.svg')})
    no-repeat center;
  background-size: cover;
  overflow: hidden;
  animation-fill-mode: forwards;
  box-shadow: 0px 12px 24px rgba(15, 20, 31, 0.04), 0px 24px 40px rgba(15, 20, 31, 0.04);

  & img {
    width: 100%;
  }

  ${Media.Phone`
    width: 242px;
    height: 320px;
    margin: 0 8px;
    margin-bottom: 32px;
    box-shadow: 0px 8px 16px rgba(15, 20, 31, 0.04), 0px 16px 32px rgba(15, 20, 31, 0.04);
  `}
`

export const Dots = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: center;
  height: 4px;
`

export const Dot = styled.div<{ isActive?: boolean }>`
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: ${props => (props.isActive ? Colors.Blue50 : Colors.Neutral30)};
  transition: background-color 0.3s ease;

  &:not(:last-child) {
    margin-right: 6px;
  }
`

export const TemplateInfo = styled.div`
  margin-bottom: 20px;
`

export const TemplateName = styled.div`
  ${Typography.Body};
  font-weight: 600;
  transform: translateY(5px);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transition-delay: 0;

  .swiper-slide-active &,
  .swiper-slide-duplicate-active & {
    transform: translateY(0);
    transition-delay: 0.25s;
    opacity: 1;
  }
`

export const UsersCount = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
  transform: translateY(5px);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transition-delay: 0;

  .swiper-slide-active &,
  .swiper-slide-duplicate-active & {
    transform: translateY(0);
    transition-delay: 0.27s;
    opacity: 1;
  }
`
