import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { Container } from 'builder/components/Container'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'
import Sizes from 'builder/styles/sizes'
import Shadows from 'builder/styles/shadows'
import AutoSuggestField from '../AutoSuggestField'
import { SearchBarType } from './SearchBar'

export const Z_INDEX_SEARCH_BAR = 547
export const TOP_POSITION_SEARCH_BAR = 81

export const Blocker = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${Colors.Neutral10};
  opacity: 0.5;
  z-index: 546;
`
export const FixedContainer = styled.div<{
  $searchBarType: SearchBarType
  $isDashboardV2: boolean
  $hasMissingFields: boolean
}>`
  top: ${TOP_POSITION_SEARCH_BAR}px;
  z-index: ${Z_INDEX_SEARCH_BAR};
  margin-bottom: 32px;
  ${({ $searchBarType }) => {
    if ($searchBarType?.includes('scrollable')) {
      return css`
        position: fixed;
        width: 100%;
      `
    }
  }}

  ${({ $isDashboardV2 }) => Media.AboveTablet`
    position: sticky;
    top: ${$isDashboardV2 ? 81 : 5}px;
  `}

  ${({ $hasMissingFields }) => $hasMissingFields && `background: white`}
`

export const WidthContainer = styled(Container)<{ $searchBarType?: SearchBarType }>`
  display: flex;
  ${Media.Tablet`
    flex-direction: column;
  `}

  ${({ $searchBarType }) => {
    if ($searchBarType?.includes('scrollable')) {
      return css`
        ${Shadows.lightWithBorder.low};
      `
    }
  }}
`

export const CenteredTextMessage = styled(WidthContainer)`
  padding: 10px 0;
  justify-content: center;
  color: ${Colors.Red40};
  background: ${Colors.White};
  ${Media.Tablet`
    margin-bottom: 0;
    text-align: center;
  `}
`

export const Bar = styled.div`
  border-radius: 12px;
  display: flex;
  width: 100%;
  background-color: ${Colors.White};
  height: 64px;
  margin: 0 auto;
  position: relative;

  ${Media.Tablet`
  height: unset;
`}
`
export const SearchButton = styled(Button)<{ $searchBarType: SearchBarType }>`
  flex: 1;
  ${Media.AboveTablet`
    margin-left: ${Sizes.S};
  `}

  ${Media.Tablet`
    width: 100%;
  `}
`

export const Fields = styled.div<{ $searchBarType: SearchBarType }>`
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 8px 20px -4px rgba(15, 56, 113, 0.12);
  border-radius: 8px;
  padding: ${Sizes['2XS']};

  ${Media.AboveTablet`
    background-color: ${Colors.White};
    position: absolute;
    left: -10px;
    right: -10px;
    height: 64px;
    
  `}

  ${({ $searchBarType }) => Media.Tablet`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    ${
      $searchBarType === 'scrollable_expanded' &&
      `
        & ${SearchButton} {
          display: block;
        }
    `
    }
  `}
`

const defaultMobileTable = css`
  ${Media.Tablet`
  width : 100%;
  `}
`
export const MajorAutoSuggestField = styled(AutoSuggestField)`
  width: 460px;
  ${defaultMobileTable};
`

export const MinorAutoSuggestField = styled(AutoSuggestField)`
  width: 224px;
  ${defaultMobileTable}
`

export const Separator = styled.div`
  background-color: ${Colors.Neutral20};
  height: 24px;
  width: 1px;
  margin: auto 5px;
  flex-shrink: 0;
  ${Media.Tablet`
    margin: 0 16px;
    background-color: ${Colors.Neutral20};
    height: 1px;
    width: 100%;
    display: flex;
  `}
`
