import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Icon from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 44px;

  ${Media.Phone`
    margin-top: 20px;
  `}
`

export const StartImprovingButton = styled(Button)``

export const Content = styled.div`
  /* Desktop and Tablet/Caption • Regular */
  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Neutral60};
`

export const Title = styled.div`
  /* Desktop and Tablet/Subhead • DemiBold */
  ${Typography.Subhead}
  ${FontWeights.DemiBold}
  margin-bottom: 12px;

  ${Media.Phone`
  ${Typography.S}
  `}
`

export const LoadingButton = styled(Button)`
  float: right;
  width: 119px;
  margin-top: 32px;

  ${Media.Phone`
    margin-top: 20px;

  `}
`

export const LoadingIcon = styled(Icon.Spinner)`
  color: ${Colors.White};
  animation: ${rotation} 0.5s linear infinite;
`
