import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'
import { FloatingHelperButtonContainer } from 'builder/components/FloatingCIOHelperButton'
import { FloatingModalCloseButton, FloatingModalContent } from 'builder/components/HelperModal'
import Media from 'builder/styles/media'
import Spinner from 'builder/components/Spinner'

export const FloatingButtonContainer = styled(FloatingHelperButtonContainer)`
  width: auto;
  padding: ${Sizes.S} ${Sizes.M} ${Sizes.S} ${Sizes.S};
  background-color: ${props => (props.isHovering ? Colors.Indigo20 : Colors.Indigo10)};
`

export const FloatingButtonTextContainer = styled.div`
  display: flex;
  gap: ${Sizes.XS};
  align-items: center;
  position: relative;
`

export const FloatingButtonText = styled.div`
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const FloatingButtonSpinner = styled(Spinner)`
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
`

export const ModalContent = styled(FloatingModalContent)`
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`

export const CloseModalButton = styled(FloatingModalCloseButton)`
  color: ${Colors.Neutral50};
  cursor: pointer;

  ${Media.Hover`
    &:hover {
      color: ${Colors.Blue50};
      background-color: ${Colors.Blue10};
    }
  `}
`

export const ModalImage = styled.img`
  height: 235px;
  width: 100%;
  object-fit: cover;
`

export const ModalBody = styled.div`
  display: flex;
  flex-flow: column;
  padding: ${Sizes.S} ${Sizes.M} ${Sizes.M};
  gap: ${Sizes.S};
`

export const ModalTextContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${Sizes['3XS']};
  color: ${Colors.Neutral100};
`

export const ModalTitle = styled.div`
  ${Typography.Subhead};
  ${FontWeights.DemiBold};
  display: flex;
  gap: ${Sizes['2XS']};
  align-items: center;
`

export const ModalDescription = styled.div`
  ${Typography.Caption};

  b {
    ${FontWeights.DemiBold};
  }
`

export const ModalButtonsContainer = styled.div`
  display: flex;
  gap: ${Sizes.XS};
`

export const ModalSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
`

export const InsightsImage = styled.img`
  width: 32px;
  flex: 0 0 32px;
`

export const InsightsReadyBadgeImage = styled.img`
  width: 24px;
  position: absolute;
  left: 18px;
  top: -7px;
`
