import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import BuilderIcon, { Icon20 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'

export const Container = styled.li<{ isActive: boolean }>`
  cursor: pointer;
  background-color: ${Colors.White};
  padding: 24px;
  border-radius: 8px;
  display: flex;

  box-shadow: 0px 0px 1px ${hexToRgba(Colors.Blue80, 0.32)},
    // eslint-disable-next-line max-len
    0px 1px 2px ${hexToRgba(Colors.Blue80, 0.12)},
    0px 2px 4px ${hexToRgba(Colors.Blue80, 0.08)};

  ${p =>
    p.isActive &&
    css`
      box-shadow: 0 0 0 2px ${Colors.Blue50};
    `}
`

export const Content = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Logo = styled.div<{ src?: string }>`
  flex-shrink: 0;
  border: 1px solid ${Colors.Neutral15};
  border-radius: 8px;
  width: 48px;
  height: 48px;
  background-image: url(${p => `"${p.src}"`});
  background-position: center;
  background-size: contain;
  border-radius: var(--Spacing-2XS, 8px);
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const Company = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CompanyName = styled.p`
  ${Typography.Body}
  color: ${Colors.Neutral50};
`

export const Position = styled.span`
  ${Typography.Body}
  ${FontWeights.DemiBold}
  margin-right: 4px;
`

export const Metas = styled.div`
  display: flex;
  font-size: 17px;
  margin-top: 16px;
  color: ${Colors.Neutral50};
  flex-wrap: wrap;

  > :not(:last-child) {
    margin-right: 12px;
  }

  svg {
    margin-right: 4px;
  }
`

export const NotificationContainer = styled.div<{ $show: boolean }>`
  display: flex;
  position: fixed;
  justify-content: center;
  position: fixed;
  bottom: 24px;
  width: fit-content;
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
  z-index: 999;
  background-color: ${Colors.Neutral100};
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -1px rgba(15, 20, 30, 0.16), 0px 8px 20px -4px rgba(15, 20, 30, 0.24);
  left: 50%;
  transform: translate(-50%);

  ${Media.Phone` 
    bottom: 84px;
    width: max-content;
    flex-direction: column;
    margin: ${Sizes.XS} 0;
  `}
`

export const SuccessIconContainer = styled.div`
  padding: 4px;
`

export const NotificationText = styled.p`
  color: ${Colors.White};
  ${Typography.Body};
`

export const SuccessIcon = styled(Icon20.SuccessIcon)`
  width: 16px;
  height: 16px;
`

export const MessageContainer = styled.div`
  gap: 8px;
  display: flex;
  border-right: 1px solid ${Colors.Neutral50};
  align-items: center;
  padding: ${Sizes.XS} ${Sizes.S};
`

export const LinkOpenButton = styled.div`
  border: none;
  padding: 14px ${Sizes.S};
`

export const Open = styled.span`
  color: ${Colors.White};
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  cursor: pointer;
`

export const HeartIconOutline = styled.img`
  width: 15px;
`

export const ActionButton = styled.div<{ isDisabled?: boolean }>`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};

  position: relative;
  transition: all 0.1s ease;

  ${props =>
    props.isDisabled
      ? null
      : css`
          :hover {
            background: ${Colors.Neutral10};
          }
        `}
`

export const HeartIcon = styled(BuilderIcon.Heart)`
  width: 20px;
  height: 20px;
  color: ${Colors.Neutral50};
`
