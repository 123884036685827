import styled, { css, keyframes } from 'styled-components'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from '../Icon'

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Heading = styled.span`
  color: ${Colors.White};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.6px;
  border-radius: 4px;
  background: ${Colors.Indigo40};
  text-transform: uppercase;
  position: absolute;
  top: -10px;
  left: 74px;
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${Colors.Indigo60};
  }
`

export const GenerateButton = styled(Button)`
  padding: 12px 24px 12px 20px;
  gap: 4px;
  background: ${Colors.Indigo50};
  color: ${Colors.White};
  ${Typography.Body};
  ${FontWeights.Medium};
  ${Media.Phone`
    ${Typography.Caption};
    padding: 7px 12px 7px 8px;
    width: 100%;
    margin-top: 10px;
    height: 34px;
  `}
  &:hover {
    background-color: ${Colors.Indigo60};
  }
`

const Banner = styled.div`
  background: ${Colors.Indigo10};
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${Media.Phone`
    flex-direction: column;
    align-items: start;
  `}

  &:hover ${GenerateButton} {
    background-color: ${Colors.Indigo60};
  }

  &:hover ${Heading} {
    background: ${Colors.Indigo60};
  }

  &:hover {
    background: rgba(219, 222, 255, 0.6);
  }
`

export const Content = styled.p`
  color: ${Colors.Indigo80};
  ${Typography.Caption};
  ${Media.Phone`
    ${Typography.Tiny};
  `}
`

export const FeaturedIcon = styled(Icon20.Featured)<{ showAnimation: boolean }>(
  ({ showAnimation }) => {
    return showAnimation
      ? css`
          animation: ${rotateAnimation} 1s infinite linear;
        `
      : ``
  },
)

export const BannerImage = styled.img`
  width: 47px;
  transition: opacity 0.1s ease-out;

  ${Media.Phone`
    width: 46px;
  `}
`

export const ImageContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  position: relative;
`

export const ImageDefault = styled(BannerImage)`
  opacity: 1;

  ${Media.Hover`
    ${Banner}:hover & {
      opacity: 0;
    }
  `}

  ${Media.Phone`
    opacity: 0;
  `}
`

export const ImageHovered = styled(BannerImage)`
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;

  ${Media.Hover`
    ${Banner}:hover & {
      opacity: 1;
    }
  `}

  ${Media.Phone`
    opacity: 1;
  `}
`

export const BadgeBanner = styled(Banner)`
  padding: 16px;
`

export const BadgeInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`
