import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { QuestionTemplate } from 'builder/modules/interview/types'
import ErrorLogger from 'builder/services/ErrorLogger'
import { POLLING_REFRESH_INTERVAL_IN_MILLIS, QUESTION_REGULAR_POLL_INTERVAL } from '../constants'
import { InitialRequest } from '../components/DashboardView/DashboardView'
import { usePerformanceOnce } from './usePerformanceOnce'

interface Params {
  jobTitleId?: number
  questionType?: 'background'
  limit?: number
  refetchIntervalPredicate?: (data?: QuestionTemplate[]) => boolean
  setInitialRequest?: React.Dispatch<React.SetStateAction<InitialRequest | undefined>>
  enabledPerformanceMetric?: boolean
}

export const useQuestionTemplate = ({
  jobTitleId,
  questionType,
  limit,
  refetchIntervalPredicate,
  setInitialRequest,
  enabledPerformanceMetric = false,
}: Params) => {
  const { registerOncePerformance, stopRegisterPerformance } = usePerformanceOnce({
    enabled: enabledPerformanceMetric,
    metricKey: `questions-library-request`,
    tags: { jobTitleId },
    successCallBack: () => {
      if (setInitialRequest) {
        setInitialRequest(prevState =>
          prevState === undefined ? undefined : { ...prevState, questionsLibrary: true },
        )
      }
    },
  })
  return useQuery(
    ['useQuestionTemplate', [jobTitleId, questionType]],
    async () => {
      registerOncePerformance()
      const response = await baseClient.get<QuestionTemplate[]>(
        `/interview_prep/question_templates`,
        {
          params: {
            data_api_job_title_id: jobTitleId,
            question_type: questionType,
            number_of_questions: limit,
          },
        },
      )

      return response.data.sort((a, b) => Number(a.used) - Number(b.used))
    },
    {
      onError: error => {
        ErrorLogger.log(error)
      },
      enabled: !!jobTitleId,
      refetchInterval: (data?: QuestionTemplate[]) => {
        if (refetchIntervalPredicate && refetchIntervalPredicate(data)) {
          stopRegisterPerformance()
          return false
        }
        if (data && data.length > 0) {
          return QUESTION_REGULAR_POLL_INTERVAL
        }
        return POLLING_REFRESH_INTERVAL_IN_MILLIS
      },
    },
  )
}
