import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useUser } from 'builder/hooks/useUser'
import { PaywallModalType, actions } from 'builder/modules/careerProfile'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as panelSelectors, actions as panelActions } from 'builder/modules/panel'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { DocumentTypes, FetchStatuses } from 'builder/modules/constants'
import { analyticEventsLabel } from '../constants'
import { ServiceNamesForAnalytic } from '../types'
import { getTailoringResumeEditorLink } from '../helpers'

export const useLinkClickHandlers = (
  analyticEventCode: Parameters<typeof trackInternalEvent>[0],
) => {
  const dispatch = useDispatch()
  const user = useUser()
  const documentsStatus = useTypedSelector(panelSelectors.documentsStatus)
  const resumes = useTypedSelector(panelSelectors.documents).filter(
    item => item.type === DocumentTypes.resume,
  )

  const isPremiumUser = !!user?.hasPremiumFeatures

  const onLinkClick = useCallback(
    (type: PaywallModalType, serviceName: ServiceNamesForAnalytic) => {
      trackInternalEvent(analyticEventCode, {
        label: analyticEventsLabel,
        service_name: serviceName,
      })

      if (isPremiumUser) {
        return
      }

      dispatch(actions.setOpenedPaywallModalType(type))
    },
    [dispatch, isPremiumUser, analyticEventCode],
  )

  const onPitchLinkClick = useCallback(
    () => onLinkClick('pitch', ServiceNamesForAnalytic.pitch),
    [onLinkClick],
  )

  const onCoverLetterLinkClick = useCallback(
    () => onLinkClick('cover-letter', ServiceNamesForAnalytic.coverLetter),
    [onLinkClick],
  )

  const onInterviewPrepLinkClick = useCallback(
    () => onLinkClick('interview-prep', ServiceNamesForAnalytic.interviewPrep),
    [onLinkClick],
  )

  const onOptimizeResumeClick = useCallback(() => {
    trackInternalEvent(analyticEventCode, {
      label: analyticEventsLabel,
      service_name: ServiceNamesForAnalytic.resumeBuilder,
    })

    if (!resumes.length) {
      dispatch(panelActions.createDocument({ type: DocumentTypes.resume, target: '_blank' }))
    } else if (resumes.length === 1) {
      window.open(getTailoringResumeEditorLink(resumes[0].id), '_target')
    } else {
      dispatch(actions.setIsOptimizeResumeModalOpened(true))
    }
  }, [dispatch, resumes, analyticEventCode])

  useEffectOnMount(() => {
    if (documentsStatus === FetchStatuses.notAsked) {
      dispatch(panelActions.fetchDocuments())
    }
  })

  return {
    onPitchLinkClick,
    onCoverLetterLinkClick,
    onInterviewPrepLinkClick,
    onOptimizeResumeClick,
  }
}
