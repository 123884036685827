import { EventCode, trackInternalEvent } from '@rio/tracking'
import { parse as parseQuery } from 'query-string'
import find from 'lodash/find'
import getByPath from 'lodash/get'
import snakeCase from 'lodash/snakeCase'
import isEmpty from 'lodash/isEmpty'
import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import isAfter from 'date-fns/isAfter'
import { Optional, Resume } from 'packages/types'
import { all, put, call, take, takeLatest, takeEvery, select, delay } from 'redux-saga/effects'
import axios, { AxiosError } from 'axios'
import { CallBackProps } from 'react-joyride'
import { apiClient, baseClient } from 'builder/modules/apiClient'
import { TemplateName } from 'builder/components/Helper/constants'
import { selectors as appSelectors, actions as initActions } from 'builder/modules/init/initModule'
import { actions as renderingActions } from 'builder/modules/rendering'
import { actions as userActions } from 'builder/modules/user'
import { SnackbarTypes, actions as uiActions } from 'builder/modules/ui'
import { createInterceptor, needOnline, InterceptionHelper } from 'builder/modules/interceptors'
import { navigate } from 'builder/modules/navigate'
import PreviewReloadService from 'builder/services/PreviewReloadService'
import ErrorLogger from 'builder/services/ErrorLogger'
import AnchorManager from 'builder/services/AnchorManager'
import { ResumeExample } from 'builder/components/FillResumeModal/UseExample/types'
import { generateRandomId } from 'builder/utils/generateRandomId'
import { isTruthy } from 'builder/utils/richTextUtils'
import { matchMediaQueries } from 'builder/utils/matchMediaQueries'
import { Store } from '../store'
import {
  AIResumeDraftEventLabels,
  AIResumeDraftEvents,
  AIResumeLinkedInSteps,
  FetchStatuses,
  GenerateResumeStatus,
  GenerateAiProfileStatus,
  ResumeUploadStep,
  PrefillResumeEvents,
  ResumeUploadStatus,
  ResumeValidationStatus,
  SpeechToTextStatus,
  UseExampleEventLabels,
  UseExampleEvents,
  VoiceInputErrors,
  AIProfileGenerateType,
} from '../constants'
import { createImageUrlFormatter } from '../../../../packages/cloudflare-image/image-url'
import { selectors } from './resumeEditorSelectors'
import { SectionNames } from './sectionNames'
import { StartResumeUpdatePayload, actions, CheckResumeScorePayload } from './resumeEditorActions'
import { mergeResumeHobbies } from './mergeResumeHobbies'
import {
  LinkedInData,
  PrefillProviders,
  PrefillStatuses,
  QuestionnaireData,
  JobPostingAPIData,
  HighlightedElementData,
  AIProfile,
  AvailableAISuggestions,
} from './types'
import { formatAIResumeQuestionnaireData } from './sagaUtils'
import {
  getCardFieldHighlightId,
  getCardHighlightId,
  joyRideSpotlightPositionInModalCssFix,
} from './utils'
import { getEmptyFieldName } from './aiProfileRules'
import { AI_PROFILE_SUMMARY_ANALYTICS_LABEL } from './constants'
import { isEmptyField } from './resumeScoreRules/sectionRule'

const DEBOUNCE_DELAY = 1000
const RESUME_STORAGE_KEY = 'OFFLINE_RESUME_DATA'
const READY_TO_PREFILL_RESUME_ID = 'READY_TO_PREFILL_RESUME_ID'

const fixSectionsOrder = ({ customSections, sectionsOrder: sectionIds }: Resume) => {
  const missingCustomSections: string[] = []

  customSections.forEach(section => {
    const currentId = `custom:${section.externalId}`
    if (sectionIds.includes(currentId)) return
    missingCustomSections.push(currentId)
  })

  return [
    ...sectionIds.filter(sectionId => {
      const matches = sectionId.match(/custom:(\d+)/)
      if (!matches) return true
      return customSections.some(el => el.externalId === matches[1])
    }),
    ...missingCustomSections,
  ]
}

const saveResumeLocally = (resume: Resume) => {
  const updatedResume = { ...resume, updatedAt: formatISO(Date.now()) }
  localStorage.setItem(`${RESUME_STORAGE_KEY}_${resume.id}`, JSON.stringify(updatedResume))

  return updatedResume
}

const deserializeResume = (resume: Resume) => ({
  ...resume,
  sectionTitles: resume.sectionTitles ?? {},
  sectionsOrder: fixSectionsOrder(resume),
})

// polling saga for prefilling status
// checks if resume is prefilling from Top-resume if user used the prefill link
function* fetchResumePrefillStatusSaga(id: number) {
  // for showing prefilling popup everytime
  yield put(actions.setPrefillStatus(PrefillStatuses.scheduled))

  // refresh status with 5-second timer
  while (true) {
    yield delay(5000)

    const {
      data: { status },
    } = yield call(apiClient.get, `/resumes/${id}/prefill/status`)

    yield put(actions.setPrefillStatus(status))

    if (status === PrefillStatuses.done) {
      yield put(actions.fetchResumeRequest({ id }))
      const { payload: resume } = yield take(actions.fetchResumeSuccess)
      PreviewReloadService.reloadResume(resume, true)
      return
    }
  }
}

export function* fetchResumeOptimizerData({ payload }: { payload: { resumeId?: number } }) {
  yield put(actions.setFetchJobPostingDataStatus(FetchStatuses.loading))
  try {
    const {
      status,
      data: { success, jobDetails, resume },
    } = yield call(baseClient.post, `/job-postings/fetch-job-posting`, null, {
      params: {
        resume_id: payload.resumeId,
      },
    })

    if (status === 200 && success) {
      const apiResponse = {
        isManagementJob: jobDetails.currentJobIsManagement === 't',
        jobPostingId: jobDetails.id,
        employerName: resume.employerName,
        recommendedJobTitle: resume.jobTitle,
        visibleJobTitle: jobDetails.jobTitle,
        recommendedSkills: jobDetails.skills,
        visibleSkills: jobDetails.skills,
        jobPostingLink: jobDetails.inputUrl,
        resumeScore: resume.score,
        apiScore: resume.responseApiScore,
        averageScore: resume.averageScore,
        keywords: jobDetails.keywords || [],
        recommendedKeywords: resume.recommendedKeywords || [],
      }
      yield put(actions.setTemporaryJobPostingAPIData(apiResponse))
      yield put(actions.saveJobPostingAPIData())
      yield put(
        actions.setResumeJobDetails({
          jobTitle: resume.jobTitle,
          employerName: resume.employerName,
        }),
      )
      yield put(actions.refreshAISuggestions())
      yield put(actions.setFetchJobPostingDataStatus(FetchStatuses.loaded))
    } else {
      yield put(actions.setFetchJobPostingDataStatus(FetchStatuses.loaded))
      yield put(actions.resetJobPostingAPIData())
    }
  } catch (err) {
    const error = err as { response: { data: { message: string } } }
    ErrorLogger.log(error)

    yield put(actions.setFetchJobPostingDataStatus(FetchStatuses.failed))
    yield put(
      uiActions.setSnackBarOpen({
        status: true,
        type: SnackbarTypes.failure,
        text:
          'Could not fetch AI Suggestions due to following error: ' +
          (error instanceof Error ? error.message : String(error)),
      }),
    )
    yield delay(1000)
    yield put(uiActions.setSnackBarOpen({ status: false, type: SnackbarTypes.failure }))
  }
}

export function* updateResumeOptimizerDataSaga({
  payload,
}: {
  payload: { jobPostingId?: number; resumeId?: number }
}) {
  yield put(actions.refreshAISuggestions())
  yield put(actions.setFetchJobPostingDataStatus(FetchStatuses.loading))
  try {
    const {
      status,
      data: { jobDetails, resume },
    } = yield call(baseClient.post, `/job-postings/update-score`, null, {
      params: {
        job_posting_id: payload.jobPostingId,
        resume_id: payload.resumeId,
      },
    })

    if (status === 200) {
      const resumeData: Resume = yield select(selectors.resume)
      const jobPostingAPIData: JobPostingAPIData = yield select(selectors.jobPostingAPIData)
      const suggestedSkills = jobDetails.skills
      const currentVisibleSkills = jobPostingAPIData.visibleSkills ?? []
      const currentResumeSkills = (resumeData.skills ?? [])
        .map(item => (item.skill ? item.skill.trim() : ''))
        .filter(Boolean)
      // Only include new skills from the suggestions
      const newSkills = suggestedSkills.filter(
        (skill: string) =>
          !(currentResumeSkills.includes(skill) || currentVisibleSkills.includes(skill)),
      )
      const updatedSkills = [...currentVisibleSkills, ...newSkills]
      const apiResponse = {
        isManagementJob: jobDetails.currentJobIsManagement === 't',
        jobPostingId: jobDetails.id,
        employerName: jobDetails.companyName,
        recommendedJobTitle: jobDetails.jobTitle,
        visibleJobTitle: jobDetails.jobTitle,
        recommendedSkills: jobDetails.skills,
        visibleSkills: updatedSkills,
        jobPostingLink: jobDetails.inputUrl,
        resumeScore: resume.score,
        apiScore: resume.responseApiScore,
        averageScore: resume.averageScore,
        keywords: jobDetails.keywords || [],
        recommendedKeywords: resume.recommendedKeywords || [],
      }
      yield put(actions.setTemporaryJobPostingAPIData(apiResponse))
      yield put(actions.saveJobPostingAPIData())
      yield put(actions.refreshAISuggestions())
      yield put(actions.setFetchJobPostingDataStatus(FetchStatuses.loaded))
    } else {
      yield put(actions.setFetchJobPostingDataStatus(FetchStatuses.failed))
    }
  } catch (error) {
    yield put(actions.setFetchJobPostingDataStatus(FetchStatuses.failed))
    ErrorLogger.log(error)
  }
}

function* fetchResumeSaga({ payload }: { payload: { id: number } }) {
  try {
    // clear prefill resume modal states
    yield put(actions.setResumeValidationStatus(ResumeValidationStatus.notStarted))
    yield put(actions.setResumeUploadStatus(ResumeUploadStatus.notStarted))
    const isPrefillable = parseQuery(window.location.search).prefillable
    const { status: prefillingStatus } = yield select(selectors.prefill)
    const { id } = payload
    const url = `/resumes/${id}`
    const { data: resume } = yield call(apiClient.get, url)
    const currentRemoteResume = mergeResumeHobbies(deserializeResume(resume)) as Resume

    const storageKey = `${RESUME_STORAGE_KEY}_${id}`
    const storageValue = localStorage?.getItem(storageKey)
    const localResume = storageValue ? JSON.parse(storageValue) : null

    // if we have a local resume, check date, send resume to server
    // and remove from local storage
    if (localResume) {
      const processedLocalResume = mergeResumeHobbies(deserializeResume(localResume)) as Resume
      const shouldUseLocalData = isAfter(
        parseISO(processedLocalResume.updatedAt || ''),
        parseISO(currentRemoteResume.updatedAt || ''),
      )
      if (shouldUseLocalData) {
        yield put(actions.fetchResumeSuccess(processedLocalResume))
        yield put(actions.startResumeUpdate())
      }
      localStorage.removeItem(storageKey)
    }

    yield put(renderingActions.checkDocument(currentRemoteResume))
    yield take(renderingActions.checkDocumentComplete)

    const { Rirekisho, Shokumukeirekisho } = TemplateName

    const country: { locale: string } = yield select(selectors.country)
    const isJapaneseBuilderon = country.locale === 'ja-JP'

    const { createdAt, updatedAt } = currentRemoteResume

    if (isJapaneseBuilderon && currentRemoteResume.template === Shokumukeirekisho) {
      yield put(actions.fetchResumeSuccess(currentRemoteResume))
    } else if (isJapaneseBuilderon && createdAt === updatedAt) {
      yield put(actions.fetchResumeSuccess({ ...currentRemoteResume, template: Rirekisho }))
    } else {
      // in all other conditions fetches the previous selected template in resume view on render
      yield put(actions.fetchResumeSuccess(currentRemoteResume))

      try {
        yield call(fetchResumeOptimizerData, {
          payload: { resumeId: payload.id },
        })

        // this needs to get correct 'recommendedKeywords' value
        const jobPostingId: number = yield select(selectors.tailoredJobPostingId)
        if (jobPostingId) {
          yield call(updateResumeOptimizerDataSaga, {
            payload: { resumeId: payload.id, jobPostingId },
          })
        }
      } catch (error) {
        ErrorLogger.log(error)
      }
    }

    if (isPrefillable && prefillingStatus === PrefillStatuses.notAsked)
      yield call(fetchResumePrefillStatusSaga, id)
  } catch (error) {
    // Customize the error message for 404
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      const axiosError = error as AxiosError
      axiosError.message = `${axiosError.message}. The requested resume might not exist.`
      ErrorLogger.log(axiosError)
    } else ErrorLogger.log(error)

    if (error instanceof Error) {
      yield put(actions.fetchResumeFail(error))
    }
  }
}

function* updateLocalResumeSaga() {
  const resume: Resume = yield select(selectors.resume)
  const updatedResume: Resume = yield call(saveResumeLocally, resume)
  yield delay(300) // for spinner
  yield put(actions.updateResumeSuccess(updatedResume))
}

function* updateRemoteResumeSaga({ payload = {} }: { payload: StartResumeUpdatePayload }) {
  try {
    const { debounce = false } = payload

    if (debounce === true) yield delay(DEBOUNCE_DELAY)
    if (typeof debounce === 'number') yield delay(debounce)

    const resume: Resume = yield select(selectors.resume)
    const { points: score } = yield select(selectors.scoreSuggestions)
    const url = `/resumes/${resume.id}`

    let { data } = yield call(apiClient.patch, url, { ...resume, score })
    const updatedResume = deserializeResume(data)

    yield put(renderingActions.checkDocument(updatedResume))
    yield take(renderingActions.checkDocumentComplete)

    yield put(actions.updateResumeSuccess(updatedResume))

    const refresh = payload?.name && ['template', 'color'].includes(payload?.name)
    PreviewReloadService.reloadResume(updatedResume, refresh)

    const jobPostingId: number = yield select(selectors.tailoredJobPostingId)
    const isAISuggestionsPopupOpen: boolean = yield select(selectors.openedAISuggestionsPopup)

    if (jobPostingId && !isAISuggestionsPopupOpen) {
      yield call(updateResumeOptimizerDataSaga, {
        payload: { jobPostingId, resumeId: resume.id },
      })
    }
  } catch (error) {
    ErrorLogger.log(error)

    // if something goes wrong when the user edits the resume,
    // save it to localStorage. It will be synced on page reload or
    // next edit with an internet connection on.
    yield call(
      updateLocalResumeSaga as unknown as {
        context: unknown
        fn: (this: unknown, ...args: unknown[]) => unknown
      },
      { payload },
    )
  }
}

function* updateRecommendedJobsBasedOnTitleSaga({
  payload,
}: ReturnType<typeof actions.updateRecommendedJobsForJobTitle>) {
  try {
    const { newJobTitle, oldJobTitle } = payload

    // eslint-disable-next-line max-len
    const url = `/job-board/cards/create-recommended-job?query=${newJobTitle}&old_job_title=${oldJobTitle}&location=Remote`

    let { data } = yield call(apiClient.get, url)

    if (data.success) yield put(actions.updateRecommendedJobsForJobTitleSuccess())
    else yield put(actions.updateRecommendedJobsForJobTitleFail())
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* updateResumeSaga(action?: ReturnType<typeof actions.startResumeUpdate>) {
  const isOnline: boolean = yield select(appSelectors.isOnline)

  // if there is no internet connection, save resume in localStorage
  yield call(
    (isOnline ? updateRemoteResumeSaga : updateLocalResumeSaga) as unknown as {
      context: unknown
      fn: (this: unknown, ...args: unknown[]) => unknown
    },
    action,
  )
}

function* prefillResumeSaga({ payload = {} }: ReturnType<typeof actions.prefillResume>) {
  try {
    const { documentId, exampleId } = payload

    const { data } = yield call(apiClient.post, `/resumes/${documentId}/prefill/${exampleId}`)

    if (data.success) {
      yield put(actions.updateResumeSuccess(data.resume))
      yield put(uiActions.setPrefillModalDocumentId(null))
    } else {
      yield put(uiActions.setWarningSnackbarOpen({ timeout: 5000, text: data.errorMessage }))
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.updateResumeFail())
    yield put(uiActions.setPrefillModalDocumentId(null))
  }
}

function* watchLinkedinPrefillSaga({ resumeId }: { resumeId: number }) {
  while (true) {
    yield delay(1000)

    const { createdAt, updatedAt } = yield select(selectors.resume)
    const isResumeEdited = createdAt < updatedAt

    // Get resume prefill status
    const {
      data: { success, status },
    } = yield call(apiClient.get, `/resumes/${resumeId}/prefill/status`)

    // update prefilling popup
    if (status === PrefillStatuses.done) {
      if (!isResumeEdited) {
        // merge resume automatically
        yield put(actions.mergeLinkedinResume({ resumeId, auto: true }))
      } else {
        // we need to save resume id for case if user doesn't complete prefill
        localStorage.setItem(READY_TO_PREFILL_RESUME_ID, JSON.stringify(resumeId))

        yield put(actions.setPrefillStatus(PrefillStatuses.readyToMerge))
      }
    } else {
      yield put(actions.setPrefillStatus(status))
    }

    // end watching
    if (success) {
      trackInternalEvent('complete_linkedin_resume_prefill', { status: 'success' })
      return
    }

    // error state
    if (status === PrefillStatuses.failed) {
      trackInternalEvent('complete_linkedin_resume_prefill', { status: 'fail' })
      return
    }
  }
}

function* linkedinPrefillSaga({ payload }: ReturnType<typeof actions.linkedinPrefill>) {
  yield put(actions.setPrefillProvider(PrefillProviders.linkedin))

  try {
    const { resumeId, linkedinProfileId } = payload

    yield put(actions.setPrefillResumeId(resumeId || null))

    // Put resume in prefill queue
    const { data: prefillStartStatus } = yield call(
      apiClient.post,
      `/api/resumes/${resumeId}/prefill/linkedin/process`,
      {
        profile_id: linkedinProfileId,
      },
    )

    if (prefillStartStatus.success) {
      // set prefilling status as status, and provider as linkedin
      yield put(actions.setPrefillStatus(PrefillStatuses.scheduled))

      // close linkedin popup
      navigate(`/resumes/${resumeId}/edit`)

      // successfully added to queue
      yield call(
        watchLinkedinPrefillSaga as unknown as {
          context: unknown
          fn: (this: unknown, ...args: unknown[]) => unknown
        },
        { resumeId },
      )
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* mergeLinkedinResumeSaga({ payload }: ReturnType<typeof actions.mergeLinkedinResume>) {
  const { resumeId, auto } = payload

  try {
    const {
      data: { success },
    } = yield call(apiClient.post, `/resumes/${resumeId}/prefill/linkedin/process/commit`)

    if (success) {
      if (auto) {
        yield put(actions.setPrefillStatus(PrefillStatuses.mergedAuto))
      } else {
        yield put(actions.setPrefillStatus(PrefillStatuses.merged))
      }

      // remove info about resume for merge
      localStorage.removeItem(READY_TO_PREFILL_RESUME_ID)

      yield put(actions.fetchResumeRequest({ id: resumeId }))
      const { payload: resume } = yield take(actions.fetchResumeSuccess)
      PreviewReloadService.reloadResume(resume, true)
    } else {
      yield put(actions.setPrefillStatus(PrefillStatuses.mergeFailed))
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setPrefillStatus(PrefillStatuses.mergeFailed))
  }
}

function* rejectLinkedinResumeSaga({ payload }: ReturnType<typeof actions.rejectLinkedinResume>) {
  const { resumeId } = payload

  try {
    const {
      data: { success },
    } = yield call(apiClient.post, `/resumes/${resumeId}/prefill/linkedin/process/reject`)

    if (success) {
      yield put(actions.setPrefillStatus(PrefillStatuses.rejected))

      // remove info about resume for merge
      localStorage.removeItem(READY_TO_PREFILL_RESUME_ID)
    } else {
      yield put(actions.setPrefillStatus(PrefillStatuses.rejectFailed))
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setPrefillStatus(PrefillStatuses.rejectFailed))
  }
}

function* startResumeUpdateSaga({ payload = {} }) {
  try {
    yield put(actions.startResumeUpdate(payload))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* updateTemplateSaga({ payload }: ReturnType<typeof actions.updateTemplate>) {
  try {
    const { id } = payload
    const templates: Store['generalEditor']['resumeTemplates'] = yield select(selectors.templates)
    const template = find(templates, { id })
    const color = getByPath(template, 'settings.color.default', null)

    yield put(actions.updateSimpleField({ name: 'color', value: color, debounce: true }))
    yield put(actions.updateSimpleField({ name: 'spacing', value: 0, debounce: true }))
    yield put(actions.updateSimpleField({ name: 'template', value: id }))
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* addCardSaga({ payload }: ReturnType<typeof actions.addCard>) {
  try {
    const { sectionName, cardId, options } = payload
    yield put(actions.startResumeUpdate(payload))

    if (options && options.scrollIntoViewport) {
      AnchorManager.scrollToAnchor(cardId)
    }

    if (
      sectionName &&
      typeof sectionName !== 'number' &&
      Object.values(SectionNames).some(name => name === sectionName)
    ) {
      trackInternalEvent(`add_resume_${snakeCase(sectionName)}_card` as EventCode)
    } else {
      trackInternalEvent(`add_resume_custom_card`)
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* addCustomSectionSaga({ payload }: ReturnType<typeof actions.addCustomSection>) {
  try {
    const { externalId, options } = payload
    yield put(actions.setCustomSection(payload))
    yield put(actions.startResumeUpdate(payload))

    if (options?.scrollIntoViewport) {
      AnchorManager.scrollToAnchor(externalId)
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* uploadImageSaga({ payload = {} }: ReturnType<typeof actions.uploadPhoto>) {
  try {
    yield put(actions.changeEditorState({ isUploadingPhoto: true, isSyncing: true }))

    const { file, transform = {}, processedFile } = payload

    const resume: Resume = yield select(selectors.resume)
    const action = file ? 'photo' : 'transform'
    const url = `/resumes/${resume.id}/${action}`

    // POST /app/resumes/:id/photo
    // FormData photo:file, transform:json
    const form = new FormData()
    if (file) form.append('photo', file)
    if (processedFile) form.append('processed', processedFile)
    form.append('transform', JSON.stringify(transform))

    const { data } = yield call(apiClient.post, url, form)
    const updatedResume = deserializeResume(data)

    yield put(
      actions.changeEditorState({
        isUploadingPhoto: false,
        isSyncing: false,
        resume: updatedResume,
      }),
    )

    PreviewReloadService.reloadResume(updatedResume, false)

    // For the career.io MVP we connected user profile picture to their resume avatar.
    // So must refresh account data after user uploads a new picture to the resume avatar editor.
    yield put(userActions.fetchUserRequest({ silent: true }))
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.changeEditorState({ isUploadingPhoto: false, isSyncing: false }))
  }
}

function* deleteImageSaga() {
  try {
    yield put(actions.changeEditorState({ isSyncing: true }))

    const resume: Resume = yield select(selectors.resume)
    const url = `/resumes/${resume.id}/photo`

    // DELETE /app/resumes/:id/photo

    const { data } = yield call(apiClient.delete, url)
    const updatedResume = deserializeResume(data)

    yield put(
      actions.changeEditorState({
        isSyncing: false,
        resume: updatedResume,
      }),
    )

    PreviewReloadService.reloadResume(updatedResume, false)

    // TODO: Remove this side-effect after releasing the updated account settings page
    yield put(userActions.fetchUserRequest({ silent: true }))
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.changeEditorState({ isSyncing: false }))
  }
}

function* internetConnectionStatusChangedSaga({
  payload,
}: ReturnType<typeof initActions.setInternetConnectionStatus>) {
  const { isOnline } = payload
  const resume: Resume = yield select(selectors.resume)
  if (!resume) return
  const storageKey = `${RESUME_STORAGE_KEY}_${resume.id}`

  // if the connection switched on, sync remote resume with
  // the local version
  if (isOnline && localStorage.getItem(storageKey)) {
    yield call(fetchResumeSaga, { payload: { id: resume.id } })
  }
}

// Check ready to merge resume prefills
export function* getPrefillStatusSaga() {
  try {
    const storedResumeId = localStorage.getItem(READY_TO_PREFILL_RESUME_ID)
    const resumeId: number | null = storedResumeId && JSON.parse(storedResumeId)

    if (resumeId) {
      const {
        data: { isCachePresent: isReadyToMerge },
      } = yield call(apiClient.get, `/resumes/${resumeId}/prefill/status`)

      if (isReadyToMerge) {
        yield put(actions.setPrefillProvider(PrefillProviders.linkedin))
        yield put(actions.setPrefillResumeId(resumeId))
        yield put(actions.setPrefillStatus(PrefillStatuses.readyToMerge))
      } else {
        localStorage.removeItem(READY_TO_PREFILL_RESUME_ID)
      }
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* handleResumeOptimizerErrorFlow({ payload }: { payload: CheckResumeScorePayload }) {
  if (payload.jobPostingManualInput && payload.jobPostingManualInput.companyName) {
    yield put(actions.setShowManualInputScreen(false))
    yield put(actions.setFetchResumeScoreStatus(FetchStatuses.failed))
  } else {
    yield put(actions.setJobPostingLinkForModal(''))
    yield put(actions.setShowManualInputScreen(true))
    yield put(actions.setFetchResumeScoreStatus(FetchStatuses.notAsked))
  }
}

export function* checkResumeScoreSaga({ payload }: ReturnType<typeof actions.checkResumeScore>) {
  const { resumeId, jobPostingLink, jobPostingManualInput } = payload

  try {
    yield put(actions.setFetchResumeScoreStatus(FetchStatuses.loading))

    const response: {
      status: number
      data: { averageScore: number; jobTitle: string; companyName: string; jobPostingId: number }
    } = yield call(baseClient.post, '/job-postings/fetch-score', {
      ...(jobPostingManualInput
        ? { jobPosting: jobPostingManualInput }
        : { job_url: jobPostingLink }),
      resume_id: resumeId,
    })

    const {
      status,
      data: { averageScore, jobTitle, companyName, jobPostingId },
    } = response

    if (status === 200) {
      const apiResponse = {
        employerName: companyName,
        suggestedJobTitle: jobTitle,
        averageScore: averageScore,
        jobPostingId,
      }
      yield put(actions.resetResumeOptimizerManualInput())
      yield put(actions.setResumeScoreAPIResponse(apiResponse))
      yield put(actions.setFetchResumeScoreStatus(FetchStatuses.loaded))
      yield put(actions.setShowManualInputScreen(false))
    } else {
      yield call(handleResumeOptimizerErrorFlow, { payload: { jobPostingManualInput } })
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield call(handleResumeOptimizerErrorFlow, { payload: { jobPostingManualInput } })
  }
}

interface ResumeTypes extends Resume {
  responseApiScore: number
  score: number
  recommendedKeywords: string[]
}

export function* saveJobPostingDataSaga({
  payload,
}: {
  payload: { jobPostingLink?: string; resumeId?: number; jobTitle?: string; companyName?: string }
}) {
  try {
    yield put(actions.setSaveJobPostingDataStatus(FetchStatuses.loading))
    const jobPostingId: number = yield select(selectors.tempJobPostingId)

    let response: {
      status: number
      errorMessage: string
      data: {
        success: string
        jobDetails: {
          currentJobIsManagement: string
          id: number
          companyName: string
          jobTitle: string
          skills: string[]
          inputUrl: string
          keywords: string[]
        }
        resume: ResumeTypes
      }
    } = yield call(baseClient.post, `/job-postings/update-job-posting`, null, {
      params: {
        ...(!jobPostingId
          ? {
              job_url: payload.jobPostingLink,
            }
          : {}),
        resume_id: payload.resumeId,
        job_title: payload.jobTitle,
        company_name: payload.companyName,
        job_posting_id: jobPostingId,
      },
    })

    yield delay(1000)

    response = yield call(baseClient.post, `/job-postings/update-score`, null, {
      params: {
        ...(!jobPostingId
          ? {
              job_url: payload.jobPostingLink,
            }
          : {}),
        job_posting_id: jobPostingId,
        resume_id: payload.resumeId,
      },
    })

    const {
      status,
      data: { jobDetails, resume },
    } = response

    if (status === 200) {
      const apiResponse = {
        isManagementJob: jobDetails.currentJobIsManagement === 't',
        jobPostingId: jobDetails.id,
        employerName: resume.employerName,
        recommendedJobTitle: resume.jobTitle,
        visibleJobTitle: jobDetails.jobTitle,
        recommendedSkills: jobDetails.skills,
        visibleSkills: jobDetails.skills,
        jobPostingLink: jobDetails.inputUrl,
        resumeScore: resume.score,
        apiScore: resume.responseApiScore,
        averageScore: resume.averageScore,
        keywords: jobDetails.keywords || [],
        recommendedKeywords: resume.recommendedKeywords || [],
      }
      yield put(actions.setTemporaryJobPostingAPIData(apiResponse))
      yield put(
        actions.setResumeJobDetails({
          jobTitle: resume.jobTitle,
          employerName: resume.employerName,
        }),
      )

      yield put(actions.setSaveJobPostingDataStatus(FetchStatuses.loaded))
    } else {
      yield put(actions.setSaveJobPostingDataStatus(FetchStatuses.notAsked))
    }
  } catch (err) {
    const error = err as { response: { data: { message: string } } }
    ErrorLogger.log(error)
    if (
      error.response &&
      error.response.data &&
      error.response.data.message === 'No job posting found.'
    ) {
      yield put(actions.setSaveJobPostingDataStatus(FetchStatuses.loaded))
      // yield put(actions.resetJobPostingAPIData())
    } else {
      let errorString =
        'Oops! Something went wrong. We apologize for the inconvenience. Please try again later. If the issue persists, please contact support for assistance.'
      if (error.response.data.message === 'Network error') {
        errorString = `Oops! It appears there's a problem with your network connection. Please check your internet connection and try again.`
      }
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.failure,
          text: errorString,
        }),
      )
      yield delay(3000)
      yield put(uiActions.setSnackBarOpen({ status: false, type: SnackbarTypes.failure }))
      yield put(actions.setSaveJobPostingDataStatus(FetchStatuses.notAsked))
    }
  }
}

export function* startResumeOptimizerProcessSaga() {
  yield put(actions.setFetchResumeScoreStatus(FetchStatuses.notAsked))
  yield put(actions.setSaveJobPostingDataStatus(FetchStatuses.notAsked))
  yield put(actions.setJobPostingLinkForModal(''))
  yield put(actions.setShowManualInputScreen(false))
}

export function* startImprovingResumeSaga({
  payload,
}: ReturnType<typeof actions.startImprovingResume>) {
  yield call(saveJobPostingDataSaga, {
    payload: { ...payload },
  })
  yield put(actions.setOpenedAISuggestionsPopup(AvailableAISuggestions.jobTitle))
  yield put(actions.setIsImproveResumePanelOpen({ status: true }))

  const saveJobPostingDataStatus: string = yield select(selectors.saveJobPostingDataStatus)
  const isResumeOptimizerBannerOpen: boolean = yield select(selectors.isResumeOptimizerBannerOpen)

  if (saveJobPostingDataStatus === FetchStatuses.loaded && !isResumeOptimizerBannerOpen) {
    yield put(actions.saveJobPostingAPIData())
    yield put(actions.refreshAISuggestions())
    yield put(actions.setOpenOptimizerModal(false))
    yield delay(500)
    yield put(actions.animateResumeOptimizerBanner(true))
    yield put(actions.animatePersonalDetailsSection(true))
    yield delay(500)
    yield put(actions.animateResumeOptimizerBanner(false))
    yield put(actions.animatePersonalDetailsSection(false))
    yield put(actions.setResumeOptimizerBannerOpen(false))
    yield put(actions.resetResumeOptimizerProcess())
  }

  if (saveJobPostingDataStatus === FetchStatuses.loaded && isResumeOptimizerBannerOpen) {
    yield put(actions.saveJobPostingAPIData())
    yield put(actions.refreshAISuggestions())
    yield put(actions.setOpenOptimizerModal(false))
    yield put(actions.resetResumeOptimizerProcess())
  }
}

export function* deleteJobPostingSaga({ payload }: ReturnType<typeof actions.deleteJobPosting>) {
  try {
    yield put(actions.setDeleteJobPostingStatus(FetchStatuses.loading))
    const response: {
      status: number
      data: { success: string }
    } = yield call(baseClient.delete, `/job-postings/soft_delete`, {
      params: { id: payload.jobPostingId, resume_id: payload.resumeId },
    })

    const {
      status,
      data: { success },
    } = response

    if (status === 200 && success) {
      window.location.href = `/app/resumes/${payload.resumeId}/edit`
      yield put(actions.setDeleteJobPostingStatus(FetchStatuses.loaded))
    }
  } catch (err) {
    const error = err as { response: { data: { message: string } } }
    ErrorLogger.log(error)
    yield put(actions.setDeleteJobPostingStatus(FetchStatuses.failed))
    yield put(
      uiActions.setSnackBarOpen({
        status: true,
        type: SnackbarTypes.failure,
        text: error.response.data.message,
      }),
    )
    yield delay(1000)
    yield put(uiActions.setSnackBarOpen({ status: false, type: SnackbarTypes.failure }))
  }
}

export function* editJobDetailsSaga({ payload }: ReturnType<typeof actions.editJobDetails>) {
  try {
    const jobPostingId: number = yield select(selectors.tailoredJobPostingId)
    const response: {
      status: number
      data: { success: string; resume: { jobTitle: string; employerName: string } }
    } = yield call(baseClient.put, `/job-postings/edit-job-posting`, {
      job_title: payload.jobTitle,
      company_name: payload.companyName,
      ...(payload.jobPostingLink
        ? { job_url: payload.jobPostingLink }
        : { job_posting_id: jobPostingId }),
      resume_id: payload.resumeId,
    })

    const {
      status,
      data: {
        success,
        resume: { jobTitle, employerName },
      },
    } = response

    if (status === 200 && success) {
      const jobPostingAPIData: JobPostingAPIData = yield select(selectors.jobPostingAPIData)
      yield put(
        actions.setTemporaryJobPostingAPIData({
          ...jobPostingAPIData,
          recommendedJobTitle: jobTitle,
          employerName: employerName,
        }),
      )
      yield put(actions.saveJobPostingAPIData())
      yield put(actions.setResumeJobDetails({ jobTitle: jobTitle, employerName: employerName }))
      yield put(actions.refreshAISuggestions())
      yield put(actions.editJobDetailsStatus(FetchStatuses.loaded))
      yield delay(1000)

      yield put(actions.setIsEditJobDetails(false))
      yield put(actions.setisClickedonEditJobDetails(false))
      yield put(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.success,
          text: payload.successMessage,
        }),
      )
      yield put(actions.editJobDetailsStatus(FetchStatuses.notAsked))
      yield delay(2000)
      yield put(uiActions.setSnackBarOpen({ status: false }))
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.editJobDetailsStatus(FetchStatuses.failed))
    yield put(
      uiActions.setSnackBarOpen({
        status: true,
        type: SnackbarTypes.failure,
        text: 'Looks like we couldn’t save the changes. Please check your internet connection and try again.',
      }),
    )
    yield delay(3000)
    yield put(actions.setIsEditJobDetails(false))
    yield put(actions.editJobDetailsStatus(FetchStatuses.notAsked))
    yield put(uiActions.setSnackBarOpen({ status: false, type: SnackbarTypes.failure }))
  }
}

export function* parseAndSavePrefilledResumeSaga({
  payload,
}: ReturnType<typeof actions.parseAndSavePrefilledResume>) {
  const { resumeId, selectedResume } = payload
  yield put(actions.setIsSavingPrefilledResume(true))
  try {
    const formData = new FormData()
    formData.append('file', selectedResume)
    formData.append('resume_id', `${resumeId}`)

    const response: {
      status: number
      data: { success: boolean; error: string }
    } = yield call(apiClient.post, `/resume-parser/parse-resume`, formData)
    if (response.data.success) {
      yield put(actions.setResumeUploadStatus(ResumeUploadStatus.uploaded))
      yield put(actions.fetchResumeRequest({ id: resumeId }))
      trackInternalEvent(PrefillResumeEvents.completeResumeParsing)
      yield delay(1000)
      yield put(actions.startResumeUpdate())
    } else {
      yield put(actions.setResumeUploadStatus(ResumeUploadStatus.failed))
      yield put(
        actions.setPrefillResumeGenericError({
          error: response.data.error,
          errorSource: ResumeUploadStep.parsing,
        }),
      )
    }
  } catch (error) {
    yield put(actions.setResumeUploadStatus(ResumeUploadStatus.failed))
    if (error instanceof Error) {
      yield put(
        actions.setPrefillResumeGenericError({
          error: error.message,
          errorSource: ResumeUploadStep.parsing,
        }),
      )
    }
  }
  yield put(actions.setIsSavingPrefilledResume(false))
}

// AICoverLetter
export function* aiCoverLetterTailoredGenerateSaga({ payload }: { payload: { resumeId: number } }) {
  try {
    const response: {
      status: number
      data: { success: string; coverLetter: { id: number } }
    } = yield call(baseClient.post, `/job-postings/generate-cover-letter`, null, {
      params: {
        resume_id: payload.resumeId,
      },
    })

    const {
      status,
      data: { success, coverLetter },
    } = response

    if (status === 200 && success && coverLetter.id) {
      // To refresh the user attempts
      trackInternalEvent(`generate_cover_letter_succesfully`)
      yield put(userActions.fetchUserRequest({ silent: true }))
      window.location.href = `/app/cover-letters/${coverLetter.id}/edit`
    } else {
      yield put(actions.setIsClickedOnAiCVBanner(false))
      yield put(actions.setGenerateAICoverLetterStatus(FetchStatuses.failed))
    }
  } catch (error) {
    ErrorLogger.log(error)
    yield put(actions.setGenerateAICoverLetterStatus(FetchStatuses.failed))
  }
}

// AICoverLetterGenerateUntailored
export function* generateAICVUntailoredSaga({
  payload,
}: ReturnType<typeof actions.generateAICVUntailored>) {
  const { resumeId } = payload
  try {
    yield call(saveJobPostingDataSaga, {
      payload: { ...payload },
    })
    yield put(actions.setOpenedAISuggestionsPopup(AvailableAISuggestions.jobTitle))

    const saveJobPostingDataStatus: string = yield select(selectors.saveJobPostingDataStatus)

    if (saveJobPostingDataStatus === FetchStatuses.loaded) {
      yield put(actions.saveJobPostingAPIData())
    }
    yield call(aiCoverLetterTailoredGenerateSaga, {
      payload: { resumeId },
    })
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export function* fetchLinkedInProfileSaga({
  payload,
}: ReturnType<typeof actions.fetchLinkedInProfile>) {
  const { linkedInProfileURL } = payload
  try {
    yield put(actions.setLinkedInProfileFetchStatus(true))

    const {
      status,
      data: { linkedin },
    } = yield call(apiClient.get, `/ai-resume-draft/profile?linkedin_url=${linkedInProfileURL}`)

    if (status === 200 && !isEmpty(linkedin)) {
      yield put(actions.setLinkedInProfileData({ ...linkedin }))
      yield put(actions.setAIResumeLinkedInStep(AIResumeLinkedInSteps.linkedInProfile))
    } else {
      // handle error
      yield put(actions.setAIResumeLinkedInStep(AIResumeLinkedInSteps.linkedInError))
    }
  } catch (error) {
    // handle error
    yield put(actions.setAIResumeLinkedInStep(AIResumeLinkedInSteps.linkedInError))
    ErrorLogger.log(error)
  } finally {
    yield put(actions.setLinkedInProfileFetchStatus(false))
  }
}

export function* generateResumeFromLinkedInSaga() {
  try {
    trackInternalEvent(AIResumeDraftEvents.startGenerating, {
      label: AIResumeDraftEventLabels.linkedInProfile,
    })
    yield put(actions.setGenerateResumeStatus(GenerateResumeStatus.processing))
    const { linkedInProfileData }: LinkedInData = yield select(selectors.linkedInData)
    const currentResume: Resume = yield select(selectors.resume)
    const {
      data: { success, resume },
    } = yield call(apiClient.put, `/ai-resume-draft/${currentResume.id}`, {
      linkedinGenerated: true,
      upsiderData: linkedInProfileData,
    })

    if (success) {
      trackInternalEvent(AIResumeDraftEvents.generateSuccessfully, {
        label: AIResumeDraftEventLabels.linkedInProfile,
      })
      yield put(actions.setGenerateResumeStatus(GenerateResumeStatus.finished))
      yield put(actions.setShowAIResumeModal(false))
      yield put(actions.fetchResumeRequest({ id: resume.id }))
    } else {
      trackInternalEvent(AIResumeDraftEvents.generateError, {
        label: AIResumeDraftEventLabels.linkedInProfile,
      })
      yield put(actions.setGenerateResumeStatus(GenerateResumeStatus.failed))
    }
  } catch (error) {
    trackInternalEvent(AIResumeDraftEvents.generateError, {
      label: AIResumeDraftEventLabels.linkedInProfile,
    })
    yield put(actions.setGenerateResumeStatus(GenerateResumeStatus.failed))
  }
}

export function* generateResumeFromQuestionnaireSaga() {
  try {
    trackInternalEvent(AIResumeDraftEvents.startGenerating, {
      label: AIResumeDraftEventLabels.questionnaire,
    })
    yield put(actions.setGenerateResumeStatus(GenerateResumeStatus.processing))
    const { firstName, lastName, id }: Resume = yield select(selectors.resume)
    const questionnaireData: QuestionnaireData = yield select(selectors.questionnaireData)
    const formattedPayload = formatAIResumeQuestionnaireData(questionnaireData)
    const {
      data: { success, resume },
    } = yield call(apiClient.put, `/ai-resume-draft/${id}`, {
      linkedinGenerated: false,
      questionnaireData: {
        name: `${firstName} ${lastName}`,
        ...formattedPayload,
      },
    })

    if (success) {
      trackInternalEvent(AIResumeDraftEvents.generateSuccessfully, {
        label: AIResumeDraftEventLabels.questionnaire,
      })
      yield put(actions.setGenerateResumeStatus(GenerateResumeStatus.finished))
      yield put(actions.setShowAIResumeModal(false))
      yield put(actions.fetchResumeRequest({ id: resume.id }))
    } else {
      trackInternalEvent(AIResumeDraftEvents.generateError, {
        label: AIResumeDraftEventLabels.questionnaire,
      })
      yield put(actions.setGenerateResumeStatus(GenerateResumeStatus.failed))
    }
  } catch (error) {
    trackInternalEvent(AIResumeDraftEvents.generateError, {
      label: AIResumeDraftEventLabels.questionnaire,
    })
    yield put(actions.setGenerateResumeStatus(GenerateResumeStatus.failed))
  }
}

export function* generateResumeAiSummarySaga() {
  try {
    yield put(actions.setSummaryStatus(GenerateAiProfileStatus.processing))

    const { id, profile }: Resume = yield select(selectors.resume)
    const { actionType }: AIProfile = yield select(selectors.aiProfile)
    const isProfileEmpty = isEmptyField(SectionNames.profile, profile)

    const { data, status } = yield call(apiClient.post, `/ai-profile-summary/${id}/summary`, {
      profile_summary: actionType === AIProfileGenerateType.improve,
    })

    if (status === 200 && data) {
      yield put(actions.setAiProfileSummaryText(data.data.summary))
      yield put(actions.setSummaryStatus(GenerateAiProfileStatus.finished))
      yield put(userActions.decrementAiProfileAttempts())
      yield put(userActions.fetchUserRequest({ silent: true }))

      trackInternalEvent('generate_successfully', {
        section: SectionNames.profile,
        label: AI_PROFILE_SUMMARY_ANALYTICS_LABEL,
        profile_empty: isProfileEmpty,
        mode: actionType,
      })

      if (isProfileEmpty) {
        yield put(actions.updateSimpleField({ name: 'profile', value: data.data.summary }))
        yield put(actions.setAIProfileEditorFieldAnimation(true))
        yield put(actions.setAIProfileResultContentAnimation(false))
      }
    }
  } catch (error) {
    yield put(actions.setSummaryStatus(GenerateAiProfileStatus.failed))
  }
}

export function* extractTextFromVoiceSaga({
  payload,
}: ReturnType<typeof actions.extractTextFromVoiceInput>) {
  const { audioBlob } = payload
  const { step }: QuestionnaireData = yield select(selectors.questionnaireData)
  const locale: string = yield select(selectors.iso_language_code)
  const isFutureGoalsStep = step === 6

  try {
    yield put(
      isFutureGoalsStep
        ? actions.setGoalsVoiceProcessingStatus(SpeechToTextStatus.processing)
        : actions.setAccomplishmentsVoiceProcessingStatus(SpeechToTextStatus.processing),
    )
    const formData = new FormData()
    const audioBlobFile = new Blob(audioBlob, { type: 'audio/mpeg-3' })
    formData.append('file', audioBlobFile)
    formData.append('locale', locale.substring(0, 2))

    const {
      data: { success, text },
    }: { data: { success: boolean; text: string } } = yield call(
      apiClient.post,
      `/ai-resume-draft/speech_to_text`,
      formData,
    )
    if (success) {
      yield put(
        isFutureGoalsStep
          ? actions.setFutureGoalsText(text)
          : actions.setPastAccomplishmentsText(text),
      )
      yield put(
        isFutureGoalsStep
          ? actions.generateResumeFromQuestionnaire()
          : actions.setQuestionnaireStep(step + 1),
      )
    } else {
      yield put(
        isFutureGoalsStep
          ? actions.setGoalsVoiceInputError(VoiceInputErrors.unknown)
          : actions.setAccomplishmentsVoiceInputError(VoiceInputErrors.unknown),
      )
      trackInternalEvent(AIResumeDraftEvents.seeRecordingError, {
        label: isFutureGoalsStep
          ? AIResumeDraftEventLabels.careerGoals
          : AIResumeDraftEventLabels.professionalHighlights,
      })
    }
    const processingStatus = success ? SpeechToTextStatus.finished : SpeechToTextStatus.failed
    yield put(
      isFutureGoalsStep
        ? actions.setGoalsVoiceProcessingStatus(processingStatus)
        : actions.setAccomplishmentsVoiceProcessingStatus(processingStatus),
    )
  } catch (error) {
    trackInternalEvent(AIResumeDraftEvents.seeRecordingError, {
      label: isFutureGoalsStep
        ? AIResumeDraftEventLabels.careerGoals
        : AIResumeDraftEventLabels.professionalHighlights,
    })
    yield put(
      isFutureGoalsStep
        ? actions.setGoalsVoiceProcessingStatus(SpeechToTextStatus.failed)
        : actions.setAccomplishmentsVoiceProcessingStatus(SpeechToTextStatus.failed),
    )
    yield put(
      isFutureGoalsStep
        ? actions.setGoalsVoiceInputError(VoiceInputErrors.unknown)
        : actions.setAccomplishmentsVoiceInputError(VoiceInputErrors.unknown),
    )
  }
}

export function* fetchResumeExamplesSaga() {
  try {
    yield put(actions.fetchingResumeExamples(true))
    const {
      status,
      data: { examples },
    } = yield call(apiClient.get, '/examples')

    if (status === 200 && examples.length) {
      const formattedExamples = examples.map((example: ResumeExample) => {
        const { previewImageUrl } = example

        if (
          previewImageUrl.startsWith('https://s3.resume.io') ||
          previewImageUrl.startsWith('https://stagingbucket.resume.io')
        ) {
          return {
            ...example,
            compressedPreviewImageUrl: createImageUrlFormatter({
              base: previewImageUrl,
            })(previewImageUrl, { width: 600, format: 'auto' }),
          }
        }
        return {
          ...example,
          compressedPreviewImageUrl: '',
        }
      })

      yield put(actions.setResumeExamples(formattedExamples))
      yield put(actions.setShowUseExamplePopup(true))
      yield put(actions.setShowPrefillResumeModal(false))
    } else {
      // handle error
    }
  } catch (error) {
    // handle error
    ErrorLogger.log(error)
  } finally {
    yield put(actions.fetchingResumeExamples(false))
  }
}

export function* generateResumeFromExampleSaga({
  payload,
}: ReturnType<typeof actions.generateResumeFromExample>) {
  try {
    const { id }: Resume = yield select(selectors.resume)
    const {
      status,
      data: { success },
    } = yield call(apiClient.put, `resumes/${id}/use_example`, {
      exampleId: payload.exampleId,
    })

    if (status === 200 && success) {
      yield put(actions.fetchResumeRequest({ id }))
      yield delay(1000)
      yield put(actions.startResumeUpdate())
      yield put(actions.setShowUseExamplePopup(false))
      yield put(actions.setShowPrefillResumeModal(false))
      yield put(actions.setShowChangeTemplateTooltip(true))
      trackInternalEvent(UseExampleEvents.prefillResumeSuccessfully, {
        label: UseExampleEventLabels.examplesPrefill,
        jobTitle: payload.jobTitle,
      })
      yield delay(5000)
      yield put(actions.setShowChangeTemplateTooltip(false))
    } else {
      // handle error
    }
  } catch (error) {
    // handle error
    ErrorLogger.log(error)
  } finally {
    yield put(actions.setShowUseExamplePopup(false))
    yield put(actions.setShowPrefillResumeModal(false))
  }
}

export function* highlightEmptyWorkExperienceSaga({
  payload,
}: ReturnType<typeof actions.highlightEmptyWorkExperience>) {
  try {
    const { workExperiences }: Resume = yield select(selectors.resume)
    const { isPhone } = matchMediaQueries()
    let cardId: Optional<number> | string
    let emptyFieldName: ReturnType<typeof getEmptyFieldName> = 'title'

    if (workExperiences.length) {
      const card = workExperiences[0]
      cardId = card.cid || card.id
      emptyFieldName = getEmptyFieldName(SectionNames.workExperiences, card)
      yield put(actions.openCard(cardId))
    } else {
      cardId = generateRandomId()
      yield put(
        actions.addCard({
          sectionName: SectionNames.workExperiences,
          cardId,
          options: { scrollIntoViewport: false, shouldOpen: true },
        }),
      )
    }

    // we need to wait until opening section item animation is done
    yield delay(300)

    const openCardId: number | string = yield select(selectors.openCardId)
    const highlightId = isPhone
      ? getCardFieldHighlightId(SectionNames.workExperiences, openCardId, emptyFieldName)
      : getCardHighlightId(SectionNames.workExperiences, openCardId)

    const targetSelector = `[data-highlight-id="${highlightId}"]`
    const targetElement = document.querySelector<HTMLElement>(targetSelector)

    if (targetElement) {
      if (targetElement.nodeName === 'INPUT') {
        targetElement.focus()
      } else {
        Array.from(targetElement.querySelectorAll('input'))
          .find(item => !isTruthy(item.value))
          ?.focus()
      }
    }

    yield put(
      actions.setHighlightedElement({
        ...payload,
        target: targetSelector,
        disableScrollParentFix: !isPhone,
        styles: joyRideSpotlightPositionInModalCssFix(targetElement),
      }),
    )

    trackInternalEvent('show_empty_work_experience_popup')

    // Wait until element closed
    const { payload: closeCallbackProps }: { payload: CallBackProps } = yield take(
      actions.closeHighlightedElement,
    )

    if (closeCallbackProps.origin === 'button_close') {
      trackInternalEvent('click_got_it', {
        label: 'empty_work_experience_popup',
      })
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

export function* autocloseWorkExperienceHighlightingSaga({
  payload,
}: ReturnType<typeof actions.updateCard>) {
  try {
    const highlightedElement: HighlightedElementData | null = yield select(
      selectors.highlightedElementData,
    )
    if (payload.sectionId === SectionNames.workExperiences && highlightedElement) {
      yield put(actions.setHighlightedElement(null))
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

// Export
export const resumeEditorSagas = function* resumeEditorSaga() {
  yield all([
    takeEvery(actions.fetchResumeRequest, fetchResumeSaga),
    takeEvery(actions.updateSimpleField, startResumeUpdateSaga),
    takeEvery(actions.updateRecommendedJobsForJobTitle, updateRecommendedJobsBasedOnTitleSaga),
    takeEvery(actions.updateTemplate, updateTemplateSaga),
    takeEvery(actions.updateCard, startResumeUpdateSaga),
    takeEvery(actions.addCard, addCardSaga),
    takeEvery(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      actions.addCustomSection as any,
      createInterceptor(needOnline as InterceptionHelper),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      addCustomSectionSaga as any,
    ),
    takeEvery(actions.deleteCard, startResumeUpdateSaga),
    takeEvery(actions.moveCard, startResumeUpdateSaga),
    takeEvery(actions.moveSection, startResumeUpdateSaga),
    takeEvery(actions.renameSection, startResumeUpdateSaga),
    takeEvery(actions.prefillResume, prefillResumeSaga),
    takeLatest(actions.linkedinPrefill, linkedinPrefillSaga),
    takeLatest(actions.deleteSection, startResumeUpdateSaga),
    takeLatest(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      actions.uploadPhoto as any,
      createInterceptor(needOnline as InterceptionHelper),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      uploadImageSaga as any,
    ),
    takeLatest(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      actions.deletePhoto as any,
      createInterceptor(needOnline as InterceptionHelper),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      deleteImageSaga as any,
    ),
    takeLatest(actions.startResumeUpdate, updateResumeSaga),
    takeLatest(actions.mergeLinkedinResume, mergeLinkedinResumeSaga),
    takeLatest(actions.rejectLinkedinResume, rejectLinkedinResumeSaga),
    takeLatest(initActions.setInternetConnectionStatus, internetConnectionStatusChangedSaga),
    takeLatest(actions.startResumeOptimizerProcess, startResumeOptimizerProcessSaga),
    takeLatest(actions.resetResumeOptimizerProcess, startResumeOptimizerProcessSaga),
    takeLatest(actions.checkResumeScore, checkResumeScoreSaga),
    takeLatest(actions.deleteJobPosting, deleteJobPostingSaga),
    takeLatest(actions.editJobDetails, editJobDetailsSaga),
    takeLatest(actions.generateTailoredAICoverLetter, aiCoverLetterTailoredGenerateSaga),
    takeLatest(actions.startImprovingResume, startImprovingResumeSaga),
    takeLatest(actions.parseAndSavePrefilledResume, parseAndSavePrefilledResumeSaga),
    takeLatest(actions.generateAICVUntailored, generateAICVUntailoredSaga),
    takeLatest(actions.fetchLinkedInProfile, fetchLinkedInProfileSaga),
    takeLatest(actions.generateResumeFromLinkedIn, generateResumeFromLinkedInSaga),
    takeLatest(actions.generateResumeFromQuestionnaire, generateResumeFromQuestionnaireSaga),
    takeLatest(actions.generateResumeAiSummary, generateResumeAiSummarySaga),
    takeLatest(actions.extractTextFromVoiceInput, extractTextFromVoiceSaga),
    takeLatest(actions.fetchResumeExamples, fetchResumeExamplesSaga),
    takeLatest(actions.generateResumeFromExample, generateResumeFromExampleSaga),
    takeLatest(actions.updateResumeOptimizerData, updateResumeOptimizerDataSaga),
    takeLatest(actions.highlightEmptyWorkExperience, highlightEmptyWorkExperienceSaga),
    takeLatest(actions.updateCard, autocloseWorkExperienceHighlightingSaga),
  ])
}
