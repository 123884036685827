import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useI18n } from 'builder/hooks/useI18n'
import Icon24 from 'builder/components/Icon'
import { ButtonTheme } from 'builder/components/Button'
import { RadioGroup } from 'builder/components/RadioGroup'
import { formatSecondsToMMSS } from 'builder/utils/formatTime'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Interview } from 'builder/modules/interview/types'
import { selectors, actions } from 'builder/modules/interview/interviewModule'
import { InterviewStatus } from '../../../InterviewStatus/InterviewStatus'
import {
  Body,
  ButtonContainer,
  Close,
  Container,
  ContainerFields,
  CustomButton,
  DateText,
  Field,
  LabelContainer,
  MobileAnimatedContainer,
  RadioGroupContainer,
  RadioLabel,
  Subtitle,
  Title,
} from './styles'

const TRANSLATION_KEY = 'builder.interview_prep_view.feedback.interview_history_dialog'
interface Props {
  onClose: () => void
  onRestartInterview: (interview?: Interview) => void
  open: boolean
  interviews?: Interview[]
}

const formatDateByLocation = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }

  return new Intl.DateTimeFormat(undefined, options).format(date)
}

export const formatInterviewDateByLocation = (interview: Interview) => {
  if (interview.questions[0]?.answer?.updated_at) {
    try {
      return formatDateByLocation(new Date(Date.parse(interview.questions[0]?.answer?.updated_at)))
    } catch (error) {}
  }
  return formatDateByLocation(new Date(Date.parse(interview.updated_at)))
}

export const InterviewHistoryDialog = ({ onClose, onRestartInterview, interviews }: Props) => {
  const { isPhone } = useMediaQueries()
  const { i18n } = useI18n()
  const interviewId = useSelector(selectors.interviewId)
  const [selectedInterviewId, setSelectedInterviewId] = useState(String(interviewId))
  const dispatch = useDispatch()
  const selectedInterview = useMemo(
    () => interviews?.find(({ id }) => id === interviewId),
    [interviews, interviewId],
  )
  const AnimatedContainer = isPhone ? MobileAnimatedContainer : ModalOverlay

  return (
    <AnimatedContainer>
      <Container>
        <Title>
          {i18n.t(`${TRANSLATION_KEY}.title`)}
          <Close onClick={onClose}>
            <Icon24.Close />
          </Close>
        </Title>
        <Subtitle>{i18n.t(`${TRANSLATION_KEY}.subtitle`)}</Subtitle>
        <Body>
          <RadioGroupContainer>
            <ContainerFields>
              <Field>{i18n.t(`${TRANSLATION_KEY}.date`)}</Field>
              <Field>{i18n.t(`${TRANSLATION_KEY}.duration`)}</Field>
            </ContainerFields>
            <RadioGroup
              value={String(selectedInterviewId)}
              onChange={e => setSelectedInterviewId(e)}
            >
              {(interviews || []).map(interview => (
                <RadioGroup.Control
                  key={interview.id}
                  name={String(interview.id)}
                  value={String(interview.id)}
                  label={
                    <RadioLabel>
                      <LabelContainer>
                        <DateText>{formatInterviewDateByLocation(interview)}</DateText>
                        <InterviewStatus interview={interview} disableTooltip />
                      </LabelContainer>

                      <DateText>{formatSecondsToMMSS(interview.total_duration)}</DateText>
                    </RadioLabel>
                  }
                />
              ))}
            </RadioGroup>
          </RadioGroupContainer>

          <ButtonContainer>
            <CustomButton
              theme={ButtonTheme.ghost}
              onClick={() => {
                onRestartInterview(selectedInterview)
                onClose()
              }}
            >
              {i18n.t(`${TRANSLATION_KEY}.restart_interview`)}
            </CustomButton>

            <CustomButton
              onClick={() => {
                dispatch(actions.setInterviewId(+selectedInterviewId))
                onClose()
              }}
            >
              {i18n.t(`${TRANSLATION_KEY}.open_selected`)}
            </CustomButton>
          </ButtonContainer>
        </Body>
      </Container>
    </AnimatedContainer>
  )
}
