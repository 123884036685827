import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { parse as parseQuery } from 'query-string'
import { useConfig } from 'builder/hooks/useConfig'
import { DocumentsPanel, FeaturesPanel } from 'builder/components/Panel'
import { useConstant } from 'builder/hooks/useConstant'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  ResumeReviewScenarios,
  actions as resumeReviewActions,
  selectors as resumeReviewSelectors,
} from 'builder/modules/resumeReview'
import { useDocumentQueryHandler } from 'builder/components/Panel/hooks'
import TopResumeNotification from 'builder/components/Panel/TopResumeNotification'
import { PanelProps } from 'builder/components/Panel/types'

type DashboardViewProps = {
  isDocumentsPage?: boolean
} & PanelProps

export const Dashboard = ({ isDocumentsPage, ...props }: DashboardViewProps) => {
  const dispatch = useDispatch()
  const config = useConfig()
  const resumeReviewScenario = useTypedSelector(resumeReviewSelectors.scenario)

  // Find out which resume the user edited before being paid
  const downloadDocumentId = useConstant(() => {
    const params = parseQuery(window.location.search, { parseNumbers: true })
    return params.download_document_id
  })

  const hasUserComeFromPayment = typeof downloadDocumentId === 'number'

  // Some of our websites send user resumes to TopResume and we have to notify people about emails
  const shouldWarnUserAboutEmail =
    hasUserComeFromPayment && resumeReviewScenario === ResumeReviewScenarios.external

  useEffect(() => {
    if (resumeReviewScenario === ResumeReviewScenarios.embedded) {
      dispatch(resumeReviewActions.fetchCritiques())
    }
  }, [dispatch, resumeReviewScenario])

  // Download a file if it's defined in the query params and sends it to topresume
  useDocumentQueryHandler()

  const showDesktopType = () => {
    if (isDocumentsPage) return <DocumentsPanel {...props} />
    if (
      config?.features.superApp ||
      config?.features.international ||
      config?.features.dashboardV2 ||
      config?.features.dashboardV3
    ) {
      return <FeaturesPanel {...props} />
    }
    return <DocumentsPanel {...props} />
  }

  return (
    <>
      {/* Notify about upcoming email from TopResume */}
      {shouldWarnUserAboutEmail && <TopResumeNotification />}
      {showDesktopType()}
    </>
  )
}
