import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, AvailableAISuggestions, selectors } from 'builder/modules/resumeEditor'
import { MouseClickEvent } from 'builder/modules/ui'
import { i18n } from 'builder/utils/i18n'
import { useKeywords } from 'builder/components/AiFeatures/Keywords'
import { Icon20 } from 'builder/components/Icon'
import { AiSuggestionsPopupLayout } from '../AiSuggestionsPopupLayout'
import { AiSuggestionsButton } from '../AiSuggestionsButton'
import { AiSuggestionsPopupListItem } from '../AiSuggestionsPopupListItem'
import { AISuggestButton } from './styles'

type Props = {
  type: AvailableAISuggestions
  onActiveStateChange?: (isActive: boolean) => void
  // specific index needed if we want to force element to be on top of ResumeScore panel
  // even if it is open (ex. Job Title suggestions)
  popupZIndex?: number
}

const initialResumeData = {
  skills: [],
  position: '',
}

const SKILLSLABEL = {
  label: 'skills',
}

const JOBTITLELABEL = {
  label: 'job_title',
}

export const AISuggestionsToggler = (props: Props) => {
  const { type, onActiveStateChange, popupZIndex } = props
  const resume = useSelector(selectors.resume)
  const { jobPostingId } = useSelector(selectors.jobPostingAPIData)
  const { skills, position: currentJobTitle } = resume ?? initialResumeData
  const dispatch = useDispatch()
  const { isKeywordsFeatureEnabled } = useKeywords()
  const openedKeywordsModalType = useSelector(selectors.openedKeywordsModalType)

  const currentSkills = skills
    .map(suggestion => (suggestion.skill ? suggestion.skill.trim() : ''))
    .filter(Boolean)

  const openedAISuggestionsPopup = useTypedSelector(
    state => state.resumeEditor.openedAISuggestionsPopup,
  )
  const jobPostingAPIData = useTypedSelector(
    state => state.resumeEditor.optimizerSection.jobPostingAPIData,
  )
  const {
    recommendedJobTitle: suggestedJobTitle,
    visibleJobTitle,
    employerName,
    visibleSkills,
  } = jobPostingAPIData
  const tailoredDesignation =
    employerName === 'n'
      ? suggestedJobTitle || ''
      : [suggestedJobTitle, employerName].join(
          ' ' + i18n.t('builder.resume_editor.labels.at') + ' ',
        )

  const suggestionsData = useMemo(
    () => (type === 'skills' ? visibleSkills : [visibleJobTitle]).filter(Boolean) ?? [],
    [visibleJobTitle, type, visibleSkills],
  )

  const handleClosePopup = useCallback(() => {
    dispatch(actions.setOpenedAISuggestionsPopup(null))
    if (resume && resume.id) {
      dispatch(actions.updateResumeOptimizerData({ jobPostingId, resumeId: resume.id }))
    }
  }, [dispatch, jobPostingId, resume])

  const handleOpenPopup = () => {
    if (!openedAISuggestionsPopup) {
      dispatch(actions.setOpenedAISuggestionsPopup(type))
      if (type === 'skills') {
        trackInternalEvent('click_ai_suggest_resume_optimizer', SKILLSLABEL)
      } else {
        trackInternalEvent('click_ai_suggest_resume_optimizer', JOBTITLELABEL)
      }
    } else {
      handleClosePopup()
    }
  }

  const handleSuggestionClick = useCallback(
    (suggestion: string) => (e: MouseClickEvent) => {
      e.preventDefault()
      switch (type) {
        case 'jobTitle':
          dispatch(
            actions.updateSimpleField({
              name: 'position',
              value: suggestion,
              debounce: true,
            }),
          )
          trackInternalEvent('apply_ai_suggest_resume_optimizer', JOBTITLELABEL)
          handleClosePopup()
          break
        case 'skills':
          dispatch(
            actions.addCard({
              sectionName: 'skills',
              options: {
                position: 'before' as const,
                fields: { skill: suggestion },
              },
            }),
          )
          trackInternalEvent('apply_ai_suggest_resume_optimizer', SKILLSLABEL)
          break
      }
    },
    [dispatch, handleClosePopup, type],
  )

  const isSuggestionAdded = useCallback(
    (suggestion: string) =>
      type === 'skills' ? currentSkills.includes(suggestion) : currentJobTitle === suggestion,
    [currentJobTitle, currentSkills, type],
  )

  const showSuggestionsButton = suggestionsData.length > 0

  const isPopupOpen = openedAISuggestionsPopup === type

  useEffect(() => {
    onActiveStateChange?.(isPopupOpen)
  }, [isPopupOpen, onActiveStateChange])

  // auto hide popup on keywords popup open
  useEffect(() => {
    if (openedKeywordsModalType) {
      dispatch(actions.setOpenedAISuggestionsPopup(null))
    }
  }, [openedKeywordsModalType, dispatch])

  useEffect(() => {
    if (isPopupOpen) {
      switch (type) {
        case 'jobTitle':
          trackInternalEvent('open_ai_suggest_resume_optimizer', JOBTITLELABEL)
          break
        case 'skills':
          trackInternalEvent('open_ai_suggest_resume_optimizer', SKILLSLABEL)
          break
      }
    }
  }, [isPopupOpen, type])

  return showSuggestionsButton ? (
    <AiSuggestionsPopupLayout
      {...{
        isPopupOpen,
        tailoredDesignation,
        onClose: handleClosePopup,
        zIndex: popupZIndex,
        content: (
          <>
            {suggestionsData.map(suggestion => {
              const isActive = isSuggestionAdded(suggestion)
              return (
                <AiSuggestionsPopupListItem
                  key={suggestion}
                  isActive={isActive}
                  text={suggestion}
                  icon={isActive ? <Icon20.Tick /> : <Icon20.Add />}
                  onClick={handleSuggestionClick(suggestion)}
                />
              )
            })}
          </>
        ),
      }}
    >
      {isKeywordsFeatureEnabled ? (
        <AiSuggestionsButton onClick={handleOpenPopup} isActive={isPopupOpen} />
      ) : (
        <AISuggestButton onClick={handleOpenPopup}>
          {i18n.t('builder.resume_optimizer.ai_suggest')}
        </AISuggestButton>
      )}
    </AiSuggestionsPopupLayout>
  ) : null
}
