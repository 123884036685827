import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { PreFillModalClose } from 'builder/components/CareerPath2Skills'
import Sizes from 'builder/styles/sizes'

export const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 544px;
  background-color: ${Colors.White};
  border-radius: ${Sizes.S};
`

export const Header = styled.div`
  width: inherit;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
`

export const ModalBody = styled.div`
  display: flex;
  flex-flow: column;
  padding: ${Sizes.L};
  gap: ${Sizes.M};
`

export const MainContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${Sizes['2XS']};
`

export const Title = styled.div`
  display: flex;
  gap: ${Sizes.XS};
  align-items: start;
  ${Typography.S};
  ${FontWeights.DemiBold};
`

export const Description = styled.div`
  color: ${Colors.Neutral50};
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: ${Sizes['2XS']};
`

export const CloseButton = styled(PreFillModalClose)`
  background-color: ${Colors.White};
  z-index: 1;
`
