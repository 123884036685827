import React, { useEffect, useMemo, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  ColumnName,
  actions as jobTrackingActions,
  selectors as jobTrackingSelectors,
} from 'builder/modules/jobTracking'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { selectors as careerProfileSelectors } from 'builder/modules/careerProfile'

import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { Container } from '../Container'
import Tabs, { Tab } from '../Tabs'
import SearchBar from './SearchBar'

import { Count, CustomTab, FindJobContainer, PageActionsContainer, TabContainer } from './styles'
import {
  JobSearchProvider,
  RecommendationsSearchStatus,
  SearchStatus,
  useJobSearch,
} from './useJobSearch'

import { JobSearchResult } from './JobSearchResult'
import { PageActionButtons } from './PageActionButtons/PageActionButtons'
import JobSavedNotification from './JobItem/JobSavedNotification'
import { usePrefillQuery } from './hooks/usePrefillQuery'
import { usePrefillLocation } from './hooks/usePrefillLocation'
import { RecommendationsRequiredAttributes, TabType } from './types'
import { Loading } from './Loading/Loading'
import { NoTargetRole } from './RecommendationsErrors/NoTargetRole'
import { NoTargetLocation } from './RecommendationsErrors/NoTargetLocation'
import { NoWorkExperience } from './RecommendationsErrors/NoWorkExperience'
import { MultipleErrors } from './RecommendationsErrors/MultipleErrors'
import { NoTargetRoleAndNoLocation } from './RecommendationsErrors/NoTargetRoleAndNoLocation'
import { NoTargetRoleAndNoWorkExperience } from './RecommendationsErrors/NoTargetRoleAndNoWorkExperience'
import { NoTargetLocationAndNoWorkExperience } from './RecommendationsErrors/NoTargetLocationAndNoWorkExperience'

import { JoyRidesJobSearchTrackingSteps } from './JoyRidesJobSearch/JoyRidesJobSearchTrackingSteps'
interface Tab {
  labelTab: React.ReactElement
  id: TabType
  content: React.ReactElement
  joyRideId?: string
}

const FindJob = () => {
  const { i18n } = useI18n()
  const { features } = useFeaturesConfig()
  const lastUpdatedCard = useTypedSelector(jobTrackingSelectors.selectLastUpdatedCard)
  const [notificationText, setNotificationText] = useState<string>()
  const recommendationsMissingAttributes = useTypedSelector(
    careerProfileSelectors.recommendationsMissingAttributes,
  )

  const { isPhone } = useMediaQueries()
  const dispatch = useDispatch()
  const {
    selectedTabId,
    handleChangeSelectedTabId,
    jobSearchResults,
    similarResults,
    similarJobsPageNumber,
    similarJobsStats,
    setSimilarJobsPageNumber,
    scrollableSearchBarType,
    updateSearchParams,
    setRecommendationPage,
    recommendationPage,
    searchStatus,
    recommendations,
    recommendationStatus,
    setActiveJob,
    activeJob,
    recommendActiveJob,
    setRecommendActiveJob,
    jobSearchStats,
    recommendationStats,
    page,
    correctedTerm,
    lastSearchedTerm,
  } = useJobSearch()
  useEffect(() => {
    if (lastUpdatedCard) {
      switch (lastUpdatedCard.status) {
        case ColumnName.auto_apply:
          setNotificationText(i18n.t(`builder.job_tracking.auto_apply.notification.added`))
          setTimeout(() => {
            setNotificationText(undefined)
          }, 4000)
          break
        case ColumnName.wishlist:
          setNotificationText(i18n.t('builder.job_search.notification.job_card_added'))
          setTimeout(() => setNotificationText(undefined), 4000)
          break
      }
      dispatch(jobTrackingActions.setLastUpdatedCard(null))
    }
  }, [i18n, lastUpdatedCard])

  const prefillLocation = usePrefillLocation()
  const prefillQuery = usePrefillQuery()
  const tabs: Tab[] = useMemo(
    () => [
      {
        labelTab: (
          <>
            {i18n.t(`builder.job_search.recommended_jobs`)}
            {recommendationStats && recommendationStats?.total_jobs > 0 && (
              <Count>&nbsp;({recommendationStats?.total_jobs})</Count>
            )}
          </>
        ),
        id: TabType.recommendation,
        joyRideId: 'recommendation-tab',
        content: (
          <JobSearchResult
            results={recommendations}
            isSearching={recommendationStatus === RecommendationsSearchStatus.searching}
            isLoading={[
              RecommendationsSearchStatus.searching,
              RecommendationsSearchStatus.loadingPage,
            ].includes(recommendationStatus)}
            isNoResults={
              recommendationStatus === RecommendationsSearchStatus.loaded &&
              recommendations.length === 0
            }
            activeJob={recommendActiveJob}
            stats={recommendationStats}
            setActiveJob={setRecommendActiveJob}
            loadNextPage={() => {
              setRecommendationPage((recommendationPage || 1) + 1)
            }}
          />
        ),
      },
      {
        labelTab: (
          <>
            {i18n.t(`builder.job_search.search`)}
            {jobSearchStats && jobSearchStats?.total_jobs > 0 && (
              <Count>&nbsp;({jobSearchStats?.total_jobs})</Count>
            )}
          </>
        ),
        id: TabType.search,
        content: (
          <JobSearchResult
            searchTerm={lastSearchedTerm}
            correctedTerm={correctedTerm}
            results={jobSearchResults}
            isSearching={
              searchStatus === SearchStatus.searching ||
              (searchStatus === SearchStatus.loaded &&
                jobSearchResults.length === 0 &&
                lastSearchedTerm !== '' &&
                !similarJobsStats)
            }
            similarResults={similarResults}
            isLoading={[SearchStatus.searching, SearchStatus.loadingPage].includes(searchStatus)}
            isNoResults={
              searchStatus === SearchStatus.loaded &&
              jobSearchResults.length === 0 &&
              similarResults.length === 0 &&
              (lastSearchedTerm === '' || similarJobsStats?.total_jobs === 0)
            }
            activeJob={activeJob}
            setActiveJob={setActiveJob}
            stats={jobSearchStats}
            similarJobsStats={similarJobsStats}
            loadNextSimilarJobsPage={() => {
              setSimilarJobsPageNumber(similarJobsPageNumber + 1)
            }}
            loadNextPage={() => {
              updateSearchParams({
                location: prefillLocation?.prefillValue,
                page: String((page ? parseInt(page) : 1) + 1),
              })
            }}
          />
        ),
      },
    ],
    [
      activeJob,
      i18n,
      jobSearchResults,
      jobSearchStats,
      page,
      recommendActiveJob,
      recommendationStats,
      recommendationStatus,
      recommendations,
      searchStatus,
      setActiveJob,
      setRecommendActiveJob,
      setRecommendationPage,
      updateSearchParams,
      similarJobsPageNumber,
      similarResults,
      similarJobsStats,
      correctedTerm,
      lastSearchedTerm,
    ],
  )

  const selectedTabContent = useMemo(
    () => tabs.find(tab => tab.id === selectedTabId)?.content,
    [tabs, selectedTabId],
  )

  useEffect(() => {
    if (prefillLocation.isDonePrefill && prefillQuery.isDonePrefill) {
      updateSearchParams({
        location: prefillLocation.prefillValue,
        term: prefillQuery.prefillValue,
        ignoreSelectedTabId: true,
      })
      setRecommendationPage(1)
    }
  }, [prefillLocation.isDonePrefill, prefillQuery.isDonePrefill])

  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)

  let RecommendationsError = null
  if (selectedTabId === TabType.recommendation) {
    const hasTargetRole = !recommendationsMissingAttributes.includes(
      RecommendationsRequiredAttributes.TargetRole,
    )
    const hasTargetLocation = !recommendationsMissingAttributes.includes(
      RecommendationsRequiredAttributes.TargetLocation,
    )
    const hasWorkExperience =
      !recommendationsMissingAttributes.includes(
        RecommendationsRequiredAttributes.WorkExperience,
      ) &&
      !recommendationsMissingAttributes.includes(
        RecommendationsRequiredAttributes.WorkExperienceWithDetails,
      )

    if (!hasTargetRole && hasTargetLocation && hasWorkExperience) {
      RecommendationsError = <NoTargetRole />
    } else if (hasTargetRole && !hasTargetLocation && hasWorkExperience) {
      RecommendationsError = <NoTargetLocation />
    } else if (hasTargetRole && hasTargetLocation && !hasWorkExperience) {
      RecommendationsError = <NoWorkExperience />
    } else if (!hasTargetRole && !hasTargetLocation && hasWorkExperience) {
      RecommendationsError = <NoTargetRoleAndNoLocation />
    } else if (!hasTargetRole && hasTargetLocation && !hasWorkExperience) {
      RecommendationsError = <NoTargetRoleAndNoWorkExperience />
    } else if (hasTargetRole && !hasTargetLocation && !hasWorkExperience) {
      RecommendationsError = <NoTargetLocationAndNoWorkExperience />
    }

    if (!RecommendationsError && (!hasTargetLocation || !hasTargetRole || !hasWorkExperience)) {
      RecommendationsError = <MultipleErrors />
    }
  }

  if (!prefillLocation.isDonePrefill || !prefillQuery.isDonePrefill) {
    return <Loading />
  }

  return (
    <>
      <JoyRidesJobSearchTrackingSteps
        handleChangeSelectedTabId={handleChangeSelectedTabId}
        recommendations={recommendations}
        recommendationIsLoading={[
          RecommendationsSearchStatus.searching,
          RecommendationsSearchStatus.loadingPage,
        ].includes(recommendationStatus)}
        setRecommendActiveJob={setRecommendActiveJob}
      />

      <FindJobContainer $dashboardV2={features.superApp || features.international}>
        <SearchBar searchBarType="fixed" />

        {selectedTabId === TabType.search && scrollableSearchBarType && (
          <SearchBar searchBarType={scrollableSearchBarType} />
        )}
        <Container>
          <TabContainer>
            <Tabs>
              {tabs.map(tab => (
                <CustomTab
                  key={tab.id}
                  isActive={tab.id === selectedTabId}
                  onClick={() => handleChangeSelectedTabId(tab.id)}
                >
                  <div id={tab.joyRideId}>{tab.labelTab}</div>
                </CustomTab>
              ))}
            </Tabs>
            <PageActionsContainer> {!isPhone && <PageActionButtons />}</PageActionsContainer>
          </TabContainer>
          {RecommendationsError || selectedTabContent}
        </Container>

        {!autoApplyBubbles.running && (
          <JobSavedNotification show={!!notificationText} text={notificationText} />
        )}
      </FindJobContainer>
    </>
  )
}

const Wrapper: React.FC = () => (
  <JobSearchProvider>
    <FindJob />
  </JobSearchProvider>
)

export default Wrapper
