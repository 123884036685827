import { FC } from 'react'
import ModalOverlay from 'builder/components/ModalOverlay'
import { PreFillModalCloseIcon } from 'builder/components/CareerPath2Skills'
import {
  CloseButton,
  Title,
  ModalBody,
  ModalContainer,
  Header,
  MainContainer,
  ButtonsContainer,
  Description,
} from './styles'

export const ModalTemplate: FC<{
  header?: JSX.Element
  title?: JSX.Element | string
  description?: JSX.Element | string
  buttons?: JSX.Element
  customContent?: JSX.Element
  closeOnOverlayClick?: boolean
  onClose?: () => void
}> = ({
  onClose,
  header,
  title,
  description,
  buttons,
  customContent,
  closeOnOverlayClick = true,
}) => {
  return (
    <ModalOverlay
      onClick={closeOnOverlayClick && onClose}
      overlayFadeDuration={150}
      contentSlideDuration={0}
    >
      <ModalContainer>
        {onClose && (
          <CloseButton onClick={onClose}>
            <PreFillModalCloseIcon />
          </CloseButton>
        )}
        {header && <Header>{header}</Header>}
        <ModalBody>
          <MainContainer>
            {title && <Title>{title}</Title>}
            {description && <Description>{description}</Description>}
            {customContent}
          </MainContainer>
          {buttons && <ButtonsContainer>{buttons}</ButtonsContainer>}
        </ModalBody>
      </ModalContainer>
    </ModalOverlay>
  )
}
