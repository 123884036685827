import { useQuery } from '@tanstack/react-query'
import ErrorLogger from 'builder/services/ErrorLogger'
import { usePerformanceOnce } from './usePerformanceOnce'

interface Params {
  questionId?: number
  url?: string
  enabledPerformanceMetric?: boolean
}
export const useDownloadS3Asset = ({
  questionId,
  url,
  enabledPerformanceMetric = false,
}: Params) => {
  const { registerOncePerformance, stopRegisterPerformance } = usePerformanceOnce({
    enabled: enabledPerformanceMetric,
    metricKey: `download_video_s3-${questionId}`,
    tags: { questionId },
  })
  return useQuery(
    ['useDownloadS3Asset', questionId],
    async () => {
      try {
        registerOncePerformance()
        const response = await fetch(url || '')
        const blob = await response.blob()
        stopRegisterPerformance()
        return window.URL.createObjectURL(blob)
      } catch (error) {
        ErrorLogger.log(error)
        console.warn(error)
        return null
      }
    },
    {
      enabled: !!url,
    },
  )
}
