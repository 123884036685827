import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { SuggestionSelectedEventData } from 'react-autosuggest'

import { useI18n } from 'builder/hooks/useI18n'
import { TextField } from 'builder/components/TextField'
import * as SimpleForm from 'builder/components/SimpleForm'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { AutoSuggestItem, LocationForm } from 'builder/modules/resumeDistribution'

import {
  fetchCitySuggestions,
  fetchCountriesSuggestions,
  fetchStatesSuggestions,
  TransformedItem,
} from '../../JobPreferencesForm/utils'
import { Container } from './styles'

const TRANSLATION = 'builder.resume_distribution.landing.form_location_detail'

type Props = {
  form: UseFormReturn<LocationForm>
  setStateFromField: (field: string, value?: AutoSuggestItem) => void
}

export const Location = ({ form, setStateFromField }: Props) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = form

  const { i18n } = useI18n()
  const [location, setLocation] = useState<{ country?: string; state?: string; city?: string }>({
    country: getValues('country')?.text,
    state: getValues('state')?.text,
    city: getValues('city')?.text,
  })

  const required = i18n.t(`${TRANSLATION}.required_field`)

  const onChangeValue = (field: 'country' | 'state' | 'city', value?: string) => {
    if (field === 'country') {
      setLocation({ ...location, country: value, state: undefined, city: undefined })
      setValue('state', undefined)
      setValue('city', undefined)
    } else if (field === 'state') {
      setLocation({ ...location, state: value, city: undefined })
      setValue('city', undefined)
    } else {
      setLocation({ ...location, [field]: value })
    }
  }

  const getError = (field: 'country' | 'state' | 'city') => {
    if (errors[field]) {
      const err = errors[field] as { message: string }
      return err?.message
    }
  }

  const onSuggestionSelected =
    (field: 'country' | 'state' | 'city') =>
    (_: React.FormEvent<unknown>, { suggestion }: SuggestionSelectedEventData<TransformedItem>) => {
      onChangeValue(field, suggestion.text)
      setValue(field, suggestion)
      setStateFromField(field, suggestion)
      clearErrors(field)
    }

  return (
    <Container>
      <SimpleForm.Row position="relative">
        <AsyncAutosuggest<TransformedItem>
          highlightedQuery
          debounceInterval={300}
          value={location.country}
          onChange={e => onChangeValue('country', e.target.value)}
          onSuggestionSelected={onSuggestionSelected('country')}
          fetchItems={fetchCountriesSuggestions}
        >
          {inputProps => (
            <TextField
              autoComplete="off"
              role="presentation"
              {...register('country', { required })}
              {...inputProps}
              error={getError('country')}
              name="country"
              label={i18n.t(`${TRANSLATION}.country`)}
              placeholder={i18n.t(`${TRANSLATION}.country`)}
            />
          )}
        </AsyncAutosuggest>
      </SimpleForm.Row>

      <SimpleForm.Row position="relative">
        <AsyncAutosuggest<TransformedItem>
          highlightedQuery
          debounceInterval={300}
          value={location.state}
          onChange={e => onChangeValue('state', e.target.value)}
          onSuggestionSelected={onSuggestionSelected('state')}
          fetchItems={query => fetchStatesSuggestions(getValues('country')?.id as string)(query)}
        >
          {inputProps => (
            <TextField
              autoComplete="off"
              role="presentation"
              {...register('state', { required })}
              {...inputProps}
              error={getError('state')}
              name="state"
              label={i18n.t(`${TRANSLATION}.state`)}
              placeholder={i18n.t(`${TRANSLATION}.state`)}
            />
          )}
        </AsyncAutosuggest>
      </SimpleForm.Row>

      <SimpleForm.Row position="relative">
        <AsyncAutosuggest<TransformedItem>
          highlightedQuery
          debounceInterval={300}
          value={location.city}
          onChange={e => onChangeValue('city', e.target.value)}
          onSuggestionSelected={onSuggestionSelected('city')}
          fetchItems={query => fetchCitySuggestions(getValues('state')?.id as string)(query)}
        >
          {inputProps => (
            <TextField
              autoComplete="off"
              role="presentation"
              {...register('city', { required })}
              {...inputProps}
              error={getError('city')}
              name="city"
              label={i18n.t(`${TRANSLATION}.city`)}
              placeholder={i18n.t(`${TRANSLATION}.city`)}
            />
          )}
        </AsyncAutosuggest>
      </SimpleForm.Row>
    </Container>
  )
}
