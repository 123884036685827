import { useState, useCallback } from 'react'
import { AutosuggestPropsMultiSection, RenderSuggestionsContainerParams } from 'react-autosuggest'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import {
  DefaultSectionType,
  RenderSuggestionComponentStateType,
} from 'builder/components/AsyncAutosuggest/types'
import { Icon20 } from 'builder/components/Icon'

import {
  Container,
  Input,
  InputPlaceholder,
  IconWrapper,
  Menu,
  SectionHeader,
  Item,
  RejectIconWrapper,
  InputContainer,
  InputWrapper,
  Separator,
  MenuInputPlaceholderPopup,
} from './styles'
import { SearchSuggestionType, Props } from './types'

const renderSectionTitle = (section: DefaultSectionType) =>
  section.title && <SectionHeader>{section.title}</SectionHeader>

const AutoSuggestField = ({
  placeholder,
  icon,
  renderIconMenu,
  defaultSuggestions,
  clearPreviousSuggestions,
  value,
  onChange,
  onClear,
  onClick,
  onBlur,
  onSuggestionSelected,
  readOnly,
  overwritePopupWidth,
  withHover = true,
  ...rest
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const renderDefaultSuggestion = (
    suggestion: SearchSuggestionType,
    state: RenderSuggestionComponentStateType,
  ) => {
    if (!suggestion) {
      return null
    }

    return (
      <Item $isHighlighted={state.isHighlighted}>
        {renderIconMenu ? renderIconMenu(suggestion) : icon}
        {suggestion.formatted ?? suggestion.text}
      </Item>
    )
  }
  const hasCloseIcon = typeof value === 'string' && value.length > 0 && onClear

  const handleChange = useCallback(
    event => {
      const target = event.target as HTMLInputElement
      if (target.value !== undefined) {
        onChange(target.value)
      }
    },
    [onChange],
  )

  const renderDefaultSuggestionsContainer = ({
    containerProps,
    children,
  }: RenderSuggestionsContainerParams) => {
    if (!isExpanded) {
      children && setIsExpanded(true)
      return null
    }

    return (
      <Menu {...containerProps} $overwritePopupWidth={overwritePopupWidth}>
        <MenuInputPlaceholderPopup />
        {children && (
          <>
            <Separator />
            {children}
          </>
        )}
      </Menu>
    )
  }

  return (
    <Container onClick={onClick} withHover={withHover}>
      <AsyncAutosuggest<
        SearchSuggestionType,
        AutosuggestPropsMultiSection<SearchSuggestionType, DefaultSectionType>
      >
        {...rest}
        value={value}
        onChange={e => onChange(e.target.value)}
        multiSection={value.length === 0}
        renderSuggestionsContainer={renderDefaultSuggestionsContainer}
        renderSectionTitle={renderSectionTitle}
        renderSuggestion={renderDefaultSuggestion}
        getSectionSuggestions={section => section && section.suggestions}
        shouldRenderSuggestions={() => true}
        defaultSuggestions={defaultSuggestions}
        onSuggestionSelected={(e, selected) => {
          setIsExpanded(false)
          onSuggestionSelected(selected.suggestion)
        }}
        inputProps={{
          placeholder,
          value,
          onBlur: () => {
            setIsExpanded(false)
            onBlur && onBlur()
          },
          onChange: handleChange,
          onClick: () => setIsExpanded(true),
          onKeyDown: event => {
            if (event.key === 'Enter' || event.key === 'Escape') {
              setIsExpanded(false)
            }
          },
          readOnly,
        }}
        clearPreviousSuggestions={clearPreviousSuggestions}
      >
        {inputProps => (
          <InputPlaceholder $isExpanded={isExpanded} $overwritePopupWidth={overwritePopupWidth}>
            <InputContainer $isExpanded={isExpanded}>
              <InputWrapper>
                <IconWrapper>{icon}</IconWrapper>
                <Input {...inputProps} />
                {hasCloseIcon && (
                  <RejectIconWrapper>
                    <Icon20.RejectIcon onClick={onClear} />
                  </RejectIconWrapper>
                )}
              </InputWrapper>
            </InputContainer>
          </InputPlaceholder>
        )}
      </AsyncAutosuggest>
    </Container>
  )
}

export default AutoSuggestField
