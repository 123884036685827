import styled, { css } from 'styled-components'

import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div<{ $hasError?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: ${Colors.Neutral5};
  border-radius: 16px;
  margin-bottom: 16px;

  ${({ $hasError }) =>
    $hasError &&
    css`
      margin-bottom: 8px;
    `}
`

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 20px 0;
  margin: 0 30px;

  ${Media.Phone`
    padding: 16px 0;
    margin: 0 16px;
    &:last-child {
      flex-basis: 100%;
      border-top: 1px solid #ccc;
    }
  `};
`

export const Box = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Media.Phone`
    width: 45%;
  `}
`

export const Header = styled.div`
  ${Typography.S};
  ${FontWeights.Medium};
  color: ${Colors.Neutral90};
`

export const SubHeader = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
  display: flex;
  align-items: center;
`

export const VerticalLine = styled.div`
  width: 1px;
  height: 40px;
  display: block;
  margin: 29px 0;
  align-self: stretch;
  border-left: 1px solid rgb(217, 217, 217);
`

export const TooltipWrapper = styled.span`
  display: flex;
  margin-left: 5px;
  align-items: center;
`

export const ContactSupportContainer = styled.div`
  color: ${Colors.Red50};
  margin-left: auto;
  width: 50%;
  padding-left: 30px;

  ${Typography.Caption}
  ${FontWeights.Regular}

  ${Media.Phone`
    width: 100%;
    text-align: center;
    padding-left: 0;
    margin-left: unset;
  `}
`

export const ContactSupport = styled.a`
  color: ${Colors.Blue50};

  &:hover {
    border-bottom: 1px solid ${Colors.Blue50};
  }
`
