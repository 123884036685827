import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { FetchStatuses } from 'builder/modules/constants'
import { SimpleEmailForm } from 'builder/components/SimpleEmailForm'
import { useI18n } from '../../hooks/useI18n'
import useWebsiteHost from '../CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { Methods } from './components/Methods'
import { StepLayout } from './components/StepLayout'
import { NameForm } from './components/NameForm'
import { useAccountCreation } from './hooks/useAccountCreation'
import { MethodName, StepName } from './types'

export const CreateAccount = () => {
  const { status, handleSubmit } = useAccountCreation()
  const [step, setStep] = useState<StepName>('social_methods')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const [query] = useSearchParams()
  const { i18n } = useI18n()
  const { getHost } = useWebsiteHost()

  const gclid = query.get('gclid')

  useEffect(() => {
    trackMarketingEvent('Sign up', 'Start Funnel')
    if (localStorage.getItem('provisioning-premium-with-3-auto-apply-credits')) {
      trackMarketingEvent('TJSS Sign up', 'Start Funnel')
    }
  }, [])

  useEffect(() => {
    trackInternalEvent(`visit_${step}_sign_up_step`)
  }, [step])

  const handleMethodChoose = useCallback((methodName: MethodName) => {
    if (methodName === 'email') {
      trackInternalEvent('skip_social_sign_up')
      setStep('introduction')
    } else {
      trackInternalEvent('choose_social_sign_up_method', { network: methodName })
    }
  }, [])

  const handleNameFormSubmit = useCallback((payload: { firstName: string; lastName: string }) => {
    trackInternalEvent('submit_introduction_sign_up_info')
    setFirstName(payload.firstName)
    setLastName(payload.lastName)
    setStep('email')
  }, [])

  const handleEmailFormSubmit = useCallback(
    async ({ email }: { email: string }) => {
      handleSubmit({ firstName, lastName, email, gclid })
    },
    [firstName, lastName, gclid, handleSubmit],
  )

  if (step === 'introduction') {
    return (
      <StepLayout
        title={i18n.t('builder.sign_up.steps.introduction.title')}
        subtitle={i18n.t('builder.sign_up.steps.introduction.subtitle')}
      >
        <NameForm
          firstName={firstName}
          lastName={lastName}
          onSubmit={handleNameFormSubmit}
          onBackClick={() => setStep('social_methods')}
        />
      </StepLayout>
    )
  }

  if (step === 'email') {
    return (
      <StepLayout
        title={i18n.t('builder.sign_up.steps.email.title')}
        subtitle={i18n.t('builder.sign_up.steps.email.subtitle')}
      >
        <SimpleEmailForm
          onSubmit={handleEmailFormSubmit}
          onBackClick={() => setStep('introduction')}
          isPending={status === FetchStatuses.loading}
          placeholder="johnappleseed@example.com"
          allowAvailableOnly
          autoFocus
        />
      </StepLayout>
    )
  }

  return (
    <StepLayout
      title={i18n.t('builder.sign_up.steps.join.title', { host: getHost })}
      subtitle={i18n.t('builder.sign_up.steps.join.subtitle')}
    >
      <Methods onChoose={handleMethodChoose} />
    </StepLayout>
  )
}
