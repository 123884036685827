import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { AutoApplyBubbles, JobSearchStore } from './types'

export const selectors = {
  autoApplyBubbles: (state: Store) => state.jobSearch.autoApplyBubbles,
  runAutoApplyBubbles: (state: Store) => state.jobSearch.autoApplyBubbles.running,
}

const initialState: JobSearchStore = {
  autoApplyBubbles: {
    userStartedAutoApplyBubbles: false,
    running: false,
    showAutoApplyGuideButton: false,
    stepIndex: 0,
    showFirstPage: true,
    initialized: false,
  },
}

export const { reducer, actions } = createSlice({
  name: 'jobSearch',
  initialState,
  reducers: {
    setAutoApplyBubble: (draft, action: PayloadAction<AutoApplyBubbles>) => {
      draft.autoApplyBubbles = action.payload
    },
    closeFirstPageApplyBubble: draft => {
      draft.autoApplyBubbles.showFirstPage = false
    },
    goNextAutoApplyStep: draft => {
      draft.autoApplyBubbles.stepIndex = draft.autoApplyBubbles.stepIndex + 1
    },
    setRunAutoApplyBubble: (draft, action: PayloadAction<{ running: boolean }>) => {
      draft.autoApplyBubbles.running = action.payload.running
    },
  },
})
