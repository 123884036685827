import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { UserBillingStatus, UserType } from 'builder/modules/user'
import { useUser } from 'builder/hooks/useUser'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { useGetCareerProfile } from 'builder/views/AutoApply/hooks/useGetCareerProfile'
import { selectors } from 'builder/modules/autoApply/autoApplyModule'

import { AUTO_APPLY_VISITED_KEY } from 'builder/views/AutoApply/constants'
import { ButtonTheme } from 'builder/components/Button'
import { UserSettings } from 'builder/components/Navigation/components/UserSettings'
import { calculateDaysDifference } from 'builder/components/Navigation/components/PremiumTracker/utils'
import { LOGO_ELEMENT_ID } from 'builder/components/Navigation'
import { ToggleCallback } from 'builder/components/Navigation/types'
import { JobSearchSolution } from 'builder/views/AutoApply/components/JobSearchSolution/JobSearchSolution'
import { useI18n } from 'builder/hooks/useI18n'
import CIO_LOGO_URL from 'images/countries/cio-logo.svg'

import {
  Container,
  Content,
  LogoLink,
  LogoStyled,
  RightColumn,
  CustomLogo,
  BookCoachButton,
  CoachButtonContainer,
  PremiumButtonWrapper,
  PremiumTrackerWrapper,
} from './styles'

interface Props {
  isUserDropdownOpen: boolean
  onUserDropdownToggle: ToggleCallback
}

export const NavBar = ({ isUserDropdownOpen, onUserDropdownToggle }: Props) => {
  const user = useUser()
  const { i18n } = useI18n()
  const location = useLocation()
  const navigate = useNavigate()
  const { isPhone } = useMediaQueries()
  const { data } = useGetCareerProfile()
  const { features } = useFeaturesConfig()

  const isAutoApplyVisited = localStorage.getItem(AUTO_APPLY_VISITED_KEY)
  const isAutoApplyFormCompleted = useTypedSelector(selectors.isAutoApplyFormCompleted)

  const promoBanner = localStorage.getItem('promoBanner')

  const daysLeftPremium = useMemo(
    () =>
      user?.billingInfo?.premiumEndsAt
        ? calculateDaysDifference(user?.billingInfo?.premiumEndsAt) + 1
        : 0,
    [user?.billingInfo.premiumEndsAt],
  )

  const isUpsiderLoaderPage = useMemo(() => {
    return location.pathname?.includes('/upsider-loading')
  }, [location.pathname])

  const showPremiumTracker = useMemo(() => {
    return (
      daysLeftPremium <= 7 &&
      user?.billingStatus !== UserBillingStatus.subscription &&
      user?.billingInfo.userType &&
      [UserType.premium, UserType.postPremium].includes(user?.billingInfo.userType)
    )
  }, [daysLeftPremium, user])

  const bookCoachOnClick = useCallback(() => {
    navigate('/career-coaching')
    trackInternalEvent('click_book_a_coach', { label: 'top_nav' })
  }, [navigate])

  const isJobSearchSolutionActive = () => {
    return (
      features?.autoApply &&
      user?.scopes?.autoApply &&
      !isAutoApplyFormCompleted &&
      !data?.autoApplyDataCompleted &&
      isAutoApplyVisited
    )
  }

  const showCareerCoaching =
    user?.hasPremiumFeatures && features.careerCoaching && !isJobSearchSolutionActive()

  return (
    <Container>
      <Content>
        {!isUpsiderLoaderPage && (
          <LogoLink
            aria-label="Logo"
            isMenuOpen={false}
            to={promoBanner === '1' ? '/?tr_promo_banner=1' : '/'}
          >
            <LogoStyled id={LOGO_ELEMENT_ID} variant="default" />
          </LogoLink>
        )}
        {isUpsiderLoaderPage && (
          <LogoLink
            aria-label="Logo"
            isMenuOpen={false}
            to={promoBanner === '1' ? '/?tr_promo_banner=1' : '/'}
          >
            <CustomLogo src={CIO_LOGO_URL} />
          </LogoLink>
        )}

        {!isUpsiderLoaderPage && (
          <RightColumn isMenuOpen={false}>
            {!isPhone && <JobSearchSolution id="job-search-solution" />}
            {!user?.hasPremiumFeatures && user?.billingInfo.userType !== UserType.postPremium && (
              <PremiumButtonWrapper />
            )}
            {showCareerCoaching && (
              <CoachButtonContainer>
                <BookCoachButton theme={ButtonTheme.ghost} onClick={bookCoachOnClick}>
                  {i18n.t('builder.navbar_v2.book_coach')}
                </BookCoachButton>
              </CoachButtonContainer>
            )}
            {showPremiumTracker && <PremiumTrackerWrapper daysLeftPremium={daysLeftPremium} />}
            {user && (
              <UserSettings
                user={user}
                isOpen={isUserDropdownOpen}
                onToggle={onUserDropdownToggle}
              />
            )}
          </RightColumn>
        )}
      </Content>
    </Container>
  )
}
