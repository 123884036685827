import { trackInternalEvent } from '@rio/tracking'
import { MouseClickEvent } from 'builder/modules/ui'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import {
  ActionButton,
  ArrowDown,
  CompletedIcon,
  CompletedIconContainer,
  Container,
  Icon,
  IconCompleted,
  IconCompletedContainer,
  SelectedIcon,
  SelectedIconContainer,
  Subtitle,
  SubtitleContainer,
  TextContainer,
  Title,
} from './styles'

interface Props {
  title: string
  subtitle: string
  isCompleted: boolean
  Images: React.ReactNode
  useSecondaryTheme?: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  handleOnClick?: () => void
  setShowHover: (showHover: boolean) => void
  buttonText: string
  analyticsEventsParam?: string
  CustomText?: React.ReactNode
  customHeight?: number
  customTabletHeight?: number
  excludeAnalytics?: boolean
  titleClassname?: string
}

export const Step = ({
  title,
  isCompleted,
  subtitle,
  useSecondaryTheme = false,
  isOpen,
  setIsOpen,
  handleOnClick,
  Images,
  setShowHover,
  buttonText,
  analyticsEventsParam,
  CustomText,
  customHeight,
  customTabletHeight,
  excludeAnalytics = false,
  titleClassname,
}: Props) => {
  const mediaQueries = useMediaQueries()

  let className = ''
  if (isCompleted) {
    className = 'completed'
  } else if (isOpen) {
    if (useSecondaryTheme) {
      className = 'selected-secondary'
    } else {
      className = 'selected-primary'
    }
  }

  return (
    <Container
      className={className}
      isOpen={isCompleted ? false : isOpen}
      onClick={() => {
        if (isCompleted) return

        if (!isOpen) {
          !excludeAnalytics &&
            trackInternalEvent('expand_stepper_action', { target: analyticsEventsParam })
        } else {
          if (!mediaQueries.isPhone) {
            handleOnClick &&
              !excludeAnalytics &&
              trackInternalEvent('click_stepper_action', { target: analyticsEventsParam })
            handleOnClick && handleOnClick()
          }
        }

        setIsOpen(!isOpen)
      }}
      onMouseOver={() => setShowHover(true)}
      onMouseOut={() => setShowHover(false)}
    >
      {!isCompleted && (
        <Icon className={useSecondaryTheme && isOpen ? 'secondary' : ''} isOpen={isOpen} />
      )}
      {isCompleted && (
        <IconCompletedContainer>
          <IconCompleted />
        </IconCompletedContainer>
      )}
      <TextContainer>
        <Title isOpen={isOpen} className={`${className} ${titleClassname || ''}`}>
          {title}
        </Title>
        <SubtitleContainer className={isOpen ? 'visible' : 'hidden'}>
          {isOpen && (
            <Subtitle className={className} height={customHeight} tabletHeight={customTabletHeight}>
              {subtitle}
            </Subtitle>
          )}
        </SubtitleContainer>
        <ActionButton
          isOpen={isOpen}
          className={className}
          onClick={(event: MouseClickEvent) => {
            event.stopPropagation()
            handleOnClick &&
              !excludeAnalytics &&
              trackInternalEvent('click_stepper_action', { target: analyticsEventsParam })
            handleOnClick && handleOnClick()
          }}
        >
          {buttonText}
        </ActionButton>
      </TextContainer>
      {isOpen && (
        <SelectedIconContainer
          className={`selected-icon-container ${useSecondaryTheme ? 'secondary' : ''}`}
        >
          <SelectedIcon
            className={`selected-icon-container ${useSecondaryTheme ? 'secondary' : ''}`}
          />
        </SelectedIconContainer>
      )}
      {CustomText && !isOpen && !isCompleted && CustomText}
      {!isOpen && !isCompleted && <ArrowDown />}
      {isCompleted && (
        <CompletedIconContainer className="completed-icon-container">
          <CompletedIcon className="completed-icon-container" />
        </CompletedIconContainer>
      )}
      {isOpen && Images}
    </Container>
  )
}
