import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useDebouncedEffect from 'use-debounced-effect'
import { trackInternalEvent } from '@rio/tracking'
import { actions } from 'builder/modules/resumeEditor'
import ModalOverlay from 'builder/components/ModalOverlay'
import { ButtonSize } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import { i18n } from 'builder/utils/i18n'

import AICVBottomResultView from '../AICoverLetter/BottomContainer/ResultView'
import AICVDataResultView from '../AICoverLetter/DataContainer/ResultView'
import {
  MainContainer,
  TopContainer,
  BottomContainer,
  ValidationSuccessIcon,
  ValidationFailIcon,
  ValidationLoadingIcon,
  CloseIcon,
  GradientContainer,
  CloseIconWrapper,
} from './styles'
import BottomContainerInitialView from './BottomContainer/Initial'
import BottomContainerLoadingView from './BottomContainer/Loading'
import DataContainerInitialView from './DataContainer/Views/Initial'
import DataContainerLoadingView from './DataContainer/Views/Loading'
import { DEBOUNCE_DELAY, urlRegex, hostURL } from './constants'
import { ErrorState } from './ErrorState/ErrorState'
import ManualInput from './ManualInput'

const statusIcons = {
  [FetchStatuses.notAsked]: <></>,
  [FetchStatuses.loading]: <ValidationLoadingIcon />,
  [FetchStatuses.loaded]: <ValidationSuccessIcon />,
  [FetchStatuses.failed]: <ValidationFailIcon />,
}

const getStatusText = (status: FetchStatuses | null): string => {
  switch (status) {
    case FetchStatuses.failed:
      return i18n.t('builder.resume_optimizer.job_posting.link_error')
    default:
      return i18n.t('builder.resume_optimizer.job_posting.example')
  }
}

interface Props {
  resumeId: number
}

export const AICoverLetter = ({ resumeId }: Props) => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const buttonSize = !isPhone ? ButtonSize.default : ButtonSize.small
  const optimizerSection = useTypedSelector(state => state.resumeEditor.optimizerSection)
  const AICoverLetter = useTypedSelector(state => state.resumeEditor.AICoverLetter)
  const isLinkedToJobPosting = !!useTypedSelector(
    state => state.resumeEditor.optimizerSection.jobPostingAPIData.jobPostingId,
  )

  const { aICVEditedJobTitle, aICVEditedCompanyName, generateAICoverLetterStatus } = AICoverLetter

  const {
    fetchScoreStatus: fetchTailoredScoreStatus,
    saveJobPostingDataStatus,
    jobPostingLinkForModal: jobPostingLink,
    resumeScoreAPIData: { averageScore, suggestedJobTitle, employerName },
    manualInput,
    showManualInputScreen,
  } = optimizerSection

  const initialValidationResult = urlRegex.test(jobPostingLink ?? '')
  const domain =
    jobPostingLink && !manualInput.companyName ? jobPostingLink.replace(hostURL, '$3') : ''
  const hostName = domain ? i18n.t('builder.ai_cover_letter.from') + ' ' + domain : ''

  const [linkValidationStatus, setLinkValidationStatus] = useState<FetchStatuses | null>(
    jobPostingLink ? (initialValidationResult ? FetchStatuses.loaded : FetchStatuses.failed) : null,
  )

  const isAICVEditedCompanyNameEmpty = !aICVEditedCompanyName || aICVEditedCompanyName.length === 0
  const isAICVEditedJobTitleEmpty = !aICVEditedJobTitle || aICVEditedJobTitle.length === 0
  const isEditDetailsEmpty = isAICVEditedCompanyNameEmpty && isAICVEditedJobTitleEmpty

  const tailoredDesignation = isEditDetailsEmpty
    ? [suggestedJobTitle, employerName].join(' ' + i18n.t('builder.resume_editor.labels.at') + ' ')
    : isAICVEditedCompanyNameEmpty
    ? aICVEditedJobTitle
    : [aICVEditedJobTitle, aICVEditedCompanyName].join(
        ' ' + i18n.t('builder.resume_editor.labels.at') + ' ',
      )

  useDebouncedEffect(
    () => {
      if (jobPostingLink) {
        const validationResult = urlRegex.test(jobPostingLink)
        setLinkValidationStatus(validationResult ? FetchStatuses.loaded : FetchStatuses.failed)
      }
    },
    DEBOUNCE_DELAY,
    [jobPostingLink],
  )

  const handleLinkChange = useCallback(
    event => {
      const inputValue = event.target.value.replaceAll('https://', '')
      const linkValue = inputValue ? 'https://' + inputValue : ''
      dispatch(actions.setJobPostingLinkForModal(linkValue))
      setLinkValidationStatus(FetchStatuses.loading)
    },
    [dispatch],
  )

  const isValidLink = linkValidationStatus === FetchStatuses.loaded
  const isInvalidLink = linkValidationStatus === FetchStatuses.failed

  const handleClose = useCallback(() => {
    if (saveJobPostingDataStatus !== FetchStatuses.loading) {
      dispatch(actions.setOpenAICVOptimizerModal(false))
      dispatch(actions.resetResumeOptimizerProcess())
      dispatch(actions.setIsClickedOnAiCVBanner(false))
      if (isLinkedToJobPosting) {
        dispatch(actions.setIsClickedOnAiCVBanner(true))
        dispatch(actions.setIsEditJobDetailsFocused(false))
        dispatch(actions.setIsClickedOnAiCVEditJobDetails(false))
      }
    }
  }, [dispatch, isLinkedToJobPosting, saveJobPostingDataStatus])

  const handleContinue = useCallback(() => {
    dispatch(
      actions.checkResumeScore({
        jobPostingLink,
        resumeId,
      }),
    )
    trackInternalEvent('click_continue_job_posting_link')
  }, [dispatch, jobPostingLink, resumeId])

  const showErrorModal = fetchTailoredScoreStatus === FetchStatuses.failed
  const showResultScreen = fetchTailoredScoreStatus === FetchStatuses.loaded
  const isGenerateCV = generateAICoverLetterStatus === FetchStatuses.loading
  if (showErrorModal) {
    trackInternalEvent('see_resume_optimizer_error_screen')
  }

  const handleTryAgain = useCallback(() => {
    dispatch(actions.resetResumeOptimizerProcess())
    if (showErrorModal) {
      trackInternalEvent('click_try_again_resume_optimizer_error_screen')
    } else if (showResultScreen) {
      trackInternalEvent('click_try_again_resume_optimizer_result_screen')
    }
  }, [dispatch, showErrorModal, showResultScreen])

  const handleTryOtherLink = useCallback(() => {
    dispatch(actions.resetResumeOptimizerProcess())
    dispatch(actions.setOpenAICVOptimizerModal(true))
    trackInternalEvent('click_another_link', { label: 'job_posting_result_screen' })
  }, [dispatch])

  useEffect(() => {
    if (isValidLink) {
      trackInternalEvent('send_job_posting_link', { status: 'ok', link: jobPostingLink })
    } else if (isInvalidLink) {
      trackInternalEvent('send_job_posting_link', { status: 'failed', link: jobPostingLink })
    }
  }, [isValidLink, isInvalidLink, jobPostingLink])

  useEffect(() => {
    if (fetchTailoredScoreStatus === FetchStatuses.notAsked) {
      trackInternalEvent('see_resume_optimizer_link_screen')
    } else if (fetchTailoredScoreStatus === FetchStatuses.loaded) {
      trackInternalEvent('see_job_posting_result_screen', { score: averageScore })
    }
  }, [fetchTailoredScoreStatus, averageScore])

  const handleGenerate = useCallback(() => {
    dispatch(actions.setGenerateAICoverLetterStatus(FetchStatuses.loading))
    dispatch(actions.setShowUntailoreView(true))

    if (isEditDetailsEmpty) {
      dispatch(
        actions.generateAICVUntailored({
          jobTitle: suggestedJobTitle,
          companyName: employerName,
          jobPostingLink,
          resumeId,
        }),
      )
    } else {
      dispatch(
        actions.generateAICVUntailored({
          jobTitle: aICVEditedJobTitle,
          companyName: aICVEditedCompanyName,
          jobPostingLink,
          resumeId,
        }),
      )
    }

    trackInternalEvent('click_generate_cover_letter', { label: 'job_posting_result_screen' })
  }, [
    aICVEditedCompanyName,
    aICVEditedJobTitle,
    dispatch,
    employerName,
    isEditDetailsEmpty,
    jobPostingLink,
    resumeId,
    suggestedJobTitle,
  ])

  const topContainerView = useMemo(() => {
    switch (fetchTailoredScoreStatus) {
      case FetchStatuses.notAsked:
        return <DataContainerInitialView {...{ jobPostingLink, isValidLink }} />
      case FetchStatuses.loading:
        return <DataContainerLoadingView />
      case FetchStatuses.loaded: {
        return (
          <AICVDataResultView
            {...{
              resumeId,
              tailoredDesignation,
              hostName,
            }}
          />
        )
      }
    }
  }, [
    fetchTailoredScoreStatus,
    jobPostingLink,
    isValidLink,
    resumeId,
    tailoredDesignation,
    hostName,
  ])

  const bottomContainerView = useMemo(() => {
    switch (fetchTailoredScoreStatus) {
      case FetchStatuses.notAsked:
        return (
          <BottomContainerInitialView
            jobPostingLink={jobPostingLink}
            onChange={handleLinkChange}
            Icon={linkValidationStatus && jobPostingLink ? statusIcons[linkValidationStatus] : null}
            error={isInvalidLink ? getStatusText(linkValidationStatus) : ''}
            status={!isInvalidLink ? getStatusText(linkValidationStatus) : ''}
            onClose={handleClose}
            size={buttonSize}
            isDisabled={!isValidLink}
            onContinue={handleContinue}
          />
        )
      case FetchStatuses.loading:
        return <BottomContainerLoadingView ButtonSize={buttonSize} />

      case FetchStatuses.loaded: {
        return (
          <AICVBottomResultView
            ButtonSize={buttonSize}
            onTryAgainClick={!isGenerateCV ? handleTryOtherLink : () => {}}
            onGenerate={handleGenerate}
          />
        )
      }
    }
  }, [
    fetchTailoredScoreStatus,
    jobPostingLink,
    handleLinkChange,
    linkValidationStatus,
    isInvalidLink,
    handleClose,
    buttonSize,
    isValidLink,
    handleContinue,
    isGenerateCV,
    handleTryOtherLink,
    handleGenerate,
  ])

  return (
    <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300}>
      {!showErrorModal ? (
        showManualInputScreen ? (
          <ManualInput onClose={handleClose} />
        ) : (
          <MainContainer>
            <TopContainer>
              <CloseIconWrapper
                showHoverState={saveJobPostingDataStatus !== FetchStatuses.loading || !isGenerateCV}
                onClick={!isGenerateCV ? handleClose : () => {}}
              >
                <CloseIcon />
              </CloseIconWrapper>
              {topContainerView}
              <GradientContainer />
            </TopContainer>

            <BottomContainer>{bottomContainerView}</BottomContainer>
          </MainContainer>
        )
      ) : (
        <ErrorState onClose={handleClose} onTryAgain={handleTryAgain} ButtonSize={buttonSize} />
      )}
    </ModalOverlay>
  )
}
