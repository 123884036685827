import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { matchPath, useLocation } from 'react-router-dom'
import { navigate } from 'builder/modules/navigate'
import { actions as jobSearchActions } from 'builder/modules/jobSearch'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { selectors as userSelectors } from 'builder/modules/user'
import {
  selectors as renderingSelectors,
  actions as renderingActions,
} from 'builder/modules/rendering'
import { useGetCareerProfile } from 'builder/views/AutoApply/hooks/useGetCareerProfile'

const MODAL_SHOWN_STORAGE_KEY = 'rd_get_started_shown'
const AUTO_APPLY_URL = '/auto-apply/start'
const RECOMMENDED_JOBS = '/job-search?view=recommendation'

export const useResumeDistributionGetStartedModal = () => {
  const wasRdGetStartedShown = localStorage.getItem(MODAL_SHOWN_STORAGE_KEY)

  const dispatch = useDispatch()
  const { data: careerProfileData } = useGetCareerProfile()
  const userData = useTypedSelector(userSelectors.userData)
  const rdUpsellGetStartedModalVisible = useTypedSelector(
    renderingSelectors.rdUpsellGetStartedModalVisible,
  )
  const location = useLocation()

  const isDashboardOpen = useMemo(() => {
    return (
      matchPath('/', location.pathname) ||
      matchPath('/resumes', location.pathname) ||
      matchPath('/cover-letters', location.pathname)
    )
  }, [location.pathname])

  const shouldModalOpen = useCallback(() => {
    return !wasRdGetStartedShown && rdUpsellGetStartedModalVisible && !!isDashboardOpen
  }, [wasRdGetStartedShown, rdUpsellGetStartedModalVisible, isDashboardOpen])

  const [isOpen, setIsOpen] = useState(shouldModalOpen)

  function setRDGetStartedShown() {
    localStorage.setItem(MODAL_SHOWN_STORAGE_KEY, 'true')
  }

  function handleModalClose() {
    setRDGetStartedShown()
    dispatch(renderingActions.closeRDUpsellGetStartedModal())
    setIsOpen(false)
  }

  function redirectToAutoApply() {
    trackInternalEvent('click_tjs_upgrade_button')
    handleModalClose()

    if (careerProfileData?.autoApplyDataCompleted) {
      dispatch(jobSearchActions.startAutoApplyBubble({ enableFirstPage: true }))
      navigate(RECOMMENDED_JOBS)
    } else {
      navigate(AUTO_APPLY_URL)
    }
  }

  const showModalAfterPayment = useCallback(() => {
    if (
      userData?.scopes?.autoApply &&
      userData?.scopes?.resumeDistribution &&
      !wasRdGetStartedShown &&
      !userData.billingInfo.premiumEndsAt &&
      !!isDashboardOpen
    ) {
      setIsOpen(true)
    }
  }, [
    userData?.billingInfo.premiumEndsAt,
    userData?.scopes?.autoApply,
    userData?.scopes?.resumeDistribution,
    wasRdGetStartedShown,
    isDashboardOpen,
  ])

  useEffect(() => {
    setIsOpen(shouldModalOpen())
    showModalAfterPayment()
  }, [rdUpsellGetStartedModalVisible, shouldModalOpen, showModalAfterPayment])

  return {
    isOpen,
    handleModalClose,
    redirectToAutoApply,
  }
}
