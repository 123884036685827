import { FC } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { UserData } from 'builder/modules/user'
import { i18n } from 'builder/utils/i18n'
import PhotoIcon from 'builder/components/PhotoUploadField/PhotoIcon'
import Icon from 'builder/components/Icon'
import {
  PhotoImage,
  Photo,
  SocialIcon,
  TitleText,
  Title,
} from 'builder/components/LinkedinPrefillModal/styles'

interface Props {
  user: UserData
  photoUrl: string | null
}

const Header: FC<Props> = ({ user, photoUrl }) => {
  return (
    <Title>
      <Photo onClick={() => trackInternalEvent('click_linkedin_prefill_preview_avatar')}>
        <PhotoIcon />
        {photoUrl && <PhotoImage src={photoUrl} />}
        <SocialIcon>
          <Icon.Linkedin />
        </SocialIcon>
      </Photo>

      <TitleText>{i18n.t('builder.prefill.linkedin.title', { name: user.firstName })}</TitleText>
    </Title>
  )
}

export default Header
