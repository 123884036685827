import { RefObject, useContext, useEffect, useRef } from 'react'
import { Optional } from 'packages/types'
import { useDispatch } from 'react-redux'
import { i18n as I18n } from 'builder/utils/i18n'
import { RichTextAreaContext } from 'builder/context/RichTextAreaContext'
import AiProfileSummary from 'builder/components/AiProfileSummary'
import PhrasesSuggestion, { transitionDurationMs } from 'builder/components/PhrasesSuggestion'
import AIProfileSummaryResult from 'builder/components/AIProfileSummaryResult'
import AiAssistantProcessing from 'builder/components/AiProfileSummary/AiAssistantProcessing/AiAssistantProcessing'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { actions, selectors, SectionNames } from 'builder/modules/resumeEditor'
import { GenerateAiProfileStatus } from 'builder/modules/constants'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { isEmptyField } from 'builder/modules/resumeEditor/resumeScoreRules/sectionRule'
import { useKeywords } from 'builder/components/AiFeatures/Keywords'
import { SuggestionVariantsPopup } from 'builder/components/AiFeatures/SuggestionVariantsPopup'
import { SuggestionConfig, ToolbarActions } from '../types'
import { SuggestionsToggler } from './SuggestionsToggler'
interface Props {
  suggestionConfig: Optional<SuggestionConfig>
  locale: string
  isAiAction: boolean
  shouldUseAiProfileSuggestions: boolean
  addPhrase: ToolbarActions['addPhrase']
  replacePhrase: ToolbarActions['replacePhrase']
  getEditorText: () => string
  editorContainerRef: RefObject<HTMLElement>
}

/**
 * Added because on some phones (probably only Android) top bar overlaps
 * page and position { top: 0 } is under this bar, so we need to compensate
 */
const MOBILE_SCROLL_TO_SUGGESTIONS_OFFSET = 63

// Addition to animation end, just to compensate possible lags
const SCROLL_DELAY_MS = 100

/**
 * Contains suggestion button and popups appeared on this button click
 */
export const useSuggestionRenders = (props: Props) => {
  const {
    suggestionConfig,
    isAiAction,
    shouldUseAiProfileSuggestions,
    getEditorText,
    locale,
    addPhrase,
    replacePhrase,
    editorContainerRef,
  } = props
  const phrasesOpenerRef = useRef<HTMLDivElement>(null)
  const phrasesSuggestionRef = useRef<HTMLDivElement>(null)
  const aiProfileSummaryResultRef = useRef<HTMLDivElement>(null)
  const {
    summary: generatedContent,
    generateAiProfileStatus,
    shouldAnimateEditorFieldContent,
    shouldAnimateResultPopupContent,
  } = useTypedSelector(selectors.aiProfile)
  const {
    isSuggestionsOpen,
    toggleAiProfileSummary,
    toggleSuggestions,
    isSuggestionVariantsOpen,
    toggleSuggestionVariants,
  } = useContext(RichTextAreaContext)
  const isHelpWithWritingOpen = useTypedSelector(selectors.isHelpWithWritingOpen)
  const isAIProfileSummaryResultOpen = useTypedSelector(selectors.isAIProfileSummaryResultOpen)
  const { isPhone, isTablet, isLaptop } = useMediaQueries()
  const isSmallDisplay = isPhone || isTablet || isLaptop
  const dispatch = useDispatch()
  const resume = useTypedSelector(selectors.resume)
  const isProfileEmpty = resume && isEmptyField(SectionNames.profile, resume[SectionNames.profile])
  const previousStatus = useRef(generateAiProfileStatus)
  const { isKeywordsAvailable } = useKeywords()

  useEffect(() => {
    if (
      // open popup only on status change
      generateAiProfileStatus !== previousStatus.current &&
      generateAiProfileStatus === GenerateAiProfileStatus.finished &&
      !isProfileEmpty
    ) {
      dispatch(actions.setIsAIProfileSummaryResultOpen({ status: true }))
    }

    previousStatus.current = generateAiProfileStatus
  }, [
    dispatch,
    shouldAnimateEditorFieldContent,
    shouldAnimateResultPopupContent,
    generateAiProfileStatus,
    isProfileEmpty,
  ])

  // By design we need to scroll to opened popup if it is not visible now
  useEffect(() => {
    let timeout: number | undefined

    if (isSmallDisplay && (isSuggestionsOpen || isAIProfileSummaryResultOpen)) {
      timeout = window.setTimeout(() => {
        const element = phrasesSuggestionRef.current || aiProfileSummaryResultRef.current
        const scrollElement = document.scrollingElement

        if (!element || !scrollElement) return

        const { top: elementRectTop } = element.getBoundingClientRect()
        const { scrollTop } = scrollElement
        const elementTop = scrollTop + elementRectTop - MOBILE_SCROLL_TO_SUGGESTIONS_OFFSET

        if (elementTop < scrollTop) {
          scrollElement.scroll({ top: elementTop, behavior: 'smooth' })
        }
      }, transitionDurationMs + SCROLL_DELAY_MS)
    }

    return () => clearTimeout(timeout)
  }, [isSmallDisplay, isSuggestionsOpen, isAIProfileSummaryResultOpen])

  const renderAction = () => {
    if (suggestionConfig?.mode === 'phrases') {
      const onToggleAiProfileSummary = () => {
        if (!isSuggestionsOpen && !isAIProfileSummaryResultOpen && !isHelpWithWritingOpen) {
          suggestionConfig.onOpenAiProfileButtonClick?.()
        }

        if (isSuggestionsOpen) {
          toggleSuggestions({ isOpen: false })
          return
        }

        if (generatedContent) {
          dispatch(actions.setIsAIProfileSummaryResultOpen({ status: true }))
          dispatch(actions.setAIProfileResultContentAnimation(false))
        } else {
          toggleAiProfileSummary({
            isOpen: !isHelpWithWritingOpen,
            trigger: 'click',
          })
        }
      }

      const onToggleSuggestionVariants = () => {
        if (!isSuggestionVariantsOpen) {
          suggestionConfig.onOpenSuggestionVariants?.()
        }
        toggleSuggestionVariants()
      }

      return (
        <>
          {shouldUseAiProfileSuggestions ? (
            <SuggestionsToggler
              isActive={isHelpWithWritingOpen || isAIProfileSummaryResultOpen || isSuggestionsOpen}
              ref={phrasesOpenerRef}
              onToggle={onToggleAiProfileSummary}
              text={I18n.t('builder.ai_profile_summary.help_with_writing_modal.button_text')}
              {...{ isAiAction }}
            />
          ) : isKeywordsAvailable ? (
            <SuggestionsToggler
              isActive={isSuggestionVariantsOpen}
              ref={phrasesOpenerRef}
              onToggle={onToggleSuggestionVariants}
              text={I18n.t('builder.ai_profile_summary.help_with_writing_modal.button_text')}
              isAiAction={true}
            />
          ) : (
            <SuggestionsToggler
              isActive={isSuggestionsOpen}
              ref={phrasesOpenerRef}
              onToggle={() => toggleSuggestions({ trigger: 'click' })}
              {...{ isAiAction }}
            />
          )}
          {isSuggestionVariantsOpen && (
            <SuggestionVariantsPopup
              onClose={() => toggleSuggestionVariants({ isOpen: false })}
              onAddPrewrittenPhrasesClick={() =>
                toggleSuggestions({ isOpen: true, trigger: 'click' })
              }
              {...{ phrasesOpenerRef, suggestionConfig, editorContainerRef }}
            />
          )}
          {isSuggestionsOpen && (
            <PhrasesSuggestion
              text={getEditorText()}
              onSelect={addPhrase}
              locale={locale}
              outsideOpenerRef={phrasesOpenerRef}
              scope={suggestionConfig.scope}
              ref={phrasesSuggestionRef}
              editorContainerRef={editorContainerRef}
            />
          )}
          {isHelpWithWritingOpen && !generatedContent && shouldUseAiProfileSuggestions && (
            <AiProfileSummary {...{ suggestionConfig, phrasesOpenerRef, editorContainerRef }} />
          )}
          {isAIProfileSummaryResultOpen && shouldUseAiProfileSuggestions && (
            <AIProfileSummaryResult
              ref={aiProfileSummaryResultRef}
              {...{ replacePhrase, phrasesOpenerRef, suggestionConfig, editorContainerRef }}
            />
          )}
          <AiAssistantProcessing {...{ suggestionConfig }} />
        </>
      )
    }

    return null
  }

  return { renderAction }
}
