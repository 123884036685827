import styled, { css } from 'styled-components'
import Button from 'builder/components/Button'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Icon from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'

enum SocialColors {
  linkedIn = '#2C66BC',
}

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 544px;
  color: ${Colors.Neutral90};
  background-color: ${Colors.White};
  border-radius: 6px;
  min-height: 236px;

  ${Media.Phone`
    min-height: 238px;
    max-height: 100%;
  `};
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 32px 0;
  margin-bottom: 24px;

  ${Media.Phone`
    margin-bottom: 16px;
    padding: 20px 20px 0;
  `};
`

export const Content = styled.div`
  padding: 0 32px 32px;

  ${Media.Phone`
    padding: 20px;
  `};
`

export const Photo = styled.div`
  margin-right: 24px;
  flex-shrink: 0;
  position: relative;
  width: 72px;
  height: 72px;
  background: ${Colors.Neutral10};
  color: ${Colors.Neutral30};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Media.Phone`
    margin-right: 16px;
  `}
`

export const PhotoImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
`

export const SocialIcon = styled.div<{ bordered?: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background-color: ${SocialColors.linkedIn};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.bordered
      ? css`
          bottom: -10px;
          right: -2px;
          width: 26px;
          height: 26px;
          border: 2px solid #113f74;
        `
      : css`
          box-shadow: 0 0 0 2px ${hexToRgba(SocialColors.linkedIn, 0.24)};
        `}

  & svg {
    width: 16px;
    height: 16px;
  }

  & path {
    fill: ${Colors.White};
  }
`

export const TitleText = styled.span`
  ${Typography.S}
  font-weight: 600;

  ${Media.Phone`
    line-height: 24px;
  `};
`

export const Field = styled.div`
  margin-bottom: 32px;
  position: relative;

  ${Media.Phone`
    margin-bottom: 20px;
  `}
`

export const FieldPlaceholder = styled.div`
  ${Typography.Caption}
  margin-top: 12px;
  color: ${Colors.Neutral50};
`

export const Caption = styled.div`
  margin-bottom: 16px;

  ${Media.Phone`
    ${Typography.Caption}
    margin-bottom: 12px;
  `};
`

export const CaptionLink = styled.a`
  color: ${Colors.Blue50};
  transition: color 0.3s ease;

  &:hover {
    color: ${Colors.Blue70};
  }
`

export const CaptionLinkIcon = styled.span`
  position: relative;
  left: 1px;
`

export const Actions = styled.div`
  display: flex;

  ${Media.Phone`
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`

export const SubmitButton = styled(Button)`
  margin-right: 12px;

  ${Media.Phone`
    width: calc(50% - 7px);
    margin-right: 0;
  `};
`

export const CancelButton = styled(Button)`
  ${Media.Phone`
    width: calc(50% - 9px);
  `};
`

export const PrefillLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`

export const PrefillIcon = styled.div`
  position: relative;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  background-color: ${Colors.Green10};

  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 3px;
    left: 3px;
    background: ${Colors.White};
    border-radius: 50%;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
`

export const PrefillLoadingTitle = styled.div`
  ${Typography.S}
  font-weight: 600;
  margin-bottom: 8px;
`
export const PrefillLoadingSubtitle = styled.div`
  ${Media.Phone`
    padding: 0 20px;
  `};
`

export const ValidationLoadingIcon = styled(Icon.Spinner)`
  color: ${Colors.Blue50};
  animation: ${rotation} 0.5s linear infinite;
`

export const ValidationSuccessIcon = styled(Icon.Success)`
  color: ${Colors.Green50};
`

export const ClearButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  color: ${Colors.Neutral50};
  transition: color 0.3s ease;
  position: absolute;
  top: 0;
  right: 0;

  &:focus,
  &:hover {
    cursor: pointer;
    outline: none;
  }
`
