import { addWeeks } from 'date-fns'

import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { formatDate } from 'builder/utils/formatDate'

import { Weeks, selectors } from 'builder/modules/resumeDistribution'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'

import { SECTION_INFO_KEY, TRANSLATION } from '../constants'
import { TooltipWrapper } from '../styles'
import {
  Container,
  Header,
  ProgressConatiner,
  TextSection,
  SubHeader,
  ProgressBar,
  Section,
  Progress,
  CompleteIcon,
  Tooltip,
} from './styles'

type Props = {
  distributionWeek: Weeks
}

export const DistributionCycle = ({ distributionWeek }: Props) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const candidate = useTypedSelector(selectors.candidate)
  const isCompleted = distributionWeek === Weeks.fourth
  const showHint = localStorage.getItem(SECTION_INFO_KEY)

  const calculatedEndDate = () => {
    if (candidate) {
      const endDate = addWeeks(new Date(candidate?.startedAt), 4)
      return formatDate(endDate, 'MMM do, yyyy')
    }
  }

  const percentage = {
    [Weeks.first]: 25,
    [Weeks.second]: 50,
    [Weeks.third]: 75,
    [Weeks.fourth]: 100,
  }

  if (distributionWeek === Weeks.not_started) return <></>

  return (
    <Container>
      <Section>
        {isCompleted ? (
          <CompleteIcon />
        ) : (
          <Progress>
            <ProgressConatiner percentage={percentage[distributionWeek]}>
              <ProgressBar />
            </ProgressConatiner>
          </Progress>
        )}
        <TextSection>
          <Header>
            {i18n.t(`${TRANSLATION}.cycle_header${isPhone ? '_mobile' : ''}`, {
              count: `${distributionWeek}/4`,
            })}
          </Header>
          <SubHeader>
            {i18n.t(`${TRANSLATION}.cycle_subheader_${isCompleted ? 'ended' : 'active'}`, {
              date: calculatedEndDate(),
            })}
            {!!showHint && (
              <TooltipWrapper>
                <Tooltip textAlign="left" position={isPhone ? 'left' : 'top'} multiline width={300}>
                  {i18n.t(`${TRANSLATION}.info_section.active`)}
                </Tooltip>
              </TooltipWrapper>
            )}
          </SubHeader>
        </TextSection>
      </Section>
    </Container>
  )
}
