import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { BannerContainer } from './styles'
import TargetBanner from './TargetBanner'
import { useResumeTailoringBanner } from './useResumeTailoringBanner'

export const JobPostingBanner = () => {
  const animateContainer = useTypedSelector(
    state => state.resumeEditor.animateResumeOptimizerBanner,
  )
  const { bannerOption, onBannerClick } = useResumeTailoringBanner('builder')

  if (!bannerOption) return null

  return (
    <BannerContainer onClick={onBannerClick} animate={animateContainer}>
      <TargetBanner />
    </BannerContainer>
  )
}
