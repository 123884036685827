import React, { HTMLAttributes, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Track, Thumb, HiddenCheckbox, Label } from './styles'

type ToggleProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  label?: string | React.ReactNode
  checked?: boolean
  tabIndex?: number
  invalid?: boolean
  disabled?: boolean
} & HTMLAttributes<HTMLInputElement>

export const Toggle = ({
  label,
  onChange,
  tabIndex,
  checked = false,
  invalid = false,
  disabled = false,
  ...rest
}: ToggleProps) => {
  const [checkedState, setChecked] = useState(checked)

  useEffect(() => {
    setChecked(checked)
  }, [checked])

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }
    setChecked(!checkedState)
  }

  return (
    <ThemeProvider theme={{ disabled, checked: checkedState, invalid }}>
      <Label>
        <HiddenCheckbox
          disabled={disabled}
          tabIndex={tabIndex}
          type="checkbox"
          onChange={onChangeHandler}
          checked={checkedState}
          {...rest}
        />
        <Track>
          <Thumb />
        </Track>
        {label}
      </Label>
    </ThemeProvider>
  )
}
