import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import { ButtonsContainer, BottomContainer } from 'builder/components/SimpleForm/styles'

export const LayoutContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: ${Colors.White};
  border-radius: 16px;
  padding: 32px;
  padding-bottom: 0;
  overflow: auto;

  ${Media.Phone`
    padding: 20px;
    padding-bottom: 0;
  `};
`

export const FormContainer = styled.div<{
  $hideButtons?: boolean
  $hideSubmit?: boolean
  $showShortVersion?: boolean
  $hideBottomContainer?: boolean
}>`
  margin-top: 16px;
  width: 100%;
  padding-bottom: 100px;
  ${ButtonsContainer} {
    display: ${({ $hideButtons }) => $hideButtons && 'none'};
    margin-top: 0;
  }
  & button[type='submit'] {
    display: ${({ $hideSubmit }) => $hideSubmit && 'none'};
  }

  ${BottomContainer} {
    position: fixed;
    bottom: var(--size-m);
    width: 940px;
    background-color: ${Colors.White};

    ${Media.Phone`
      bottom: 71px;
      width: 90vw;
      padding-right: 20px;
      padding-bottom: 10px;
   `};

    ${({ $hideBottomContainer }) =>
      $hideBottomContainer &&
      css`
        display: none;
      `}
  }

  ${({ $showShortVersion }) =>
    $showShortVersion &&
    css`
      ${BottomContainer} {
        width: inherit;
        position: absolute;
        bottom: var(--size-s);

        ${Media.Phone`
          position: fixed;
          bottom: 75px;
          left: 0;
          padding-right: 0;
        `};
      }
      ${ButtonsContainer} {
        width: inherit;

        & button {
          margin-left: none;
          margin: 0 auto 32px;
        }
      }
    `}
`

export const Container = styled.div`
  width: 100%;
  max-width: 416px;
  height: auto;
  align-items: center;

  ${Media.Tablet`
    max-width: 400px;
  `};

  ${Media.Phone`
    width: 100%;
  `};
`

export const TitleWrapper = styled.div<{
  $showShortVersion?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 512px;

  ${Media.Phone`
    height: 75px;
  `};

  ${({ $showShortVersion }) =>
    $showShortVersion &&
    css`
      margin-bottom: 16px;
    `}
`

export const Title = styled.div`
  text-align: center;
  ${Typography.M};
  ${FontWeights.Medium};
`

export const FillWarning = styled.div`
  text-align: center;
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`
