import { useMutation } from '@tanstack/react-query'

export const useTextToSpeechBlob = () => {
  return useMutation({
    mutationFn: async (url?: string) => {
      const response = await fetch(url || '')
      const blob = await response.blob()
      return blob
    },
  })
}
