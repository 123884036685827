import { ComponentPropsWithoutRef } from 'react'
import 'tippy.js/dist/tippy.css'
import { Container, IconContainer, Text } from './styles'

type Props = {
  text: string
  isActive?: boolean
  isDisabled?: boolean
  icon?: JSX.Element
} & ComponentPropsWithoutRef<'div'>

export const AiSuggestionsPopupListItem = (props: Props) => {
  const { text, icon, isActive, isDisabled, ...rest } = props

  return (
    <Container $isActive={isActive} $isDisabled={isDisabled} {...rest}>
      <Text>{text}</Text>
      {icon && <IconContainer>{icon}</IconContainer>}
    </Container>
  )
}
