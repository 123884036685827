import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { Icon16, Icon20, Icon24 } from 'builder/components/Icon'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { ColumnName, selectors } from 'builder/modules/jobTracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import {
  selectors as jobSearchSelectors,
  actions as jobSearchActions,
} from 'builder/modules/jobSearch'
import { useJobSearch } from '../useJobSearch'
import { TabType } from '../types'
import {
  AutoApplyButton,
  AutoApplyButtonContainer,
  AutoApplyGuide,
  AutoApplyGuideBubble,
  HiddenOnTablet,
  Bubble,
  Container,
  CreditValue,
  SavedJobs,
  SavedJobsCount,
  SettingIconContainer,
} from './styles'
import { useDispatch } from 'react-redux'

export const PageActionButtons = () => {
  const navigate = useNavigate()
  const { i18n } = useI18n()
  const { features } = useFeaturesConfig()
  const user = useUser()
  const dispatch = useDispatch()

  const isAutoApplyEnabled = features?.autoApply && !!user?.scopes?.autoApply
  const { recommendations } = useJobSearch()
  const cards = useTypedSelector(selectors.selectJobs)
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)
  const uniqueExternalSlugIdCard = cards
    .filter(card => card.status === ColumnName.auto_apply)
    .map(e => e.externalSlugId)

  const uniqCardAndRecommendation = new Set(uniqueExternalSlugIdCard)

  const hasCardToBeUsedOnAutoApplyGuide = !!recommendations.find(
    recommendation => !uniqCardAndRecommendation.has(recommendation.external_slug_id),
  )

  const savedJobsCount = useTypedSelector(selectors.savedJobsCount)

  const { handleChangeSelectedTabId } = useJobSearch()

  const handleSavedJobs = () => {
    trackInternalEvent('click_saved_jobs', {
      label: 'search',
    })
    navigate(`/job-tracking`)
  }

  const handleAutoApply = () => {
    trackInternalEvent('click_auto_apply', { label: 'job_search' })
    navigate(`/job-tracking`)
  }

  const handleSettingClick = () => {
    navigate(`/auto-apply/personal-info`)
  }

  return (
    <Container>
      {isAutoApplyEnabled && (
        <>
          {autoApplyBubbles.showAutoApplyGuideButton && hasCardToBeUsedOnAutoApplyGuide && (
            <AutoApplyGuide
              onClick={() => {
                handleChangeSelectedTabId(TabType.recommendation)
                dispatch(jobSearchActions.startAutoApplyBubble({ enableFirstPage: false }))
              }}
            >
              <HiddenOnTablet>
                {i18n.t(`builder.job_tracking.auto_apply_guide_button`)}
              </HiddenOnTablet>
              <AutoApplyGuideBubble>
                <Icon20.QuestionIcon />
              </AutoApplyGuideBubble>
            </AutoApplyGuide>
          )}
          <AutoApplyButtonContainer>
            <AutoApplyButton onClick={handleAutoApply}>
              <Icon24.FeaturedIcon />
              <HiddenOnTablet>{i18n.t(`builder.job_tracking.auto_apply_button`)}</HiddenOnTablet>
            </AutoApplyButton>
            <Bubble>
              <CreditValue>
                {typeof user.scopes?.autoApply === 'object'
                  ? `${user.scopes?.autoApply?.creditCountUsed ?? 0}/${
                      user.scopes?.autoApply?.creditCount ?? 0
                    }`
                  : `∞`}
              </CreditValue>
            </Bubble>
            <Bubble>
              <SettingIconContainer onClick={handleSettingClick}>
                <Icon16.SettingIcon />
              </SettingIconContainer>
            </Bubble>
          </AutoApplyButtonContainer>
        </>
      )}

      <SavedJobs onClick={handleSavedJobs}>
        <Icon24.Heart />
        <span>Saved Jobs</span>
        <SavedJobsCount>{savedJobsCount}</SavedJobsCount>
      </SavedJobs>
    </Container>
  )
}
