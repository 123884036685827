import { useState, useEffect, useRef } from 'react'
import { Interview } from 'builder/modules/interview/types'
import { useTextToSpeechBlob } from 'builder/views/Interview/hooks/useTextToSpeechBlob'
import { AudioPlayerUtils, getNumberOfQuestionOfInterview } from 'builder/views/Interview/utils'
import { useInterviewQuestions } from 'builder/views/Interview/hooks/useInterviewQuestions'
import { PerformanceLoggerWithMainDsn as PerformanceLogger } from 'builder/services/PerformanceLogger'
import ErrorLogger from 'builder/services/ErrorLogger'

type Answer = Record<number, Promise<Blob>>

export function useTextToSpeechPlay(startQuestionIndex: number, interview?: Interview) {
  const [announcements, setAnnouncements] = useState<Answer>({})

  const { mutateAsync: postTextToSpeech } = useTextToSpeechBlob()
  const audioUtilsRef = useRef(new AudioPlayerUtils())

  const [speaking, setSpeaking] = useState(false)
  const [loading, setLoading] = useState(true)

  const maxQuestions = interview ? getNumberOfQuestionOfInterview(interview) : 0
  const { data: pollingQuestions = [] } = useInterviewQuestions(
    interview?.id,
    pollingQuestions =>
      maxQuestions === pollingQuestions?.filter(question => question.announcement).length,
  )

  useEffect(() => {
    return () => audioUtilsRef.current?.stopSound()
  }, [])

  useEffect(() => {
    async function checkAllAnnouncementFinished() {
      if (!interview) {
        return
      }
      const promiseAnnouncements = Object.values(announcements)
      if (promiseAnnouncements.length === maxQuestions) {
        await Promise.allSettled(promiseAnnouncements)
        PerformanceLogger.operationEnd('load-all-announcement')
      }
    }
    checkAllAnnouncementFinished()
  }, [interview, announcements, maxQuestions])

  useEffect(() => {
    if (!interview) {
      return
    }
    if (loading) {
      PerformanceLogger.operation('load-first-announcement')
      PerformanceLogger.operation('load-all-announcement', {
        tags: { countQuestions: interview.questions.length },
      })
    } else {
      PerformanceLogger.operationEnd('load-first-announcement')
    }
  }, [interview, loading])

  useEffect(() => {
    async function downloadTextToSpeechBlobs() {
      if (!interview) {
        return
      }
      const startQuestion = interview.questions[startQuestionIndex]
      const startPollingQuestion = pollingQuestions.find(
        question => question.id === startQuestion?.id,
      )
      if (
        startPollingQuestion &&
        !announcements[startPollingQuestion.id] &&
        startPollingQuestion.announcement
      ) {
        const blob = postTextToSpeech(startPollingQuestion.announcement)
        setAnnouncements(prevAnnouncements => ({
          ...prevAnnouncements,
          [startPollingQuestion.id]: blob,
        }))
        try {
          await blob
        } catch (error) {
          ErrorLogger.log(error)
        } finally {
          setLoading(false)
        }
      }
    }
    downloadTextToSpeechBlobs()
  }, [pollingQuestions, interview])

  useEffect(() => {
    async function startDownloadFromReadyAnnouncement() {
      if (!interview) {
        return
      }
      const downloadedAnnouncementIds = new Set(Object.keys(announcements))

      if (downloadedAnnouncementIds.size === 0 || downloadedAnnouncementIds.size === maxQuestions) {
        return
      }

      const missingAnnouncements = pollingQuestions
        .filter(
          question => question.announcement && !downloadedAnnouncementIds.has(String(question.id)),
        )
        .reduce(
          (acc, question) => ({ ...acc, [question.id]: postTextToSpeech(question.announcement) }),
          {},
        )

      if (Object.keys(missingAnnouncements).length > 0) {
        setAnnouncements(prevAnnouncements => ({ ...prevAnnouncements, ...missingAnnouncements }))
      }
    }

    startDownloadFromReadyAnnouncement()
  }, [interview, announcements, pollingQuestions])

  const playQuestionAnnouncement = async (id?: number) => {
    if (id === undefined) {
      return
    }
    const announcement = announcements[id]

    if (!announcement) {
      return
    }
    try {
      const questionBlob = await Promise.race([announcement, Promise.resolve(undefined)])
      if (!questionBlob) {
        return
      }
      setSpeaking(true)
      await audioUtilsRef.current.playAudioFromBlob(questionBlob)
    } catch (error) {
      ErrorLogger.log(error)
    }

    setSpeaking(false)
  }

  return { playQuestionAnnouncement, loading, speaking }
}
