import styled, { css } from 'styled-components'

export const BannerContainer = styled.div<{ animate: boolean }>(({ animate }) => {
  return css`
    ${animate &&
    css`
      position: absolute;
    `}
  `
})
