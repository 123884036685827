import { useEffect, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useNavigate } from 'react-router-dom'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/user'
import { useI18n } from 'builder/hooks/useI18n'
import { InternalEvents } from 'builder/components/SubscriptionCancellationFormNew/common/types'
import { selectors as initSelectors } from 'builder/modules/init'
import { PauseModal } from '../PauseModal'
import {
  Container,
  Title,
  Content,
  Description,
  SubtitleOneLine,
  ItemsContainer,
  Item,
  ItemContainer,
  ResumeLimitLogo,
  ButtonsContainer,
  PremiumButton,
  CancelSubcriptionText,
  SubtitleTwoLines,
  Icon,
} from './styles'
import DOCUMENTS_ICON from './assets/icons/documents.svg'
import DOWNLOAD_ICON from './assets/icons/download.svg'
import LOCKED_ICON from './assets/icons/locked.svg'
import ARROW_TARGET_ICON from './assets/icons/arrow_target.svg'
import BAR_CHART_ICON from './assets/icons/bar_chart.svg'
import RESUME_LIMIT_LOGO from './assets/resume_limit_logo.png'

interface SubscriptionCancellationViewStepOneProps {
  onContinueCancellation: () => void
}

const ITEMS = (t: (key: string) => string) => [
  {
    src: DOCUMENTS_ICON,
    text: t('builder.subscription_cancellation_v4.step_one.icons.document'),
  },
  {
    src: DOWNLOAD_ICON,
    text: t('builder.subscription_cancellation_v4.step_one.icons.download'),
  },
  {
    src: BAR_CHART_ICON,
    text: t('builder.subscription_cancellation_v4.step_one.icons.bar_chart'),
  },
  {
    src: ARROW_TARGET_ICON,
    text: t('builder.subscription_cancellation_v4.step_one.icons.arrow'),
  },
  {
    src: LOCKED_ICON,
    text: t('builder.subscription_cancellation_v4.step_one.icons.locked'),
  },
]

export const SubscriptionCancellationViewStepOne = ({
  onContinueCancellation,
}: SubscriptionCancellationViewStepOneProps) => {
  const navigate = useNavigate()
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false)
  const userData = useTypedSelector(selectors.userData)
  const { i18n } = useI18n()
  const alreadyGotFreePausePeriodDuringCancellation =
    userData?.billingInfo?.alreadyGotFreePausePeriodDuringCancellation
  const locale = useTypedSelector(initSelectors.locale)

  useEffect(() => {
    trackInternalEvent('see_loss_aversion_popup')
  }, [])

  return (
    <>
      <Container>
        <Title>{i18n.t('builder.subscription_cancellation_v4.step_one.title')}</Title>
        <Content>
          <Description>
            <SubtitleOneLine>
              {i18n.t('builder.subscription_cancellation_v4.step_one.subtitle')}
            </SubtitleOneLine>
            <SubtitleTwoLines>
              {i18n.t('builder.subscription_cancellation_v4.step_one.subtitle_line_one')}
              <br />
              {i18n.t('builder.subscription_cancellation_v4.step_one.subtitle_line_two')}
            </SubtitleTwoLines>
            <ItemsContainer>
              {ITEMS(i18n.t).map(item => (
                <ItemContainer key={item.src}>
                  <Icon src={item.src} />
                  <Item>{item.text}</Item>
                </ItemContainer>
              ))}
            </ItemsContainer>
          </Description>
          <ResumeLimitLogo src={RESUME_LIMIT_LOGO} />
        </Content>
        <ButtonsContainer>
          <PremiumButton
            onClick={() => {
              trackInternalEvent(InternalEvents.abort)
              navigate('/')
            }}
          >
            {i18n.t('builder.subscription_cancellation_v4.step_one.premium_button')}
          </PremiumButton>
          <CancelSubcriptionText
            onClick={() => {
              if (alreadyGotFreePausePeriodDuringCancellation || locale === 'nl-NL') {
                onContinueCancellation()
              } else {
                setIsPauseModalOpen(true)
              }
            }}
          >
            {i18n.t('builder.subscription_cancellation_v4.step_one.cancel_button')}
          </CancelSubcriptionText>
        </ButtonsContainer>
      </Container>
      {isPauseModalOpen && (
        <PauseModal
          onContinueCancellation={onContinueCancellation}
          onClose={() => setIsPauseModalOpen(false)}
        />
      )}
    </>
  )
}
