import { createAction, createReducer } from '@reduxjs/toolkit'
import sortBy from 'lodash/sortBy'
import type { Optional } from '@rio/types'
import { FetchStatuses } from '../constants'
import { columnize, flatten } from './utils'
import {
  JobTrackingStore,
  Job,
  ColumnName,
  JobMovePayload,
  JobDeletePayload,
  JobCreatePayload,
  FetchJobsPayload,
  ResumeList,
  UpdateSelectedResumePayload,
  ResumeSelection,
  ApplyTrackingDialog,
  TailorResumeStatus,
} from './types'

// ---
// Actions
// ---

export const actions = {
  // Setters
  setLastUpdatedCard: createAction<Job | null>('builder/SET_UPDATE_CARD_FETCH'),
  setFetchStatus: createAction<FetchStatuses>('builder/SET_JOBS_FETCH_STATUS'),
  setJobsData: createAction<Job[]>('builder/SET_JOBS_DATA'),
  setJobsError: createAction<Optional<string>>('builder/SET_JOBS_ERROR'),
  addJob: createAction<Job>('builder/ADD_JOB'),
  updateJob: createAction<Job>('builder/UPDATE_JOB'),
  deleteJob: createAction<JobDeletePayload>('builder/DELETE_JOB'),
  setAlreadyAppliedJob: createAction<boolean>('builder/SET_ALREADY_APPLIED_JOB'),
  setApplyTrackingDialog: createAction<ApplyTrackingDialog | null>(
    'builder/SET_APPLY_TRACKING_DIALOG',
  ),
  setResumeList: createAction<ResumeList[]>('builder/SET_RESUME_LIST'),
  setResumeSelection: createAction<ResumeSelection | null>('builder/SET_RESUME_SELECTION'),
  setUpdateResumeStatus: createAction<TailorResumeStatus | null>(
    'builder/SET_UPDATE_RESUME_STATUS',
  ),
  setShowJTChromeBanner: createAction<boolean>('builder/SET_SHOW_JT_CHROME_BANNER'),
  setHasInstalledExtension: createAction<boolean>('builder/SET_INSTALLED'),
  setCanInstallExtension: createAction<boolean>('builder/SET_CAN_INSTALL_EXTENSION'),

  // Requests
  fetchJobsRequest: createAction<FetchJobsPayload>('builder/FETCH_JOBS_REQUEST'),
  deleteJobRequest: createAction<JobDeletePayload>('builder/DELETE_JOB_REQUEST'),
  createJobRequest: createAction<JobCreatePayload>('builder/CREATE_JOB_REQUEST'),
  patchJobRequest: createAction<Partial<Job> & Pick<Job, 'id'>>('builder/PATCH_JOB_REQUEST'),
  editJobRequest: createAction<Pick<Job, 'id'> & Partial<Omit<Job, 'id'>>>(
    'builder/EDIT_JOB_REQUEST',
  ),
  fetchAlreadyAppliedJobRequest: createAction<string>('builder/FETCH_ALREADY_APPLIED_JOB_REQUEST'),
  fetchResumeListRequest: createAction('builder/FETCH_RESUME_LIST_REQUEST'),
  UpdateSelectedResumeRequest: createAction<UpdateSelectedResumePayload>(
    'builder/UPDATE_SELECTED_RESUME',
  ),
  fetchJTShowBannerRequest: createAction('builder/FETCH_JT_SHOW_BANNER'),

  // Move
  moveRequest: createAction<JobMovePayload>('builder/MOVE_JOB_REQUEST'),
  moveManyRequest: createAction<void>('builder/MOVE_MANY_JOB_REQUEST'),
  move: createAction<JobMovePayload>('builder/MOVE_JOB'),
  reorderRequest: createAction<JobMovePayload>('builder/REORDER_JOB_REQUEST'),
  reorder: createAction<JobMovePayload>('builder/REORDER_JOB'),
  setCioLimitModal: createAction<boolean>('builder/SET_CIO_LIMIT_MODAL'),
  removeAutoApplyBubbleDummyCard: createAction('builder/REMOVE_AUTO_APPLY_BUBBLE_DUMMY_CARD'),
}

// ---
// Reducer
// ---

const initialState: JobTrackingStore = {
  columnNames: Object.values(ColumnName),
  fetchStatus: FetchStatuses.notAsked,
  lastUpdatedCard: null,
  jobs: [],
  error: null,
  applyTrackingDialog: null,
  alreadyApplied: false,
  resumeList: [],
  resumeSelection: null,
  fetchUpdateResumeStatus: null,
  isCioLimitModal: false,
  jtShowBanner: false,
  hasInstalledExtension: false,
  canInstallExtension: false,
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setLastUpdatedCard, (draft, action) => {
    draft.lastUpdatedCard = action.payload
  })

  reducer.addCase(actions.setFetchStatus, (draft, action) => {
    draft.fetchStatus = action.payload
  })

  reducer.addCase(actions.setJobsError, (draft, action) => {
    draft.error = action.payload
  })

  reducer.addCase(actions.setJobsData, (draft, action) => {
    draft.jobs = sortBy(action.payload, ['positionNumber'])
  })

  reducer.addCase(actions.addJob, (draft, action) => {
    const externalSlugId = action.payload.externalSlugId

    if (externalSlugId) {
      const filteredJobs = draft.jobs.filter(job => job.externalSlugId !== externalSlugId)
      draft.jobs = sortBy([action.payload, ...filteredJobs], ['positionNumber'])
    } else {
      draft.jobs = sortBy([action.payload, ...draft.jobs], ['positionNumber'])
    }
  })

  reducer.addCase(actions.updateJob, (draft, action) => {
    const index = draft.jobs.map(elt => elt.id).indexOf(action.payload.id)
    draft.jobs[index] = action.payload
  })

  reducer.addCase(actions.deleteJob, (draft, action) => {
    draft.jobs = draft.jobs.filter(job => job.id !== action.payload.id)
  })

  reducer.addCase(actions.reorder, (draft, action) => {
    const { source, destination } = action.payload

    const columns = columnize(draft.jobs)
    const result = columns[destination.droppableId as ColumnName]
    const [removed] = result.splice(source.index, 1)
    result.splice(destination.index, 0, removed)

    draft.jobs = flatten(columns)
  })

  reducer.addCase(actions.move, (draft, action) => {
    const { source, destination } = action.payload

    const columns = columnize(draft.jobs)

    const sourceColumn = columns[source.droppableId as ColumnName]
    const destColumn = columns[destination.droppableId as ColumnName]

    const [removed] = sourceColumn.splice(source.index, 1)
    removed.status = destination.droppableId
    removed.movedAt = Date.now()

    // Sync processing label when from/to wishlist
    if (
      source.droppableId !== ColumnName.auto_apply &&
      destination.droppableId === ColumnName.auto_apply &&
      !removed.autoApplyStatus
    ) {
      removed.autoApplyStatus = 'processing'
    }
    if (
      destination.droppableId !== ColumnName.auto_apply &&
      source.droppableId === ColumnName.auto_apply &&
      removed.autoApplyStatus === 'processing'
    ) {
      removed.autoApplyStatus = null
    }

    destColumn.splice(destination.index, 0, removed)

    columns[source.droppableId as ColumnName] = sourceColumn
    columns[destination.droppableId as ColumnName] = destColumn

    draft.jobs = flatten(columns)
  })

  reducer.addCase(actions.setAlreadyAppliedJob, (draft, action) => {
    draft.alreadyApplied = action.payload
  })

  reducer.addCase(actions.setApplyTrackingDialog, (draft, action) => {
    draft.applyTrackingDialog = action.payload
  })

  reducer.addCase(actions.setResumeList, (draft, action) => {
    draft.resumeList = action.payload
  })

  reducer.addCase(actions.setResumeSelection, (draft, action) => {
    draft.resumeSelection = action.payload
  })

  reducer.addCase(actions.setShowJTChromeBanner, (draft, action) => {
    draft.jtShowBanner = action.payload
  })

  reducer.addCase(actions.setUpdateResumeStatus, (draft, action) => {
    draft.fetchUpdateResumeStatus = action.payload
  })
  reducer.addCase(actions.setCioLimitModal, (draft, action) => {
    draft.isCioLimitModal = action.payload
  })

  reducer.addCase(actions.setHasInstalledExtension, (draft, action) => {
    draft.hasInstalledExtension = action.payload
  })

  reducer.addCase(actions.setCanInstallExtension, (draft, action) => {
    draft.canInstallExtension = action.payload
  })
})
