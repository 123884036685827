import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  display: flex;
  gap: 16px;

  ${Media.Phone`
  
    flex-wrap: wrap;
    justify-content: center;
  `}
`

export const HiddenOnTablet = styled.div`
  display: block;

  ${Media.Tablet`
    display: none;
  `}

  ${Media.Phone`
    display: block;
  `}
`

export const AutoApplyButtonContainer = styled.div`
  display: flex;
  gap: 2px;
`
export const SavedJobsCount = styled.div`
  display: flex;
  background: ${Colors.Blue10};
  padding: 0 ${Sizes['3XS']};
  border-radius: 32px;
  ${FontWeights.Regular};
  ${Typography.Caption};
  color: ${Colors.Blue50};
  margin: 0 5px;
  height: 24px;
  align-items: center;
`

export const SavedJobs = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${Colors.Blue50};
  justify-content: center;
  svg {
    margin-right: 6px;
  }
`

export const AutoApplyButton = styled.div`
  display: flex;
  gap: 2px;
  color: ${Colors.Blue50};
  ${Typography.Body};
  align-items: center;
  background: ${Colors.White};
  cursor: pointer;
  border-radius: 12px;
`

export const Bubble = styled.div`
  border-radius: 100%;
  background: ${Colors.Blue10};
  flex-shrink: 0;
  height: 24px;
  color: ${Colors.Blue50};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CreditValue = styled.div`
  padding: 0 ${Sizes['3XS']};
  color: ${Colors.Blue50};
  ${FontWeights.Regular};
  ${Typography.Body};
`

export const SettingIconContainer = styled.div`
  height: 24px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const AutoApplyGuide = styled.div`
  display: flex;
  color: ${Colors.Neutral90};
  ${FontWeights.Regular};
  ${Typography.Body};
  align-items: center;
  background: ${Colors.White};
  cursor: pointer;
  border-radius: 12px;
  gap: 8px;
`

export const AutoApplyGuideBubble = styled.div`
  border-radius: 100%;
  background: ${Colors.Neutral10};
  padding: 2px;
  flex-shrink: 0;
  display: flex;
  color: ${Colors.Neutral50};
  align-items: center;
  justify-content: center;
`
