import { useMemo } from 'react'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/resumeEditor'
import { useConfig } from 'builder/hooks/useConfig'

export const useKeywords = () => {
  const { keywords, recommendedKeywords } = useTypedSelector(selectors.jobPostingAPIData)
  const tailoredJobPostingId = useTypedSelector(selectors.tailoredJobPostingId)
  const config = useConfig()
  const isKeywordsFeatureEnabled = !!(tailoredJobPostingId && config?.features.keywordsInRo)

  const addedKeywords = useMemo(() => {
    return keywords.filter(keyword => !recommendedKeywords.includes(keyword))
  }, [keywords, recommendedKeywords])

  const allKeywords = useMemo(
    () => (keywords.length ? [...recommendedKeywords, ...addedKeywords] : []),
    [recommendedKeywords, addedKeywords, keywords.length],
  )

  const isKeywordsReceived = !!allKeywords.length
  const isAllKeywordsAdded = addedKeywords.length === allKeywords.length
  const isKeywordsAvailable = isKeywordsFeatureEnabled && isKeywordsReceived && !isAllKeywordsAdded

  return {
    allKeywords,
    addedKeywords,
    restKeywords: recommendedKeywords,
    isKeywordsAvailable,
    isKeywordsFeatureEnabled,
  }
}
