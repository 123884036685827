import { useI18n } from 'builder/hooks/useI18n'
import { Title, Subtitle, EmptyResultsContainer, NoSearchResultsImage } from './styles'
import NO_SEARCH_RESULTS from './assets/no_search_results.png'

export const NoResultsSearch = () => {
  const { i18n } = useI18n()

  return (
    <EmptyResultsContainer>
      <NoSearchResultsImage src={NO_SEARCH_RESULTS} />
      <Title>{i18n.t(`builder.job_search.job_search_results.empty_results.title`)}</Title>
      <Subtitle withMaxWidth>
        {i18n.t(`builder.job_search.job_search_results.empty_results.subtitle`)}
      </Subtitle>
    </EmptyResultsContainer>
  )
}
