import { useMemo } from 'react'
import PropTypes from 'prop-types'
import times from 'lodash/times'
import { FetchStatuses } from 'builder/modules/constants'
import { translate } from './utils'
import { useHistory } from './hooks'
import Paywall from './Paywall'
import HistoryView from './HistoryView'
import HistoryViewPlaceholder from './HistoryViewPlaceholder'

import { HistoryContainer, HistoryTitle, HistoryMessage } from './styles'

export const MODES = {
  paywall: 'paywall',
  loading: 'loading',
  empty: 'empty',
  list: 'list',
}

const History = ({ resumeId, hasPremium }) => {
  const { status, items, infiniteRef } = useHistory({ resumeId, hasPremium })

  const mode = useMemo(() => {
    if (!hasPremium) return MODES.paywall
    if (status !== FetchStatuses.loaded && items.length === 0) return MODES.loading
    if (status === FetchStatuses.loaded && items.length === 0) return MODES.empty
    return MODES.list
  }, [status, hasPremium, items.length])

  const displayTitle = [MODES.loading, MODES.list].includes(mode)

  return (
    <HistoryContainer ref={infiniteRef} mode={mode}>
      {displayTitle && <HistoryTitle>{translate('history.title')}</HistoryTitle>}

      {/* Display paywall instead of the list if user doesn't have premium account */}
      {mode === MODES.paywall && <Paywall />}

      {/* Display a message that views history will appear here */}
      {mode === MODES.empty && <HistoryMessage>{translate('history.empty')}</HistoryMessage>}

      {/* Render skeletons during the first page loading */}
      {mode === MODES.loading && times(3, index => <HistoryViewPlaceholder key={index} />)}

      {/* Render an infinite list */}
      {mode === MODES.list && items.map((view, index) => <HistoryView key={index} view={view} />)}
    </HistoryContainer>
  )
}

History.propTypes = {
  resumeId: PropTypes.number.isRequired,
  hasPremium: PropTypes.bool,
}

export default History
