import { createSelector } from '@reduxjs/toolkit'
import { Store } from '../store'
import { getScoreSuggestions } from './resumeScoreOracle'

const wizardStepsSelector = (state: Store) => state.resumeEditor.wizardSteps
const currentWizardStepIdSelector = (state: Store) => state.resumeEditor.currentWizardStepId

export const selectors = {
  resume: (state: Store) => state.resumeEditor.resume,
  templates: (state: Store) => state.generalEditor.resumeTemplates,
  resumeScore: (state: Store) => state.resumeEditor.resume?.resumeScore,
  scoreSuggestions: (state: Store) => getScoreSuggestions(state.resumeEditor.resume),
  wizardSteps: wizardStepsSelector,
  currentWizardStep: createSelector(
    currentWizardStepIdSelector,
    wizardStepsSelector,
    (currentWizardStepId, wizardSteps) =>
      wizardSteps.find(step => step.id === currentWizardStepId) || wizardSteps[0],
  ),
  currentWizardStepId: currentWizardStepIdSelector,
  openCardId: (state: Store) => state.resumeEditor.openCardId,
  prefill: (state: Store) => state.resumeEditor.prefill,
  country: (state: Store) => state.application.configs.country,
  isJobPostingOpen: (state: Store) => state.resumeEditor.isOptimizerModalOpen,
  isFromJobTracker: (state: Store) => state.resumeEditor.isFromJobTracker,
  isOpenSavingInfoBanner: (state: Store) => state.resumeEditor.isOpenSavingInfoBanner,
  isResumeOptimizerBannerOpen: (state: Store) => state.resumeEditor.isResumeOptimizerBannerOpen,
  jobPostingAPIData: (state: Store) => state.resumeEditor.optimizerSection.jobPostingAPIData,
  saveJobPostingDataStatus: (state: Store) =>
    state.resumeEditor.optimizerSection.saveJobPostingDataStatus,
  linkedInData: (state: Store) => state.resumeEditor.aiResume.linkedInData,
  questionnaireData: (state: Store) => state.resumeEditor.aiResume.questionnaireData,
  tempJobPostingId: (state: Store) =>
    state.resumeEditor.optimizerSection.resumeScoreAPIData.jobPostingId,
  tailoredJobPostingId: (state: Store) =>
    state.resumeEditor.optimizerSection.jobPostingAPIData.jobPostingId,
  locale: (state: Store) => state.resumeEditor.resume?.locale,
  iso_language_code: (state: Store) => {
    let locale = state.resumeEditor.resume?.locale
    switch (locale) {
      // Map the resume locale to the correct ISO 639 locale code for certain APIs
      case 'cs':
        locale = 'cz'
        break
      case 'da':
        locale = 'dk'
        break
      default:
        // Keep the original value of locale
        break
    }
    return locale
  },
  openedAISuggestionsPopup: (state: Store) => state.resumeEditor.openedAISuggestionsPopup,
  aiProfile: (state: Store) => state.resumeEditor.aiProfile,
  highlightedElementData: (state: Store) => state.resumeEditor.highlightedElementData,
  openedKeywordsModalType: (state: Store) => state.resumeEditor.openedKeywordsModalType,
  isImproveResumePanelOpen: (state: Store) => state.resumeEditor.isImproveResumePanelOpen,
  isHelpWithWritingOpen: (state: Store) => state.resumeEditor.isHelpWithWritingOpen,
  isAIProfileSummaryResultOpen: (state: Store) => state.resumeEditor.isAIProfileSummaryResultOpen,
}
