import PropTypes from 'prop-types'
import { sortableHandle } from 'react-sortable-hoc'
import { useI18n } from 'builder/hooks/useI18n'
import { Icon20 } from 'builder/components/Icon'
import { Wrapper, Tooltip } from './styles'

const DragHandle = ({ isDragging, ...rest }) => {
  const { i18n } = useI18n()

  return (
    <Wrapper isDragging={isDragging} {...rest}>
      <Tooltip isDragging={isDragging}>{i18n.t('builder.resume_editor.drag')}</Tooltip>
      <Icon20.Drag />
    </Wrapper>
  )
}

DragHandle.propTypes = {
  isDragging: PropTypes.bool,
}

export default sortableHandle(DragHandle)
