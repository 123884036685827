import type { UserDocumentType } from '@rio/types'
import { useI18n } from 'builder/hooks/useI18n'
import DialogModal from 'builder/components/DialogModal'

interface Props {
  type: UserDocumentType
  onConfirm: () => void
  onCancel: () => void
}

export const DeletePrompt = ({ type, onConfirm, onCancel }: Props) => {
  const { i18n } = useI18n()

  return (
    <DialogModal
      title={i18n.t(`builder.resume_editor.delete_dialog.${type}.title`)}
      text={i18n.t(`builder.resume_editor.delete_dialog.${type}.text`)}
      primaryButtonText={i18n.t('builder.resume_editor.delete')}
      secondaryButtonText={i18n.t('builder.resume_editor.cancel')}
      primaryAction={onConfirm}
      secondaryAction={onCancel}
      closeAction={onCancel}
      isOpen
    />
  )
}
