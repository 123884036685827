import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Button from 'builder/components/Button'

export const Container = styled.div`
  margin: auto;
  height: fit-content;
  width: fit-content;
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.White};

  ${Media.Tablet`
    max-width: 544px;
    padding-bottom: 37px;
    margin-top: 12px;
  `};

  ${Media.Phone`
    max-width: 335px;
    margin-top: 8px;
    padding-bottom: 0;
  `};

  /* To make the Container focusable */
  outline: none;
`

export const Title = styled.p`
  ${Typography.XL};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
  text-align: center;

  ${Media.Phone`
    ${Typography.S};
  `};
`

export const Content = styled.div`
  display: flex;
  gap: 54px;
  margin-top: 32px;

  ${Media.Tablet`
    flex-direction: column;
    align-items: center;
    gap: 0;
  `};

  ${Media.Phone`
    margin-top: 20px;
  `};
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 48px;
  padding-bottom: 32px;
  max-width: 561px;
  border-bottom: 1px solid ${Colors.Neutral15};

  ${Media.Tablet`
    order: 1;
    padding-top: 32px;
  `};

  ${Media.Phone`
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 12px;
    max-width: 300px;
  `};
`

export const SubtitleOneLine = styled.p`
  ${Typography.S};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};

  ${Media.Tablet`
    display: none;
  `};

  ${Media.Phone`
    display: block;
    ${Typography.Caption};
  `};
`

export const SubtitleTwoLines = styled.p`
  display: none;
  ${Typography.S};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};

  ${Media.Tablet`
    display: block;
  `};

  ${Media.Phone`
    display: none;
  `};
`

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${Media.Phone`
    gap: 12px;
    max-width: 291px;
  `};
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`

export const Item = styled.p`
  ${Typography.Body};
  color: ${Colors.Neutral80};

  ${Media.Phone`
    font-size: 15px;
  `};
`

export const ResumeLimitLogo = styled.img`
  height: fit-content;

  ${Media.Tablet`
    order: 0;
    width: 313px;
  `};

  ${Media.Phone`
    width: 201px;
  `};
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 32px;
  align-self: flex-start;
  align-items: center;

  ${Media.Tablet`
    flex-direction: column;
    align-self: center;
  `};

  ${Media.Phone`
    margin-top: 20px;
    width: 100%;
    gap: 12px;
  `};
`

export const PremiumButton = styled(Button)`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.White};
  background-color: ${Colors.Blue50};
  border-radius: 4px;
  width: 206px;
  padding: 12px 24px;

  &:hover {
    background-color: ${Colors.Blue60};
  }

  ${Media.Phone`
    width: 100%;
  `};
`

export const CancelSubcriptionText = styled.p`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral50};
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Icon = styled.img`
  ${Media.Phone`
    width: 20px;
    height: 20px;
  `};
`
