import { forwardRef } from 'react'
import { TooltipV2 } from 'builder/components/Tooltip'
import Icon from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import useImproveResumePanel from '../../hooks/useImproveResumePanel'
import {
  StyledButton,
  ChevronDownIcon,
  ImproveResumeContainer,
  HintIcon,
  HintIconContainer,
  CompletenessIcon,
} from './styles'

const ImproveResumeButton = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  (props, ref) => {
    const { i18n } = useI18n()
    const {
      handleToggleImproveResumePanal,
      isImproveResumePanelOpen,
      hintTooltipTextKey,
      isPhone,
    } = useImproveResumePanel()

    return (
      <ImproveResumeContainer ref={ref}>
        <StyledButton onClick={handleToggleImproveResumePanal}>
          {!isPhone && (
            <CompletenessIcon>
              <Icon.Completeness50Percent />
            </CompletenessIcon>
          )}

          {!isPhone
            ? i18n.t('builder.resume_editor.keywords.improve_resume_panel.toggle_button_text')
            : i18n.t(
                'builder.resume_editor.keywords.improve_resume_panel.toggle_button_mobile_text',
              )}
          <ChevronDownIcon isActive={isImproveResumePanelOpen}>
            <Icon.ChevronDown />
          </ChevronDownIcon>
        </StyledButton>
        {!isPhone && (
          <TooltipV2 content={i18n.t(hintTooltipTextKey)} multiline={false}>
            <HintIconContainer>
              <HintIcon />
            </HintIconContainer>
          </TooltipV2>
        )}
      </ImproveResumeContainer>
    )
  },
)

export default ImproveResumeButton
