import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { ActiveInterviewTab, InterviewStore } from './types'

export const selectors = {
  jobTitleId: (state: Store) =>
    state.interview.jobTitleId || localStorage.jobTitleId ? Number(localStorage.jobTitleId) : 0,
  jobTitle: (state: Store) => state.interview.jobTitle || (localStorage.jobTitle as string),
  interviewId: (state: Store) =>
    state.interview.interviewId || localStorage.interviewId ? Number(localStorage.interviewId) : 0,
  presignedUrlCalls: (state: Store) => state.interview.presignedUrlCalls,
  putCalls: (state: Store) => state.interview.putCalls,
  answerCompleteStatus: (state: Store) => state.interview.answerComplete,
  activeInterviewTab: (state: Store) => state.interview.activeInterviewTab,
  videoStream: (state: Store) => state.interview.videoStream,
  audioStream: (state: Store) => state.interview.audioStream,
  getInterviewUrl: (state: Store) =>
    `/interview-preparation/${state.interview.jobTitleId ? 'dashboard' : 'start'}`,
  getInterviewDomainUrl: (state: Store) =>
    `https://career.io/app/interview-preparation/interview-preparation/${
      state.interview.jobTitleId ? 'dashboard' : 'start'
    }`,
}

const initialState: InterviewStore = {
  jobTitleId: 0,
  jobTitle: '',
  interviewId: 0,
  presignedUrlCalls: {},
  putCalls: {},
  answerComplete: {},
  activeInterviewTab: 'in_progress',
  videoStream: undefined,
  audioStream: undefined,
}

const { reducer, actions } = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    setVideoStream: (state, action: PayloadAction<MediaStream | undefined>) => {
      state.videoStream = action.payload
    },
    setAudioStream: (state, action: PayloadAction<MediaStream | undefined>) => {
      state.audioStream = action.payload
    },
    setJobTitleId: (state, action: PayloadAction<number>) => {
      state.jobTitleId = action.payload
      localStorage.setItem('jobTitleId', String(action.payload))
    },
    setJobTitle: (state, action: PayloadAction<string>) => {
      state.jobTitle = action.payload
      localStorage.setItem('jobTitle', String(action.payload))
    },
    setInterviewId: (state, action: PayloadAction<number>) => {
      state.interviewId = action.payload
      localStorage.setItem('interviewId', String(action.payload))
      state.answerComplete = {}
      state.putCalls = {}
      state.presignedUrlCalls = {}
    },
    resetAnswer: (state, action: PayloadAction<number>) => {
      const questionId = action.payload
      delete state.answerComplete[questionId]
      delete state.putCalls[questionId]
      delete state.presignedUrlCalls[questionId]
    },
    setPresignedUrl: (
      state,
      action: PayloadAction<{
        answerId: number
        promise: Promise<unknown>
      }>,
    ) => {
      const { answerId, promise } = action.payload
      state.presignedUrlCalls = {
        ...state.presignedUrlCalls,
        [answerId]: [
          ...(state.presignedUrlCalls[answerId] ? state.presignedUrlCalls[answerId] : []),
          promise,
        ],
      }
    },
    setPutCall: (
      state,
      action: PayloadAction<{ answerId: number; promise: Promise<Response> }>,
    ) => {
      const { answerId, promise } = action.payload
      state.putCalls = {
        ...state.putCalls,
        [answerId]: [...(state.putCalls[answerId] ? state.putCalls[answerId] : []), promise],
      }
    },
    setAnswerComplete: (
      state,
      action: PayloadAction<{ answerId: number; userMedia: 'audio' | 'video' }>,
    ) => {
      const { answerId, userMedia } = action.payload
      state.answerComplete = {
        ...state.answerComplete,
        [answerId]: {
          ...(state.answerComplete[answerId]
            ? state.answerComplete[answerId]
            : { audio: false, video: false }),
          [userMedia]: true,
        },
      }
    },
    setAnswerCompletePromise: (
      state,
      action: PayloadAction<{ answerId: number; promise: Promise<unknown> }>,
    ) => {
      const { answerId, promise } = action.payload
      state.answerComplete = {
        ...state.answerComplete,
        [answerId]: {
          ...state.answerComplete[answerId],
          completedPromise: promise,
        },
      }
    },
    setActiveInterviewTab: (state, action: PayloadAction<ActiveInterviewTab>) => {
      state.activeInterviewTab = action.payload
    },
    stopVideoStream: state => {
      state.videoStream?.getTracks().forEach(track => {
        track.stop()
      })
      state.videoStream = undefined
    },
    stopAudioStream: state => {
      state.audioStream?.getTracks().forEach(track => {
        track.stop()
      })
      state.audioStream = undefined
    },
  },
})

export const setupVideoStream = createAsyncThunk(
  'interview/setupVideoStream',
  async (_: void, { getState, dispatch }) => {
    const state: Store = getState() as Store
    const { videoStream } = state.interview
    if (videoStream && videoStream.active) {
      return videoStream
    }
    dispatch(actions.stopVideoStream())

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 1080 }, // Reduce video resolution
          height: { ideal: 720 },
          frameRate: { ideal: 24 }, // Reduce frame rate
          facingMode: { ideal: 'user' }, // on mobile choose the self webcam
        },
        audio: true,
      })
      stream.getTracks().forEach(track => {
        const handleEndedEvent = () => {
          dispatch(actions.setVideoStream(undefined))
          track.removeEventListener('ended', handleEndedEvent)
        }
        track.addEventListener('ended', handleEndedEvent)
      })

      dispatch(actions.setVideoStream(stream))
    } catch (error) {
      console.error(error)
    }
  },
)

export const setupAudioStream = createAsyncThunk(
  'interview/setupAudioStream',
  async (_: void, { getState, dispatch }) => {
    const state: Store = getState() as Store
    const { audioStream } = state.interview
    if (audioStream && audioStream.active) {
      return audioStream
    }
    try {
      dispatch(actions.stopAudioStream())
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      stream.getAudioTracks().forEach(track => {
        const handleEndedEvent = () => {
          dispatch(actions.setAudioStream(undefined))
          track.removeEventListener('ended', handleEndedEvent)
        }
        track.addEventListener('ended', handleEndedEvent)
      })
      dispatch(actions.setAudioStream(stream))
    } catch (error) {
      console.error(error)
    }
  },
)

export { reducer, actions }
