import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { trackInternalEvent } from '@rio/tracking'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as SimpleForm from 'builder/components/SimpleForm'
import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import { selectors } from 'builder/modules/autoApply/autoApplyModule'
import { Routes, TotalPageCount, StartCompleteScreenRoute } from '../../constants'
import { Container, FillWarning, LayoutContainer, Title, TitleWrapper } from '../styles'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { usePatchForm } from '../../hooks/usePatchForm'
import { usePrompt } from '../../hooks/usePrompt'
import { FormContainer } from './styles'
import { LicenseAndCertification } from './LicenceAndCertification'

const TRANSLATION = 'builder.auto_apply.form.licenses_and_certifications'

export const LicenseAndCertificationContainer = () => {
  const formState = useSelector(selectors.form)
  const form = useForm<Pick<AutoApplyForm, 'coursesAttributes'>>({
    mode: 'onChange',
  })

  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { isPromptVisible, handleCancel, handleConfirm } = usePrompt({
    when: true,
    ignoreParentRoute: ['job-search', 'auto-apply'],
  })

  const { mutateAsync: patchForm, isLoading } = usePatchForm()

  const onNextStep = async () => {
    const values = form.getValues()
    const isEditMode = formState.autoApplyDataCompleted

    await patchForm({
      ...values,
      autoApplyDataCompleted: true,
    })

    const url = isEditMode ? '/job-search' : `/auto-apply/${StartCompleteScreenRoute.COMPLETED}`
    navigate(url)
  }

  const onBackClick = () => {
    form.clearErrors()
    navigate(`/auto-apply/${Routes.JOB_PREFERENCES_2}`)
  }

  const hasCourses = (data: Array<{ [x: string]: unknown }>) => {
    return data?.some(item => {
      delete item.id
      delete item.position
      return Object.keys(item).some(key => item[key])
    })
  }

  useEffect(() => {
    trackInternalEvent('aa_licenses', {
      label: 'total_job_search_solution',
    })
  }, [])

  useEffect(() => {
    const { coursesAttributes } = formState
    form.setValue('coursesAttributes', coursesAttributes)
  }, [form, formState])

  return (
    <LayoutContainer>
      <Container>
        <TitleWrapper>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <FillWarning>{i18n.t(`${TRANSLATION}.sub_title`)}</FillWarning>
        </TitleWrapper>
        <FormContainer $heightOfOthers={205}>
          <SimpleForm.Form
            submitButtonText={i18n.t(
              `${TRANSLATION}.${hasCourses(form.watch('coursesAttributes')) ? 'next' : 'skip'}`,
            )}
            onBackClick={onBackClick}
            onSubmit={form.handleSubmit(onNextStep)}
            isPending={isLoading}
            processIndicator={{
              totalStep: TotalPageCount,
              currentStep: 4,
            }}
          >
            <LicenseAndCertification form={form} />
          </SimpleForm.Form>
        </FormContainer>
      </Container>
      {isPromptVisible && <ConfirmModal onClose={handleCancel} onConfirm={handleConfirm} />}
    </LayoutContainer>
  )
}
