import startCase from 'lodash/startCase'
import kebabCase from 'lodash/kebabCase'

import type { UserDocumentType } from '@rio/types'
import { EDashboardLocalStoreKeys } from 'builder/components/CIO-Dasboard-2.0/types'

/** "cover_letter" → "cover-letters" (for URLs) */
export const typeToSlug = (type: UserDocumentType) => kebabCase(type) + 's'

/** "cover_letter" → "Cover Letter" (for Google Analytics event names) */
export const typeToName = (type: UserDocumentType) => startCase(type)

export const getIntialActiveTab = () => {
  const pastActiveTab = localStorage.getItem(EDashboardLocalStoreKeys.ACTIVE_TAB)
  if (pastActiveTab && !isNaN(parseInt(JSON.parse(pastActiveTab)))) {
    return parseInt(JSON.parse(pastActiveTab))
  }
  return 0
}
