import { times } from 'lodash'
import { RefObject, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { trackInternalEvent } from '@rio/tracking'

import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import ApplyModal from 'builder/components/ApplyModal'
import LimitPaywallModal from 'builder/components/LimitPaywallModal'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Icon24 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { selectors as careerProfileSelectors } from 'builder/modules/careerProfile'

import JobDetails, { JobDetailsSkeleton } from '../JobDetails'
import JobItem, { JobItemSkeleton } from '../JobItem'

import { PageActionButtons } from '../PageActionButtons/PageActionButtons'
import { NoResultsSearch } from '../Banners/NoResultsSearch/NoResultsSearch'
import { useJobSearch } from '../useJobSearch'
import { SHOW_CHECK_PROFILE_BANNER_STORAGE_KEY, TabType } from '../types'
import { CheckProfileBanner } from '../Banners/CheckProfile/CheckProfile'
import { NoResultsRecommendation } from '../Banners/NoResultsSearch/NoResultsRecommendation'
import {
  CheckProfileContainer,
  Details,
  DetailsFixedContainer,
  Grid,
  HeadingContainer,
  JobsList,
  SimilarJobsTitle,
  RecommendedJobsDescription,
  RecommendedJobsDescriptionContainer,
  SearchForInsteadLink,
  SearchResultsSubtitle,
  SearchResultsTitle,
} from './styles'
import { Job, ResultStats } from './types'

interface Props {
  results: Job[]
  isSearching: boolean
  isLoading: boolean
  isNoResults: boolean
  activeJob: Job | null
  stats: ResultStats | null
  similarJobsStats?: ResultStats | null
  similarResults?: Job[]
  correctedTerm?: string | null
  searchTerm?: string
  setActiveJob: (job: Job | null) => void
  loadNextPage: () => void
  loadNextSimilarJobsPage?: () => void
}

export const JobSearchResult = ({
  results,
  isSearching,
  isLoading,
  isNoResults,
  stats,
  activeJob,
  setActiveJob,
  loadNextPage,
  similarResults,
  similarJobsStats,
  loadNextSimilarJobsPage = () => {},
  correctedTerm,
  searchTerm,
}: Props) => {
  const { selectedTabId, handleChangeSelectedTabId, updateSearchParams } = useJobSearch()
  const { i18n } = useI18n()

  const { features } = useFeaturesConfig()

  const { isPhone } = useMediaQueries()

  const [limitPaywallOpen, setLimitPaywallOpen] = useState<boolean>(false)
  const [applyDialog, setApplyDialog] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement | null>(null)

  const isNewProfile = useTypedSelector(careerProfileSelectors.isNewProfile)

  // Infinite scroll logic
  const hasNextPage = results.length < (stats?.total_jobs || 0)
  const hasNextPageSimilarJobs = similarResults?.length
    ? similarResults.length < (similarJobsStats?.total_jobs || 0)
    : false

  const infiniteRef: RefObject<HTMLLIElement> = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasNextPage || hasNextPageSimilarJobs,
    onLoadMore: hasNextPage ? loadNextPage : loadNextSimilarJobsPage,
  })

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0
    }
  }, [activeJob])

  const handleSelectJob = (job: Job) => {
    setActiveJob(job)
  }

  return (
    <>
      {isPhone && (
        <HeadingContainer>
          <PageActionButtons />
        </HeadingContainer>
      )}

      {selectedTabId === TabType.recommendation &&
        !isNewProfile &&
        (localStorage.getItem(SHOW_CHECK_PROFILE_BANNER_STORAGE_KEY) ? (
          <CheckProfileContainer>
            <CheckProfileBanner />
          </CheckProfileContainer>
        ) : (
          !isNoResults && (
            <RecommendedJobsDescriptionContainer>
              {!isPhone && <Icon24.DoubleStars />}
              <RecommendedJobsDescription>
                {isPhone && <Icon24.DoubleStars style={{ marginRight: 4 }} />}
                {i18n.t(`builder.job_search.recommendations.description.line_one`)}{' '}
                <Link
                  to="/career-profile/edit?section=workExperiences&returnUrl=/job-search?view=recommendation"
                  onClick={() => {
                    trackInternalEvent('click_edit_preferences', { tab: 'work_experience' })
                    trackInternalEvent('view_profile_editor', { label: 'recommended_jobs' })
                  }}
                >
                  {i18n.t(`builder.job_search.recommendations.description.work_experience`)}
                </Link>{' '}
                {i18n.t(`builder.job_search.recommendations.description.and`)}{' '}
                <Link
                  to="/career-profile/edit?section=jobPreferences&returnUrl=/job-search?view=recommendation"
                  onClick={() => {
                    trackInternalEvent('click_edit_preferences', { tab: 'job_preferences' })
                    trackInternalEvent('view_profile_editor', { label: 'recommended_jobs' })
                  }}
                >
                  {i18n.t(`builder.job_search.recommendations.description.job_preferences`)}
                </Link>
              </RecommendedJobsDescription>
            </RecommendedJobsDescriptionContainer>
          )
        ))}

      {isNoResults &&
        (selectedTabId === TabType.recommendation ? (
          <NoResultsRecommendation handleSearch={() => handleChangeSelectedTabId(TabType.search)} />
        ) : (
          <NoResultsSearch />
        ))}

      <Grid>
        <JobsList>
          {isSearching ? (
            <>
              {times(6).map(key => (
                <JobItemSkeleton key={key} />
              ))}
            </>
          ) : (
            <>
              {!!correctedTerm && results.length > 0 && (
                <>
                  <SearchResultsTitle>
                    {i18n.t(`builder.job_search.job_search_results.show_results_for`)}{' '}
                    {correctedTerm}
                  </SearchResultsTitle>
                  <SearchResultsSubtitle>
                    {i18n.t(`builder.job_search.job_search_results.search_instead_for`)}{' '}
                    <SearchForInsteadLink
                      onClick={() => updateSearchParams({ correct_term: false })}
                    >
                      {searchTerm}
                    </SearchForInsteadLink>
                  </SearchResultsSubtitle>
                </>
              )}
              {results.map((job, index) => (
                <JobItem
                  key={job.id}
                  isActive={job.id === activeJob?.id}
                  job={{ ...job, position: index + 1 }}
                  onClick={() => handleSelectJob(job)}
                />
              ))}
              {hasNextPage && <JobItemSkeleton />}
              {!hasNextPage && similarResults?.length ? (
                <>
                  <SimilarJobsTitle withMarginTop={results.length > 0}>
                    {i18n.t('builder.job_search.job_search_results.similar_jobs')}
                  </SimilarJobsTitle>
                  {similarResults.map((job, index) => (
                    <JobItem
                      key={index}
                      isActive={job.id === activeJob?.id}
                      job={{ ...job, position: index + 1 }}
                      onClick={() => handleSelectJob(job)}
                    />
                  ))}
                  {hasNextPageSimilarJobs && <JobItemSkeleton />}
                </>
              ) : null}
            </>
          )}
          <li ref={infiniteRef} />
        </JobsList>
        {!isNoResults && (
          <Details $isSearching={isSearching} hasActiveJob={!!activeJob}>
            <DetailsFixedContainer
              ref={ref}
              $isSearching={isSearching}
              $isDashboardV2={features.superApp || features.international}
            >
              {isSearching && <JobDetailsSkeleton />}
              {activeJob && !isSearching && (
                <JobDetails
                  job={activeJob}
                  setApplyDialog={setApplyDialog}
                  goBack={() => setActiveJob(null)}
                />
              )}
            </DetailsFixedContainer>
          </Details>
        )}

        {limitPaywallOpen && (
          <LimitPaywallModal
            limitPaywallOpen={limitPaywallOpen}
            setLimitPaywallOpen={setLimitPaywallOpen}
            from="search"
          />
        )}

        {applyDialog && activeJob && (
          <ApplyModal
            job={activeJob}
            applyDialog={applyDialog}
            setApplyDialog={setApplyDialog}
            setLimitPaywallOpen={setLimitPaywallOpen}
            parameterTracking={
              selectedTabId === TabType.recommendation ? 'recommendation' : 'search'
            }
          />
        )}
      </Grid>
    </>
  )
}
