import { trackInternalEvent } from '@rio/tracking'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { i18n } from 'builder/utils/i18n'
import { actions } from 'builder/modules/resumeEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import writingHandDefault from './images/writingHandDefault.png'
import writingHandHovered from './images/writingHandHovered.png'
import {
  BadgeBanner as Banner,
  BadgeInfoContainer,
  Heading,
  Content,
  GenerateButton,
  FeaturedIcon,
  ImageContainer,
  ImageDefault,
  ImageHovered,
} from './styles'

interface AiCoverLetterBannerProps {
  onBannerGenerateClick: () => void
}

const BadgeBanner = ({ onBannerGenerateClick }: AiCoverLetterBannerProps) => {
  const targetRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const { viewGenerateCLBannerEventTracked } = useTypedSelector(state => state.resumeEditor)

  const handleIntersection: IntersectionObserverCallback = useCallback(
    entries => {
      entries.forEach(entry => {
        // if the banner is in the viewport, it will trigger the event, if it is not tracked already
        if (entry.isIntersecting) {
          trackInternalEvent('see_ai_cover_letter_banner')
          dispatch(actions.setGenerateCLBannerEventTracked(true))
        }
      })
    },
    [dispatch],
  )

  useEffect(() => {
    // Create an observer with a callback function to trigger the analytics event
    if (viewGenerateCLBannerEventTracked) return

    const observer = new IntersectionObserver(handleIntersection)
    const bannerRef = targetRef.current
    if (bannerRef) {
      observer.observe(bannerRef)
    }

    return () => {
      if (bannerRef) {
        observer.unobserve(bannerRef)
      }
    }
  }, [handleIntersection, viewGenerateCLBannerEventTracked, targetRef])

  return (
    <Banner ref={targetRef}>
      <Heading>{i18n.t('builder.ai_cover_letter.badge_banner.title')}</Heading>
      <BadgeInfoContainer>
        <ImageContainer>
          <ImageDefault src={writingHandDefault} alt="Default Image" />
          <ImageHovered src={writingHandHovered} alt="Hovered Image" />
        </ImageContainer>
        <Content>{i18n.t('builder.ai_cover_letter.badge_banner.content_new')}</Content>
      </BadgeInfoContainer>
      <GenerateButton onClick={onBannerGenerateClick}>
        <FeaturedIcon showAnimation={false} />
        {i18n.t('builder.ai_cover_letter.generate')}
      </GenerateButton>
    </Banner>
  )
}

export default BadgeBanner
