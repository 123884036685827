import { useMutation } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'

type EtagBody = {
  part_number: number
  etag: string
}
export type CompleteInterviewVideoParams = {
  questionId: number
  answerId: number
  answerDuration: number
  totalBytes: number
  body: {
    audio: EtagBody[]
    video: EtagBody[]
  }
}

export const useCompleteInterviewVideo = () => {
  return useMutation({
    mutationFn: (params: CompleteInterviewVideoParams) => {
      return baseClient.post(
        // eslint-disable-next-line max-len
        `/interview_prep/questions/${params.questionId}/answer/complete?answer_duration=${params.answerDuration}&total_bytes=${params.totalBytes}`,
        {
          body: JSON.stringify(params.body),
        },
      )
    },
  })
}
