import { ChangeEvent, MouseEventHandler } from 'react'
import { i18n } from 'builder/utils/i18n'

import Icon from 'builder/components/Icon'
import Toggle from 'builder/components/Toggle'
import { getMonths } from './months'

import {
  PanelWrapper,
  DatePickerPanel,
  YearPicker,
  Year,
  LeftArrow,
  RightArrow,
  MonthPicker,
  PickersContainer,
  Month,
  CurrentlyContainer,
} from './styles'

type Props = {
  year: number
  month: number | null
  onChangeYear: (value: number) => void
  onChoose: (month: number | null, year: number, shouldClose?: boolean) => void

  onIsPresentToggle?: (value: boolean) => void
  isPresent?: boolean
  showIsPresent?: boolean
  alignRight?: boolean
  currentlyLabel?: string
  noActiveMonth?: boolean
  maxYear?: number
  maxMonth?: number | null
  minYear?: number
  minMonth?: number | null
}

const PickerPanel = (props: Props) => {
  const {
    month,
    year,
    isPresent,
    showIsPresent,
    alignRight,
    currentlyLabel,
    noActiveMonth,
    onChangeYear,
    onChoose,
    onIsPresentToggle,
    maxYear,
    maxMonth,
    minYear,
    minMonth,
  } = props

  const isYearTooLate = (year: number) => {
    return !!maxYear && year > maxYear
  }

  const isYearTooEarly = (year: number) => {
    return !!minYear && year < minYear
  }

  const isDateTooLate = (month: number | null, year: number) => {
    const isMonthTooLate =
      !!maxYear && !!maxMonth && !!month && maxYear === year && month > maxMonth

    return isYearTooLate(year) || isMonthTooLate
  }

  const isDateTooEarly = (month: number | null, year: number) => {
    const isMonthTooEarly =
      !!minYear && !!minMonth && !!month && minYear === year && month < minMonth

    return isYearTooEarly(year) || isMonthTooEarly
  }

  const handleYearInc = () => {
    onChangeYear(+year + 1)

    if (month && maxMonth && maxYear && isDateTooLate(month, year + 1)) {
      onChoose(maxMonth, maxYear, false)
    }
  }

  const handleYearDecr = () => {
    onChangeYear(+year - 1)

    if (month && minMonth && minYear && isDateTooEarly(month, year - 1)) {
      onChoose(minMonth, minYear, false)
    }
  }

  const handleChoose: MouseEventHandler = event => {
    const target = event.target as HTMLDivElement
    onChoose(+(target.dataset.number || 0), year)
  }

  const handleYearClick = () => {
    onChoose(null, year)
  }

  const toggleIsPresent = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    onIsPresentToggle?.(target.checked)

    if (minMonth && minYear && isDateTooEarly(month, year)) {
      onChangeYear(minYear)
      onChoose(minMonth, minYear)
    }

    if (maxMonth && maxYear && isDateTooLate(month, year)) {
      onChangeYear(maxYear)
      onChoose(maxMonth, maxYear)
    }
  }

  return (
    <PanelWrapper>
      <DatePickerPanel $alignRight={alignRight}>
        <PickersContainer>
          <YearPicker>
            <LeftArrow onClick={handleYearDecr} $isDisabled={isPresent || isYearTooEarly(year - 1)}>
              <Icon.ToggleArrow />
            </LeftArrow>
            <Year onClick={handleYearClick} $isActive={noActiveMonth} $isDisabled={isPresent}>
              {year}
            </Year>
            <RightArrow onClick={handleYearInc} $isDisabled={isPresent || isYearTooLate(year + 1)}>
              <Icon.ToggleArrow />
            </RightArrow>
          </YearPicker>
          <MonthPicker>
            {getMonths().map(el => {
              return (
                <Month
                  key={el.number}
                  $isActive={!noActiveMonth && el.number === month}
                  data-number={el.number}
                  onClick={handleChoose}
                  $isDisabled={
                    isPresent || isDateTooLate(el.number, year) || isDateTooEarly(el.number, year)
                  }
                >
                  {el.name}
                </Month>
              )
            })}
          </MonthPicker>
          {showIsPresent && (
            <CurrentlyContainer>
              <Toggle
                tabIndex={-1}
                label={currentlyLabel || i18n.t('builder.date_range_picker.present')}
                checked={isPresent}
                onChange={toggleIsPresent}
              />
            </CurrentlyContainer>
          )}
        </PickersContainer>
      </DatePickerPanel>
    </PanelWrapper>
  )
}

export default PickerPanel
