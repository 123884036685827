import { useRef, useEffect, ChangeEvent, useMemo } from 'react'
import { formatDate } from 'builder/utils/formatDate'
import { i18n } from 'builder/utils/i18n'
import Toggle from 'builder/components/Toggle'

import { convertToInternal } from './utils'

import { Container, Label, LabelContent, MobileFields, MobileField, MobileInput } from './styles'
import { DateRangePickerProps } from './types'

const EXTERNAL_FORMAT = 'yyyy-MM-dd'
const NATIVE_FORMAT = 'yyyy-MM'

const MobilePicker = (props: DateRangePickerProps) => {
  const {
    value,
    currentlyLabel,
    errors: { startError, endError } = {
      startError: false,
      endError: false,
    },
    dateFromHighlightId,
    dateUntilHighlightId,
    onChange = () => {},
    isUntilToday = false,
  } = props
  const { dateFrom, dateUntil, isDateUntilPresent, isMonthFromHidden, isMonthUntilHidden } = value

  const dateFromRef = useRef<HTMLInputElement>(null)
  const dateUntilRef = useRef<HTMLInputElement>(null)

  // Fix iOS "Clear" button (https://github.com/facebook/react/issues/8938)
  useEffect(() => {
    if (dateFromRef.current) dateFromRef.current.defaultValue = ''
    if (dateUntilRef.current) dateUntilRef.current.defaultValue = ''
  }, [])

  const handleChange = (event: ChangeEvent) => {
    const { value: inputValue, name } = event.target as HTMLInputElement
    const value = inputValue ? formatDate(inputValue, EXTERNAL_FORMAT) : null
    const monthHiddenKey = name === 'dateFrom' ? 'isMonthFromHidden' : 'isMonthUntilHidden'

    onChange({
      [name]: value,
      [monthHiddenKey]: false,
    })
  }

  const handleToggleChange = () => {
    const { isDateUntilPresent } = value
    onChange({ isDateUntilPresent: !isDateUntilPresent })
  }

  const [startValue] = convertToInternal(dateFrom, {
    isMonthHidden: isMonthFromHidden,
  })
  const [endValue] = convertToInternal(dateUntil, {
    isDatePresent: isDateUntilPresent,
    isMonthHidden: isMonthUntilHidden,
  })

  const maxEndValue = useMemo(() => {
    if (!isUntilToday) {
      return ''
    }

    return formatDate(new Date().toISOString(), NATIVE_FORMAT)
  }, [isUntilToday])

  const maxStartValue = useMemo(() => {
    if (isDateUntilPresent || isDateUntilPresent) {
      return maxEndValue || ''
    }

    return dateUntil ? formatDate(dateUntil, NATIVE_FORMAT) : ''
  }, [maxEndValue, isDateUntilPresent, dateUntil])

  const minEndValue = useMemo(() => {
    return dateFrom ? formatDate(dateFrom, NATIVE_FORMAT) : ''
  }, [dateFrom])

  const isStartEmpty = startValue.length === 0
  const isEndEmpty = endValue.length === 0
  const placeholder = i18n.t('builder.date_range_picker.placeholder')

  return (
    <Container>
      <Label>
        <LabelContent $hasError={startError || endError}>
          {i18n.t('builder.date_range_picker.title')}
        </LabelContent>
      </Label>
      <MobileFields>
        <MobileField $isEmpty={isStartEmpty} $hasError={startError}>
          {isStartEmpty ? placeholder : startValue}
          <MobileInput
            ref={dateFromRef}
            name="dateFrom"
            onChange={handleChange}
            value={dateFrom ? formatDate(dateFrom, NATIVE_FORMAT) : ''}
            data-highlight-id={dateFromHighlightId}
            max={maxStartValue}
          />
        </MobileField>
        <MobileField $isEmpty={isEndEmpty} $hasError={endError}>
          {isEndEmpty ? placeholder : endValue}
          {!isDateUntilPresent && (
            <MobileInput
              ref={dateUntilRef}
              name="dateUntil"
              onChange={handleChange}
              value={dateUntil ? formatDate(dateUntil, NATIVE_FORMAT) : ''}
              data-highlight-id={dateUntilHighlightId}
              min={minEndValue}
              max={maxEndValue}
            />
          )}
        </MobileField>
      </MobileFields>
      <Toggle
        label={currentlyLabel || i18n.t('builder.date_range_picker.present')}
        checked={isDateUntilPresent}
        onChange={handleToggleChange}
      />
    </Container>
  )
}

export default MobilePicker
