import SUCCESS_ICON from '../../../../../app/assets/images/snackbar-success-icon.svg'
import { Container, OpenButton, Wrapper, IconImage, Text } from './styles'

interface Props {
  text: string
  onOpen: () => void
}

const OpenableSnackbar = ({ text, onOpen }: Props) => {
  return (
    <Container>
      <Wrapper>
        <IconImage src={SUCCESS_ICON} alt="icon" />
        <Text>{text}</Text>
      </Wrapper>
      <OpenButton onClick={onOpen}>Open</OpenButton>
    </Container>
  )
}

export default OpenableSnackbar
