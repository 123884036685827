import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { ExpertDoc } from 'packages/types'
import Icon, { Icon24 } from 'builder/components/Icon'
import { i18n as I18n } from 'builder/utils/i18n'
import TRDocumentActionMenu from 'builder/components/TRDocumentActionMenu'
import { DocumentTypes, FORMATS, FetchStatuses } from 'builder/modules/constants'
import Logo from 'builder/components/Logo'
import { actions } from 'builder/modules/panel'
import { actions as renderingActions } from 'builder/modules/rendering'
import { actions as uiActions } from 'builder/modules/ui'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import TREditModal from 'builder/components/TREditModal'
import loaderImage from 'images/builder/resume-editor/resume-loader.svg'
import noPreviewImg from './no-preview.svg'
import {
  PageContainer,
  Container,
  Header,
  BackButton,
  ChevronLeft,
  DownloadMenu,
  DownloadButton,
  Actions,
  ActionsButton,
  LoaderContainer,
  Loader,
  ResumeImg,
  NoPreviewContainer,
  NoPreviewBackground,
  NoPreviewDownload,
  NoPreviewImg,
  NoPreviewText,
} from './styles'

export const TRDocumentView = () => {
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { panel, rendering, ui } = useAppSelector(store => store)
  const { isTREditModalOpen } = ui
  const { trDocuments, trDocumentsStatus } = panel
  const { isDocumentDownloading } = rendering

  const pathId = window.location.pathname.split('/')[3] // get document id from pathname
  const isValidId = /^\d+$/.test(pathId) // matches one or more occurences of [0-9]
  const documentId = parseInt(pathId)

  const [trDocument, setTrDocument] = useState<ExpertDoc | null>(null)
  const [trDocumentStatus, setTrDocumentStatus] = useState<FetchStatuses | null>(null)

  useEffect(() => {
    if (!trDocument && trDocuments[documentId]) {
      setTrDocument({ ...trDocuments[documentId] })
    }
  }, [trDocument, trDocuments, documentId])

  useEffect(() => {
    setTrDocumentStatus(trDocumentsStatus[documentId])
  }, [trDocumentsStatus, documentId])

  useEffect(() => {
    if (!isValidId) {
      navigate('/tr-documents')
    } else {
      dispatch(actions.fetchTRDocument({ id: documentId }))
    }
  }, [dispatch, documentId, isValidId, navigate])

  const getDocType = useCallback(() => {
    if (trDocument?.doc_type === 'resume') {
      return DocumentTypes.resume
    } else if (trDocument?.doc_type === 'cover_letter') {
      return DocumentTypes.coverLetter
    }

    return DocumentTypes.resume
  }, [trDocument?.doc_type])

  const hasDocxFormat = useCallback(
    () =>
      trDocument?.files?.some(
        file => file?.filename?.endsWith('.docx') || file?.filename?.endsWith('.doc'),
      ) || false,
    [trDocument?.files],
  )

  const hasPdfFormat = useCallback(
    () => trDocument?.files?.some(file => file?.filename?.endsWith('.pdf')) || false,
    [trDocument?.files],
  )

  const handleDownloadDocument = useCallback(
    ({ format = FORMATS.tr_pdf }) => {
      dispatch(
        renderingActions.download({
          id: documentId,
          type: getDocType(),
          format,
          source: 'tr_doc',
          trDocFiles: trDocument?.files ?? [],
        }),
      )
      trackInternalEvent('click_download_resume_button', format)
    },
    [dispatch, documentId, getDocType, trDocument?.files],
  )

  const handleEdit = useCallback(() => {
    dispatch(uiActions.openTREditModal(documentId))
    trackInternalEvent('click_edit_resume')
  }, [dispatch, documentId])

  const renderDocument = () => {
    const { loading, loaded, failed } = FetchStatuses
    const hasPreviews = trDocument?.previews.length

    if (trDocumentStatus === loading) {
      return (
        <LoaderContainer>
          <Loader src={loaderImage} />
        </LoaderContainer>
      )
    }

    if (trDocumentStatus === loaded && hasPreviews) {
      return trDocument.previews.map(preview => <ResumeImg src={preview.url} key={preview.url} />)
    }

    if ((trDocumentStatus === loaded && !hasPreviews) || trDocumentStatus === failed) {
      return (
        <NoPreviewContainer>
          <NoPreviewBackground>
            <NoPreviewImg src={noPreviewImg} />
            <NoPreviewText>Sorry, we can’t show preview of this file</NoPreviewText>
            {!!trDocument && hasPdfFormat() && (
              <NoPreviewDownload
                onClick={handleDownloadDocument}
                isDisabled={isDocumentDownloading}
              >
                {I18n.t('builder.resume_editor.download')}
              </NoPreviewDownload>
            )}
          </NoPreviewBackground>
        </NoPreviewContainer>
      )
    }
  }

  return (
    <PageContainer>
      <Container>
        <Header>
          <BackButton onClick={() => navigate('/tr-documents')}>
            <ChevronLeft>
              <Icon24.Chevron />
            </ChevronLeft>
            My Documents
          </BackButton>
          <Logo />
          {!!trDocument && (
            <DownloadMenu>
              {hasPdfFormat() && (
                <DownloadButton onClick={handleDownloadDocument} isDisabled={isDocumentDownloading}>
                  {I18n.t('builder.resume_editor.download')}
                </DownloadButton>
              )}
              <Actions>
                <ActionsButton
                  onClick={() => setIsActionMenuOpen(true)}
                  isDisabled={isDocumentDownloading}
                >
                  <Icon.More />
                </ActionsButton>
                {isActionMenuOpen && (
                  <TRDocumentActionMenu
                    {...{
                      position: 'bottom',
                      documentId,
                      isTRdocument: true,
                      isResumeType: trDocument.doc_type === 'resume',
                      isDocxFormat: hasDocxFormat(),
                      onDownload: handleDownloadDocument,
                      documentType: getDocType(),
                      onClose: () => setIsActionMenuOpen(false),
                      onEdit: handleEdit,
                    }}
                  />
                )}
              </Actions>
            </DownloadMenu>
          )}
        </Header>
        {renderDocument()}
      </Container>
      {isTREditModalOpen && <TREditModal id={documentId} />}
    </PageContainer>
  )
}
