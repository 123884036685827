import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import BaseButton from 'builder/components/Button'

export const Container = styled.div<{ isOpen: boolean }>(({ isOpen }) => {
  return css`
    border-radius: 16px;
    background-color: ${Colors.Neutral5};
    display: flex;
    padding: 16px;
    gap: 12px;
    cursor: pointer;
    align-items: ${isOpen ? 'flex-start' : 'center'};
    position: relative;
    overflow: hidden;

    &:hover {
      background-color: ${Colors.Neutral10};
    }

    ${Media.Phone`
      padding: 12px 16px;
      text-align: ${isOpen ? 'center' : 'initial'};
    `};

    &.selected-primary {
      background-color: ${Colors.Blue10};
      padding: 16px 12px 16px 16px;

      ${Media.Phone`
        padding: 180px 16px 16px;
      `};

      &:hover {
        background-color: ${Colors.Blue20};

        .selected-icon-container {
          background-color: ${Colors.Blue50};
          color: ${Colors.White};
        }
      }
    }

    &.selected-secondary {
      background-color: ${Colors.Indigo10};
      padding: 16px 12px 16px 16px;

      ${Media.Phone`
        padding: 180px 16px 16px;
      `};

      &:hover {
        background-color: ${Colors.Indigo20};

        .selected-icon-container {
          background-color: ${Colors.Indigo50};
          color: ${Colors.White};
        }
      }
    }

    &.completed {
      cursor: default;
      background-color: ${Colors.Green10};
      padding: 12px 12px 12px 16px;

      &:hover {
        background-color: ${Colors.Green20};

        .completed-icon-container {
          background-color: ${Colors.Green40};
          color: ${Colors.White};
        }
      }
    }
  `
})

export const Title = styled.p<{ isOpen: boolean }>(({ isOpen }) => {
  return css`
    ${FontWeights.Medium};
    ${Typography.Body};
    color: ${Colors.Neutral90};
    margin-bottom: ${isOpen ? '0' : '-5px'};

    &.selected-primary {
      color: ${Colors.Blue80};
    }

    &.selected-secondary {
      color: ${Colors.Indigo80};
    }

    &.completed {
      opacity: 0.7;
      color: ${Colors.Green80};
      text-decoration: line-through;
    }

    &.external-banner {
      ${FontWeights.DemiBold};
      ${Typography.Subhead};
      color: ${Colors.Neutral100};
    }

    ${Media.Tablet`
      max-width: 336px;
    `};

    ${Media.Phone`
      ${isOpen && 'margin: auto;'}
    `};
  `
})

export const Subtitle = styled.p<{ height?: number; tabletHeight?: number }>(
  ({ height, tabletHeight }) => {
    return css`
      ${Typography.Body};
      color: ${Colors.Neutral90};
      max-width: 512px;
      min-height: ${height || 48}px;

      &.selected-primary {
        color: ${Colors.Blue80};
      }

      &.selected-secondary {
        color: ${Colors.Indigo80};
      }

      ${Media.Tablet`
        max-width: 336px;
        min-height: ${tabletHeight || 48}px;
      `};

      ${Media.Phone`
        margin: auto;
        min-height: unset;
      `};
    `
  },
)

export const SubtitleContainer = styled.div`
  display: flex;
  width: 51%;
  transition: max-height 0.8s ease-in-out;

  &.visible {
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
  }

  ${Media.Phone`
    width: 100%;
  `}
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`

export const Icon = styled(Icon20.StatusNew)<{ isOpen: boolean }>(({ isOpen }) => {
  return css`
    color: ${Colors.Blue40};
    margin-top: 2px;

    &.secondary {
      color: ${Colors.Indigo40};
    }

    ${Media.Phone`
      display: ${isOpen ? 'none' : 'flex'};
    `};
  `
})

export const IconCompleted = styled(Icon20.Tick)`
  color: ${Colors.White};
`

export const IconCompletedContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${Colors.Green50};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ArrowDown = styled(Icon20.ChevronDown)`
  width: 24px;
  height: 24px;
  color: ${Colors.Neutral90};
  margin-left: auto;
`

export const CompletedIcon = styled(Icon20.Tick)`
  &.completed-icon-container {
    color: ${Colors.Green50};
  }
`

export const CompletedIconContainer = styled.div`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: auto;
  margin-top: auto;

  &.completed-icon-container {
    background-color: ${Colors.Green20};
  }

  ${Media.Phone`
    display: none;
  `};
`

export const SelectedIcon = styled(Icon20.ChevronDown)`
  transform: rotate(-90deg);

  &.selected-icon-container {
    color: ${Colors.Blue50};

    &.secondary {
      color: ${Colors.Indigo50};
    }
  }
`

export const SelectedIconContainer = styled.div`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: auto;
  margin-top: auto;
  cursor: pointer;
  margin-bottom: -4px;
  z-index: 2;

  &.selected-icon-container {
    background-color: ${Colors.Blue20};

    &.secondary {
      background-color: ${Colors.Indigo20};
    }
  }

  ${Media.Tablet`
    display: none;
  `};
`

export const ActionButton = styled(BaseButton)<{ isOpen: boolean }>(({ isOpen }) => {
  return css`
    display: none;
    margin-top: 16px;
    ${Typography.Body};
    ${FontWeights.Medium};
    border-radius: 8px;

    &.selected-primary {
      background-color: ${Colors.Blue20};
      color: ${Colors.Blue50};
    }

    &.selected-secondary {
      background-color: ${Colors.Indigo20};
      color: ${Colors.Indigo50};
    }

    ${Media.Phone`
      display: ${isOpen ? 'flex' : 'none'};
    `};
  `
})
