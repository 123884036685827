import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'
import { Interview } from 'builder/modules/interview/types'
import { ALL_INTERVAL_REFETCH } from '../constants'
import { InitialRequest } from '../components/DashboardView/DashboardView'
import { usePerformanceOnce } from './usePerformanceOnce'

export const useInterviews = (
  jobTitleId?: number,
  setInitialRequest?: React.Dispatch<React.SetStateAction<InitialRequest | undefined>>,
  enabledPerformanceMetric = false,
) => {
  const { registerOncePerformance, stopRegisterPerformance } = usePerformanceOnce({
    enabled: enabledPerformanceMetric,
    metricKey: `interviews-request`,
    tags: { jobTitleId },
    successCallBack: () => {
      if (setInitialRequest) {
        setInitialRequest(prevState =>
          prevState === undefined ? undefined : { ...prevState, interviews: true },
        )
      }
    },
  })
  return useQuery(
    ['useInterviews', jobTitleId],
    async () => {
      registerOncePerformance()
      const response = await baseClient.get<Interview[]>(`/interview_prep/interviews`, {
        params: { data_api_job_title_id: jobTitleId },
      })
      stopRegisterPerformance()
      return response.data
    },
    {
      refetchInterval: (data?: Interview[]) => {
        if (data && !data.some(i => i.status === 'started')) {
          return false
        }
        return ALL_INTERVAL_REFETCH
      },
    },
  )
}
