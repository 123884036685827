export enum TabType {
  recommendation = 'recommendation',
  search = 'search',
}

export const AVAILABLE_COUNTRY = 'United States'

export const SHOW_CHECK_PROFILE_BANNER_STORAGE_KEY = 'showCheckProfileBanner'

export const ONBOARDING_STRUCTURED_RESUME_DATA_KEY = 'onboardingStructuredResumeData'

export enum RecommendationsRequiredAttributes {
  Profile = 'profile',
  TargetRole = 'target_role',
  TargetLocation = 'target_location',
  WorkExperience = 'work_experience',
  WorkExperienceWithDetails = 'work_experience_with_description_and_title',
}

export type RecommendationsRequiredAttributesType = `${RecommendationsRequiredAttributes}`
