import { useDispatch } from 'react-redux'
import { useMatch, useSearchParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { actions as uiActions } from 'builder/modules/ui'
import { useI18n } from 'builder/hooks/useI18n'
import {
  Text,
  Container,
  TextContainer,
  PostDecoration,
  PreDecoration,
  MenuLink,
  Badge,
  CrownIcon,
  TooltipWrapper,
} from './styles'

interface IProps {
  to: string
  children: React.ReactNode
  href?: string
  open?: boolean
  active?: boolean
  subMenu?: boolean
  endDecoration?: React.ReactNode
  startDecoration?: React.ReactNode
  startDecorationActive?: React.ReactNode
  className?: string
  isPostPremium?: boolean
  eventParam?: string
  onClickLink?: (link: string) => void
  onClick?: () => void
  [x: string]: unknown
}

const WithTooltip = ({
  open,
  children,
  isPostPremium,
}: {
  open?: boolean
  isPostPremium?: boolean
  children: React.ReactNode
}) => {
  const { i18n } = useI18n()
  return isPostPremium ? (
    <TooltipWrapper
      $isOpen={open}
      position="right"
      value={i18n.t('builder.side_menu.post_premium_tooltip')}
      multiline
    >
      {children}
    </TooltipWrapper>
  ) : (
    <>{children}</>
  )
}

export const MenuItemContent = ({
  to,
  children,
  href,
  open,
  active,
  subMenu,
  endDecoration,
  startDecoration,
  startDecorationActive,
  className,
  eventParam,
  isPostPremium,
  onClickLink,
  onClick,
  ...rest
}: IProps) => {
  const match = useMatch(to)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const matchQueryParams = () => {
    return to.split('?')[1] === searchParams.toString()
  }

  const isActive = active || !!match || matchQueryParams()
  const showBadge = !open && !subMenu && endDecoration

  const handleEvents = () => {
    if (!eventParam) return

    if (eventParam === 'chrome_extension') {
      trackInternalEvent('click_install_chrome', { source: 'left_hand_nav' })
    } else if (to.includes('/career-profile')) {
      trackInternalEvent('click_profile_service', { tool: eventParam })
    } else {
      trackInternalEvent('click_left_nav_menu_link', { target: eventParam })
    }
  }

  const handlePostPremium = () => {
    if (isPostPremium) dispatch(uiActions.setTrackJobPaywall(true))
  }

  const handleExternalLinks = () => {
    if (href) window.open(href, '_blank')
  }

  const getUrl = () => {
    if (isPostPremium || onClick) return '#'
    return to
  }

  const onClickMenuItem = (e: React.MouseEvent) => {
    handleEvents()
    if (isPostPremium) handlePostPremium()
    if (onClick) onClick()
    handleExternalLinks()
    if (onClickLink) {
      e.stopPropagation()
      e.preventDefault()
      onClickLink(to)
    }
  }

  return (
    <MenuLink to={getUrl()} onClick={(e: React.MouseEvent<HTMLElement>) => onClickMenuItem(e)}>
      <WithTooltip open={open} isPostPremium={isPostPremium}>
        <Container {...rest} className={className} open={open} active={isActive}>
          <TextContainer>
            <PreDecoration>
              {showBadge && (isPostPremium ? <CrownIcon /> : <Badge />)}
              {isActive ? startDecorationActive : startDecoration}
            </PreDecoration>
            {open && <Text>{children}</Text>}
          </TextContainer>
          {open && <PostDecoration>{endDecoration}</PostDecoration>}
        </Container>
      </WithTooltip>
    </MenuLink>
  )
}
