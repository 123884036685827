import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'builder/components/Providers/Providers'
import { baseClient } from 'builder/modules/apiClient'

export const useMutationInterviewAnswerQuestion = () => {
  return useMutation({
    mutationFn: async (questionId: number) => {
      const response = await baseClient.post(`/interview_prep/questions/${questionId}/answer`)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['useQuestionTemplate'])
    },
  })
}
