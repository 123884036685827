import { useMemo, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { Tabs, Tab } from 'builder/components/Tabs'
import { useI18n } from 'builder/hooks/useI18n'
import Icon24 from 'builder/components/Icon'
import { useUser } from 'builder/hooks/useUser'
import { InterviewAnswer, InterviewQuestion } from 'builder/modules/interview/types'
import tonOfVoiceImg from '../../assets/ton-of-voice.png'
import fillerWordsImg from '../../assets/filler-words.png'
import pausesImg from '../../assets/pauses.png'
import wordsPerMinuteImg from '../../assets/words-per-minute.png'

import { Display } from '../Display/Display'
import { PremiumBanner } from '../PremiumBanner/PremiumBanner'
import { TRANSLATION_PREFIX } from '../../FeedbackView'
import { InterviewStatus } from '../../../InterviewStatus/InterviewStatus'
import {
  ButtonContainer,
  Container,
  DisplayContainer,
  HeaderContainer,
  Question,
  Text,
  PrevNextButton,
  CloseIcon,
  CustomBadgeCloseIconContainer,
  FooterMobile,
  Body,
  QuestionTitle,
  LoadingTextContainer,
  LoadingBody,
  Spinner,
  Skeleton,
  SkeletonContainer,
  LoadingText,
} from './styles'

interface Props {
  showSidebar: boolean
  questions: InterviewQuestion[]
  selectedQuestionIndex: number
  selectedQuestion?: InterviewQuestion
  onClose: () => void
  onNextQuestion: () => void
  onPrevQuestion: () => void
  answer?: InterviewAnswer
}

type Tab = { labelTab: string; id: TabId }
type TabId = 'feedback' | 'example_good_answer' | 'update'

export const Sidebar = ({
  questions,
  selectedQuestionIndex,
  selectedQuestion,
  onClose,
  onNextQuestion,
  onPrevQuestion,
  showSidebar,
  answer,
}: Props) => {
  const { i18n } = useI18n()
  const [selectedTabId, setSelectedTabId] = useState<TabId>('feedback')
  const user = useUser()
  const isFreePlan = !user?.hasPremiumFeatures
  const isLoading = !answer || !answer?.feedback

  const tabs: Tab[] = useMemo(
    () => [
      {
        labelTab: i18n.t(`${TRANSLATION_PREFIX}.feedback`),
        id: 'feedback',
      },

      {
        labelTab: i18n.t(`${TRANSLATION_PREFIX}.example_good_answer`),
        id: 'example_good_answer',
      },
    ],
    [i18n],
  )
  function renderTabContent() {
    if (!answer?.feedback) {
      return (
        <LoadingBody>
          <LoadingTextContainer>
            <Spinner />
            <LoadingText>{i18n.t(`${TRANSLATION_PREFIX}.loading_sidebar_text`)}</LoadingText>
          </LoadingTextContainer>

          <SkeletonContainer>
            <Skeleton $height={128} />
            <Skeleton $height={128} />
            <Skeleton $height={128} />
            <Skeleton $height={128} />
          </SkeletonContainer>
        </LoadingBody>
      )
    }
    switch (selectedTabId) {
      case 'feedback':
        return (
          <Body $isPremium={true}>
            {answer?.feedback.content ? (
              <>
                <Text
                  $isFree={false}
                  dangerouslySetInnerHTML={{ __html: answer?.feedback.content }}
                />
              </>
            ) : (
              <Skeleton $height={400} />
            )}
            <DisplayContainer>
              <Display
                imgLink={tonOfVoiceImg}
                title={i18n.t(`${TRANSLATION_PREFIX}.ton_of_voice`)}
                result={answer?.feedback.ton_of_voice || ''}
              />

              <Display
                imgLink={fillerWordsImg}
                title={i18n.t(`${TRANSLATION_PREFIX}.filler_words`)}
                result={String(answer?.feedback.filler_words)}
              />

              <Display
                imgLink={pausesImg}
                title={i18n.t(`${TRANSLATION_PREFIX}.pauses`)}
                result={String((answer?.feedback?.pauses || []).length)}
              />

              <Display
                imgLink={wordsPerMinuteImg}
                title={i18n.t(`${TRANSLATION_PREFIX}.words_per_minute`)}
                result={String(answer?.feedback.words_per_minute)}
              />
            </DisplayContainer>
          </Body>
        )

      case 'example_good_answer':
        return (
          <Body $isPremium={!isFreePlan}>
            {answer?.feedback.ideal_answer ? (
              <>
                <Text
                  $isFree={isFreePlan}
                  dangerouslySetInnerHTML={{ __html: answer?.feedback.ideal_answer }}
                />
                {isFreePlan && !isLoading && <PremiumBanner />}
              </>
            ) : (
              <Skeleton $height={400} />
            )}
          </Body>
        )

      default:
        return null
    }
  }

  return (
    <Container $showSidebar={showSidebar}>
      <HeaderContainer>
        <ButtonContainer>
          <PrevNextButton
            onClick={onPrevQuestion}
            $disabled={selectedQuestionIndex === 0}
            $marginRight={4}
          >
            <Icon24.ChevronLeft />
          </PrevNextButton>
          <PrevNextButton
            onClick={onNextQuestion}
            $disabled={questions.length - 1 === selectedQuestionIndex}
          >
            <Icon24.ChevronRight />
          </PrevNextButton>
          <QuestionTitle>
            {i18n.t(`${TRANSLATION_PREFIX}.question`)} {selectedQuestionIndex + 1}
          </QuestionTitle>
        </ButtonContainer>
        <CustomBadgeCloseIconContainer>
          <InterviewStatus
            position="bottom"
            answer={answer}
            isLoading={isLoading}
            tooltipWidth={120}
          />
          <CloseIcon onClick={onClose} />
        </CustomBadgeCloseIconContainer>
      </HeaderContainer>

      <Question>{selectedQuestion?.content}</Question>
      <Tabs>
        {tabs.map(tab => (
          <Tab
            key={tab.id}
            isActive={tab.id === selectedTabId}
            onClick={() => {
              setSelectedTabId(tab.id)
              trackInternalEvent(tab.id === 'feedback' ? 'open_feedback' : 'open_example', {
                label: 'interview_prep',
              })
            }}
          >
            {tab.labelTab}
          </Tab>
        ))}
      </Tabs>
      {renderTabContent()}

      <FooterMobile>
        <PrevNextButton onClick={onPrevQuestion} $disabled={selectedQuestionIndex === 0}>
          <Icon24.ChevronLeft />
        </PrevNextButton>
        {i18n.t(`${TRANSLATION_PREFIX}.question`)} {selectedQuestionIndex + 1}
        <PrevNextButton
          onClick={onNextQuestion}
          $disabled={questions.length - 1 === selectedQuestionIndex}
        >
          <Icon24.ChevronRight />
        </PrevNextButton>
      </FooterMobile>
    </Container>
  )
}
