import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useDebouncedEffect from 'use-debounced-effect'
import { trackInternalEvent } from '@rio/tracking'
import { actions } from 'builder/modules/resumeEditor'
import ModalOverlay from 'builder/components/ModalOverlay'
import { ButtonSize } from 'builder/components/Button'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import { i18n } from 'builder/utils/i18n'
import {
  MainContainer,
  TopContainer,
  BottomContainer,
  ValidationSuccessIcon,
  ValidationFailIcon,
  ValidationLoadingIcon,
  CloseIcon,
  GradientContainer,
  CloseIconWrapper,
} from './styles'
import BottomContainerInitialView from './BottomContainer/Initial'
import BottomContainerLoadingView from './BottomContainer/Loading'
import BottomContainerResultView from './BottomContainer/Result'
import DataContainerInitialView from './DataContainer/Views/Initial'
import DataContainerLoadingView from './DataContainer/Views/Loading'
import DataContainerResultView from './DataContainer/Views/Result'
import ManualInput from './ManualInput'
import { DEBOUNCE_DELAY, urlRegex } from './constants'
import { ErrorState } from './ErrorState/ErrorState'

const statusIcons = {
  [FetchStatuses.notAsked]: <></>,
  [FetchStatuses.loading]: <ValidationLoadingIcon />,
  [FetchStatuses.loaded]: <ValidationSuccessIcon />,
  [FetchStatuses.failed]: <ValidationFailIcon />,
}

const getStatusText = (status: FetchStatuses | null): string => {
  switch (status) {
    case FetchStatuses.failed:
      return i18n.t('builder.resume_optimizer.job_posting.link_error')
    default:
      return i18n.t('builder.resume_optimizer.job_posting.example')
  }
}

interface Props {
  resumeId: number
}

export const JobPosting = ({ resumeId }: Props) => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const buttonSize = !isPhone ? ButtonSize.default : ButtonSize.small

  const optimizerSection = useTypedSelector(state => state.resumeEditor.optimizerSection)

  const {
    fetchScoreStatus: fetchTailoredScoreStatus,
    saveJobPostingDataStatus,
    jobPostingLinkForModal: jobPostingLink,
    resumeScoreAPIData: { averageScore, suggestedJobTitle, employerName },
    showManualInputScreen,
  } = optimizerSection

  const initialValidationResult = urlRegex.test(jobPostingLink)

  const [linkValidationStatus, setLinkValidationStatus] = useState<FetchStatuses | null>(
    jobPostingLink ? (initialValidationResult ? FetchStatuses.loaded : FetchStatuses.failed) : null,
  )

  const tailoredDesignation = !employerName
    ? suggestedJobTitle
    : [suggestedJobTitle, employerName].join(' ' + i18n.t('builder.resume_editor.labels.at') + ' ')
  useDebouncedEffect(
    () => {
      if (jobPostingLink) {
        const validationResult = urlRegex.test(jobPostingLink)
        setLinkValidationStatus(validationResult ? FetchStatuses.loaded : FetchStatuses.failed)
      }
    },
    DEBOUNCE_DELAY,
    [jobPostingLink],
  )

  const handleLinkChange = useCallback(
    event => {
      const inputValue = event.target.value.replaceAll('https://', '')
      const linkValue = inputValue ? 'https://' + inputValue : ''
      dispatch(actions.setJobPostingLinkForModal(linkValue))
      setLinkValidationStatus(FetchStatuses.loading)
    },
    [dispatch],
  )

  const isValidLink = linkValidationStatus === FetchStatuses.loaded
  const isInvalidLink = linkValidationStatus === FetchStatuses.failed

  const handleClose = useCallback(() => {
    if (saveJobPostingDataStatus !== FetchStatuses.loading) {
      dispatch(actions.setOpenOptimizerModal(false))
      dispatch(actions.resetResumeOptimizerProcess())
      dispatch(actions.setIsClickedOnAiCVBanner(false))
    }
  }, [dispatch, saveJobPostingDataStatus])

  const handleContinue = useCallback(() => {
    dispatch(
      actions.checkResumeScore({
        jobPostingLink,
        resumeId,
      }),
    )
    trackInternalEvent('click_continue_job_posting_link')
  }, [dispatch, jobPostingLink, resumeId])

  const showErrorModal = fetchTailoredScoreStatus === FetchStatuses.failed
  const showResultScreen = fetchTailoredScoreStatus === FetchStatuses.loaded
  if (showErrorModal) {
    trackInternalEvent('see_resume_optimizer_error_screen', { label: 'no_manual_input' })
  }

  const handleTryAgain = useCallback(() => {
    dispatch(actions.resetResumeOptimizerProcess())
    if (showErrorModal) {
      trackInternalEvent('click_try_again_resume_optimizer_error_screen', {
        label: 'no_manual_input',
      })
      dispatch(actions.setShowManualInputScreen(true))
    } else if (showResultScreen) {
      trackInternalEvent('click_try_again_resume_optimizer_result_screen')
    }
  }, [dispatch, showErrorModal, showResultScreen])

  const handleStartImproving = useCallback(() => {
    dispatch(
      actions.startImprovingResume({
        jobPostingLink,
        resumeId,
      }),
    )
    trackInternalEvent('click_start_improving_resume_optimizer_result_screen')
  }, [dispatch, jobPostingLink, resumeId])

  useEffect(() => {
    if (isValidLink) {
      trackInternalEvent('send_job_posting_link', { status: 'ok', link: jobPostingLink })
    } else if (isInvalidLink) {
      trackInternalEvent('send_job_posting_link', { status: 'failed', link: jobPostingLink })
    }
  }, [isValidLink, isInvalidLink, jobPostingLink])

  useEffect(() => {
    if (fetchTailoredScoreStatus === FetchStatuses.notAsked) {
      trackInternalEvent('see_resume_optimizer_link_screen')
    } else if (fetchTailoredScoreStatus === FetchStatuses.loaded) {
      trackInternalEvent('see_resume_optimizer_result_screen', { score: averageScore })
    }
  }, [fetchTailoredScoreStatus, averageScore])

  const topContainerView = useMemo(() => {
    switch (fetchTailoredScoreStatus) {
      case FetchStatuses.notAsked:
        return <DataContainerInitialView {...{ jobPostingLink, isValidLink }} />
      case FetchStatuses.loading:
        return <DataContainerLoadingView />
      case FetchStatuses.loaded:
        return (
          <DataContainerResultView
            {...{ resumeId, resumeScore: averageScore, tailoredDesignation }}
          />
        )
    }
  }, [
    fetchTailoredScoreStatus,
    jobPostingLink,
    isValidLink,
    resumeId,
    tailoredDesignation,
    averageScore,
  ])

  const bottomContainerView = useMemo(() => {
    switch (fetchTailoredScoreStatus) {
      case FetchStatuses.notAsked:
        return (
          <BottomContainerInitialView
            jobPostingLink={jobPostingLink}
            onChange={handleLinkChange}
            Icon={linkValidationStatus && jobPostingLink ? statusIcons[linkValidationStatus] : null}
            error={isInvalidLink ? getStatusText(linkValidationStatus) : ''}
            status={!isInvalidLink ? getStatusText(linkValidationStatus) : ''}
            onClose={handleClose}
            size={buttonSize}
            isDisabled={!isValidLink}
            onContinue={handleContinue}
          />
        )
      case FetchStatuses.loading:
        return <BottomContainerLoadingView ButtonSize={buttonSize} />

      case FetchStatuses.loaded:
        return (
          <BottomContainerResultView
            resumeScore={averageScore}
            onStartImproving={handleStartImproving}
            onTryAgainClick={handleTryAgain}
            ButtonSize={buttonSize}
            isSavingJobPostingData={saveJobPostingDataStatus === FetchStatuses.loading}
          />
        )
    }
  }, [
    fetchTailoredScoreStatus,
    jobPostingLink,
    handleLinkChange,
    linkValidationStatus,
    isInvalidLink,
    handleClose,
    buttonSize,
    isValidLink,
    handleContinue,
    averageScore,
    handleStartImproving,
    handleTryAgain,
    saveJobPostingDataStatus,
  ])

  return (
    <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300}>
      {!showErrorModal ? (
        showManualInputScreen ? (
          <ManualInput onClose={handleClose} />
        ) : (
          <MainContainer>
            <TopContainer>
              <CloseIconWrapper
                showHoverState={saveJobPostingDataStatus !== FetchStatuses.loading}
                onClick={handleClose}
              >
                <CloseIcon />
              </CloseIconWrapper>
              {topContainerView}
              <GradientContainer />
            </TopContainer>

            <BottomContainer>{bottomContainerView}</BottomContainer>
          </MainContainer>
        )
      ) : (
        <ErrorState onClose={handleClose} onTryAgain={handleTryAgain} ButtonSize={buttonSize} />
      )}
    </ModalOverlay>
  )
}
