import styled, { css } from 'styled-components'
import Mixins from 'builder/styles/mixins'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'
import { Typography, FontWeights } from 'builder/styles/typography'

type CommonProps = {
  $isActive?: boolean
  $isDisabled?: boolean
}

type LabelProps = {
  $isEmpty?: boolean
  $hasError?: boolean
}

export const Container = styled.div``

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`

export const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  &:first-child {
    margin-right: 8px;
  }
`

export const PanelWrapper = styled.div`
  position: relative;
`

export const DatePickerPanel = styled.div<{ $alignRight?: boolean }>`
  ${Mixins.Shadow};
  border-radius: 8px;
  position: absolute;
  top: 8px;
  right: 0;
  background-color: ${Colors.White};
  z-index: 2;
  transition: transform 0.14s ease;
  transform-origin: 50% 0;
  width: 256px;
  box-shadow: 0px 2px 4px -1px rgba(15, 56, 113, 0.08), 0px 8px 20px -4px rgba(15, 56, 113, 0.12),
    0px 0px 1px 0px rgba(15, 56, 113, 0.32);
  ${props =>
    !props.$alignRight &&
    css`
      left: 0;
    `};
`

export const PickersContainer = styled.div``

export const YearPicker = styled.div`
  padding: 13px 24px 5px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Year = styled.div<CommonProps>`
  ${FontWeights.Medium};
  user-select: none;
  padding: 0 16px;
  font-size: 19px;
  line-height: 38px;
  color: ${props => (props.$isActive && !props.$isDisabled ? Colors.White : Colors.Neutral90)};
  border-radius: 19px;
  cursor: pointer;
  background-color: ${props =>
    props.$isActive && !props.$isDisabled ? Colors.Blue50 : 'transparent'};

  &:hover {
    color: ${props => (props.$isActive && !props.$isDisabled ? Colors.White : Colors.Blue50)};
    background-color: ${props => (props.$isActive ? Colors.Blue60 : '#deeefc')};
  }

  ${props =>
    props.$isDisabled &&
    css`
      pointer-events: none;
      color: ${Colors.Neutral50};
      user-select: none;
    `};
`

export const RightArrow = styled.div<CommonProps>`
  user-select: none;
  cursor: pointer;
  color: ${Colors.Neutral50};
  transition: color 0.1s ease;

  &:hover {
    color: ${Colors.Blue50};
  }

  ${props =>
    props.$isDisabled &&
    css`
      pointer-events: none;
      color: ${Colors.Neutral50};
      user-select: none;
    `};

  svg {
    pointer-events: none;
  }
`

export const LeftArrow = styled(RightArrow)`
  transform: rotate(180deg);
`

export const MonthPicker = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0px 12px 12px 12px;
`

export const Month = styled.div<CommonProps>`
  position: relative;
  user-select: none;
  cursor: pointer;
  font-size: 19px;
  color: ${props => (props.$isActive ? Colors.White : Colors.Neutral90)};
  width: 58px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.$isActive &&
    !props.$isDisabled &&
    css`
      &::before {
        content: '';
        width: 58px;
        height: 38px;
        border-radius: 19px;
        background-color: ${Colors.Blue50};
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: -1;
      }
    `};

  ${props =>
    props.$isDisabled &&
    css`
      pointer-events: none;
      color: ${Colors.Neutral50};
      user-select: none;
    `};

  &:hover {
    color: ${props => (props.$isActive ? Colors.White : Colors.Blue50)};
  }

  &:hover::before {
    content: '';
    width: 58px;
    height: 38px;
    border-radius: 19px;
    background-color: ${props => (props.$isActive ? Colors.Blue60 : '#deeefc')};
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
  }
`

export const CurrentlyContainer = styled.div`
  padding: 0px 25px 20px 25px;
`

export const MobileFields = styled.div`
  display: flex;
  margin-bottom: 12px;
`

export const MobileField = styled.label<LabelProps>`
  display: block;
  position: relative;
  flex: 1;
  border-radius: 3px;
  padding: 11px 16px 11px;
  background-color: ${Colors.Neutral10};
  color: ${props => (props.$isEmpty ? Colors.Neutral50 : Colors.Neutral90)};
  border-bottom: ${props => (props.$hasError ? `2px solid ${Colors.Red50}` : 'none')};

  &:first-child {
    margin-right: 8px;
  }
`

export const MobileInput = styled.input.attrs({
  type: 'month',
})`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  outline: none;
  border: none;

  /* Trigger dropdown when input is focused: https://stackoverflow.com/a/51349612 */
  &::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-clear-button {
    display: none;
  }
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  margin-bottom: 6px;
`

export const LabelContent = styled.label<LabelProps>`
  color: ${props => (props.$hasError ? Colors.Red50 : Colors.Neutral50)};
`

export const LabelHint = styled(TooltipQuestion)`
  margin-left: 6px;

  ${Media.Tablet`
    display: none;
  `};
`
