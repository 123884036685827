import { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useNavigate } from 'react-router-dom'
import type { Resume } from '@rio/types'
import ModalOverlay from 'builder/components/ModalOverlay'
import { actions as resumeEditorActions } from 'builder/modules/resumeEditor'
import { FetchStatuses } from 'builder/modules/constants'
import { UserData } from 'builder/modules/user'
import { useUrlValidation } from './hooks'
import PrefillForm from './PrefillForm'
import { Container } from './styles'

interface LinkedinPrefillModalProps {
  resume: Resume
  user: UserData
}

export const LinkedinPrefillModal: FC<LinkedinPrefillModalProps> = ({ resume, user }) => {
  const [url, setUrl] = useState('')
  const [validationStatus, setValidationStatus] = useState(FetchStatuses.notAsked)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isValidUrl, linkedinProfileId] = useUrlValidation(url)

  // Saves url in state and resets validation state
  const handleUrlChange = (url: string) => {
    setValidationStatus(FetchStatuses.notAsked)
    setUrl(url)
  }

  // Set prefill resume id as null to close modal
  const handleClose = useCallback(() => {
    trackInternalEvent('reject_linkedin_resume_prefill')
    navigate(`/resumes/${resume.id}/edit`, { replace: true })
  }, [navigate, resume.id])

  // Handle submit button click
  const handleSubmit = useCallback(() => {
    trackInternalEvent('confirm_linkedin_resume_prefill')

    // Set validation status to failed if url is empty or not a linkedin url
    if (!isValidUrl) {
      trackInternalEvent('enter_invalid_linkedin_profile_link', {
        field_content: url.length ? url : 'empty',
        reason: 'frontend',
      })

      // Show validation error after submit button click
      setValidationStatus(FetchStatuses.failed)
      return
    }

    // Run prefill saga
    dispatch(resumeEditorActions.linkedinPrefill({ resumeId: resume.id, linkedinProfileId }))
  }, [url, dispatch, resume.id, linkedinProfileId, isValidUrl])

  // Track modal open
  useEffect(() => {
    trackInternalEvent('see_linkedin_prefill_popup')
  }, [])

  return (
    <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300}>
      <Container>
        <PrefillForm
          url={url}
          status={validationStatus}
          user={user}
          resume={resume}
          onSubmit={handleSubmit}
          onChange={handleUrlChange}
          onClose={handleClose}
        />
      </Container>
    </ModalOverlay>
  )
}
