import PropTypes from 'prop-types'
import trim from 'lodash/trim'
import { Icon24 } from 'builder/components/Icon'
import { fixHref } from '../utils'

import { FieldIconContainer } from './styles'

const iconComponents = {
  title: Icon24.Person,
  salary: Icon24.Cash,
  link: Icon24.Link,
  location: Icon24.Pin,
  description: Icon24.Description,
}

const FieldIcon = ({ name, value }) => {
  let props = {}
  let href = trim(value)
  let Icon = iconComponents[name]

  if (!Icon) return null

  // Make the link icon clickable
  if (name === 'link' && href) {
    props = {
      as: 'a',
      href: fixHref(href),
      target: '_blank',
      rel: 'noreferrer noopener',
    }
  }

  return (
    <FieldIconContainer {...props}>
      <Icon />
    </FieldIconContainer>
  )
}

FieldIcon.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default FieldIcon
