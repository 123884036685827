import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
// import { selectors as userSelectors } from 'builder/modules/user'

import {
  selectors as renderingSelectors,
  actions as renderingActions,
} from 'builder/modules/rendering'

// const MODAL_SHOWN_STORAGE_KEY = 'rd_not_enough_credits_shown'

export const useResumeDistributionNotEnoughCreditsModal = () => {
  const dispatch = useDispatch()
  const rdNotEnoughCreditsModalVisible = useTypedSelector(
    renderingSelectors.rdNotEnoughCreditsModalVisible,
  )

  const shouldModalOpen = useCallback(() => {
    return rdNotEnoughCreditsModalVisible
  }, [rdNotEnoughCreditsModalVisible])

  const [isOpen, setIsOpen] = useState(shouldModalOpen)

  // function setRDNotEnoughCreditsShown() {
  //   localStorage.setItem(MODAL_SHOWN_STORAGE_KEY, 'true')
  // }

  useEffect(() => {
    if (shouldModalOpen()) {
      trackInternalEvent('show_credits_limit_modal')
      trackInternalEvent('credits_exceeded')
    }
    setIsOpen(shouldModalOpen())
  }, [rdNotEnoughCreditsModalVisible, shouldModalOpen])

  function handleModalClose() {
    dispatch(renderingActions.closeRDNotEnoughCreditsModal())
    // setRDNotEnoughCreditsShown()
  }

  return {
    isOpen,
    handleModalClose,
  }
}
