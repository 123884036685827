import { useMemo } from 'react'
import { Icon20 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { SectionNames, AvailableAISuggestions } from 'builder/modules/resumeEditor'
import useImproveResumePanel from '../../hooks/useImproveResumePanel'
import { Header } from '../../styles'
import TailorResumeBanner from '../TailorResumeBanner/TailorResumeBanner'
import { Container, List, Item, Icon, KeywordsCounter, Section } from './styles'

export const TailoredSuggests = () => {
  const { i18n } = useI18n()
  const {
    showResumeOptimizerBanner,
    isPageScrolled,
    handleAddTailored,
    keywordsCount,
    hasSkillsToTailor,
    showAISuggestedJobTitleButton,
  } = useImproveResumePanel()

  const TailoredActions = useMemo(() => {
    const result: { name: string; onClick: () => void; counter?: JSX.Element }[] = []

    if (hasSkillsToTailor) {
      result.push({
        name: i18n.t(
          'builder.resume_editor.keywords.improve_resume_panel.tailored_suggests.add_tailored_skills_anchor',
        ),
        onClick: () => handleAddTailored(AvailableAISuggestions.skills),
      })
    }

    if (keywordsCount) {
      result.push({
        name: i18n.t(
          'builder.resume_editor.keywords.improve_resume_panel.tailored_suggests.add_profile_summary_keywords_anchor',
        ),
        onClick: () => handleAddTailored(SectionNames.profile),
        counter: <KeywordsCounter>{keywordsCount}</KeywordsCounter>,
      })
    }

    if (showAISuggestedJobTitleButton) {
      result.push({
        name: i18n.t(
          'builder.resume_editor.keywords.improve_resume_panel.tailored_suggests.apply_ai_suggested_job_title',
        ),
        onClick: () => handleAddTailored(AvailableAISuggestions.jobTitle),
      })
    }

    if (keywordsCount) {
      result.push({
        name: i18n.t(
          'builder.resume_editor.keywords.improve_resume_panel.tailored_suggests.add_work_experience_keywords_anchor',
        ),
        onClick: () => handleAddTailored(SectionNames.workExperiences),
        counter: <KeywordsCounter>{keywordsCount}</KeywordsCounter>,
      })
    }

    return result
  }, [handleAddTailored, i18n, keywordsCount, hasSkillsToTailor, showAISuggestedJobTitleButton])

  return showResumeOptimizerBanner ? (
    <TailorResumeBanner />
  ) : TailoredActions.length ? (
    <Section isPageScrolled={isPageScrolled}>
      <Header>
        {i18n.t('builder.resume_editor.keywords.improve_resume_panel.tailored_suggests.title_text')}
      </Header>
      <Container>
        <List>
          {TailoredActions.map((item, index) => {
            return (
              <Item key={index} onClick={item.onClick}>
                <Icon>
                  <Icon20.ArrowTarget2 />
                </Icon>
                {item.name}
                {item.counter}
              </Item>
            )
          })}
        </List>
      </Container>
    </Section>
  ) : null
}
