import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { navigate } from 'builder/modules/navigate'
import { useI18n } from 'builder/hooks/useI18n'
import {
  NotificationContainer,
  SuccessIcon,
  LinkOpenButton,
  MessageContainer,
  SuccessIconContainer,
  NotificationText,
  Open,
} from './styles'

export type Props = {
  show: boolean
  text?: string
}

const JobSavedNotification = ({ show, text }: Props) => {
  const { i18n } = useI18n()
  const handleJobListOpenClick = () => {
    trackInternalEvent('open_job_tracking', { label: 'notification' })
    navigate('/job-tracking')
  }

  useEffect(() => {
    if (show) {
      trackInternalEvent('view_notification', { label: 'notification' })
    }
  }, [show])

  return (
    <NotificationContainer $show={show}>
      <MessageContainer>
        <SuccessIconContainer>
          <SuccessIcon />
        </SuccessIconContainer>
        <NotificationText>
          {text || i18n.t('builder.job_search.notification.job_card_added')}
        </NotificationText>
      </MessageContainer>
      <LinkOpenButton onClick={handleJobListOpenClick}>
        <Open>Open</Open>
      </LinkOpenButton>
    </NotificationContainer>
  )
}

export default JobSavedNotification
