import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'

import { RioImage, Container, Image, Text } from './styles'

import LOADER_ILLUSTRATION from './assets/img/freeTrailLoad.gif'
import RIO_ILLUSTRATION from './assets/img/rio-ilustration.svg'

const PremiumTrialLoader = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const location = useLocation()
  const hostName = window.location.hostname

  const queryParams = new URLSearchParams(location.search)
  const premiumDays = queryParams?.get('premium_days') || 7
  const domainText = hostName.includes('career.io') ? 'Career.io' : 'Resume.io'

  useEffect(() => {
    setTimeout(() => {
      navigate(`/premium-trail-activated${location.search}`)
    }, 3000)
  }, [navigate, location])

  return (
    <Container>
      {hostName.includes('career.io') ? (
        <Image src={LOADER_ILLUSTRATION} alt="Free Trail Loading" data-testid="cio-loader-icon" />
      ) : (
        <RioImage src={RIO_ILLUSTRATION} alt="Free Trail Loading" data-testid="rio-loader-icon" />
      )}
      <Text>
        {i18n.t('builder.premium_trial_loader.text', { days: premiumDays, domain: domainText })}
      </Text>
    </Container>
  )
}

export default PremiumTrialLoader
