import { useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'

import Icon24, { Icon16, Icon20 } from 'builder/components/Icon'
import { EXTENSION_URL } from 'builder/modules/jobTracking'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { useUser } from 'builder/hooks/useUser'
import {
  AutoApplyButton,
  AutoApplyContainer,
  Bubble,
  ButtonContainer,
  Container,
  GetExtensionButton,
  IconContainer,
  SettingIconContainer,
} from './styles'

export const JobTrackerHeader = () => {
  const navigate = useNavigate()

  const { i18n } = useI18n()
  const { features } = useFeaturesConfig()
  const user = useUser()

  const isAutoApplyEnabled = features?.autoApply && !!user?.scopes?.autoApply

  const handleSettingClick = () => {
    navigate(`/auto-apply/personal-info`)
  }

  return (
    <Container>
      <IconContainer>
        <Icon16.Columns />
        {i18n.t(`builder.job_tracking.board_title`)}
      </IconContainer>
      <ButtonContainer>
        {isAutoApplyEnabled && (
          <AutoApplyContainer>
            <AutoApplyButton>
              <Icon24.FeaturedIcon />
              {i18n.t(`builder.job_tracking.auto_apply_button`)}
            </AutoApplyButton>

            <Bubble>
              {typeof user.scopes?.autoApply === 'object'
                ? `${user.scopes?.autoApply?.creditCountUsed ?? 0}/${
                    user.scopes?.autoApply?.creditCount ?? 0
                  }`
                : `∞`}
            </Bubble>
            <Bubble>
              <SettingIconContainer onClick={handleSettingClick}>
                <Icon16.SettingIcon />
              </SettingIconContainer>
            </Bubble>
          </AutoApplyContainer>
        )}
        <GetExtensionButton onClick={() => window.open(EXTENSION_URL, '_blank')}>
          <Icon20.Chrome />
          {i18n.t(`builder.job_tracking.chrome_extension_button`)}
        </GetExtensionButton>
      </ButtonContainer>
    </Container>
  )
}
