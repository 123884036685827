import { Route, Routes } from 'react-router-dom'

import { ResumeDistributionRoutes, StartCompleteScreenRoute } from './constants'
import { LocationContainer } from './components/ResumeDistribution/Location/LocationContainer'
import { JobPreferencesContainer } from './components/ResumeDistribution/JobPreferences/JobPreferencesContainer'
import { ResumeDistributionPreviewView } from './components/ResumeDistribution/ResumeDistributionPreview/ResumeDistributionPreviewView'

export const ResumeDistributionView = () => {
  return (
    <Routes>
      <Route path={`${ResumeDistributionRoutes.LOCATION}`} element={<LocationContainer />} />
      <Route
        path={`${ResumeDistributionRoutes.JOB_PREFERENCES}`}
        element={<JobPreferencesContainer />}
      />
      <Route
        path={`${StartCompleteScreenRoute.RD_COMPLETED}`}
        element={<ResumeDistributionPreviewView />}
      />
    </Routes>
  )
}
