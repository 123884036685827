import { useEffect, useState } from 'react'
import { BriefResume, BriefLetter } from 'builder/modules/panel'
import { apiClient } from 'builder/modules/apiClient'

export const useDocuments = () => {
  const [resumes, setResumes] = useState<BriefResume[]>([])
  const [coverLetters, setCoverLetters] = useState<BriefLetter[]>([])

  useEffect(() => {
    apiClient.get<{ resumes: BriefResume[] }>(`/resumes`).then(res => setResumes(res.data.resumes))
    apiClient
      .get<{ coverLetters: BriefLetter[] }>(`/cover-letters`)
      .then(res => setCoverLetters(res.data.coverLetters))
  }, [])

  return {
    resumes,
    coverLetters,
  }
}
