import { Container } from 'builder/components/Container'
import { useConfig } from 'builder/hooks/useConfig'
import { useI18n } from 'builder/hooks/useI18n'
import { isIFrame } from 'builder/utils/isIFrame'
import PartnersSection from '../PartnersSection'
import DocumentsSection from '../DocumentsSection'
import { PanelContainer } from '../styles'
import { PanelProps } from '../types'

export const DocumentsPanel = (props: PanelProps) => {
  const config = useConfig()
  const { i18n } = useI18n()

  const showOnRIO = config?.features.international

  // We help premium users to complete their resume
  const hidePartnerSection =
    config?.features.superApp ||
    (config?.features.isWidget && isIFrame()) ||
    showOnRIO ||
    config?.features.dashboardV2 ||
    config?.features.dashboardV3

  return (
    <>
      <PanelContainer>
        <Container>
          {/* tr documents, resumes and cover letters list */}
          <DocumentsSection
            title={i18n.t('builder.navigation.items.documents.title')}
            type={props.documentType}
            onTypeChange={props.onDocumentTypeChange}
          />
          {/* Features list or partner banners */}
          {!hidePartnerSection && <PartnersSection />}
        </Container>
      </PanelContainer>
    </>
  )
}
