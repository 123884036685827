import { Fragment, FC, useCallback, useMemo } from 'react'
import type { Resume } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import Icon from 'builder/components/Icon'
import { TextField } from 'builder/components/TextField'
import { FetchStatuses } from 'builder/modules/constants'
import { UserData } from 'builder/modules/user'
import { i18n } from 'builder/utils/i18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useUrlValidation } from './hooks'
import ModalHeader from './Header'
import {
  Actions,
  CancelButton,
  Caption,
  CaptionLink,
  CaptionLinkIcon,
  ClearButton,
  Content,
  Field,
  FieldPlaceholder,
  SubmitButton,
  ValidationLoadingIcon,
  ValidationSuccessIcon,
} from './styles'

const statusIcons = {
  [FetchStatuses.loading]: <ValidationLoadingIcon />,
  [FetchStatuses.loaded]: <ValidationSuccessIcon />,
}

interface LinkedinPrefillFormProps {
  url: string
  status: FetchStatuses
  user: UserData
  resume: Resume
  onChange: (url: string) => void
  onClose: () => void
  onSubmit: () => void
}

const LinkedinPrefillModal: FC<LinkedinPrefillFormProps> = ({
  url = '',
  status,
  user,
  onSubmit,
  onChange,
  onClose,
}) => {
  const { isPhone } = useMediaQueries()
  const [isValidUrl] = useUrlValidation(url)
  const linkedinProfile = user.socialProfiles.find(profile => profile.provider === 'linkedin')
  const linkedinAvatarUrl = linkedinProfile?.pictureUrl || null
  const EXAMPLE_URL = isPhone
    ? 'https://www.linkedin.com/mwlite/in/john-doe-123a/'
    : 'https://www.linkedin.com/in/john-doe-123a/'
  const LINKEDIN_URL = isPhone ? 'https://www.linkedin.com/' : 'https://www.linkedin.com/in/'

  const errorMessage = useMemo(() => {
    const isFailed = status === FetchStatuses.failed

    // Empty linkedin link
    if (isFailed && !url)
      return i18n.t('builder.prefill.linkedin.errors.empty_v2', {
        example: `${EXAMPLE_URL}`,
      })

    // Invalid linkedin link
    if (isFailed && !isValidUrl)
      return i18n.t('builder.prefill.linkedin.errors.invalid_link_v2', {
        example: `${EXAMPLE_URL}`,
      })

    return ''
  }, [status, url, isValidUrl, EXAMPLE_URL])

  const isSubmitButtonDisabled = useMemo(
    () => status === FetchStatuses.loading || status === FetchStatuses.failed,
    [status],
  )

  const handleUrlChange = useCallback(
    event => {
      onChange(event.target.value)
    },
    [onChange],
  )

  return (
    <Fragment>
      <ModalHeader user={user} photoUrl={linkedinAvatarUrl} />
      <Content>
        <Caption>
          {i18n.t('builder.prefill.linkedin.caption')}
          <CaptionLink
            href={LINKEDIN_URL}
            onClick={() => trackInternalEvent('click_linkedin_prefill_profile_link')}
            target="_blank"
            rel="noreferrer noopener"
          >
            {i18n.t('builder.prefill.linkedin.caption_link')}
            <CaptionLinkIcon>
              <Icon.ExternalLink />
            </CaptionLinkIcon>
          </CaptionLink>
        </Caption>

        <Field>
          <TextField
            value={url}
            error={errorMessage}
            placeholder={i18n.t('builder.prefill.linkedin.placeholder')}
            onChange={handleUrlChange}
            // @ts-expect-error TODO fix TS error
            inputIcon={statusIcons[status]}
            inputIconPosition="right"
          />

          {url && status === FetchStatuses.failed && (
            <ClearButton onClick={() => onChange('')}>
              <Icon.Error />
            </ClearButton>
          )}
          {!errorMessage && (
            <FieldPlaceholder>
              {i18n.t('builder.prefill.linkedin.example', { url: EXAMPLE_URL })}
            </FieldPlaceholder>
          )}
        </Field>

        <Actions>
          <SubmitButton isDisabled={isSubmitButtonDisabled} onClick={onSubmit}>
            {i18n.t(`builder.prefill.linkedin.submit_action_v2${isPhone ? '_short' : ''}`)}
          </SubmitButton>

          <CancelButton theme="ghost" onClick={onClose}>
            {i18n.t('builder.prefill.linkedin.decline_action')}
          </CancelButton>
        </Actions>
      </Content>
    </Fragment>
  )
}

export default LinkedinPrefillModal
