import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { HelperModal } from 'builder/components/HelperModal'
import ModalOverlay from 'builder/components/ModalOverlay'
import {
  selectors,
  actions,
  EWidgetsName,
  IHelperWidget,
  IWidgetsInfo,
  IWidgetsStatus,
  ResumeUpdateStatus,
} from 'builder/modules/panel'
import { selectors as eventSelectors } from 'builder/modules/events'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { DropDownContentProps } from '../HelperModal/constants'
import { HelpModalLabelPayload } from '../FloatingCIOHelperButton/types'
import * as Styled from './styles'
import { JobSearchModalMetadata, PremierVirtualModalMetadata } from './constants'

interface IProps {
  currentWidgetName: EWidgetsName
  isHelperModalOpen: boolean
  setIsHelperModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  totalSteps: number
  completedSteps: number
  setModalAsCentre: React.Dispatch<React.SetStateAction<boolean>>
  modalAsCentre: boolean
  labelPayload: HelpModalLabelPayload
  onStepActionClick: (step_name: string) => void
  handleClose: () => void
  TrackingEvents: (option: IWidgetsInfo) => void
  helperLabel: string
}

export const JobSearchHelper = ({
  currentWidgetName,
  isHelperModalOpen,
  totalSteps,
  completedSteps,
  modalAsCentre,
  labelPayload,
  onStepActionClick,
  handleClose,
  TrackingEvents,
  helperLabel,
}: IProps) => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()

  const [content, setContent] = useState<DropDownContentProps[]>([])
  const helperWidgetsData = useTypedSelector(selectors.helperWidgets)
  const helperAnimation = useTypedSelector(eventSelectors.helperAnimation)
  const tunerResumeId = useTypedSelector(selectors.tunerResumeId)
  const resumeUpdateStatus = useTypedSelector(selectors.resumeUpdateStatus)
  const isPremierVirtual = currentWidgetName === EWidgetsName.PREMIER_VIRTUAL

  useEffect(() => {
    if (resumeUpdateStatus === ResumeUpdateStatus.Started) return

    dispatch(actions.fetchDocuments())
    const widgetInfo: IHelperWidget | undefined = helperWidgetsData?.find(
      (value: IHelperWidget) => value.widgetCollectionName === currentWidgetName,
    )
    const metadata = isPremierVirtual ? PremierVirtualModalMetadata : JobSearchModalMetadata
    const modalSteps = metadata
      ?.map((option: DropDownContentProps) => {
        if (isPremierVirtual) {
          const isBuildResume = option.title === 'Build a winning resume'
          const isOptimizeResume = option.title === 'Optimize your resume'
          if ((tunerResumeId && isBuildResume) || (!tunerResumeId && isOptimizeResume)) {
            return null // Skip this option
          }
        }
        const stepCurrent: IWidgetsInfo | undefined = widgetInfo?.widgetsList.find(
          (step: IWidgetsInfo) => option.name === step.name,
        )
        return {
          ...option,
          status: stepCurrent?.status,
          id: stepCurrent?.id,
        }
      })
      .filter(Boolean) as DropDownContentProps[]
    setContent(modalSteps || [])
  }, [
    currentWidgetName,
    dispatch,
    helperWidgetsData,
    isPremierVirtual,
    resumeUpdateStatus,
    tunerResumeId,
  ])

  const handleStepCompleteClick = (status: IWidgetsStatus, stepId?: number) => {
    const updateWidgetInfo: IHelperWidget | undefined = helperWidgetsData?.filter(
      (value: IHelperWidget) => value.widgetCollectionName === currentWidgetName,
    )?.[0]

    if (!updateWidgetInfo || !stepId) {
      return
    }

    // Update In Database
    dispatch(actions.updateHelperWidgets({ stepId, status }))

    // Update Store Data
    const updatedWidgetsList: IWidgetsInfo[] = updateWidgetInfo?.widgetsList?.map(
      (option: IWidgetsInfo) => {
        if (option.id === stepId) {
          TrackingEvents(option)
          return {
            ...option,
            status,
          }
        }
        return option
      },
    )
    dispatch(actions.setHelperWidgets({ ...updateWidgetInfo, widgetsList: updatedWidgetsList }))
  }

  return (
    <>
      <Styled.ModalContainer
        modalAsCentre={modalAsCentre}
        isOverlay={isHelperModalOpen}
        isHelper={isHelperModalOpen}
        helperAnimation={helperAnimation}
      >
        {isHelperModalOpen && (isPhone || modalAsCentre) && (
          <ModalOverlay onClick={handleClose}> </ModalOverlay>
        )}
        <HelperModal
          dropDownContent={content}
          heading={labelPayload?.modalTitle}
          subHeading={labelPayload?.modalDescription}
          action={onStepActionClick}
          helperLabel={helperLabel}
          animationHeading={labelPayload?.allStepCompletionStateTitle}
          animationSubHeading={labelPayload?.allStepCompletionStateSubTitle}
          {...{ handleStepCompleteClick, handleClose, totalSteps, completedSteps }}
        />
      </Styled.ModalContainer>
    </>
  )
}
