import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import TargetImage from '../../../../../../app/assets/images/resume_optimizer/target_image.png'
import {
  ArrowIcon,
  Banner,
  BannerContent,
  BannerImage,
  BannerTitle,
  ContentWrapper,
} from './styles'

const TargetBanner = () => {
  const { i18n } = useI18n()
  const animateContainer = useTypedSelector(
    state => state.resumeEditor.animateResumeOptimizerBanner,
  )
  return (
    <Banner animate={animateContainer}>
      <BannerImage src={TargetImage} alt="Target Image" />
      <ContentWrapper>
        <BannerTitle>{i18n.t('builder.resume_optimizer.banner.title')}</BannerTitle>
        <BannerContent>{i18n.t('builder.resume_optimizer.banner.content')}</BannerContent>
      </ContentWrapper>
      <ArrowIcon />
    </Banner>
  )
}

export default TargetBanner
