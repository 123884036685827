import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Tabs, { Tab } from 'builder/components/Tabs'
import { Icon20 } from 'builder/components/Icon'
import { useInterviews } from 'builder/views/Interview/hooks/useInterviews'
import { actions, selectors } from 'builder/modules/interview/interviewModule'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelector } from 'builder/modules/user'
import { ActiveInterviewTab, Interview } from 'builder/modules/interview/types'
import { PremiumDialog } from '../../PremiumDialog/PremiumDialog'
import { InitialRequest } from '../DashboardView'
import { Card } from './Card/Card'
import { BlankCards } from './BlankCards/BlankCards'
import {
  ButtonWrapper,
  CardSkeleton,
  CardsContainer,
  Container,
  CustomMobileButton,
  CustomTab,
  CustomTabletDesktopButton,
  Top,
} from './style'

export const TRANSLATION_KEY = 'builder.interview_prep_view.interview_dashboard'

type Tab = { labelTab: string; id: ActiveInterviewTab }

interface Props {
  setInitialRequest: React.Dispatch<React.SetStateAction<InitialRequest | undefined>>
}

export const ActiveInterviews = ({ setInitialRequest }: Props) => {
  const { data: interviews, isLoading } = useInterviews(undefined, setInitialRequest, true)
  const navigate = useNavigate()

  const [activeInterviews, setActiveInterviews] = useState<Interview[]>([])
  useEffect(() => {
    interviews && setActiveInterviews(interviews)
  }, [interviews])

  const inProgress = activeInterviews?.filter(interview => interview.status !== 'completed')
  const finished = activeInterviews?.filter(interview => interview.status === 'completed')
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const selectedTabId = useSelector(selectors.activeInterviewTab)

  const [showPremiumDialog, setShowPremiumDialog] = useState(false)
  const isPremiumUser = useTypedSelector(userSelector.premiumAccess)

  const tabs: Tab[] = [
    {
      labelTab: i18n.t(`${TRANSLATION_KEY}.in_progress`),
      id: 'in_progress',
    },

    {
      labelTab: i18n.t(`${TRANSLATION_KEY}.complete`),
      id: 'complete',
    },
  ]
  const onCreateNewInterview =
    (parameter: 'interview_dash_custom' | 'interview_dash_history') => () => {
      trackInternalEvent('pick_interview', {
        label: 'interview_prep',
        parameter,
      })
      if (isPremiumUser) {
        navigate('/interview-preparation/custom-interview')
      } else {
        setShowPremiumDialog(true)
      }
    }

  const onCardDispose = (interview: Interview) => {
    const filtered = activeInterviews?.filter(i => i.id !== interview.id)
    setActiveInterviews(filtered)
  }

  const renderContentTab = () => {
    switch (selectedTabId) {
      case 'in_progress':
        if (inProgress?.length === 0) {
          return (
            <BlankCards
              type="incomplete"
              onClick={onCreateNewInterview('interview_dash_history')}
            />
          )
        }
        return (
          <>
            {inProgress?.map(interview => (
              <Card
                key={interview.id}
                interview={interview}
                onDispose={() => onCardDispose(interview)}
              />
            ))}
          </>
        )
      case 'complete':
        if (finished?.length === 0) {
          return (
            <BlankCards type="complete" onClick={onCreateNewInterview('interview_dash_history')} />
          )
        }
        return finished?.map(interview => (
          <Card
            key={interview.id}
            interview={interview}
            onDispose={() => onCardDispose(interview)}
          />
        ))

      default:
        return null
    }
  }

  return (
    <Container>
      <Top>
        <Tabs>
          {tabs.map(tab => (
            <CustomTab
              isActive={tab.id === selectedTabId}
              key={tab.id}
              onClick={() => dispatch(actions.setActiveInterviewTab(tab.id))}
            >
              {tab.labelTab}
            </CustomTab>
          ))}
        </Tabs>
        <CustomTabletDesktopButton onClick={onCreateNewInterview('interview_dash_custom')}>
          <Icon20.Add />
          {i18n.t(`${TRANSLATION_KEY}.create_new`)}
        </CustomTabletDesktopButton>
      </Top>
      <ButtonWrapper>
        <CustomMobileButton onClick={onCreateNewInterview('interview_dash_custom')}>
          {i18n.t(`${TRANSLATION_KEY}.new_interview`)}
        </CustomMobileButton>
      </ButtonWrapper>
      <CardsContainer>
        {isLoading && [1, 2, 3, 4].map(i => <CardSkeleton key={i} />)}
        {!isLoading && renderContentTab()}
      </CardsContainer>
      {showPremiumDialog && (
        <PremiumDialog
          trackingParameter="interview_dash_custom"
          onClose={() => setShowPremiumDialog(false)}
        />
      )}
    </Container>
  )
}
