import { useI18n } from 'builder/hooks/useI18n'

import { LoadingScreen } from '../../LoadingScreen/LoadingScreen'

export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player'
interface EndLoadingProps {
  stopButtonClicked: boolean
}
export const EndLoading = ({ stopButtonClicked }: EndLoadingProps) => {
  const { i18n } = useI18n()

  return (
    <div>
      <LoadingScreen
        content={{
          title: i18n.t(
            `${TRANSLATION_PREFIX}.${stopButtonClicked ? 'stopped_interview' : 'congratulations'}`,
          ),
          content: i18n.t(
            `${TRANSLATION_PREFIX}.${
              stopButtonClicked ? 'stopped_interview_subtitle' : 'congratulations_subtitle'
            }`,
          ),
          customContentWidth: 500,
        }}
      />
    </div>
  )
}
