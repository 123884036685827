import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { PerformanceLoggerWithMainDsn as PerformanceLogger } from 'builder/services/PerformanceLogger'
import { selectors } from 'builder/modules/interview/interviewModule'
import { useComponentWillMount } from 'builder/hooks/useComponentWillMount'
import { QuestionLibrarySection } from '../QuestionsLibraryView/QuestionLibrarySection/QuestionLibrarySection'
import { invalidateCacheForInterviews } from '../../utils'
import { ActiveInterviews } from './ActiveInterviews/ActiveInterviews'
import { CompanyInterview } from './CompanyInterview/CompanyInterview'
import { Wrapper } from './styles'
import { MyInterviews } from './MyInterview/MyInterviews'

export interface InitialRequest {
  dailyRequest: boolean
  interviews: boolean
  questionsLibrary: boolean
  companyInterview: boolean
}
export const InterviewDashboardView = () => {
  const jobTitleId = useSelector(selectors.jobTitleId)

  const navigate = useNavigate()
  const [initialRequest, setInitialRequest] = useState<InitialRequest>()
  const performanceFinished = useRef(false)
  useComponentWillMount(() => {
    async function componentWillMount() {
      // make sure the page has fresh data
      await invalidateCacheForInterviews()

      PerformanceLogger.listen({
        name: 'interview-dashboard-page',
      })
      setInitialRequest({
        dailyRequest: false,
        interviews: false,
        questionsLibrary: false,
        companyInterview: false,
      })
    }
    componentWillMount()
  })

  useEffect(() => {
    const countRequest = Object.values(initialRequest || {})
    if (
      countRequest.length === 4 &&
      countRequest.find(r => r === false) === undefined &&
      performanceFinished.current === false
    ) {
      setInitialRequest(undefined)
      performanceFinished.current = true
      PerformanceLogger.finish()
    }
  }, [initialRequest])

  useEffect(() => {
    trackInternalEvent('visit_interview_dash', {
      label: 'interview_prep',
    })

    return () => {
      if (performanceFinished.current === false) {
        PerformanceLogger.skipCurrent()
      }
    }
  }, [])

  useEffect(() => {
    if (!jobTitleId) {
      return navigate('/interview-preparation/start')
    }
  }, [jobTitleId, navigate])

  return (
    <Wrapper>
      <MyInterviews setInitialRequest={setInitialRequest} />
      <ActiveInterviews setInitialRequest={setInitialRequest} />
      <QuestionLibrarySection setInitialRequest={setInitialRequest} />
      <CompanyInterview setInitialRequest={setInitialRequest} />
    </Wrapper>
  )
}
