import axios from 'axios'
import trim from 'lodash/trim'
import { GenerateInterviewPayload, GenerateInterviewResponse } from './types'

// fixes an URL if it doesn't have a protocol
export const fixHref = (href: string) => {
  return /https?:\/\//i.test(href) ? trim(href) : 'http://' + trim(href)
}

export const generateMockInterview = async (
  token: string,
  { title, description }: GenerateInterviewPayload,
) => {
  const base = `${process.env.INTERVIEW_SCHOOL_APP_HOST}/api/interview/generate`

  const headers = {
    Authorization: `CBearer ${token}`,
    'Content-Type': 'multipart/form-data',
  }

  const formData = new FormData()
  formData.append('title', title)
  formData.append('query', description)

  const { data } = await axios.post<GenerateInterviewResponse>(base, formData, { headers })

  if (!data?.success) {
    throw new Error(data.message)
  }

  return data.data
}
