import { useState } from 'react'
import useDebouncedEffect from 'use-debounced-effect'
import { FetchStatuses } from 'builder/modules/constants'

export const validationExpression = /linkedin\.com\/(in|pub|profile|mwlite\/in)\/([0-9\w-/%]+)\/?/

export const useUrlValidation = (url: string): [boolean, string] => {
  const [status, setStatus] = useState<FetchStatuses>(FetchStatuses.notAsked)
  const [profileId, setProfileId] = useState<string>('')

  useDebouncedEffect(
    () => {
      const urlMatch = url.match(validationExpression)

      if (urlMatch === null) return setStatus(FetchStatuses.failed)

      setProfileId(urlMatch[2])
      setStatus(FetchStatuses.loaded)
    },
    250,
    [url],
  )

  return [status === FetchStatuses.loaded, profileId]
}
