import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { selectors, actions } from 'builder/modules/autoApply/autoApplyModule'
import { ResumeDistributionForm } from 'builder/modules/autoApply/types'
import { AutoSuggestItem } from 'builder/modules/resumeDistribution'
import { Location } from 'builder/views/ResumeDistributionView/components/Forms/Location/Location'

import * as SimpleForm from 'builder/components/SimpleForm'
import { Container, FillWarning, LayoutContainer, Title, TitleWrapper } from '../../styles'
import {
  ResumeDistributionRoutes,
  StartCompleteScreenRoute,
  TotalRdPageCount,
} from '../../../constants'

import { FormContainer } from '../styles'

const TRANSLATION = 'builder.auto_apply.resume_distribution.location'

export const LocationContainer = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const formState = useSelector(selectors.resumeDistributionForm)
  const form = useForm<ResumeDistributionForm['location']>({
    mode: 'onChange',
    defaultValues: formState.location,
  })

  const setFormValue = (field: string, value?: AutoSuggestItem) => {
    dispatch(
      actions.updateResumeDistributionForm({
        ...formState,
        location: { ...formState.location, [field]: value },
      }),
    )
  }

  const onNextStep = (data: ResumeDistributionForm['location']) => {
    dispatch(actions.updateResumeDistributionForm(data))
    navigate(`/auto-apply/rd/${ResumeDistributionRoutes.JOB_PREFERENCES}`)
  }

  const onBackClick = () => {
    form.clearErrors()
    navigate(`/auto-apply/${StartCompleteScreenRoute.COMPLETED}`)
  }

  useEffect(() => {
    trackInternalEvent('rd_current_location', {
      label: 'total_job_search_solution',
    })
  }, [])

  return (
    <LayoutContainer>
      <Container>
        <TitleWrapper>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <FillWarning>{i18n.t(`${TRANSLATION}.sub_title`)}</FillWarning>
        </TitleWrapper>
        <FormContainer>
          <SimpleForm.Form
            submitButtonText={i18n.t(`${TRANSLATION}.next_button`)}
            onBackClick={onBackClick}
            onSubmit={form.handleSubmit(onNextStep, e => console.log(e))}
            processIndicator={{
              totalStep: TotalRdPageCount,
              currentStep: 0,
            }}
            autoComplete="off"
          >
            <Location form={form} setStateFromField={setFormValue} />
          </SimpleForm.Form>
        </FormContainer>
      </Container>
    </LayoutContainer>
  )
}
