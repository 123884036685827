import {
  ContentState,
  DraftBlockType,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js'
import { BlockTypes } from './listPlugin'

/**
 * Determine whether the start of the paragraph indicates that it is part of an ordered list
 */
export const shouldEnterOl = (text: string, olRegex: RegExp) => {
  return olRegex.test(text)
}

/**
 * Determine whether the start of the paragraph indicates that it is part of an unordered list
 */
export const shouldEnterUl = (text: string, ulChars: string[]) => {
  return ulChars.includes(text[0])
}

/**
 * Start a list of the desired type and remove the characters which were typed for starting the list
 */
export const startList = (
  editorState: EditorState,
  content: ContentState,
  blockKey: string,
  firstSpacePos: number,
  blockType: DraftBlockType,
) => {
  /** This removal handles removal of space typed after the list character
   * e.g. when you type '1. ' */
  let selectionToRemove = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: 0,
    focusKey: blockKey,
    focusOffset: firstSpacePos + 1,
  })
  let updatedContent = Modifier.removeRange(content, selectionToRemove, 'backward')

  /** This removal handles removal of the dot typed after the space,
   * when space is entered between the existing characters. Only needed for ordered list
   * e.g. when you type space in between '1.abc' */
  if (blockType === BlockTypes.OL) {
    selectionToRemove = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: firstSpacePos + 2,
    })
    updatedContent = Modifier.removeRange(content, selectionToRemove, 'backward')
  }
  let updatedState = EditorState.push(editorState, updatedContent, 'change-block-type')
  updatedState = EditorState.forceSelection(updatedState, updatedContent.getSelectionAfter())
  updatedState = RichUtils.toggleBlockType(updatedState, blockType)
  return updatedState
}
