import styled from 'styled-components'
import { animated } from '@react-spring/web'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Shadows from 'builder/styles/shadows'

interface PopupContentProps {
  $contentHeight?: string
}

export const Pointer = styled.img`
  fill: ${Colors.White};

  [data-placement^='top'] & {
  }

  [data-placement^='bottom'] & {
    transform: rotate(180deg);
  }

  [data-placement^='left'] & {
    transform: rotate(90deg);
  }

  [data-placement^='right'] & {
    transform: rotate(-90deg);
  }
`

export const PointerContainer = styled.div`
  display: flex;

  [data-placement^='top'] & {
    bottom: -8px;
  }

  [data-placement^='bottom'] & {
    top: -8px;
  }

  [data-placement^='left'] & {
    right: -8px;
  }

  [data-placement^='right'] & {
    left: -8px;
  }
`

export const PopupContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: ${Colors.White};
  ${Shadows.light.low};
  ${Shadows.light.high};
  scroll-margin-top: 130px;
  position: relative;
  width: 280px;

  ${Media.Phone`
    width: 335px;
  `};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
  }
`

export const PopupContent = styled.div<PopupContentProps>`
  flex-grow: 1;
  position: relative;
  align-items: center;
  justify-content: center;
  max-height: ${props => props.$contentHeight || '240px'};
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  padding: 16px 16px 0 16px;
`

export const JobPosition = styled.span`
  align-items: center;
  margin-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 192px;
  height: 20px;
  color: ${Colors.Neutral90};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PopupCloseButton = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  margin-left: 13px;
  color: ${Colors.Neutral70};
  cursor: pointer;
  transition: color 0.1s;
  float: right;

  &:hover {
    color: ${Colors.Blue50};
  }

  ${Media.Phone`
    margin-left: 76px;
  `}
`

export const SuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 190px;
  overflow-y: scroll;
  padding: 0 4px 16px 16px;

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Neutral20};
    border: 4px solid ${Colors.White};
    border-radius: 6px;

    &:hover {
      background-color: ${Colors.Neutral30};
    }
  }

  ${Media.Phone`
    overflow-y: scroll;
  `}
`
