import { baseClient } from 'builder/modules/apiClient'
import { SearchSuggestionType } from '../AutoSuggestField/types'

interface Response {
  suggested_locations: SuggestedLocation[]
}

export interface SuggestedLocation {
  id: number
  name: string
  formatted_name: string
}

export const createNewLocationSuggestionsApiFetcher = async (location: string) => {
  try {
    const response = await baseClient.get<Response>(`/jobs/v1/search/suggest-locations`, {
      params: { location, limit: 5 },
    })

    return response.data
      ? response.data.suggested_locations.map<SearchSuggestionType>(location => ({
          value: String(location.id),
          text: location.name,
          formatted: location.formatted_name,
          locationType: 'location_id',
        }))
      : []
  } catch (error) {
    return []
  }
}
