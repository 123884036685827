import { memo } from 'react'
import { i18n as I18n } from 'builder/utils/i18n'
import { ButtonSize } from 'builder/components/Button'
import starWhite from 'builder/components/GenerateAICVBanner/StarWhite.svg'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FetchStatuses } from 'builder/modules/constants'
import { GenerateButton, Star } from 'builder/components/GenerateAICVBanner/styles'
import { TryAnotherLink, Revert } from '../../AICoverLetter/styles'
import { Title, Content, ButtonWrapper } from './styles'

interface BottomContainerprops {
  ButtonSize: ButtonSize
  onTryAgainClick: () => void
  onGenerate: () => void
}

const AICVBottomResultView = ({ onTryAgainClick, onGenerate }: BottomContainerprops) => {
  const AICoverLetter = useTypedSelector(state => state.resumeEditor.AICoverLetter)
  const { generateAICoverLetterStatus } = AICoverLetter
  const isGenerateCV = generateAICoverLetterStatus === FetchStatuses.loading
  return (
    <>
      <Title>{I18n.t('builder.ai_cover_letter.result_screen.title')}</Title>
      <Content>{I18n.t('builder.ai_cover_letter.result_screen.content')}</Content>
      <ButtonWrapper>
        <TryAnotherLink
          isDisabled={isGenerateCV}
          onClick={!isGenerateCV ? onTryAgainClick : () => {}}
        >
          <Revert />
          {I18n.t('builder.ai_cover_letter.try_another_link')}
        </TryAnotherLink>
        <GenerateButton onClick={onGenerate} disabled={isGenerateCV} style={{ width: 'auto' }}>
          <Star showAnimation={isGenerateCV} src={starWhite} alt="Star Image" />
          {I18n.t(
            isGenerateCV
              ? 'builder.ai_cover_letter.generating'
              : 'builder.ai_cover_letter.generate',
          )}
        </GenerateButton>
      </ButtonWrapper>
    </>
  )
}

export default memo(AICVBottomResultView)
