import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Button from 'builder/components/Button'
import { Icon20 } from 'builder/components/Icon'

export const ImproveResumeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

export const StyledButton = styled(Button)`
  padding: 2px 6px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  border: none;
  color: ${Colors.Blue50};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  background-color: ${Colors.Blue10};

  &:hover {
    background-color: ${Colors.Blue10};
    color: ${Colors.Blue60};
  }
`

export const ChevronDownIcon = styled(Icon20.ChevronDown)<{ isActive: boolean }>`
  fill: ${Colors.Blue50};
  color: transparent;
  pointer-events: none;

  ${StyledButton}:hover & {
    fill: ${Colors.Blue60};
  }

  ${props =>
    props.isActive &&
    css`
      transform: rotate(180deg);
    `}
`

export const CompletenessIcon = styled.div`
  pointer-events: none;
`

export const HintIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`

export const HintIcon = styled(Icon20.Hint)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: ${Colors.Blue50};
  background-color: transparent;
  border: 1px solid ${Colors.Blue50};
  border-radius: 20px;

  &:hover {
    color: white;
    background-color: ${Colors.Blue50};
  }
`
