import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { apiClient, ApiResponse } from 'builder/modules/apiClient'
import { setLocale as setDatesLocale } from 'builder/utils/formatDate'
import { getPrefillStatusSaga } from 'builder/modules/resumeEditor/resumeEditorSagas'
import { actions as initActions } from './initModule'
import { ConfigTypes } from './types'

/**
 * Takes a specific config from data-attribute or fetches it from API
 * Supported configs "basic" and "app".
 * "basic" stands for the minimal settings required to make app work.
 * "app" config is the one that required to run the authorized area.
 */
function* fetchConfigSaga(action: ReturnType<typeof initActions.fetchConfig>) {
  const { scope } = action.payload

  const { data }: ApiResponse<ConfigTypes> = yield call(apiClient.get, '/config', {
    params: { scope },
  })

  // set proper locale in date-fns
  if ('locale' in data) {
    setDatesLocale(data.locale)
  }

  // if the user came from extension -> redirect him to extension auth
  if ('links' in data && data.links.authBrowserExtensionRedirect) {
    window.location.href = data.links.authBrowserExtensionRedirect
  }

  yield put(initActions.updateConfig({ ...data, scope }))
}

/** Watches network connection state */
function* startInternetConnectionWatcherSaga() {
  const internetOfflineChannel = eventChannel(emitter => {
    const changeInternetConnectionStatus = (status: { isOnline: boolean }) => {
      emitter(status)
    }
    const offlineCallback = () => changeInternetConnectionStatus({ isOnline: false })
    const onlineCallback = () => changeInternetConnectionStatus({ isOnline: true })

    window.addEventListener('offline', offlineCallback)
    window.addEventListener('online', onlineCallback)

    return () => {
      window.removeEventListener('offline', offlineCallback)
      window.removeEventListener('online', onlineCallback)
    }
  })

  while (true) {
    const { isOnline } = yield take(internetOfflineChannel)
    yield put(initActions.setInternetConnectionStatus({ isOnline }))
  }
}

/** Initialize the application */
export const sagas = function* saga() {
  yield fork(startInternetConnectionWatcherSaga)

  yield fork(getPrefillStatusSaga)

  yield all([takeLatest(initActions.fetchConfig, fetchConfigSaga)])
}
