import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { useEffect, useState } from 'react'
import * as SimpleForm from 'builder/components/SimpleForm'
import { useI18n } from 'builder/hooks/useI18n'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import Foldable from 'builder/components/Foldable'
import CardTitle from 'builder/components/Card/CardTitle'
import { formatDateRange } from 'builder/utils/formatDateRange'
import { TextField } from 'builder/components/TextField'
import DateRangePicker from 'builder/components/DateRangePicker'
import { AddLink, Course, Courses, CourseContent, CourseDates } from './styles'

const TRANSLATION = 'builder.auto_apply.form.licenses_and_certifications'

type Props = {
  form: UseFormReturn<Pick<AutoApplyForm, 'coursesAttributes'>>
}

export const LicenseAndCertification = (props: Props) => {
  const { form } = props

  const {
    control,
    setValue,
    watch,
    register,
    formState: { errors, submitCount },
    trigger,
  } = form
  const { i18n } = useI18n()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'coursesAttributes',
  })
  const [showIndex, setShowIndex] = useState<number | undefined>(0)

  useEffect(() => {
    const checkForm = async () => {
      const valid = await trigger('coursesAttributes', { shouldFocus: true })
      if (!valid) {
        const index = errors.coursesAttributes?.findIndex(a => a)
        setShowIndex(index)
      }
    }
    checkForm()
  }, [errors.coursesAttributes, submitCount, trigger])

  const onCardDelete = (index: number) => () => {
    if (fields.length > 1) remove(index)
  }

  const onDateRangePickerChange =
    (index: number) =>
    ({ dateUntil, dateFrom }: { dateUntil?: string | null; dateFrom?: string | null }) => {
      if (dateFrom !== undefined) {
        setValue(`coursesAttributes.${index}.dateFrom`, dateFrom || '')
      }
      if (dateUntil !== undefined) {
        setValue(`coursesAttributes.${index}.dateUntil`, dateUntil || '')
      }
    }

  const onAddLink = async () => {
    const valid = await trigger('coursesAttributes', { shouldFocus: true })
    if (valid) {
      append({ course: '', institution: '', dateFrom: '', dateUntil: '' })
      setShowIndex(fields.length)
    } else {
      const index = errors.coursesAttributes?.findIndex(a => a)
      setShowIndex(index)
    }
  }

  return (
    <>
      <SimpleForm.Row position="relative">
        <Courses>
          {fields.map((item, index) => (
            <Course key={`${item.course} + ${index}`} data-testid={item.id}>
              <CardTitle
                title={
                  watch(`coursesAttributes.${index}.course`) || i18n.t(`${TRANSLATION}.no_course`)
                }
                subtitle={formatDateRange({
                  dateFrom: watch(`coursesAttributes.${index}.dateFrom`),
                  dateUntil: watch(`coursesAttributes.${index}.dateUntil`),
                })}
                isOpen={showIndex === index}
                isDragging={false}
                onToggle={() => {
                  setShowIndex(showIndex === index ? undefined : index)
                }}
                onDelete={onCardDelete(index)}
                disableMove={true}
              />
              <Foldable isOpen={showIndex === index} unmountOnExit={false}>
                <CourseContent>
                  <TextField
                    {...register(`coursesAttributes.${index}.course`)}
                    label={i18n.t(`${TRANSLATION}.course.label`)}
                    error={errors.coursesAttributes?.[index]?.course?.message}
                  />
                  <TextField
                    {...register(`coursesAttributes.${index}.institution`)}
                    label={i18n.t(`${TRANSLATION}.institution.label`)}
                  />
                  <CourseDates>
                    <DateRangePicker
                      value={{
                        dateFrom: watch(`coursesAttributes.${index}.dateFrom`),
                        dateUntil: watch(`coursesAttributes.${index}.dateUntil`),
                      }}
                      onChange={onDateRangePickerChange(index)}
                      isUntilToday={false}
                    />
                  </CourseDates>
                </CourseContent>
              </Foldable>
            </Course>
          ))}
        </Courses>
      </SimpleForm.Row>
      <AddLink onClick={onAddLink}>{i18n.t(`${TRANSLATION}.add_license`)}</AddLink>
    </>
  )
}
