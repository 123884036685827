import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

export const ResumeDistributionRoute = () => {
  const navigate = useNavigate()
  const features = useFeaturesConfig()

  useEffect(() => {
    if (!features?.showResumeDistribution()) {
      navigate('/')
    }
  }, [features, navigate])

  return <Outlet />
}
