import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { trackInternalEvent } from '@rio/tracking'
import getByPath from 'lodash/get'
import { withConfig } from 'builder/components/Config'
import EditorTitle from 'builder/components/EditorTitle'
import { TextField } from 'builder/components/TextField'
import LanguageSelect from 'builder/components/LanguageSelect'
import EditorSection from 'builder/components/EditorSection'
import EditorRow from 'builder/components/EditorRow'
import EditorField from 'builder/components/EditorField'
import RichTextArea from 'builder/components/RichTextArea'
import starWhite from 'builder/components/GenerateAICVBanner/StarWhite.svg'
import { CoverLetterRegenerationStatus } from 'builder/modules/constants'

import loaderImage from 'images/builder/resume-editor/editor-loader.svg'

import {
  EditorPanel,
  EditorScroll,
  EditorContent,
  EditorSubtitle,
  LoaderContainer,
  Loader,
  Note,
  NoteIcon,
  NoteText,
  UserNavigation,
  RegenerateSection,
  RemainingAttemptsText,
} from './styles'
import Paywall from 'builder/components/AiFeatures/Paywall'
import { GenerateButton, Star } from '../GenerateAICVBanner/styles'
import ConfirmationModal from './AICoverLetter/ConfirmationModal'
import { withMediaQueries } from '../MediaQueries'
import { ButtonSize } from '../Button'
import { ErrorState } from './AICoverLetter/ErrorState'
import { withI18n } from '../../hocs/withI18n'
import { ResumeSideMenu } from './ResumeSideMenu/ResumeSideMenu'
import { BuilderType } from './ResumeSideMenu/types'

class CoverLetterEditor extends Component {
  hostname = window.location.hostname
  redirectURL =
    this.hostname === 'career.io' || this.hostname === 'staging.career.io' ? '/' : '/cover-letters'

  static propTypes = {
    editorState: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    isOnline: PropTypes.bool,
    coverLetterState: PropTypes.object.isRequired,
    updateSimpleField: PropTypes.func.isRequired,
    isAPremiumUser: PropTypes.bool,
    aiClFreeAttempts: PropTypes.number,
    startCoverLetterDetailsAnimation: PropTypes.func,
    setShowAICoverLetterPaywall: PropTypes.func,
    fallbackLocale: PropTypes.string,
    setShowConfirmRegenerateModal: PropTypes.func,
    coverLetterId: PropTypes.number,
    mediaQueries: PropTypes.object,
    resetAIRegenerationStatus: PropTypes.func,
    i18n: PropTypes.object,
    isContentAnimationPending: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.letterDetailsRef = createRef()
  }

  state = {
    shouldScrollToDetails: false,
    scrollTimeout: null,
  }

  handleCoverLetterNameChange = value => {
    this.props.updateSimpleField('name', value, true)
  }

  handleLocaleSelect = id => {
    this.props.updateSimpleField('locale', id, true)
  }

  updateSimpleField = e => {
    this.props.updateSimpleField(e.target.name, e.target.value, true)
  }

  updateDescription = val => {
    this.props.updateSimpleField('content', val, true)
  }

  handleAnimationEnd = () => {
    this.props.updateSimpleField('openCL', false)
  }

  componentDidUpdate = prevProps => {
    const { coverLetterState, isContentAnimationPending } = this.props
    const { shouldScrollToDetails } = this.state
    const { letterDetailsRef } = this

    if (
      !prevProps.isContentAnimationPending &&
      isContentAnimationPending &&
      coverLetterState.coverLetter.content
    ) {
      // We should scroll to details if animation is started
      this.setState({ shouldScrollToDetails: true })
    }

    if (shouldScrollToDetails && letterDetailsRef.current) {
      this.setState({
        shouldScrollToDetails: false,
        scrollTimeout: setTimeout(() => {
          // Trigger the animation to scroll down to letter content
          letterDetailsRef.current?.scrollIntoView({ behavior: 'smooth' })
          this.setState({ shouldScrollToDetails: false, scrollTimeout: null })
        }, 100),
      })
    }
  }

  componentWillUnmount = () => {
    const { scrollTimeout } = this.state
    if (scrollTimeout) clearTimeout(scrollTimeout)
  }

  render() {
    const {
      coverLetterState,
      editorState,
      config,
      aiClFreeAttempts,
      isAPremiumUser,
      setShowAICoverLetterPaywall,
      fallbackLocale,
      setShowConfirmRegenerateModal,
      coverLetterId,
      mediaQueries,
      resetAIRegenerationStatus,
      i18n: I18n,
      isContentAnimationPending: isContentAnimationInProgress,
      isOnline,
    } = this.props
    const {
      coverLetter,
      aiGenerationSection: {
        showPaywall: showAICoverLetterPaywall,
        showConfirmationModal: showConfirmRegenerateModal,
        regenerationStatus,
      },
    } = coverLetterState
    const { coverLetterTemplates, locales } = editorState
    const guideLink = getByPath(config, 'links.howToWriteCoverLetter')
    const { superApp, international } = config?.features || {}
    const coverLetterGenerationLimitReached = !isAPremiumUser && aiClFreeAttempts <= 0

    const generateMethod = coverLetterGenerationLimitReached
      ? setShowAICoverLetterPaywall
      : () => setShowConfirmRegenerateModal(true)

    const handleClickGenerateButton = () => {
      trackInternalEvent('click_regenerate_cover_letter')
      generateMethod()
    }

    if (!coverLetter || coverLetterTemplates.length === 0) {
      return (
        <EditorPanel>
          <LoaderContainer>
            <Loader src={loaderImage} />
          </LoaderContainer>
        </EditorPanel>
      )
    }

    const isGeneratingCoverLetter =
      isContentAnimationInProgress ||
      regenerationStatus === CoverLetterRegenerationStatus.generating

    const showErrorStateModal = regenerationStatus === CoverLetterRegenerationStatus.failed
    const errorStateButtonSize = !mediaQueries.isPhone ? ButtonSize.default : ButtonSize.small

    const onRetryErrorStateModal = () => {
      resetAIRegenerationStatus()
      setShowConfirmRegenerateModal(true)
    }

    const additionalContentStyle =
      coverLetter.aiGenerated && mediaQueries.isPhone ? { paddingBottom: '100px' } : {}

    return (
      <>
        <EditorPanel data-testid="editor-panel">
          {(superApp || international) && (
            <ResumeSideMenu isOnline={isOnline} builderType={BuilderType.coverLetterBuilder} />
          )}
          {!(superApp || international) && isOnline && <UserNavigation hasFeatures />}
          <EditorScroll>
            <EditorContent style={additionalContentStyle}>
              <EditorTitle
                onChange={this.handleCoverLetterNameChange}
                value={
                  coverLetter.name ? coverLetter.name : I18n.t('builder.resume_editor.untitled')
                }
              />
              <EditorSubtitle withoutScore>
                <LanguageSelect
                  selected={coverLetter.locale || fallbackLocale}
                  onSelect={this.handleLocaleSelect}
                  options={locales}
                />
              </EditorSubtitle>

              <EditorSection title={I18n.t('builder.cover_letter_editor.personal_details')}>
                <EditorRow>
                  <EditorField>
                    <TextField
                      label={I18n.t('builder.cover_letter_editor.full_name')}
                      value={coverLetter.senderName || ''}
                      name="senderName"
                      onChange={this.updateSimpleField}
                    />
                  </EditorField>

                  <EditorField>
                    <TextField
                      label={I18n.t('builder.cover_letter_editor.job_title')}
                      value={coverLetter.applyingPosition || ''}
                      name="applyingPosition"
                      onChange={this.updateSimpleField}
                      hint={I18n.t('builder.resume_editor.job_title_hint')}
                    />
                  </EditorField>
                </EditorRow>

                <EditorRow>
                  <EditorField>
                    <TextField
                      label={I18n.t('builder.cover_letter_editor.address')}
                      value={coverLetter.address || ''}
                      name="address"
                      onChange={this.updateSimpleField}
                    />
                  </EditorField>

                  <EditorField>
                    <TextField
                      label={I18n.t('builder.cover_letter_editor.email')}
                      value={coverLetter.email || ''}
                      name="email"
                      onChange={this.updateSimpleField}
                    />
                  </EditorField>
                </EditorRow>

                <EditorRow>
                  <EditorField>
                    <TextField
                      label={I18n.t('builder.cover_letter_editor.phone_number')}
                      value={coverLetter.phoneNumber || ''}
                      name="phoneNumber"
                      onChange={this.updateSimpleField}
                    />
                  </EditorField>
                </EditorRow>
              </EditorSection>

              <EditorSection title={I18n.t('builder.cover_letter_editor.recipient_details')}>
                <EditorRow>
                  <EditorField>
                    <TextField
                      label={I18n.t('builder.cover_letter_editor.company')}
                      value={coverLetter.company || ''}
                      name="company"
                      onChange={this.updateSimpleField}
                    />
                  </EditorField>

                  <EditorField>
                    <TextField
                      label={I18n.t('builder.cover_letter_editor.recipient_name')}
                      value={coverLetter.companyRecipient || ''}
                      name="companyRecipient"
                      onChange={this.updateSimpleField}
                    />
                  </EditorField>
                </EditorRow>
              </EditorSection>

              <EditorSection
                title={I18n.t('builder.cover_letter_editor.letter_content')}
                containerRef={this.letterDetailsRef}
              >
                <EditorRow>
                  <RichTextArea
                    label={I18n.t('builder.cover_letter_editor.letter_content_label')}
                    value={coverLetter.content || ''}
                    isAnimating={isContentAnimationInProgress}
                    onAnimationEnd={this.handleAnimationEnd}
                    onChange={this.updateDescription}
                    height={180}
                    locale={coverLetter.locale}
                  />
                </EditorRow>
                {guideLink && (
                  <Note href={guideLink} target="_blank" rel="noreferrer noopener">
                    <NoteIcon />
                    <NoteText>{I18n.t('builder.cover_letter_editor.letter_content_note')}</NoteText>
                  </Note>
                )}
              </EditorSection>
              {coverLetter.aiGenerated && (
                <RegenerateSection>
                  <GenerateButton
                    disabled={isContentAnimationInProgress}
                    onClick={handleClickGenerateButton}
                  >
                    <Star
                      showAnimation={isContentAnimationInProgress}
                      src={starWhite}
                      alt="Star Image"
                    />
                    {I18n.t(
                      isContentAnimationInProgress
                        ? 'builder.ai_cover_letter.generating'
                        : 'builder.ai_cover_letter.regenerate',
                    )}
                  </GenerateButton>
                  {!isAPremiumUser && (
                    <RemainingAttemptsText>{`${
                      aiClFreeAttempts > 0 ? aiClFreeAttempts : 'No'
                    } free attempt${
                      aiClFreeAttempts === 0 || aiClFreeAttempts > 1 ? 's' : ''
                    } left`}</RemainingAttemptsText>
                  )}
                </RegenerateSection>
              )}
            </EditorContent>
          </EditorScroll>

          {showAICoverLetterPaywall && <Paywall onClose={setShowAICoverLetterPaywall} />}
          {showConfirmRegenerateModal && (
            <ConfirmationModal {...{ coverLetterId, isGeneratingCoverLetter }} />
          )}
          {showErrorStateModal && (
            <ErrorState
              onClose={resetAIRegenerationStatus}
              onTryAgain={onRetryErrorStateModal}
              ButtonSize={errorStateButtonSize}
            />
          )}
        </EditorPanel>
      </>
    )
  }
}

export default withI18n(withConfig(withMediaQueries(CoverLetterEditor)))
