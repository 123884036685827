import { connect } from 'react-redux'
import { Component } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import find from 'lodash/find'
import { compose } from 'redux'
import { i18n as I18n } from 'builder/utils/i18n'
import { matchMediaQueries } from 'builder/utils/matchMediaQueries'
import DialogModal from 'builder/components/DialogModal'
// import { Icon20 } from 'builder/components/Icon'
import { withMediaQueries } from 'builder/components/MediaQueries'
import { withConfig } from 'builder/components/Config'
import { AppConfig } from 'builder/modules/init'
import { Store } from 'builder/modules/store'
import { UserData } from 'builder/modules/user'
import {
  ColumnName,
  FetchJobsPayload,
  Job,
  JobCreatePayload,
  JobDeletePayload,
  JobMovePayload,
  ResumeSelection,
  actions,
} from 'builder/modules/jobTracking'
import { actions as renderingActions } from 'builder/modules/rendering'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import { AutoApplyBubbles } from 'builder/modules/jobSearch/types'
import tunerURL from 'images/modals/resume.jpg'
import { CioLimitModal } from '../CioLimitModal/CioLimitModal'
import JobTrackerExtensionBanner from '../Panel/CareerGoal/components/JobTrackerExtensionBanner'
import Board from './Board'
import BoardPlaceholder from './BoardPlaceholder'
import EditModal from './EditModal'
import { Sidebar } from './Sidebar'
import {
  Container,
  Header,
  // HeaderTitle,
  // RunOnboarding,
  RightSide,
  BodyScrollBlocker,
} from './styles'
import ChromeExtensionNotification from './ChromeExtensionNotification'

type Props = {
  jobs: Job[]
  isJobsLoading: boolean
  columnNames: ColumnName[]
  fetchJobs: (payload: FetchJobsPayload) => void
  createJob: (payload: JobCreatePayload) => void
  deleteJob: (payload: JobDeletePayload) => void
  editJob: (job: Job) => void
  move: (payload: JobMovePayload) => void
  reorder: (payload: JobMovePayload) => void
  setResumeSelection: (payload: ResumeSelection | null) => void
  setCioLimitModal: (payload: boolean) => void
  openRdGotCreditModal: () => void
  config: AppConfig | null
  mediaQueries: ReturnType<typeof matchMediaQueries>
  user: UserData
  isCioLimitModal: boolean
  fromDashboard?: boolean
  autoApplyBubbles: AutoApplyBubbles
}

type State = {
  editId: number | null
  tempEditId: number | null
  isOnboardingOpen: boolean
  canInstallExtension: boolean
  interviewJob: Partial<Job> | null
  deleteId: number | null
  BoardComponent?: typeof Board
}

// TODO: Rewrite from class component to functional one
class JobTracking extends Component<Props, State> {
  static defaultProps = {
    jobs: [],
  }

  closedOnboardingKey = 'JOB_TRACKER_ONBOARDING_CLOSED'

  constructor(props: Props) {
    super(props)
    this.state = {
      editId: null,
      tempEditId: null,
      isOnboardingOpen: !localStorage.getItem(this.closedOnboardingKey),
      canInstallExtension: false,
      interviewJob: null,
      deleteId: null,
      BoardComponent: undefined,
    }
  }

  showRDGotCreditModal() {
    const params = new URLSearchParams(window.location.search)
    if (params.get('show_credit_modal')) {
      this.props.openRdGotCreditModal()
    }
  }

  async componentDidMount() {
    if (!this.props.autoApplyBubbles.running) {
      this.props.fetchJobs({
        location: 'Remote',
        dummy: this.props.autoApplyBubbles.running,
      })
    }

    import(/* webpackChunkName: "job-tracking-board" */ './Board').then(module => {
      this.setState({
        BoardComponent: module.default,
      })
    })

    setTimeout(this.checkAvailabilityToInstallExtension, 300)
    this.showRDGotCreditModal()
  }

  componentWillUnmount() {
    this.props.setResumeSelection(null)
  }

  checkAvailabilityToInstallExtension = () => {
    const { mediaQueries } = this.props

    const isDesktopChrome = /Chrome/i.test(navigator.userAgent) && !mediaQueries.isPhone
    const isExtensionInstalled = document.getElementById('jtrack-root') !== null
    this.setState({ canInstallExtension: isDesktopChrome && !isExtensionInstalled })
  }

  isSuperApp() {
    return this.props.config?.features.superApp
  }

  createJob = (fields: JobCreatePayload) => {
    this.props.createJob({ ...fields, dummy: this.props.autoApplyBubbles.running })
    trackInternalEvent('create_job_tracking_card')

    // User just created a new card in "interview" column
    // if (this.isSuperApp() && fields.status === ColumnName.interview) {
    //   this.setState({ interviewJob: fields })
    // }
  }

  deleteJob = () => {
    if (this.state.deleteId) this.props.deleteJob({ id: this.state.deleteId })

    this.setState({
      deleteId: null,
      editId: null,
    })
  }

  handleCardClick = (editId: number) => {
    this.setState({ editId })
    this.setState({ tempEditId: editId })
  }

  handleDeleteClick = (deleteId: number) => {
    this.setState({ deleteId })
  }

  closeOnboarding = () => {
    this.setState({ isOnboardingOpen: false })
    localStorage.setItem(this.closedOnboardingKey, 'true')
  }

  openOnboarding = () => {
    this.setState({ isOnboardingOpen: true })
  }

  handleEditModalClose = () => {
    this.setState({ editId: null })
  }

  handleDeleteModalClose = () => {
    this.setState({ deleteId: null })
  }

  handleEditJob = (patch: Job) => {
    this.props.editJob(patch)

    const currentJob = this.props.jobs.find(job => job.id === patch.id)

    // Interview Prep is supported by CIO only
    if (!this.isSuperApp()) return
    // Card already was in interview column
    if (currentJob?.status === ColumnName.interview) return
    // User moved it to some another column
    if (patch.status !== ColumnName.interview) return

    this.setState({ interviewJob: patch })
  }

  handleDontShowAgaianClicked = () => {
    this.setState({ editId: this.state.tempEditId })
  }

  handleMove = ({ source, destination }: JobMovePayload) => {
    this.props.move({ source, destination })
  }

  handleSetInterviewJob = (selectedJob: Partial<Job> | null) => {
    this.setState({ interviewJob: selectedJob })
  }

  render() {
    const { columnNames, jobs, reorder, isJobsLoading, user, isCioLimitModal, fromDashboard } =
      this.props

    const {
      editId,
      deleteId,
      BoardComponent,
      // isOnboardingOpen,
      canInstallExtension,
    } = this.state

    const isFreePlan = !user.hasPremiumFeatures

    const editableJob = editId ? find(jobs, { id: editId }) : null
    const isBoardLoaded = BoardComponent && !isJobsLoading

    const handleClose = () => {
      this.props.setCioLimitModal(false)
      trackInternalEvent('skip_paywall')
    }

    // const isOnboardingVisible = isFreePlan || isOnboardingOpen

    return (
      <>
        <Container>
          {fromDashboard && (
            <Header fromDashboard={fromDashboard}>
              {fromDashboard && <JobTrackerExtensionBanner componentType="job_tracker_top" />}
              {/* <HeaderTitle>{I18n.t('builder.job_tracking.title')}</HeaderTitle> */}
              <RightSide>
                {/* <RunOnboarding onClick={this.openOnboarding}>
              <Icon20.Info />
              {I18n.t('builder.job_tracking.how_to_use')}
            </RunOnboarding> */}
              </RightSide>
            </Header>
          )}

          {!isBoardLoaded && <BoardPlaceholder />}

          {isBoardLoaded && (
            <BoardComponent
              columnNames={columnNames.filter(columnName => columnName !== ColumnName.recommended)}
              jobs={jobs}
              move={this.handleMove}
              reorder={reorder}
              onCardCreate={this.createJob}
              onCardClick={this.handleCardClick}
              isFreePlan={isFreePlan}
              deleteJob={this.props.deleteJob}
              handleSetInterviewJob={this.handleSetInterviewJob}
              interviewJob={this.state.interviewJob}
              fromDashboard={fromDashboard}
            />
          )}

          {/* {isOnboardingVisible && (
          <Onboarding isFreePlan={isFreePlan} onClose={this.closeOnboarding} />
        )} */}

          {false && (
            <EditModal
              job={editableJob}
              columnNames={columnNames}
              canInstallExtension={canInstallExtension}
              onSubmit={this.handleEditJob}
              onClose={this.handleEditModalClose}
              onDelete={this.handleDeleteClick}
            />
          )}

          {/* Uncomment below lines to show upgrade banner on moving any job to interview column

         {this.isSuperApp() && (
            <InterviewMock
              selectedJob={interviewJob}
              onClose={() => this.setState({ interviewJob: null })}
            />
          )} */}

          <DialogModal
            dontShowAgainId={`${user.id}_deleteItem_jobTracking`}
            title={I18n.t('builder.job_tracking.delete_job')}
            text={I18n.t('builder.job_tracking.delete_modal.sure_to_delete_job')}
            primaryButtonText={I18n.t('builder.job_tracking.delete')}
            secondaryButtonText={I18n.t('builder.job_tracking.cancel')}
            isOpen={!!deleteId}
            primaryAction={this.deleteJob}
            secondaryAction={this.handleDeleteModalClose}
            closeAction={this.handleDeleteModalClose}
            dontShowAgaianClicked={this.handleDontShowAgaianClicked}
          />

          {!fromDashboard && <BodyScrollBlocker />}
        </Container>
        {editId && (
          <Sidebar
            job={editableJob}
            columnNames={columnNames}
            onSubmit={this.handleEditJob}
            onClose={this.handleEditModalClose}
            onDelete={this.handleDeleteClick}
            handleSetInterviewJob={this.handleSetInterviewJob}
            isDashboard={fromDashboard}
          />
        )}
        <ChromeExtensionNotification />
        {isCioLimitModal && (
          <CioLimitModal
            documentType="resume"
            cancelButtom={I18n.t('builder.job_tracking.limit_modal.cancel')}
            upgradebutton={I18n.t('builder.job_tracking.limit_modal.upgrade')}
            tunerImg={tunerURL}
            onClose={() => handleClose()}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state: Store) => {
  return {
    user: state.user.data,
    isCioLimitModal: state.jobTracking.isCioLimitModal,
    autoApplyBubbles: jobSearchSelectors.autoApplyBubbles(state),
  }
}

const mapDispatchToProps = {
  setResumeSelection: actions.setResumeSelection,
  setCioLimitModal: actions.setCioLimitModal,
  openRdGotCreditModal: renderingActions.showRDGotCreditsModal,
}

export default compose(
  withConfig,
  withMediaQueries,
  connect(mapStateToProps, mapDispatchToProps),
)(JobTracking)
