import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import { Typography, FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import { ButtonSize, ButtonTheme } from './types'
import { BaseButton } from './BaseButton'

export const Button = styled(BaseButton)<{ isDisabled?: boolean }>`
  position: relative;
  ${Typography.ButtonM};
  ${FontWeights.DemiBold};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  outline: none;
  white-space: nowrap;
  text-align: center;
  color: ${props => (props.isDisabled ? Colors.Neutral50 : Colors.White)};
  background-color: ${props => (props.isDisabled ? Colors.Neutral20 : Colors.Blue50)};
  padding: 13px 24px 13px 20px;
  border-radius: 4px;
  transition: background-color 0.1s, color 0.1s, box-shadow 0.1s;
  cursor: pointer;
  border-width: 0;
  user-select: none;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};

  &:hover,
  &:focus-visible {
    background-color: ${props => (props.isDisabled ? Colors.Neutral20 : Colors.Blue60)};
  }

  &:active {
    background-color: ${props => (props.isDisabled ? Colors.Neutral20 : Colors.Blue70)};
  }

  ${props => {
    switch (props.theme) {
      case ButtonTheme.destructive:
        return css`
          background-color: ${Colors.Red50};

          &:hover,
          &:focus-visible {
            background-color: ${Colors.Red60};
          }

          &:active {
            background-color: ${Colors.Red70};
          }
        `
      case ButtonTheme.confirmative:
        return css`
          background-color: ${Colors.Green50};

          &:hover,
          &:focus-visible {
            background-color: ${Colors.Green60};
          }

          &:active {
            background-color: ${Colors.Green70};
          }
        `
      case ButtonTheme.ghost:
        return css`
          background: ${hexToRgba(Colors.Blue50, 0)};
          box-shadow: inset 0 0 0 1px ${Colors.Neutral20};
          color: ${Colors.Neutral90};

          &:hover,
          &:focus-visible {
            box-shadow: inset 0 0 0 1px ${Colors.Blue50};
            color: ${Colors.Blue50};
            background-color: ${hexToRgba(Colors.Blue50, 0)};
          }

          ${Media.HoverNotSupported`
            &:hover,
            &:focus-visible {
              background: ${hexToRgba(Colors.Blue50, 0)};
              box-shadow: inset 0 0 0 1px ${Colors.Neutral20};
              color: ${Colors.Neutral90};
            }
          `}

          &:active {
            background-color: ${hexToRgba(Colors.Blue50, 0.08)};
          }
        `
      case ButtonTheme.alternative:
        return css`
          background-color: ${Colors.Indigo50};

          &:hover,
          &:focus-visible {
            background-color: ${Colors.Indigo60};
          }

          &:active {
            background-color: ${Colors.Indigo70};
          }
        `
      case ButtonTheme.secondary:
        return css`
          background-color: ${hexToRgba(Colors.Blue50, 0.1)};
          ${FontWeights.Medium};
          color: ${Colors.Blue50};

          &:hover,
          &:focus-visible {
            background-color: ${hexToRgba(Colors.Blue50, 0.15)};
          }

          &:active {
            background-color: ${hexToRgba(Colors.Blue50, 0.2)};
          }
        `
    }
  }};

  ${props => {
    switch (props.size) {
      case ButtonSize.small:
        return css`
          ${Typography.ButtonS};
          padding: 8px 12px;
        `
    }
  }};
`
