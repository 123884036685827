export const Settings3 = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.56099 11.7007L15.9986 7.40661L23.4362 11.7007V20.2889L15.9986 24.583L8.56099 20.2889V11.7007ZM15.4986 5.61682C15.808 5.43819 16.1892 5.43819 16.4986 5.61682L24.7362 10.3728C25.0456 10.5514 25.2362 10.8816 25.2362 11.2388V20.7508C25.2362 21.1081 25.0456 21.4382 24.7362 21.6168L16.4986 26.3728C16.1892 26.5514 15.808 26.5514 15.4986 26.3728L7.26099 21.6168C6.95159 21.4382 6.76099 21.1081 6.76099 20.7508V11.2388C6.76099 10.8816 6.95159 10.5514 7.26099 10.3728L15.4986 5.61682ZM13.7892 14.6037L15.8986 13.3859C15.9605 13.3502 16.0367 13.3502 16.0986 13.3859L18.208 14.6037C18.2699 14.6395 18.308 14.7055 18.308 14.777V17.2127C18.308 17.2841 18.2699 17.3502 18.208 17.3859L16.0986 18.6037C16.0367 18.6395 15.9605 18.6395 15.8986 18.6037L13.7892 17.3859C13.7273 17.3502 13.6892 17.2841 13.6892 17.2127V14.777C13.6892 14.7055 13.7273 14.6395 13.7892 14.6037Z"
      fill="currentColor"
    />
  </svg>
)

export const CIOProductsJobTracker = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" rx="8" fill="#F1F2FF" />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M5.19995 10.3172C5.19995 9.47928 5.87924 8.79999 6.71719 8.79999H21.8896C22.7276 8.79999 23.4068 9.47928 23.4068 10.3172V17.9034C23.4068 18.7414 22.7276 19.4207 21.8896 19.4207H6.71719C5.87924 19.4207 5.19995 18.7414 5.19995 17.9034V10.3172Z"
        fill="url(#paint0_linear_4291_8953)"
      />
    </g>
    <g filter="url(#filter0_b_4291_8953)">
      <path
        d="M8.99377 14.1549C8.99377 13.2924 9.69649 12.5931 10.5633 12.5931H25.6311C26.498 12.5931 27.2007 13.2923 27.2007 14.1549V21.6519C27.2007 22.5145 26.498 23.2138 25.6311 23.2138H10.5633C9.69649 23.2138 8.99377 22.5145 8.99377 21.6519V14.1549Z"
        fill="#96D0AD"
        fillOpacity="0.01"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M8.99377 14.1549C8.99377 13.2924 9.69649 12.5931 10.5633 12.5931H25.6311C26.498 12.5931 27.2007 13.2923 27.2007 14.1549V21.6519C27.2007 22.5145 26.498 23.2138 25.6311 23.2138H10.5633C9.69649 23.2138 8.99377 22.5145 8.99377 21.6519V14.1549Z"
        fill="#9BA1FB"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_4291_8953"
        x="3.99708"
        y="7.59639"
        width="28.2003"
        height="20.6141"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.49835" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4291_8953" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_4291_8953"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4291_8953"
        x1="14.3034"
        y1="8.79999"
        x2="14.3034"
        y2="19.4207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBDEFF" />
        <stop offset="1" stopColor="#BBC1FF" />
      </linearGradient>
    </defs>
  </svg>
)

export const CIOProductsPitch = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" fill="#EAF6FF" />
    <path
      d="M24 15.9992C24 17.9992 23.2 19.2752 23.6 21.1992C24.1501 23.8453 24.8 25.1992 24.4 25.5992C24 25.9992 22.8677 25.228 20.8 24.3992C19.2 23.7579 17.4123 24.7992 15.2 24.7992C10.3399 24.7992 6.40002 20.8593 6.40002 15.9992C6.40002 11.1391 10.3399 7.19922 15.2 7.19922C20.0601 7.19922 24 11.1391 24 15.9992Z"
      fill="url(#paint0_linear_4291_8964)"
    />
    <g filter="url(#filter0_b_4291_8964)">
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 17.7433V17.5672V17.5672C16 16.1244 16.5731 14.7408 17.5933 13.7206C18.6135 12.7004 19.9972 12.1272 21.44 12.1272C22.8828 12.1272 24.2665 12.7004 25.2867 13.7206C26.3069 14.7408 26.88 16.1244 26.88 17.5672V17.5672V17.744C25.2798 18.6695 23.4221 19.1992 21.4406 19.1992C19.4586 19.1992 17.6004 18.6693 16 17.7433ZM24.16 8.31924C24.16 9.82146 22.9422 11.0392 21.44 11.0392C19.9378 11.0392 18.72 9.82146 18.72 8.31924C18.72 6.81703 19.9378 5.59924 21.44 5.59924C22.9422 5.59924 24.16 6.81703 24.16 8.31924Z"
          fill="#111111"
          fillOpacity="0.01"
        />
      </g>
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 17.7433V17.5672V17.5672C16 16.1244 16.5731 14.7408 17.5933 13.7206C18.6135 12.7004 19.9972 12.1272 21.44 12.1272C22.8828 12.1272 24.2665 12.7004 25.2867 13.7206C26.3069 14.7408 26.88 16.1244 26.88 17.5672V17.5672V17.744C25.2798 18.6695 23.4221 19.1992 21.4406 19.1992C19.4586 19.1992 17.6004 18.6693 16 17.7433ZM24.16 8.31924C24.16 9.82146 22.9422 11.0392 21.44 11.0392C19.9378 11.0392 18.72 9.82146 18.72 8.31924C18.72 6.81703 19.9378 5.59924 21.44 5.59924C22.9422 5.59924 24.16 6.81703 24.16 8.31924Z"
        fill="#54AFF8"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_4291_8964"
        x="12"
        y="1.59924"
        width="18.88"
        height="21.6"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4291_8964" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_4291_8964"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4291_8964"
        x1="9.20002"
        y1="22.7992"
        x2="21.6"
        y2="12.3992"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#96D4FF" />
        <stop offset="1" stopColor="#C3E5FE" />
      </linearGradient>
    </defs>
  </svg>
)

export const CIOProductsInterview = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" rx="8" fill="#E7F4ED" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.373 5.59857C8.18514 5.59828 5.60067 8.18229 5.60038 11.3701L5.59998 15.8029C5.59997 15.8232 5.60029 15.8434 5.60091 15.8635V20.0404C5.60091 20.5547 6.22264 20.8122 6.58626 20.4486L9.30752 17.7273L16.0006 17.7279C19.1884 17.7282 21.7729 15.1442 21.7732 11.9564L21.7732 11.3716C21.7735 8.18375 19.1895 5.59928 16.0017 5.59899L11.373 5.59857Z"
      fill="url(#paint0_linear_4291_8627)"
    />
    <g filter="url(#filter0_b_4291_8627)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0877 10.3151C12.8999 10.3151 10.3157 12.8994 10.3157 16.0872V16.6723C10.3157 19.8602 12.8999 22.4444 16.0877 22.4444H22.4463L24.8472 24.8453C25.4532 25.4513 26.4894 25.0221 26.4894 24.165V18.4013H26.488V16.0872C26.488 12.8994 23.9038 10.3151 20.716 10.3151H16.0877Z"
        fill="#7A81F5"
        fillOpacity="0.01"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0877 10.3146C12.8999 10.3146 10.3157 12.8988 10.3157 16.0866V16.6718C10.3157 19.8596 12.8999 22.4438 16.0877 22.4438H22.4463L25.5041 25.5016C25.8677 25.8652 26.4894 25.6077 26.4894 25.0935V18.4008H26.488V16.0866C26.488 12.8988 23.9038 10.3146 20.716 10.3146H16.0877Z"
        fill="#48BA75"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_4291_8627"
        x="5.50563"
        y="5.50508"
        width="25.7939"
        height="24.4339"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.40502" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4291_8627" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_4291_8627"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4291_8627"
        x1="13.6866"
        y1="5.59857"
        x2="13.6866"
        y2="20.6188"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C6E4D2" />
        <stop offset="1" stopColor="#9BD6B3" />
      </linearGradient>
    </defs>
  </svg>
)
