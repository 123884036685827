import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { EXTENSION_ID, actions, selectors } from 'builder/modules/jobTracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'

export const useChromeExtension = () => {
  const dispatch = useDispatch()
  const { isPhone, isTablet } = useMediaQueries()
  const installed = useTypedSelector(selectors.hasInstalledExtension)
  const canInstallExtension = useTypedSelector(selectors.canInstallExtension)

  const checkExtensionInstalled = async () => {
    return fetch(`chrome-extension://${EXTENSION_ID}/icon128.png`)
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }

  const checkAvailabilityToInstallExtension = useCallback(async () => {
    const isDesktopChrome = /Chrome/i.test(navigator.userAgent) && !(isPhone || isTablet)
    const isExtensionInstalled = await checkExtensionInstalled()
    dispatch(actions.setHasInstalledExtension(isExtensionInstalled))
    dispatch(actions.setCanInstallExtension(isDesktopChrome && !isExtensionInstalled))
  }, [isPhone, isTablet, dispatch])

  useEffect(() => {
    checkAvailabilityToInstallExtension()
  }, [checkAvailabilityToInstallExtension])

  return {
    isInstalled: installed,
    canInstallExtension,
  }
}
