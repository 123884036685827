import * as React from 'react'
import { useSelector } from 'react-redux'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useUser } from 'builder/hooks/useUser'
import { useConfig } from 'builder/hooks/useConfig'
import Logo from 'builder/components/Logo'
import { selectors as interviewSelector } from 'builder/modules/interview/interviewModule'
import { authService } from 'builder/services/AuthService'
import { MobileNavbar } from './components/MobileNavbar'
import { DesktopNavbar } from './components/DesktopNavbar'

import { Container, Content, LogoLink } from './styles'
import { PrimaryLinks, SecondaryLinks } from './types'

export const Navbar = () => {
  const config = useConfig()
  const user = useUser()
  const location = useLocation()
  const { i18n, isScopeLoaded } = useI18n()
  const { isTablet, isPhone } = useMediaQueries()
  const interviewUrl = useSelector(interviewSelector.getInterviewUrl)
  const superAppPromotion = config?.features.superAppPromotionType

  const handlePrimaryLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    // Track clicks in ClickHouse
    const target = event.currentTarget.dataset.name
    if (typeof target === 'string') trackInternalEvent('click_navbar_link', { target })
  }

  const isDashboardOpen = React.useMemo(() => {
    return (
      matchPath('/', location.pathname) ||
      matchPath('/resumes', location.pathname) ||
      matchPath('/cover-letters', location.pathname)
    )
  }, [location.pathname])

  // Do not render menu until the application config and user are loaded
  if (!user || !config || !isScopeLoaded('builder')) return null

  // Some InterviewSchool screens don't look good on small screen resolutions (less than 768px)
  const { interviewSchool } = config.features
  const isInterviewSchoolEnabled = interviewSchool && !isPhone

  const primaryLinks = [
    {
      name: 'dashboard',
      to: {
        pathname: '/',
      },
      as: Link,
      isActive: isDashboardOpen,
      children: superAppPromotion
        ? i18n.t('builder.navbar.documents')
        : i18n.t('builder.navbar.dashboard'),
    },
    isInterviewSchoolEnabled && {
      name: 'interview_school',
      to: interviewUrl,
      children: i18n.t('builder.navbar.interview_prep'),
    },
    config.features.jobTracking && {
      name: 'job_tracking',
      to: '/job-tracking',
      children: i18n.t('builder.navbar.job_tracking'),
    },
    config.features.jobSearch && {
      name: 'job_search',
      to: '/job-search',
      children: i18n.t('builder.navbar.job_search'),
    },
  ].filter(Boolean) as PrimaryLinks

  // Dropdown menu links
  const secondaryLinks: SecondaryLinks = [
    {
      to: '/account',
      children: i18n.t('builder.navbar.account_settings'),
    },
    {
      href: config.links.updates || 'https://updates.resume.io/',
      children: i18n.t('builder.navbar.updates'),
      target: '_blank',
      rel: 'noreferrer noopener',
    },
    {
      href: config.links.help,
      children: i18n.t('builder.navbar.faq'),
      target: '_blank',
      rel: 'noreferrer noopener',
    },
    {
      children: i18n.t('builder.navbar.log_out'),
      onClick: () => authService.logout(),
    },
  ]

  const barHeight = isPhone ? 64 : 80

  return (
    <Container barHeight={barHeight}>
      <Content>
        <LogoLink
          to={{
            pathname: '/',
          }}
        >
          <Logo />
        </LogoLink>

        {isTablet && !superAppPromotion ? (
          <MobileNavbar
            user={user}
            barHeight={barHeight}
            primaryLinks={primaryLinks}
            secondaryLinks={secondaryLinks}
            onPrimaryLinkClick={handlePrimaryLinkClick}
          />
        ) : (
          <DesktopNavbar
            user={user}
            primaryLinks={primaryLinks}
            isDashboardOpen={Boolean(isDashboardOpen)}
            onPrimaryLinkClick={handlePrimaryLinkClick}
          />
        )}
      </Content>
    </Container>
  )
}
