import { takeEvery, takeLatest, delay, select, all, call } from 'redux-saga/effects'
import { trackInternalEvent } from '@rio/tracking'
import { Optional, Resume } from '@rio/types'
import { selectors } from 'builder/modules/resumeEditor/resumeEditorSelectors'
import { selectors as initSelectors } from 'builder/modules/init'
import {
  checkTextCreditability,
  CreditabilityCheckStatus,
} from 'builder/utils/checkTextCreditability'
import { convertHtmlToText } from 'builder/utils/convertHtmlToText'
import { SectionNames } from 'builder/modules/resumeEditor/sectionNames'
import { actions } from 'builder/modules/resumeEditor'
import jwtService from 'builder/services/JwtService'

/*
  Sending internal events of resume updates observing
 */

type EventName = Parameters<typeof trackInternalEvent>[0]

interface Field {
  sectionId: SectionNames
  contentType: CreditabilityCheckStatus
  cardId?: Optional<number | string>
}

interface TrackingEvent {
  name: EventName
  data: {
    section: SectionNames
    content_type: CreditabilityCheckStatus
    card_id?: Optional<number | string>
  }
}

// Object for store resume data between updates
let persistedResumes: Record<string, Resume> = {}

export function persistValues(resume: Resume) {
  persistedResumes[resume.id] = resume
}

// Get array of marketing events
function getEventsFromDifferences(differences: Field[]): TrackingEvent[] {
  if (!differences.length) return []

  return differences.map(({ sectionId, contentType, cardId }) => ({
    name: 'change_resume_field_content',
    data: {
      section: sectionId,
      content_type: contentType,
      ...(cardId && { card_id: cardId }),
    },
  }))
}

// Returns an array with differences between resumes in profile and work experiences
function findDifferences(newResume: Resume, oldResume: Resume): Field[] {
  const newSummaryText = convertHtmlToText(newResume.profile || '')
  const oldSummaryText = convertHtmlToText(oldResume.profile || '')
  const result: Field[] = []

  // Find differences of profile section
  if (newSummaryText !== oldSummaryText) {
    result.push({
      sectionId: SectionNames.profile,
      contentType: checkTextCreditability(newSummaryText),
    })
  }

  // Find differences of work experiences
  newResume.workExperiences.forEach(newCard => {
    const oldCard = oldResume.workExperiences.find(card => card.id === newCard.id)
    const newDescription = convertHtmlToText(newCard?.description || '')
    const oldDescription = convertHtmlToText(oldCard?.description || '')

    if (newDescription !== oldDescription) {
      result.push({
        sectionId: SectionNames.workExperiences,
        contentType: checkTextCreditability(newDescription),
        ...(newCard.id && { cardId: newCard.id }),
      })
    }
  })

  return result
}

function getResumeTrackingEvents(newResume: Resume): TrackingEvent[] {
  // Get last saved version of resume from persisted data
  const oldResume = persistedResumes[newResume.id]
  if (!oldResume) return []

  const differences = findDifferences(newResume, oldResume)
  return getEventsFromDifferences(differences)
}

function trackResumeTexts(resume: Resume) {
  const events = getResumeTrackingEvents(resume)
  if (events.length) {
    events.forEach(({ name, data }) => {
      trackInternalEvent(name, { ...data, resume_id: resume.id })
    })

    persistValues(resume)
  }
}

function* fetchResumeSuccessSaga(action: ReturnType<typeof actions.fetchResumeSuccess>) {
  // persist fetched resume for change fields tracking
  if (action.payload) {
    persistValues(action.payload)
  }
}

/*
  Saga sends internal events if profile or work experience descriptions changed
 */
export function* trackResumeTextsSaga(): Generator<object, void, Resume> {
  const resume = yield select(selectors.resume)
  trackResumeTexts(resume)
}

// We need to call tracking after 10 seconds from last editing
function* delayedTrackResumeTextsSaga() {
  yield delay(9 * 1000) // Plus ~1 second of debounce
  yield trackResumeTextsSaga()
}

/*
  Saga sends description and skills to job recommendation API
*/
function* updateJobRecommendationsSaga(): Generator<object, void, Resume> {
  const superApp = yield select(initSelectors.feature, 'superApp')
  if (!superApp) return
  const resume = yield select(selectors.resume)
  const { profile, skills } = resume
  try {
    yield call(jwtService.client.post, `${process.env.JOB_REC_API_BASE}/profile`, {
      description: profile,
      skills: skills.map(({ skill }) => skill),
    })
  } catch {}
}

function* delayedUpdateJobRecommendations() {
  yield delay(60 * 1000) // Delay of 60 seconds
  yield updateJobRecommendationsSaga()
}

export const trackingSagas = function* trackingSaga() {
  yield all([
    takeEvery(actions.fetchResumeSuccess, fetchResumeSuccessSaga),
    takeLatest(actions.updateResumeSuccess, delayedTrackResumeTextsSaga),
    takeLatest(actions.updateResumeSuccess, delayedUpdateJobRecommendations),
  ])
}
