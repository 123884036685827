import { DefaultSuggestionType } from 'builder/components/AsyncAutosuggest'

export const BAR_HEIGHT = 80

export const PARAM_TERM = 'term'
export const PARAM_QUERY = 'query'
export const PARAM_LOCATION = 'location'
export const PARAM_LOCATION_ID = 'location_id'
export const PARAM_LOCATION_LAT = 'location_latitude'
export const PARAM_LOCATION_LNG = 'location_longitude'

export const PARAM_TIME = 'within_n_days'

export const PARAM_PAGE = 'page'

export const PARAM_VIEW = 'view'

export const LOCAL_STORAGE_JS_HISTORY_QUERY_KEY = 'JOB_SEARCH_HISTORY_QUERY_V3'
export const LOCAL_STORAGE_JS_HISTORY_LOCATIONS_KEY = 'JOB_SEARCH_HISTORY_LOCATIONS_V3'

export type LocationTypes = 'location_id' | 'near_me' | 'remote' | 'string_location'

export interface Props {
  withBanner?: boolean
  newDash?: boolean
}

export interface DefaultSuggestion {
  title?: string
  suggestions: Array<DefaultSuggestionType>
}

export const NO_LOCATION_ACCESS_RESULTS = [
  {
    suggestions: [
      {
        formatted: 'Remote',
      },
    ],
  },
]

export const DEFAULT_TIME_RESULTS = ['0', '30', '10', '5', '1']

export interface AddressResponse {
  details: {
    locationTitle: string
  }
  success: boolean
}

export interface LocationResponse {
  id: number
  name: string
  short_name: string
  formatted_name: string
  type: string
  parent: LocationResponse
  locationType: LocationTypes
}
