import { ReactNode } from 'react'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { ERDSteps } from '../../types'
import { BlueStep, LayoutContainer, NormalStep, StepsCounter } from './styles'

interface IProps {
  activeStep: ERDSteps
  children: ReactNode
}

const FormLayout = ({ activeStep, children }: IProps) => {
  const { features } = useFeaturesConfig()
  const steps = [ERDSteps.FORM_STEP_1, ERDSteps.FORM_STEP_2, ERDSteps.FORM_STEP_3]

  return (
    <LayoutContainer isNewDash={features.superApp || features.international}>
      {children}
      <StepsCounter isNewDash={features.superApp || features.international}>
        {steps.map((step: ERDSteps, index: number) => {
          if (step === activeStep) {
            return <BlueStep key={index} />
          } else {
            return <NormalStep key={index} />
          }
        })}
      </StepsCounter>
    </LayoutContainer>
  )
}

export default FormLayout
