import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useDispatch } from 'react-redux'
import { actions } from 'builder/modules/autoApply/autoApplyModule'
import { useI18n } from 'builder/hooks/useI18n'
import { MouseClickEvent } from 'builder/modules/ui'
import { DocumentTypes } from 'builder/modules/constants'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import { Icon56 } from 'builder/components/Icon'
import * as SimpleForm from 'builder/components/SimpleForm'
import { FileInput } from 'builder/components/PhotoUploadField/styles'
import SelectResumeModal from 'builder/views/ResumeDistributionView/components/SelectResumeModal'

import { BriefDocumentType } from 'builder/modules/panel'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import { useSelectResume } from '../../hooks/useSelectResume'
import { useUploadResume } from '../../hooks/useUploadResume'
import { ALLOWED_FILES, Routes, TotalPageCount } from '../../constants'

import { FillWarning, LayoutContainer, Title, TitleWrapper, FormContainer } from '../styles'
import Boston from '../../assets/Boston.png'
import Boston2 from '../../assets/Boston2.png'
import {
  Box,
  UploadResumeImage,
  BoxWrapper,
  Container,
  SelectionTitle,
  Description,
  FakeResume,
  Image1,
  Image2,
  FadeResume,
  UploadFromDevice,
  InfoSection,
  InfoSectionIcon,
  InfoSectionDescription,
  InfoSectionDescriptionBold,
  FadeUpload,
  UploadResumeContainer,
} from './styles'

export const ResumeSelectView = () => {
  const dispatch = useDispatch()
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { isPhone } = useMediaQueries()
  const { features } = useFeaturesConfig()

  const TRANSLATION = `builder.auto_apply.resume_select${
    features.careerProfileShortVersion ? '.short_version' : ''
  }`

  const onResumeSuccess = (data: AutoApplyForm) => {
    dispatch(actions.updateForm(data))
  }

  const getHost = () => {
    if (features.superApp) {
      return 'Career.io'
    } else if (features.international) {
      return 'Resume.io'
    } else {
      return window.location.hostname
    }
  }

  const { mutateAsync: selectResume } = useSelectResume(onResumeSuccess)
  const { mutateAsync: uploadResume } = useUploadResume(undefined, onResumeSuccess)
  const [showResumeSelect, setShowResumeSelect] = useState(false)

  const onNextStep = () => {
    navigate(`/auto-apply/${Routes.PERSONAL_INFO}`)
  }

  const onBackClick = () => {
    const path = features.careerProfileShortVersion
      ? `/auto-apply/${Routes.JOB_PREFERENCES_2}`
      : '/'
    navigate(path)
  }

  const handleUploadClick = (event: MouseClickEvent) => {
    event.stopPropagation()
    trackInternalEvent('submit_resume', {
      label: 'total_job_search_solution',
      type: 'upload',
    })
  }

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedResume = event.target.files?.[0]
    if (selectedResume) {
      await uploadResume(selectedResume)
      onNextStep()
    }
  }

  const onBuilderResumeSelect = (resumeDetails: BriefDocumentType) => {
    let type: 'app' | 'TR'
    if (resumeDetails.type === DocumentTypes.trDocument) {
      type = 'TR'
      selectResume({ trResumeId: resumeDetails.id })
    } else {
      type = 'app'
      selectResume({ resumeId: resumeDetails.id })
    }

    trackInternalEvent('submit_resume', {
      label: 'total_job_search_solution',
      type,
    })
    navigate(`/auto-apply/${Routes.PERSONAL_INFO}`)
  }

  useEffect(() => {
    trackInternalEvent('aa_resume_selection', {
      label: 'total_job_search_solution',
    })
  }, [])

  return (
    <LayoutContainer>
      <Container $showShortVersion={features.careerProfileShortVersion}>
        <TitleWrapper $showShortVersion={features.careerProfileShortVersion}>
          <Title>{i18n.t(`${TRANSLATION}.title`)}</Title>
          <FillWarning>{i18n.t(`${TRANSLATION}.sub_title`)}</FillWarning>
        </TitleWrapper>
        <FormContainer
          $hideBottomContainer={!features.careerProfileShortVersion}
          $showShortVersion={features.careerProfileShortVersion}
        >
          <SimpleForm.Form
            onBackClick={onBackClick}
            processIndicator={{
              totalStep: TotalPageCount,
              currentStep: features.careerProfileShortVersion ? 2 : 0,
            }}
          >
            <BoxWrapper>
              <Box
                onClick={() => {
                  setShowResumeSelect(true)
                  trackInternalEvent('visit_app_resume_selection_popup', {
                    label: 'total_job_search_solution',
                  })
                }}
              >
                <FakeResume>
                  <Image1 src={Boston} />
                  <Image2 src={Boston2} />
                  <FadeResume />
                </FakeResume>
                <SelectionTitle $showShortVersion={features.careerProfileShortVersion}>
                  {i18n.t(
                    `${TRANSLATION}.select_from_builder`,
                    features.careerProfileShortVersion ? { host: getHost() } : undefined,
                  )}
                </SelectionTitle>
                <Description $showShortVersion={features.careerProfileShortVersion}>
                  {i18n.t(
                    `${TRANSLATION}.choose_resume`,
                    features.careerProfileShortVersion ? { host: getHost() } : undefined,
                  )}
                </Description>
              </Box>
              <Box>
                <UploadFromDevice htmlFor="auto-apply-file-resume-upload">
                  <UploadResumeContainer $showShortVersion={features.careerProfileShortVersion}>
                    <FadeUpload $showShortVersion={features.careerProfileShortVersion} />
                    <UploadResumeImage>
                      <Icon56.UploadResume />
                    </UploadResumeImage>
                  </UploadResumeContainer>
                  <SelectionTitle withMargin>
                    {i18n.t(`${TRANSLATION}.upload_resume`)}
                  </SelectionTitle>
                  <Description withMargin $showShortVersion={features.careerProfileShortVersion}>
                    {i18n.t(`${TRANSLATION}.existing_resume`)}
                  </Description>
                  <FileInput
                    type="file"
                    onClick={handleUploadClick}
                    onChange={handleFileInputChange}
                    accept={ALLOWED_FILES}
                    id="auto-apply-file-resume-upload"
                  />
                </UploadFromDevice>
              </Box>
            </BoxWrapper>
          </SimpleForm.Form>
        </FormContainer>
        {!features.careerProfileShortVersion && !isPhone && (
          <InfoSection>
            <InfoSectionIcon />
            <InfoSectionDescription>
              {i18n.t(`${TRANSLATION}.time`)}
              <InfoSectionDescriptionBold>
                {i18n.t(`${TRANSLATION}.time_minutes`)}
              </InfoSectionDescriptionBold>
            </InfoSectionDescription>
          </InfoSection>
        )}
      </Container>
      {showResumeSelect && (
        <SelectResumeModal
          title={i18n.t(`${TRANSLATION}.modal_title`)}
          onClose={() => setShowResumeSelect(false)}
          submitSelectedResume={onBuilderResumeSelect}
          isTotalJobSearchFlow
        />
      )}
    </LayoutContainer>
  )
}
