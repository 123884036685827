import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSpring, config } from '@react-spring/web'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import Logo from 'builder/components/DocumentEditor/JobPosting/Images/CompanyLogo.svg'
import {
  actions,
  AvailableAISuggestions,
  ResumeFieldSuggest,
  selectors,
} from 'builder/modules/resumeEditor'
import { selectors as uiSelectors } from 'builder/modules/ui'
import Icon from 'builder/components/Icon'
import AnchorManager from 'builder/services/AnchorManager'
import FocusManager from 'builder/services/FocusManager'
import { useI18n } from 'builder/hooks/useI18n'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { generateRandomId } from 'builder/utils/generateRandomId'
import type { ToneName } from 'builder/styles/colors'
import { FetchStatuses } from 'builder/modules/constants'
import { getEmptyCard } from 'builder/modules/resumeEditor/resumeScoreRules/sectionRule'
import { useConfig } from 'builder/hooks/useConfig'
import { camelToSnake } from 'builder/utils/camelToSnake'
import { CompanyLogo } from '../DocumentEditor/JobPosting/DataContainer/Views/Result/styles'
import ImproveResumePanel from '../ImproveResumePanel/ImproveResumePanel'
import ImproveResumeButton from '../ImproveResumePanel/components/ImproveResumeButton/ImproveResumeButton'
import useImproveResumePanel from '../ImproveResumePanel/hooks/useImproveResumePanel'
import NextSuggestion from './NextSuggestion'
import {
  Container,
  Header,
  HeaderMain,
  HeaderAside,
  Close,
  Percent,
  Hint,
  Progress,
  ProgressInner,
  SuggestionMenu,
  SuggestionList,
  Suggestion,
  PercentSuggestion,
  HeaderText,
} from './styles'
import EditView from './JobTitleSection/EditView'

export const ResumeScore = () => {
  const appConfig = useConfig()
  const isSuggestionsOpen = useTypedSelector(uiSelectors.isSuggestionsOpen)
  const isImproveResumePanelOpen = useTypedSelector(selectors.isImproveResumePanelOpen)
  const [isOpen, setOpen] = useState(false || isSuggestionsOpen)
  const isKeywordsFeaturesAvailable = appConfig?.features.keywordsInRo
  const containerRef = useRef<HTMLDivElement | null>(null)
  const listRef = useRef<HTMLDivElement | null>(null)
  const improveResumeButtonRef = useRef<HTMLDivElement>(null)
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const resume = useTypedSelector(selectors.resume)
  const { suggestions } = useTypedSelector(selectors.scoreSuggestions)
  const resumeOptimizerData = useTypedSelector(selectors.jobPostingAPIData)
  const isLinkedToJobPosting = !!useTypedSelector(
    state => state.resumeEditor.optimizerSection.jobPostingAPIData.jobPostingId,
  )
  const { isPageScrolled } = useImproveResumePanel()
  let tailoredJobTitle = ''
  const { recommendedJobTitle, employerName } = resumeOptimizerData
  const resumeScore = useTypedSelector(selectors.resumeScore) || 0

  const points = resumeOptimizerData.averageScore || resumeScore
  tailoredJobTitle =
    employerName !== 'n'
      ? [recommendedJobTitle, employerName].join(
          ' ' + i18n.t('builder.resume_editor.labels.at') + ' ',
        )
      : recommendedJobTitle || ''

  const editJobPosting = useTypedSelector(state => state.resumeEditor.editJobPosting)
  const isEditJobDetails = useTypedSelector(state => state.resumeEditor.isEditJobDetails)

  useEffect(() => {
    if (editJobPosting) {
      dispatch(actions.setFetchResumeScoreStatus(FetchStatuses.notAsked))
      dispatch(actions.setSaveJobPostingDataStatus(FetchStatuses.notAsked))
      dispatch(actions.setIsEditJobPosting(false))
    }
  }, [dispatch, editJobPosting])

  const nextSuggestion = suggestions[0]

  const [barColor] = useMemo(() => {
    const getColorByPoints = (points: number): ToneName => {
      if (points > 70) return 'green'
      if (points > 30) return 'amber'
      return 'red'
    }

    const color = getColorByPoints(points)
    return [`var(--${color}-40)`, `var(--${color}-50)`]
  }, [points])

  const props = useSpring({
    config: config.slow,
    from: { points: 0 },
    to: { points: Math.min(points, 100) },
  })

  useClickOutside(containerRef, () => setOpen(false))

  const handleSuggestionClick = useCallback(
    (suggestion: ResumeFieldSuggest) => {
      setOpen(false)
      const sectionName = suggestion.sectionName

      if (suggestion.type === 'section' && sectionName) {
        if (sectionName === 'skills' && recommendedJobTitle) {
          dispatch(actions.setOpenedAISuggestionsPopup(AvailableAISuggestions.skills))
        } else {
          const emptyCard = resume && getEmptyCard(sectionName, resume)
          if (emptyCard) {
            const cardId = emptyCard.cid || emptyCard.id
            dispatch(actions.openCard(cardId))
            AnchorManager.scrollToAnchor(cardId)
          } else {
            dispatch(
              actions.addCard({
                sectionName,
                cardId: generateRandomId(),
                options: { scrollIntoViewport: true, shouldOpen: true },
              }),
            )
          }
        }
        trackInternalEvent('click_suggestion_recommendations_panel', {
          tailored: !!isLinkedToJobPosting,
          score: resumeScore,
          suggestion_type: camelToSnake(sectionName),
        })
      }

      if (suggestion.type === 'simpleField' && suggestion.fieldId) {
        if (suggestion.fieldId === 'position') {
          dispatch(actions.setOpenedAISuggestionsPopup(AvailableAISuggestions.jobTitle))
        }
        AnchorManager.scrollToAnchor(suggestion.sectionId || suggestion.fieldId)
        FocusManager.focus(suggestion.fieldId)
        trackInternalEvent('click_suggestion_recommendations_panel', {
          tailored: !!isLinkedToJobPosting,
          score: resumeScore,
          suggestion_type: camelToSnake(suggestion.fieldId),
        })
      }
    },
    [dispatch, isLinkedToJobPosting, recommendedJobTitle, resume, resumeScore],
  )

  useEffect(() => {
    setOpen(isSuggestionsOpen)
  }, [isSuggestionsOpen])

  const getSuggestionScore = useCallback(
    (suggestion: ResumeFieldSuggest) => {
      const requiredScore = 100 - resumeScore

      if (suggestion.points > requiredScore) {
        suggestion.points = requiredScore
      }
      if (isLinkedToJobPosting) {
        return suggestion.key === 'position_8' || suggestion.sectionName === 'skills'
          ? suggestion.points
          : Math.ceil(suggestion.points / 2)
      } else {
        return suggestion.points
      }
    },
    [resumeScore, isLinkedToJobPosting],
  )

  const handleToggleSuggestionMenu = useCallback(() => {
    if (!isOpen) {
      setOpen(true)
      trackInternalEvent('click_improve_resume_recommendations_panel', {
        tailored: isLinkedToJobPosting,
        score: resumeScore,
      })
    } else {
      setOpen(false)
      trackInternalEvent('click_close_recommendations_panel', {
        tailored: isLinkedToJobPosting,
        score: resumeScore,
      })
    }
  }, [isLinkedToJobPosting, isOpen, resumeScore])

  const isJobpostingData = recommendedJobTitle
  const isSuggestions = resumeScore < 100

  return (
    <Container ref={containerRef} isPageScrolled={isPageScrolled}>
      <Header>
        <HeaderMain>
          <Percent points={points}>{props.points.to(value => value.toFixed(0))}</Percent>
          {isJobpostingData ? (
            <>
              <CompanyLogo style={{ marginRight: '8px' }} size={20} src={Logo} />
              <EditView tailoredJobTitle={tailoredJobTitle} />
            </>
          ) : (
            <HeaderText>{i18n.t('builder.resume_tuner.banner.dashboard_score')}</HeaderText>
          )}
        </HeaderMain>
        {!isEditJobDetails &&
          (isKeywordsFeaturesAvailable ? (
            <HeaderAside>
              <ImproveResumeButton ref={improveResumeButtonRef} />
            </HeaderAside>
          ) : (
            <HeaderAside>
              {nextSuggestion && isSuggestions && (
                <NextSuggestion
                  suggestion={nextSuggestion}
                  onClick={() => handleSuggestionClick(nextSuggestion)}
                />
              )}

              {isOpen ? (
                <Close onClick={handleToggleSuggestionMenu}>
                  <Icon.HintClose />
                </Close>
              ) : (
                isSuggestions && (
                  <Hint onClick={handleToggleSuggestionMenu}>
                    <Icon.Hint />
                  </Hint>
                )
              )}
            </HeaderAside>
          ))}
      </Header>

      <Progress>
        <ProgressInner
          style={{
            transform: props.points.to(value => `scaleX(${value / 100})`),
            backgroundColor: barColor,
          }}
        />
      </Progress>

      {isImproveResumePanelOpen && isKeywordsFeaturesAvailable && (
        <ImproveResumePanel improveResumeButtonRef={improveResumeButtonRef} />
      )}

      {isOpen && (
        <SuggestionMenu>
          <SuggestionList ref={listRef}>
            {suggestions.map(suggestion => (
              <Suggestion key={suggestion.key} onClick={() => handleSuggestionClick(suggestion)}>
                <PercentSuggestion>+{getSuggestionScore(suggestion)}</PercentSuggestion>
                {suggestion.text}
              </Suggestion>
            ))}
          </SuggestionList>
        </SuggestionMenu>
      )}
    </Container>
  )
}
