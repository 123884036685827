import { createAction, createReducer } from '@reduxjs/toolkit'
import type { Optional } from '@rio/types'
import { Store } from 'builder/modules/store'
import { FetchStatuses } from 'builder/modules/constants'

import { UIStatesConfigStore } from '../panel'
import {
  UserStore,
  UserData,
  UserSocialProfileProvider,
  AccountApiSuccessResponse,
  ISmartBannerOrder,
} from './types'
import { createProfilePictureSelector } from './helpers'

// ---
// Action creators
// ---

export const actions = {
  // Setters
  setFetchStatus: createAction<FetchStatuses>('builder/SET_USER_FETCH_STATUS'),
  setUserUpdating: createAction<boolean>('builder/SET_USER_UPDATING'),
  setDiscountStatus: createAction<FetchStatuses>('builder/SET_DISCOUNT_GETTING_STATUS'),
  setDiscountError: createAction<string | null>('builder/SET_DISCOUNT_ERROR'),
  setCancellationStatus: createAction<FetchStatuses>('builder/SET_CANCELLATION_STATUS'),
  setCancellationError: createAction<Optional<string>>('builder/SET_CANCELLATION_ERROR'),
  setAccount: createAction<Omit<AccountApiSuccessResponse, 'success'>>('builder/SET_ACCOUNT'),
  setUserError: createAction<string | null>('builder/SET_USER_ERROR'),
  setSocialUpdating: createAction<{ provider: UserSocialProfileProvider; status: boolean }>(
    'builder/SET_SOCIAL_UPDATING',
  ),
  setUserJwt: createAction<{ jwt: string; jwtExpiresAt: string }>('builder/SET_USER_JWT'),
  setPauseSubscriptionStatus: createAction<FetchStatuses>('builder/SET_PAUSE_SUBSCRIPTION_STATUS'),
  setSubmitFeedbackStatus: createAction<FetchStatuses>('builder/SET_SUBMIT_FEEDBACK_STATUS'),
  setIsUserLoggingFirstTime: createAction<boolean>('builder/SET_IS_USER_LOGGING_FIRST_TIME'),
  setIsResumeDownloaded: createAction<boolean>('builder/SET_IS_RSUME_DOWNLOADED'),

  // Requests
  fetchUserRequest: createAction<{ silent?: boolean } | undefined>('builder/FETCH_USER_REQUEST'),
  updateUserRequest: createAction<Partial<UserData>>('builder/UPDATE_USER_REQUEST'),
  deleteUserRequest: createAction('builder/DELETE_USER_REQUEST'),
  cancelUserSubscription: createAction<Record<string, string>>('builder/CANCEL_USER_SUBSCRIPTION'),
  pauseUserSubscription: createAction<{ redirectUrl?: string; onLoaded?: () => void } | undefined>(
    'builder/PAUSE_USER_SUBSCRIPTION',
  ),
  toggleEmailNotification: createAction<{ name: string; value: boolean }>(
    'builder/TOGGLE_USER_EMAIL_NOTIFICATION',
  ),
  extendJwtRequest: createAction<{ token: string }>('builder/EXTEND_USER_JWT_REQUEST'),
  disconnectSocialRequest: createAction<UserSocialProfileProvider>('builder/DISCONNECT_SOCIAL'),
  getDiscountNew: createAction('builder/GET_DISCOUNT_NEW'),
  getAbandonedDiscount: createAction('builder/GET_ABANDONED_DISCOUNT'),
  submitFeedbackForSubscriptionCancellation: createAction<{
    email: string
    name: string
    feedback: string
    plan?: string
    subject?: string
  }>('builder/CANCEL_SUBSCRIPTION_SUBMIT_FEEDBACK'),
  fetchFirstTimeLoginStatus: createAction('builder/FETCH_FIRST_TIME_LOGIN_STATUS'),
  updateSmartBannerOrderRequest: createAction<ISmartBannerOrder[]>(
    'builder/UPDATE_SMART_BANNER_ORDER_REQUEST',
  ),
  setUIUserState: createAction<UIStatesConfigStore>('builder/SET_UI_USER_STATE'),

  // User sign in Carrier.io
  loginToSuperApp: createAction('builder/LOGIN_TO_SUPERAPP'),
  setSuperAppLoginStatus: createAction<FetchStatuses>('builder/SET_SUPERAPP_LOGIN_STATUS'),

  // AI profile
  decrementAiProfileAttempts: createAction('builder/DECREASE_AI_PROFILE_ATTEMPTS'),
}

// ---
// Selectors
// ---
export const selectors = {
  isFetched: (state: Store) => state.user.fetchStatus === FetchStatuses.loaded,
  availableTopResumeReview: (state: Store) => state.user.data?.availableTopResumeReview,
  premiumAccess: (state: Store) => state.user.data?.hasPremiumFeatures ?? false,
  getUpgradeUrl: (state: Store) => state.user.data?.billingInfo.upgradeUrl,
  userData: (state: Store) => state.user.data,
  postPremium: (state: Store) => state.user.data?.billingInfo?.userType,
  isRioUser: (state: Store) => state.user.data?.isRioUser,
  photoUrl: createProfilePictureSelector((state: Store) => state.user.data?.profilePicture),
  superAppLoginStatus: (state: Store) => state.user.superAppLoginStatus,
  pauseSubscriptionStatus: (state: Store) => state.user.pauseSubscriptionStatus,
  cancellationStatus: (state: Store) => state.user.cancellationStatus,
  discountStatus: (state: Store) => state.user.discountStatus,
  discountErrorText: (state: Store) => state.user.discountErrorText,
  feedbackStatus: (state: Store) => state.user.submitFeedbackStatus,
  isUserLoggingFirstTime: (state: Store) => state.user.isUserLoggingFirstTime,
  isResumeDownloaded: (state: Store) => state.user.isResumeDownloaded,
  displayCioPromoModal: (state: Store) => state.user.data?.showCioPromoModal,
  smartBannersOrder: (state: Store) => state.user.data?.smartBannerOrder,
  showAbandonmentFlows: (state: Store) => state.user.data?.showAbandonmentFlows,
  aiProfileAttempts: (state: Store) => state.user.data?.aiProfileAttempts,
  aiProfileFreeAttempts: (state: Store) => state.user.data?.aiProfileFreeAttempts,
}

// ---
// Reducer
// ---

const initialState: UserStore = {
  fetchStatus: FetchStatuses.notAsked,
  isUpdating: false,
  discountStatus: FetchStatuses.notAsked,
  discountErrorText: null,
  cancellationStatus: FetchStatuses.notAsked,
  cancellationError: null,
  socialUpdating: {
    facebook: false,
    linkedin: false,
    google_oauth2: false,
  },
  data: null,
  jwt: null,
  jwtExpiresAt: null,
  error: null,
  superAppLoginStatus: FetchStatuses.notAsked,
  pauseSubscriptionStatus: FetchStatuses.notAsked,
  submitFeedbackStatus: FetchStatuses.notAsked,
  isUserLoggingFirstTime: false,
  isResumeDownloaded: false,
}

export const reducer = createReducer(initialState, builder => {
  builder.addCase(actions.setFetchStatus, (draft, action) => {
    draft.fetchStatus = action.payload
  })

  builder.addCase(actions.setUserUpdating, (draft, action) => {
    draft.isUpdating = action.payload
  })

  builder.addCase(actions.setDiscountStatus, (draft, action) => {
    draft.discountStatus = action.payload
  })

  builder.addCase(actions.setCancellationStatus, (draft, action) => {
    draft.cancellationStatus = action.payload
  })

  builder.addCase(actions.setCancellationError, (draft, action) => {
    draft.cancellationError = action.payload
  })

  builder.addCase(actions.setAccount, (draft, { payload }) => {
    draft.jwt = payload.jwt
    draft.jwtExpiresAt = payload.jwtExpiresAt
    if (payload.user) draft.data = payload.user
    draft.fetchStatus = FetchStatuses.loaded
  })

  builder.addCase(actions.toggleEmailNotification, (draft, action) => {
    if (draft.data) {
      draft.data.emailNotifications[action.payload.name] = action.payload.value
    }
  })

  builder.addCase(actions.setUserJwt, (draft, action) => {
    draft.jwt = action.payload.jwt
    draft.jwtExpiresAt = action.payload.jwtExpiresAt
  })

  builder.addCase(actions.setUserError, (draft, action) => {
    draft.error = action.payload
  })

  builder.addCase(actions.setSocialUpdating, (draft, action) => {
    draft.socialUpdating[action.payload.provider] = action.payload.status
  })

  builder.addCase(actions.setSuperAppLoginStatus, (draft, action) => {
    draft.superAppLoginStatus = action.payload
  })

  builder.addCase(actions.setPauseSubscriptionStatus, (draft, action) => {
    draft.pauseSubscriptionStatus = action.payload
  })

  builder.addCase(actions.setSubmitFeedbackStatus, (draft, action) => {
    draft.submitFeedbackStatus = action.payload
  })

  builder.addCase(actions.setIsUserLoggingFirstTime, (draft, action) => {
    draft.isUserLoggingFirstTime = action.payload
  })

  builder.addCase(actions.setIsResumeDownloaded, (draft, action) => {
    draft.isResumeDownloaded = action.payload
  })

  builder.addCase(actions.setDiscountError, (draft, action) => {
    draft.discountErrorText = action.payload
  })

  builder.addCase(actions.setUIUserState, (draft, action) => {
    if (draft.data?.uiStates) {
      draft.data.uiStates = {
        ...draft.data?.uiStates,
        ...action.payload,
      }
    }
  })

  builder.addCase(actions.decrementAiProfileAttempts, draft => {
    if (!draft.data) return
    if (typeof draft.data.aiProfileFreeAttempts === 'number') {
      draft.data.aiProfileFreeAttempts -= 1
    }
  })
})
