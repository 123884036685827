import { Resume } from '@rio/types'
import { useI18n } from 'builder/hooks/useI18n'
import { useResume } from 'builder/hooks/useResume'

import { Candidate as CandidateType } from 'builder/modules/resumeDistribution'
import { CURRENCY_SIGN } from 'builder/views/ResumeDistributionView/components/JobPreferencesForm/constants'

import Icon24 from 'builder/components/Icon'
import { DocumentPreview } from 'builder/components/Panel/Document/styles'
import { formatDocumentPreviewUrl } from 'builder/utils/formatDocumentPreviewUrl'

import {
  CandidateBox,
  CandidateContainer,
  CandidateInfo,
  CandidateInfoHeader,
  CandidateInfoLabel,
  CandidateInfoText,
  CandidatePreview,
  CandidatePreviewShadow,
  CandidateResumeImage,
  IconContainer,
  TrResumeLabel,
  TrResumeLabelIcon,
  TrResumeLabelText,
} from './styles'

const TRANSLATION = 'builder.auto_apply.resume_distribution.rd_completed.loaded.candidate'

interface RdCandidateProps {
  candidate: CandidateType
}

const getIconByFileExt = (filename: string) => {
  const extMatch = /(?:\.([^.]+))?$/.exec(filename)
  const ext = extMatch && extMatch[1]

  switch (ext) {
    case 'txt':
      return <Icon24.Text width={30} height={30} />
    case 'pdf':
      return <Icon24.Pdf width={30} height={30} />
    case 'doc':
    case 'docx':
      return <Icon24.Word width={30} height={30} />
    default:
      return <></>
  }
}

export const Candidate = ({ candidate }: RdCandidateProps) => {
  const { i18n } = useI18n()
  const resumeDoc = useResume(candidate.resumeId)

  const getThumbnailUrl = (resume: Resume) => {
    return formatDocumentPreviewUrl(resume)
  }

  return (
    <CandidateContainer>
      <CandidateBox>
        <CandidatePreview>
          <CandidateResumeImage $showPlaceholder={!resumeDoc}>
            {!resumeDoc && <IconContainer>{getIconByFileExt(candidate.fileName)}</IconContainer>}
            {resumeDoc && (
              <DocumentPreview showUnavailablePreview={false} src={getThumbnailUrl(resumeDoc)} />
            )}
          </CandidateResumeImage>
          {candidate.documentId && (
            <TrResumeLabel>
              <TrResumeLabelIcon />
              <TrResumeLabelText>{i18n.t(`${TRANSLATION}.tr_resume`)}</TrResumeLabelText>
            </TrResumeLabel>
          )}
        </CandidatePreview>
        <CandidatePreviewShadow />
      </CandidateBox>
      <CandidateBox>
        <CandidateInfo>
          <CandidateInfoText>
            <CandidateInfoLabel>{i18n.t(`${TRANSLATION}.industry`)}</CandidateInfoLabel>
            <CandidateInfoHeader>{candidate?.preferences?.industryName}</CandidateInfoHeader>
          </CandidateInfoText>
          <CandidateInfoText>
            <CandidateInfoLabel>{i18n.t(`${TRANSLATION}.location`)}</CandidateInfoLabel>
            <CandidateInfoHeader>
              {candidate.location.stateName}, {candidate.location.countryName}
            </CandidateInfoHeader>
          </CandidateInfoText>
          <CandidateInfoText>
            <CandidateInfoLabel>{i18n.t(`${TRANSLATION}.salary`)}</CandidateInfoLabel>
            <CandidateInfoHeader>{`${CURRENCY_SIGN[candidate.preferences.desiredSalary.currency]} ${
              candidate?.preferences.desiredSalary.amount
            }`}</CandidateInfoHeader>
          </CandidateInfoText>
        </CandidateInfo>
      </CandidateBox>
    </CandidateContainer>
  )
}
