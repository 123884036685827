import styled, { css } from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'

export const AUTOSUGGEST_FIELD_HEIGHT = 61

const ContainerHoverState = css`
  z-index: 1;
  transition: 0.2s all;
  box-shadow: 0px 0px 1px ${hexToRgba(Colors.Blue80, 0.32)},
    0px 2px 4px -1px ${hexToRgba(Colors.Blue80, 0.08)},
    0px 8px 20px -4px ${hexToRgba(Colors.Blue80, 0.12)};
`

export const Container = styled.div<{ withHover: boolean }>`
  position: relative;
  background-color: ${Colors.White};
  ${Media.Tablet`   width: 100%;`}
  ${p =>
    p.withHover &&
    `
    &:hover {
      ${ContainerHoverState}
    }
  `}
`
export const InputPlaceholder = styled.div<{
  $isExpanded: boolean
  $overwritePopupWidth?: number
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${AUTOSUGGEST_FIELD_HEIGHT}px;
  width: ${({ $overwritePopupWidth, $isExpanded }) =>
    $overwritePopupWidth && $isExpanded ? `${$overwritePopupWidth - 10}px` : '100%'};
  ${Media.Tablet` 
     flex-direction: column;
     width: 100%;
  `}
`

export const InputContainer = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  min-width: 100%;
  overflow: hidden;
  align-items: center;
  position: relative;
  top: 0;

  ${({ $isExpanded }) =>
    $isExpanded &&
    `  
    position: absolute;
    left:0;
    z-index: 9999;
`}
`

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
  left: 0;
  width: 100%;
  gap: ${Sizes['2XS']};
  padding-left: ${Sizes['2XS']};
  align-items: center;
`

export const Input = styled.input`
  border: none;
  height: ${AUTOSUGGEST_FIELD_HEIGHT}px;
  width: 100%;
  background-color: transparent;
`

export const IconWrapper = styled.div`
  display: flex;
`

export const RejectIconWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  padding-right: ${Sizes.S};

  height: 100%;
  align-items: center;

  & svg {
    cursor: pointer;
  }
`

export const Menu = styled.div<{ $overwritePopupWidth?: number }>`
  position: absolute;
  padding: 0 ${Sizes['2XS']};
  padding-bottom: 0;
  min-width: ${({ $overwritePopupWidth }) =>
    $overwritePopupWidth ? `${$overwritePopupWidth}px` : '100%'};
  white-space: nowrap;
  align-items: unset;
  z-index: 666;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px rgba(15, 56, 113, 0.32), 0px 2px 4px -1px rgba(15, 56, 113, 0.08),
    0px 8px 20px -4px rgba(15, 56, 113, 0.12);
  background: white;
  flex-direction: column;

  & input {
    height: 60px;
  }
  ${Media.AboveTablet`    
    top: -2px;
    left: -8px;
    padding-top: 2px;
  `}

  ${Media.Tablet`   
    top: -9px;
    left: -7px;
    right: -7px;
    min-width: 100%;
    padding-bottom: 10px;
  `}
`

export const Separator = styled.div`
  height: 1px;
  margin: 0 ${Sizes['2XS']};
  background: ${Colors.Neutral15};
`

export const SectionHeader = styled.div`
  ${Typography.Tiny}
  ${FontWeights.DemiBold}
  color: ${Colors.Neutral50};
  text-transform: uppercase;
  letter-spacing: 1.8px;
  padding-left: ${Sizes['2XS']};
  margin-top: 16px;
  margin-bottom: 4px;
`

export const Item = styled.div<{ $isHighlighted: boolean }>`
  ${Typography.Body}
  ${FontWeights.Regular}
  display: flex;
  align-items: center;
  padding: ${Sizes.XS} ${Sizes['2XS']};
  cursor: pointer;
  overflow: hidden;
  svg {
    margin-right: 8px;
    flex-shrink: 0;
  }

  ${p =>
    p.$isHighlighted &&
    css`
      background-color: ${Colors.Neutral5};
    `}
`

export const MenuInputPlaceholderPopup = styled.div`
  height: 61px;
`
