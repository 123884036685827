import { useEffect, useRef, useState } from 'react'

import { useI18n } from 'builder/hooks/useI18n'
import { ButtonTheme } from 'builder/components/Button'
import { Job } from '../../JobSearchResult/types'
import {
  Container,
  ShowMoreContainer,
  ShowMoreButton,
  ChevronIcon,
  Body,
  JOB_SUMMARY_HEIGHT,
} from './styles'

interface Props {
  job: Job
  title: React.ReactElement
}
export const JobSummary = ({ title, job }: Props) => {
  const [showCollapseButton, setShowCollapseButton] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null)

  const [isCollapsed, setIsCollapsed] = useState(true)
  const { i18n } = useI18n()

  useEffect(() => {
    setIsCollapsed(true)
  }, [job])

  useEffect(() => {
    if (containerRef.current && JOB_SUMMARY_HEIGHT >= containerRef.current.scrollHeight) {
      setShowCollapseButton(false)
    } else {
      setShowCollapseButton(true)
    }
  }, [containerRef, job])

  return (
    <Container
      $isCollapsed={isCollapsed}
      ref={containerRef}
      $showCollapseButton={showCollapseButton}
    >
      {title}
      <Body dangerouslySetInnerHTML={{ __html: job.description }} />

      {showCollapseButton && (
        <ShowMoreContainer $isCollapsed={isCollapsed}>
          <ShowMoreButton
            theme={ButtonTheme.secondary}
            onClick={() => setIsCollapsed(isCollapsed => !isCollapsed)}
          >
            {i18n.t(
              `builder.job_search.job_search_results.${isCollapsed ? 'show_more' : 'show_less'}`,
            )}
            <ChevronIcon $up={!isCollapsed} />
          </ShowMoreButton>
        </ShowMoreContainer>
      )}
    </Container>
  )
}
