import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Step, selectors } from 'builder/modules/dashboard'

import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useChromeExtension } from 'builder/features/navigation/hooks/useChromeExtension'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { PanelProps } from 'builder/components/Panel/types'
import DocumentsSection from 'builder/components/Panel/DocumentsSection'
import JobTrackingView from 'builder/views/JobTrackingView'

import {
  Container,
  DocumentsContainer,
  JobSearchContainer,
  JobTrackerButton,
  JobTrackerContainer,
  JobTrackerContent,
  JobTrackerShadow,
  ListExpand,
  MenuTabs,
  NavTab,
  TabContainer,
} from './styles'
import { ActiveTab, ActiveTabs, Tabs } from './types'

const USER_TAB_SELECTION = 'dashboard_widget_tab'
const DEFAULT_TABS_STATE: ActiveTabs = {
  documents: false,
  jobTracker: false,
  jobSearch: false,
}

export const DashboardWidget = (props: PanelProps) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { isPhone, isTablet } = useMediaQueries()
  const { isInstalled, canInstallExtension } = useChromeExtension()
  const [tabs, setTabs] = useState<ActiveTabs>(DEFAULT_TABS_STATE)
  const stepsData = useTypedSelector(selectors.stepsData)
  const stepsAreLoaded = useTypedSelector(selectors.isLoaded)

  const handleTabsSwitch = (tab: ActiveTab) => {
    setTabs({ ...DEFAULT_TABS_STATE, [tab]: true })
  }

  const handleOnClickTab = (tab: ActiveTab) => {
    handleTabsSwitch(tab)
    localStorage.setItem(USER_TAB_SELECTION, tab)
  }

  const handleOnClickViewMore = () => {
    navigate('/job-tracking')
  }

  const selectedDefaultTab = useCallback(() => {
    const userSelection = localStorage.getItem(USER_TAB_SELECTION)
    const isJobTracker = !userSelection && stepsData[Step.PERSONALIZED_JOBS].completed
    const userSelectedTab = (userSelection as keyof ActiveTabs | null) || 'documents'

    handleTabsSwitch(isJobTracker ? 'jobTracker' : userSelectedTab)
  }, [stepsData])

  useEffect(() => {
    if (!isPhone && !isTablet) {
      selectedDefaultTab()
    }
  }, [stepsAreLoaded, selectedDefaultTab])

  useEffect(() => {
    if (isPhone || isTablet) {
      handleTabsSwitch('documents')
    }
  }, [isPhone, isTablet])

  const TABS: Tabs[] = [
    { id: 'documents', name: 'Documents' },
    // { id: 'jobSearch', name: 'Job Search Help' },
  ]

  if (!isPhone && !isTablet) {
    TABS.push({ id: 'jobTracker', name: 'Job Tracker' })
  }

  return (
    <Container>
      <MenuTabs disableSelector>
        {TABS.map(tab => (
          <NavTab
            id={tab.id}
            key={tab.id}
            isActive={tabs[tab.id]}
            onClick={() => handleOnClickTab(tab.id)}
          >
            {tab.name}
          </NavTab>
        ))}
      </MenuTabs>

      <TabContainer>
        {tabs.documents && (
          <DocumentsContainer>
            <DocumentsSection
              title=""
              type={props.documentType}
              onTypeChange={props.onDocumentTypeChange}
              fromDashboard
              newDashHeader
            />
          </DocumentsContainer>
        )}

        {tabs.jobTracker && !isPhone && !isTablet && (
          <JobTrackerContainer>
            <JobTrackerContent withBanner={!isInstalled && canInstallExtension}>
              <JobTrackingView fromDashboard />
            </JobTrackerContent>
            <JobTrackerShadow />
            <JobTrackerButton>
              <ListExpand onClick={handleOnClickViewMore}>
                {i18n.t('builder.dashboard_v2.job_tracker_widget.view_more')}
              </ListExpand>
            </JobTrackerButton>
          </JobTrackerContainer>
        )}

        {tabs.jobSearch && (
          <JobSearchContainer>
            {/* Put the job search help component in this container */}
          </JobSearchContainer>
        )}
      </TabContainer>
    </Container>
  )
}
