import { useRef } from 'react'
import PropTypes from 'prop-types'
import times from 'lodash/times'
import { useSelector } from 'react-redux'
import { selectors as userSelectors } from 'builder/modules/user'
import { numberToDelimited } from 'builder/utils/numberToDelimited'
import { useBodyScrollLock } from 'builder/hooks/useBodyScrollLock'
import History from './History'
import { useGeneralStats } from './hooks'
import { translate, formatDescriptionHtml } from './utils'
import { FetchStatuses } from 'builder/modules/constants'

import {
  Container,
  Header,
  Title,
  Description,
  Numbers,
  Number,
  NumberValue,
  NumberDiff,
  NumberLabel,
  TextPlaceholder,
} from './styles'

const Sharing = ({ resumeId }) => {
  const hasPremium = useSelector(userSelectors.premiumAccess)
  const { status, viewsLastWeek, viewsTotal, downloadsTotal } = useGeneralStats({ resumeId })
  const loaded = status === FetchStatuses.loaded
  const containerRef = useRef()

  useBodyScrollLock(containerRef)

  return (
    <Container ref={containerRef}>
      <Header>
        <Title>{translate('title')}</Title>
        {!loaded && (
          <Description>
            <TextPlaceholder size={45} />
            <br />
            <TextPlaceholder size={25} />
          </Description>
        )}
        {loaded && (
          <Description
            dangerouslySetInnerHTML={{
              __html: formatDescriptionHtml(viewsTotal, viewsLastWeek),
            }}
          />
        )}

        {hasPremium && !loaded && (
          <Numbers>
            {times(2, index => (
              <Number key={index}>
                <NumberValue>
                  <TextPlaceholder size={8} />
                </NumberValue>
                <NumberLabel>
                  <TextPlaceholder size={8} />
                </NumberLabel>
              </Number>
            ))}
          </Numbers>
        )}

        {hasPremium && loaded && (
          <Numbers>
            <Number>
              <NumberValue>
                {numberToDelimited(viewsTotal)}
                <NumberDiff stat="views" isActive={viewsLastWeek} value={translate('recent_views')}>
                  +{viewsLastWeek}
                </NumberDiff>
              </NumberValue>
              <NumberLabel>{translate('views')}</NumberLabel>
            </Number>
            <Number>
              <NumberValue>
                {numberToDelimited(downloadsTotal)}
                <NumberDiff
                  stat="downloads"
                  isActive={downloadsTotal}
                  value={translate('viewers_downloaded')}
                >
                  {viewsTotal ? Math.round((downloadsTotal / viewsTotal) * 100) : 0}%
                </NumberDiff>
              </NumberValue>
              <NumberLabel>{translate('downloads')}</NumberLabel>
            </Number>
          </Numbers>
        )}

        {!hasPremium && (
          <Numbers>
            <Number>
              <NumberValue locked />
              <NumberLabel>{translate('views')}</NumberLabel>
            </Number>
            <Number>
              <NumberValue locked />
              <NumberLabel>{translate('downloads')}</NumberLabel>
            </Number>
          </Numbers>
        )}
      </Header>

      <History resumeId={resumeId} hasPremium={hasPremium} />
    </Container>
  )
}

Sharing.propTypes = {
  resumeId: PropTypes.number.isRequired,
}

export default Sharing
