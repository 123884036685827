import { useDispatch } from 'react-redux'
import { memo, useCallback, useEffect, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n, i18n } from 'builder/utils/i18n'
import { ButtonSize } from 'builder/components/Button'
import starWhite from 'builder/components/GenerateAICVBanner/StarWhite.svg'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { actions } from 'builder/modules/resumeEditor'
import Logo from 'builder/components/DocumentEditor/JobPosting/Images/CompanyLogo.svg'
import { EditFields } from 'builder/components/ResumeScore/JobTitleSection/EditJobDetails/types'
import { InputChangeEvent } from 'builder/modules/ui'
import { FetchStatuses } from 'builder/modules/constants'
import { Star } from 'builder/components/GenerateAICVBanner/styles'
import { hostURL } from '../JobPosting/constants'
import {
  ButtonWrapper,
  Cancel,
  CloseIcon,
  CompanyDesignation,
  CompanyLogo,
  Container,
  Content,
  DataWrapper,
  EditButtonWrapper,
  EditContainer,
  EditIcon,
  Hostname,
  InputField,
  JobDetailsWrapper,
  RefreshDetailsIcon,
  GenerateButton,
  Revert,
  Save,
  Title,
  TryAnotherLink,
  Wrapper,
} from './styles'

const AiCoverletterTailoredView = () => {
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const jobPostingAPIData = useTypedSelector(
    state => state.resumeEditor.optimizerSection.jobPostingAPIData,
  )
  const resumeId = useTypedSelector(state => state.resumeEditor.resume?.id)
  const AICoverLetter = useTypedSelector(state => state.resumeEditor.AICoverLetter)
  const { isClickedOnAiCVEditJobDetails, generateAICoverLetterStatus } = AICoverLetter
  const { recommendedJobTitle, employerName, jobPostingLink } = jobPostingAPIData
  const [newJobTitle, setNewJobTitle] = useState(recommendedJobTitle)
  const [newEmployerName, setNewEmployerName] = useState(employerName)
  const buttonSize = !isPhone ? ButtonSize.default : ButtonSize.small
  const jobPostingHostName = jobPostingLink ? jobPostingLink.replace(hostURL, '$3') : ''
  const hostName = jobPostingHostName
    ? i18n.t('builder.ai_cover_letter.from') + ' ' + jobPostingHostName
    : ''
  const tailoredDesignation =
    employerName && employerName === null
      ? recommendedJobTitle
      : [recommendedJobTitle, employerName].join(
          ' ' + i18n.t('builder.resume_editor.labels.at') + ' ',
        )

  const handleEditClick = useCallback(() => {
    dispatch(actions.setIsClickedOnAiCVEditJobDetails(true))
    trackInternalEvent('click_edit_job_posting', { label: 'job_posting_selection_screen' })
  }, [dispatch])

  const handleTryAgain = () => {
    dispatch(actions.setOpenAICVOptimizerModal(true))
    dispatch(actions.setIsClickedOnAiCVBanner(false))
    dispatch(actions.setGenerateAICoverLetterStatus(FetchStatuses.notAsked))
    trackInternalEvent('click_another_link', { label: 'job_posting_selection_screen' })
  }

  const handleCancelEditJobDetails = useCallback(() => {
    dispatch(actions.setIsClickedOnAiCVEditJobDetails(false))
    setNewJobTitle(recommendedJobTitle)
    setNewEmployerName(employerName)
  }, [dispatch, employerName, recommendedJobTitle])

  const handleAICVClose = () => {
    dispatch(actions.setGenerateAICoverLetterStatus(FetchStatuses.notAsked))
    dispatch(actions.setIsClickedOnAiCVBanner(false))
  }

  const handleGenerateAICV = useCallback(() => {
    dispatch(actions.setGenerateAICoverLetterStatus(FetchStatuses.loading))
    if (resumeId) {
      dispatch(actions.generateTailoredAICoverLetter({ resumeId }))
    }
    trackInternalEvent('click_generate_cover_letter', { label: 'job_posting_selection_screen' })
  }, [dispatch, resumeId])

  const handleJobTitleChange = (e: InputChangeEvent) => {
    setNewJobTitle(e.target.value)
  }

  const handleCompanyNameChange = (e: InputChangeEvent) => {
    setNewEmployerName(e.target.value)
  }

  const MapEditFields = [
    {
      key: EditFields.jobTitle,
      label: i18n.t('builder.resume_optimizer.edit_job_details.job_title'),
      value: newJobTitle,
      onValueChange: handleJobTitleChange,
    },
    {
      key: EditFields.companyName,
      label: i18n.t('builder.resume_optimizer.edit_job_details.company_name'),
      value: newEmployerName,
      onValueChange: handleCompanyNameChange,
    },
  ]

  useEffect(() => {
    setNewJobTitle(recommendedJobTitle)
    setNewEmployerName(employerName)
  }, [recommendedJobTitle, employerName])

  const isRefresh = newJobTitle !== recommendedJobTitle || newEmployerName !== employerName
  const isSaveButtonDisabled = !newJobTitle || !newEmployerName

  const handleRefresh = useCallback(() => {
    setNewJobTitle(recommendedJobTitle)
    setNewEmployerName(employerName)
    trackInternalEvent('click_revert_job_title_resume_optimizer')
    dispatch(actions.setIsEditJobDetailsFocused(false))
  }, [dispatch, employerName, recommendedJobTitle])

  const onSaveJobDetails = useCallback(() => {
    if (resumeId) {
      dispatch(
        actions.editJobDetails({
          jobTitle: newJobTitle || '',
          companyName: newEmployerName || '',
          jobPostingLink,
          resumeId,
          successMessage: i18n.t('builder.resume_optimizer.edit_job_details.success_message'),
        }),
      )
    }
    dispatch(actions.setIsEditJobDetailsFocused(false))
    dispatch(actions.setIsClickedOnAiCVEditJobDetails(false))
    trackInternalEvent('click_save_changes', { label: 'job_posting_selection_screen' })
  }, [dispatch, jobPostingLink, newEmployerName, newJobTitle, resumeId])

  const isGenerateCV = generateAICoverLetterStatus === FetchStatuses.loading

  return (
    <ModalOverlay overlayFadeDuration={300} contentSlideDuration={300}>
      <Container>
        <Wrapper>
          <Title>{I18n.t('builder.ai_cover_letter.job_tailored.title')}</Title>
          <CloseIcon
            showHoverState={!isGenerateCV}
            onClick={!isGenerateCV ? handleAICVClose : () => {}}
          />
        </Wrapper>
        <Content>{I18n.t('builder.ai_cover_letter.job_tailored.content')}</Content>
        <JobDetailsWrapper>
          <CompanyLogo src={Logo} />
          <DataWrapper>
            <Wrapper>
              <CompanyDesignation>{tailoredDesignation}</CompanyDesignation>
              {!isClickedOnAiCVEditJobDetails && !isGenerateCV && (
                <EditIcon onClick={handleEditClick} />
              )}
              {isClickedOnAiCVEditJobDetails && isRefresh && (
                <RefreshDetailsIcon isRefresh={isRefresh} onClick={handleRefresh} />
              )}
            </Wrapper>
            {hostName && <Hostname>{hostName}</Hostname>}
          </DataWrapper>
        </JobDetailsWrapper>
        {isClickedOnAiCVEditJobDetails && (
          <EditContainer>
            {MapEditFields.map(field => (
              <InputField
                key={field.key}
                label={field.label}
                value={field.value || ''}
                onChange={field.onValueChange}
              />
            ))}
          </EditContainer>
        )}

        <ButtonWrapper>
          <TryAnotherLink
            isDisabled={isGenerateCV}
            onClick={!isGenerateCV ? handleTryAgain : () => {}}
          >
            <Revert />
            {I18n.t('builder.ai_cover_letter.try_another_link')}
          </TryAnotherLink>
          {!isClickedOnAiCVEditJobDetails ? (
            <GenerateButton onClick={handleGenerateAICV} disabled={isGenerateCV}>
              <Star showAnimation={isGenerateCV} src={starWhite} alt="Star Image" />
              {I18n.t(
                isGenerateCV
                  ? 'builder.ai_cover_letter.generating'
                  : 'builder.ai_cover_letter.generate',
              )}
            </GenerateButton>
          ) : (
            <EditButtonWrapper>
              <Cancel size={buttonSize} theme="ghost" onClick={handleCancelEditJobDetails}>
                {i18n.t('builder.resume_optimizer.job_posting.cancel')}
              </Cancel>
              <Save size={buttonSize} onClick={onSaveJobDetails} isDisabled={isSaveButtonDisabled}>
                {i18n.t('builder.resume_optimizer.edit_job_details.save')}
              </Save>
            </EditButtonWrapper>
          )}
        </ButtonWrapper>
      </Container>
    </ModalOverlay>
  )
}

export default memo(AiCoverletterTailoredView)
