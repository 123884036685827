import {
  useLayoutEffect,
  useCallback,
  useRef,
  useContext,
  useState,
  useMemo,
  useEffect,
  forwardRef,
} from 'react'
import PropTypes from 'prop-types'
import { trackInternalEvent } from '@rio/tracking'
import useDebouncedEffect from 'use-debounced-effect'
import { FetchStatuses } from 'builder/modules/constants'
import { apiClient } from 'builder/modules/apiClient'
import Icon, { Icon20 } from 'builder/components/Icon'
import { reflow } from 'builder/utils/reflow'
import { useI18n } from 'builder/hooks/useI18n'
import { useEscapeHandler } from 'builder/hooks/useEscapeHandler'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import useSearch from './hooks/useSearch'
import Placeholder from './Placeholder'
import Phrase from './Phrase'
import { RichTextAreaContext } from 'builder/context/RichTextAreaContext'

import {
  Container,
  Header,
  CloseButton,
  SearchIcon,
  SearchField,
  SearchSpinner,
  Content,
  Tags,
  Tag,
  Note,
  Scroll,
  Group,
  GroupTitle,
} from './styles'
import { useForwardedRef } from 'builder/hooks/useForwardedRef'
import { useClickOutsideExcept } from 'builder/hooks/useClickOutsideExcept'

const PhrasesSuggestion = (props, ref) => {
  const { scope, locale, text, wide, outsideOpenerRef, onSelect, editorContainerRef } = props
  const { i18n } = useI18n()
  const [isVisible, setVisible] = useState(false)
  const container = useForwardedRef(ref)
  const scroll = useRef()

  const interactionState = useRef({
    phrase_added: false,
    search_used: false,
    section: scope,
    search_queries: {},
    added_phrase_ids: [],
    tips_queries: [],
  })

  const {
    jobTitle,
    toggleSuggestions,
    suggestionQuery,
    onChangeSuggestionQuery,
    isSuggestionsOpen,
  } = useContext(RichTextAreaContext)
  const search = useSearch({ scope, locale, query: suggestionQuery })
  const { status, result, categories, categoryFilter, foundAnything, performSearch } = search
  const shouldDisplayPlaceholder = result.length === 0 && status !== FetchStatuses.loaded

  // Start entering transition
  useLayoutEffect(() => {
    reflow(container.current)
    setVisible(true)
  }, [])

  // Pre-fills query with the current job title on mount
  useEffectOnMount(() => {
    if (!suggestionQuery && jobTitle) {
      onChangeSuggestionQuery(jobTitle)
    }
  })

  // Scroll the list to top if results are changed
  useLayoutEffect(() => {
    scroll.current.scrollTop = 0
  }, [result])

  // Perform search immediately if user clicked to category
  const handleCategoryClick = useCallback(
    category => {
      interactionState.current.tips_queries.push(category)
      onChangeSuggestionQuery(category)
      performSearch(category)
    },
    [performSearch, onChangeSuggestionQuery],
  )

  const handleItemClick = useCallback(
    item => {
      onSelect(item)
      // Track phrase usage to offer a better list of most popular phrases
      apiClient.post(`/phrases/${item.digest}/use?locale=${locale}`)

      interactionState.current.phrase_added = true
      interactionState.current.added_phrase_ids.push(item.digest)
    },
    [onSelect, locale],
  )

  const handleSearch = useCallback(
    event => onChangeSuggestionQuery(event.target.value),
    [onChangeSuggestionQuery],
  )

  useDebouncedEffect(
    () => {
      const query = search.query.trim()

      if (query.length) {
        const phraseCount = foundAnything ? result.reduce((acc, g) => acc + g.phrases.length, 0) : 0

        interactionState.current.search_used = true
        interactionState.current.search_queries[query] = phraseCount
      }
    },
    500,
    [search.query, scope, foundAnything, result],
  )

  useClickOutsideExcept(container, [outsideOpenerRef, editorContainerRef], () => {
    if (isSuggestionsOpen) {
      toggleSuggestions({ isOpen: false })
    }
  })

  useEscapeHandler(() => toggleSuggestions({ isOpen: false }))

  useEffect(() => {
    const eventParams = interactionState.current

    return () => trackInternalEvent('close_pre_written_phrases_dialog', eventParams)
  }, [])

  // Mark phrases and subphrases as active if they're present in the rich text
  const normalizedResult = useMemo(() => {
    const processPhrase = item => {
      let subphrases = item.subphrases ? item.subphrases.map(processPhrase) : []
      // Link subphrases with the parent
      subphrases = subphrases.map(s => ({ ...s, isSubphrase: true }))
      // The entire phrase text is added to the content
      const phraseTextAdded = text.includes(item.phrase)
      // All subphrases were used to fill the content
      const allSubphrasesAdded = subphrases.length > 0 && subphrases.every(s => s.isActive)
      // Override and extend phrase/subphrase fields
      return { ...item, subphrases, isActive: phraseTextAdded || allSubphrasesAdded }
    }

    return result.map(group => ({
      ...group,
      phrases: group.phrases.map(processPhrase),
    }))
  }, [result, text])

  return (
    <Container id="suggestionPanel" ref={container} isVisible={isVisible} wide={wide}>
      <Header>
        <SearchIcon />
        <SearchField
          placeholder={i18n.t('builder.resume_editor.phrase_suggestion.search_placeholder')}
          onChange={handleSearch}
          value={suggestionQuery}
        />
        {status !== FetchStatuses.loaded && <SearchSpinner />}
        <CloseButton onClick={() => toggleSuggestions({ isOpen: false })}>
          <Icon.Close />
        </CloseButton>
      </Header>

      <Content>
        {categories.length > 0 && (
          <Tags>
            {categories.map((category, index) => (
              <Tag
                key={index}
                isActive={categoryFilter === category}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </Tag>
            ))}
          </Tags>
        )}

        {foundAnything === false && (
          <Note>{i18n.t('builder.resume_editor.phrase_suggestion.no_results')}</Note>
        )}

        <Scroll ref={scroll}>
          {shouldDisplayPlaceholder && <Placeholder />}

          {!shouldDisplayPlaceholder &&
            normalizedResult.map((group, index) => (
              <Group key={index}>
                <GroupTitle groupType={group.groupType}>
                  {group.groupType === 'most_popular' && <Icon20.Crown />}
                  {group.groupTitle}
                </GroupTitle>
                {group.phrases.map(item => (
                  <Phrase
                    key={item.digest}
                    isActive={text.includes(item.phrase)}
                    item={item}
                    onClick={handleItemClick}
                  />
                ))}
              </Group>
            ))}
        </Scroll>
      </Content>
    </Container>
  )
}

PhrasesSuggestion.propTypes = {
  scope: PropTypes.string.isRequired,
  locale: PropTypes.string,
  text: PropTypes.string,
  wide: PropTypes.bool,
  outsideOpenerRef: PropTypes.shape({ current: PropTypes.object }),
  editorContainerRef: PropTypes.shape({ current: PropTypes.object }),
  onSelect: PropTypes.func,
}

PhrasesSuggestion.defaultProps = {
  locale: 'en',
  text: '',
  wide: false,
  outsideOpenerRef: null,
  editorContainerRef: null,
  onSelect: () => {},
}

export default forwardRef(PhrasesSuggestion)
