import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Button from 'builder/components/Button'
import Media from 'builder/styles/media'

export const Title = styled.div`
  /* Desktop and Tablet/Subhead • DemiBold */
  ${Typography.Subhead}
  ${FontWeights.DemiBold}

  ${Media.Phone`
    /* Mobile/S • DemiBold */
    ${Typography.S};
  `}
`
export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  ${Media.Phone`
  margin-top: 12px;
  `}
`

export const PercentWrapper = styled.div`
  padding: 10px 5px;
  background: ${Colors.Green10};
  border-radius: 8px;
  margin-right: 12px;
  width: fit-content;
  height: 40px;
`

export const PercentNeeded = styled.div`
  ${Typography.Tiny};
  ${FontWeights.DemiBold}
  border-radius: 4px;
  color: ${Colors.White};
  font-variant-numeric: tabular-nums;
  padding: 2px 3px;
  background: ${Colors.Green40};

  &:before {
    content: '+';
  }

  &:after {
    content: '%';
  }
`

export const Content = styled.div`
  /* Desktop and Tablet/Caption • Regular */
  ${Typography.Caption}
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;

  ${Media.Phone`
    margin-top: 14px;
  `}
`

export const TryAgainButton = styled(Button)(({ isDisabled }) => {
  return css`
    /* Desktop and Tablet/Body • Medium */
    ${Typography.Body}
    ${FontWeights.Medium};
    color: ${!isDisabled ? Colors.Blue50 : Colors.Neutral30};
    background: ${Colors.White};

    &:hover {
      color: ${!isDisabled ? Colors.Blue60 : Colors.Neutral30};
      background: ${Colors.White};
    }
    svg {
      position: unset !important;
    }
    span {
      margin-left: unset !important;
    }
  `
})

export const StartImprovingButton = styled(Button)`
  /* Desktop and Tablet/Body • Medium */
  ${Typography.Body}
  ${FontWeights.Medium};
`
