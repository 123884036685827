import parseISO from 'date-fns/parseISO'
import sortBy from 'lodash/sortBy'
import { createAction, createReducer } from '@reduxjs/toolkit'
import type { UserDocumentType } from '@rio/types'
import { Store } from 'builder/modules/store'
import { FetchStatuses, DocumentTypes, ResumeIDStatuses } from 'builder/modules/constants'
import {
  BriefLetter,
  BriefResume,
  BriefExpertDoc,
  BriefDocumentMap,
  PanelStore,
  FetchResumeStatus,
  ResumeUpdateStatus,
  IHelperWidget,
  IWidgetsStatus,
  UIStatesConfig,
  CreateDocumentActionPayload,
  DocumentAiJobStatus,
} from './types'
import { getIntialActiveTab } from './utils'

// ---
// Action creators
// ---
export const actions = {
  sendResume: createAction<{ resumeId: number }>('builder/SEND_RESUME_TO_REVIEW'),
  fetchDocuments: createAction('builder/PANEL_FETCH_DOCUMENTS'),
  setDocuments: createAction<Array<BriefResume | BriefLetter | BriefExpertDoc>>(
    'builder/PANEL_SET_DOCUMENTS',
  ),
  setDocumentsStatus: createAction<FetchStatuses>('builder/PANEL_SET_DOCUMENTS_STATUS'),
  addDocument: createAction<BriefResume | BriefLetter | BriefExpertDoc>(
    'builder/PANEL_ADD_DOCUMENT',
  ),
  createDocument: createAction<CreateDocumentActionPayload>('builder/PANEL_CREATE_DOCUMENT'),
  copyDocument: createAction<{ id: number; type: UserDocumentType }>('builder/PANEL_COPY_DOCUMENT'),
  deleteDocument: createAction<{ id: number; type: UserDocumentType }>(
    'builder/PANEL_DELETE_DOCUMENT',
  ),
  renameDocument: createAction<{ id: number; name: string; type: UserDocumentType }>(
    'builder/PANEL_RENAME_DOCUMENT',
  ),
  renameTrDocument: createAction<{
    id: number
    title: string
    slug: string
    type: UserDocumentType
  }>('builder/PANEL_RENAME_TR_DOCUMENT'),
  setResumeStatus: createAction<ResumeIDStatuses>('builder/PANEL_SET_RESUME_STATUS'),
  fetchResumeStatus: createAction<FetchResumeStatus>('builder/PANEL_FETCH_RESUME_STATUS'),
  setResumeId: createAction<{ resumeId: number }>('builder/PANEL_SET_RESUME_ID'),
  setActiveTabID: createAction<{ tabId: number }>('builder/PANEL_SET_ACTIVE_TAB_ID'),
  setTailorResume: createAction('builder/builder/PANEL_SET_TAILOR_RESUME'),
  setOpenWelcomeModal: createAction<boolean>('builder/builder/OPEN_WELCOME_MODAL'),
  navigateBackToRIO: createAction<{
    id: number
    type: UserDocumentType
    feature: string
    extraInfo?: string
  }>('builder/NAVIGATE_BACK_TO_RIO'),
  updateResumeStatus: createAction<ResumeUpdateStatus>('builder/PANEL_UPDATE_RESUME_STATUS'),
  fetchHelperWidgets: createAction<{ widgetName: string }>('builder/PANEL_FETCH_HELPER_WIDGETS'),
  setHelperWidgets: createAction<IHelperWidget>('builder/PANEL_SET_HELPER_WIDGETS'),
  updateHelperWidgets: createAction<{ stepId: number; status: IWidgetsStatus }>(
    'builder/PANEL_UPDATE_HELPER_WIDGETS',
  ),
  fetchTRDocument: createAction<{ id: number }>('builder/FETCH_TR_DOCUMENT'),
  setTRDocument: createAction<{ document: BriefExpertDoc }>('builder/SET_TR_DOCUMENT'),
  setTRDocumentStatus: createAction<{ status: FetchStatuses; id: number }>(
    'builder/SET_TR_DOCUMENT_STATUS',
  ),
  duplicateTRDocument: createAction<{ id: number }>('builder/DUPLICATE_TR_DOCUMENT'),
  setDuplicationUrl: createAction<string>('builder/SET_DUPLICATION_URL'),
  setIsDuplicationLoading: createAction<boolean>('builder/SET_IS_DUPLICATION_LOADING'),
  setUIStatesConfigStatus: createAction<UIStatesConfig>('builder/PANEL_SET_UI_STATE_STATUS'),
  setDocumentAiJobStatus: createAction<DocumentAiJobStatus>('builder/DOCUMENT_AI_JOB_STATE_STATUS'),
}

// ---
// Selectors
// ---
export const selectors = {
  documentsStatus: (state: Store) => state.panel.documentsStatus,
  documents: (state: Store) => state.panel.documents,
  /** Finds the latest created document of the given type */
  lastCreated: <T extends DocumentTypes>(state: Store, type: T): BriefDocumentMap[T] | null => {
    const documents = state.panel.documents.filter((d): d is BriefDocumentMap[T] => d.type === type)
    const sortedDocuments = sortBy(documents, resume => -parseISO(resume.createdAt).getTime())
    return sortedDocuments.length ? sortedDocuments[0] : null
  },
  /** Detect a resume to tune (find the last created resume) */
  tunerResumeId: (state: Store): number | null => {
    return selectors.lastCreated(state, DocumentTypes.resume)?.id ?? null
  },
  resumeStatus: (state: Store) => state.panel.resumeStatus,
  resumeId: (state: Store) => state.panel.resumeId,
  activeTabId: (state: Store) => state.panel.activeTabId,
  isTailorResume: (state: Store) => state.panel.isTailorResume,
  isWelcomeModelopen: (state: Store) => state.panel.isWelcomeModelopen,
  resumeUpdateStatus: (state: Store) => state.panel.resumeUpdateStatus,
  helperWidgets: (state: Store) => state.panel.helperWidgets,
  aiJobStatusById: (id: number) => (state: Store) => {
    const doc = state.panel.documents.find(doc => doc.id === id)
    return { resumeId: doc?.id, status: doc?.aiJobStatus }
  },
}

// ---
// Reducer
// ---

const initialState: PanelStore = {
  documents: [],
  documentsStatus: FetchStatuses.notAsked,
  resumeStatus: ResumeIDStatuses.skipped,
  resumeId: 0,
  activeTabId: getIntialActiveTab(),
  isTailorResume: false,
  isWelcomeModelopen: false,
  resumeUpdateStatus: ResumeUpdateStatus.Initial,
  helperWidgets: [],
  trDocuments: {},
  trDocumentsStatus: {},
  duplicationUrl: '',
  isDuplicationLoading: false,
  aiJobStatus: null,
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setDocuments, (draft, action) => {
    draft.documents = action.payload
  })

  reducer.addCase(actions.setDocumentsStatus, (draft, action) => {
    draft.documentsStatus = action.payload
  })

  reducer.addCase(actions.renameDocument, (draft, action) => {
    const { id, type, name } = action.payload
    const document = draft.documents.find(d => d.id === id && d.type === type)
    if (document) document.name = name
  })

  reducer.addCase(actions.addDocument, (draft, action) => {
    draft.documents.unshift(action.payload)
  })

  reducer.addCase(actions.deleteDocument, (draft, action) => {
    const { id, type } = action.payload
    const index = draft.documents.findIndex(d => d.id === id && d.type === type)
    draft.documents.splice(index, 1)
  })

  reducer.addCase(actions.setResumeStatus, (draft, action) => {
    draft.resumeStatus = action.payload
  })

  reducer.addCase(actions.setResumeId, (draft, action) => {
    draft.resumeId = action.payload.resumeId
  })

  reducer.addCase(actions.setActiveTabID, (draft, action) => {
    draft.activeTabId = action.payload.tabId
  })

  reducer.addCase(actions.setTailorResume, draft => {
    draft.isTailorResume = true
  })

  reducer.addCase(actions.setOpenWelcomeModal, (draft, action) => {
    draft.isWelcomeModelopen = action.payload
  })

  reducer.addCase(actions.updateResumeStatus, (draft, action) => {
    draft.resumeUpdateStatus = action.payload
  })

  reducer.addCase(actions.setHelperWidgets, (draft, action) => {
    let isAlreadyPresent = false
    draft.helperWidgets = draft.helperWidgets?.map((widget: IHelperWidget) => {
      if (widget.widgetCollectionName === action.payload.widgetCollectionName) {
        isAlreadyPresent = true
        return { ...widget, widgetsList: action.payload.widgetsList }
      }
      return widget
    })
    if (!isAlreadyPresent) {
      draft.helperWidgets?.push({
        widgetCollectionName: action.payload.widgetCollectionName,
        widgetsList: action.payload.widgetsList,
      })
    }
  })

  reducer.addCase(actions.setTRDocumentStatus, (draft, action) => {
    draft.trDocumentsStatus = {
      ...draft.trDocumentsStatus,
      [action.payload.id]: action.payload.status,
    }
  })

  reducer.addCase(actions.setTRDocument, (draft, action) => {
    draft.trDocuments = {
      ...draft.trDocuments,
      [action.payload.document.id]: action.payload.document,
    }
  })

  reducer.addCase(actions.setDuplicationUrl, (draft, action) => {
    draft.duplicationUrl = action.payload
  })

  reducer.addCase(actions.setIsDuplicationLoading, (draft, action) => {
    draft.isDuplicationLoading = action.payload
  })

  reducer.addCase(actions.setDocumentAiJobStatus, (draft, action) => {
    const document = draft.documents.find(doc => doc.id === action.payload?.id)
    if (document) {
      document.aiJobStatus = action.payload.aiJobStatus
    }
  })
})
