import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Icon24 from 'builder/components/Icon'
import Sizes from 'builder/styles/sizes'

export const Container = styled.div`
  position: sticky;
  top: 0;
  margin: 0 10px;
  margin: ${Sizes.L};
  ${Media.Tablet`
    margin: ${Sizes.S};
  `}

  ${Media.Phone`
    margin: ${Sizes.XS};
  `}
`

export const HeaderContainer = styled.div`
  display: flex;
  margin-top: 32px;
`
export const Header = styled.div`
  margin-left: 16px;
`

export const Company = styled.div`
  color: ${Colors.Neutral50};
  margin-bottom: 8px;
`

export const Position = styled.h1`
  ${Typography.S}
  ${FontWeights.DemiBold}
  margin-bottom: 8px;
`

export const Metas = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${Sizes.S};
  color: ${Colors.Neutral50};
  align-items: center;

  > :not(:last-child) {
    margin-right: 16px;
  }

  svg {
    color: ${Colors.Neutral50};
    fill: currentColor;
    margin-right: 4.1px;
  }
`

export const MetasAutoApply = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${Colors.Neutral50};
  margin-top: ${Sizes.S};
  margin-bottom: ${Sizes.M};
  align-items: center;

  > :not(:last-child) {
    margin-right: 12px;
  }

  svg {
    margin-right: 4.2px;
  }
`

export const Separator = styled.div`
  margin: 32px 0px;
  height: 1px;
  background-color: ${Colors.Neutral20};
`

export const ApplyButtonIcon = styled(Icon24.ExternalLink)`
  margin-right: 8px;
  flex: 1;
`

export const AccordionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${Sizes.XL};
  margin-bottom: ${Sizes.L};
  gap: ${Sizes.S};
`

export const Title = styled.div`
  ${Typography.Subhead}
  ${FontWeights.DemiBold}
  color: ${Colors.Neutral90};
`

export const AccordionTitleButton = styled(Link)`
  cursor: pointer;
  ${Typography.Body}
  ${FontWeights.Regular}
  color: ${Colors.Blue50};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BackButton = styled.div`
  ${Typography.Caption};
  ${FontWeights.DemiBold};
  position: sticky;
  top: 16px;
  left: 0;
  display: inline-flex;
  margin: ${Sizes.S};
  margin-left: 0;
  padding: 4px 16px 4px 8px;
  align-items: center;
  border-radius: 20px;
  background: ${Colors.Neutral10};
  align-items: center;
  color: ${Colors.Neutral50};
  z-index: 3;
  cursor: pointer;

  ${Media.Hover`
    &:hover {
      background: ${Colors.Blue10};
      color: ${Colors.Blue50}
    }
  `};

  & path {
    fill: currentColor;
  }
  ${Media.AboveTablet`
    display: none;
  `};
`

export const LeftArrowIcon = styled(Icon24.ChevronLeft)`
  padding-left: 3px;
`

export const ActionButtonBottomWrapper = styled.div`
  position: sticky;
  bottom: 0px;
  margin-bottom: 10px;
  display: block;
  ${Media.AboveTablet`
  display: none;
  `}
`
