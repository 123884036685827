import { FC, memo, useEffect, useRef, useState } from 'react'
import { Optional } from 'packages/types'
import Tippy from '@tippyjs/react/headless'
import 'tippy.js/dist/tippy.css'
import { useSpring } from '@react-spring/web'
import { Icon20 } from 'builder/components/Icon'
import Logo from 'builder/components/DocumentEditor/JobPosting/Images/CompanyLogo.svg'
import { CompanyLogo } from 'builder/components/DocumentEditor/JobPosting/DataContainer/Views/Result/styles'
import {
  PopupContainer,
  PopupCloseButton,
  PopupContent,
  JobPosition,
  HeaderContainer,
  SuggestionsContainer,
  Pointer,
  PointerContainer,
} from './styles'
import pointerImageUrl from './assets/pointer.svg'

type Props = {
  isPopupOpen: boolean
  onClose: () => void
  tailoredDesignation: Optional<string>
  contentHeight?: string
  shouldCloseOnClickOutside?: boolean
  children?: React.ReactElement
  content?: JSX.Element
  zIndex?: number
}

export const AiSuggestionsPopupLayout: FC<Props> = memo(props => {
  const {
    isPopupOpen = false,
    tailoredDesignation,
    onClose,
    contentHeight,
    shouldCloseOnClickOutside = true,
    children,
    content,
    zIndex,
  } = props
  const container = useRef<HTMLDivElement>(null)
  const animationConfig = { duration: 150 }
  const animationFrom = { opacity: 0, transform: 'scale(0.5)' }
  const animationTo = { opacity: 1, transform: 'scale(1)' }
  const [animationProps, setAnimationProps] = useSpring(() => animationFrom)
  const [isVisible, setIsVisible] = useState(false)

  const onShown = () => {
    if (!container.current) return

    const { top, bottom } = container.current.getBoundingClientRect()

    if (top < 0 || bottom > window.innerHeight) {
      container.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const onMount = () => {
    setAnimationProps({
      ...animationTo,
      onRest: onShown,
      config: animationConfig,
    })
  }

  const onHide = ({ unmount }: { unmount: () => void }) => {
    setAnimationProps({
      ...animationFrom,
      onRest: unmount,
      config: { ...animationConfig, clamp: true },
    })
  }

  const onClickOutside = () => {
    if (!shouldCloseOnClickOutside) return
    if (isPopupOpen) onClose()
  }

  // this needed to properly update popup position if container with button can move
  // (for example during hover animation)
  useEffect(() => {
    if (isPopupOpen) {
      const timeout = setTimeout(() => setIsVisible(true), 100)
      return () => clearTimeout(timeout)
    } else {
      setIsVisible(false)
    }
  }, [isPopupOpen])

  return (
    <Tippy
      placement="top"
      render={attrs => {
        return (
          <PopupContainer ref={container} {...attrs} style={animationProps}>
            <PopupContent $contentHeight={contentHeight}>
              <HeaderContainer>
                <CompanyLogo size={20} src={Logo} />
                {tailoredDesignation && <JobPosition>{tailoredDesignation}</JobPosition>}
                <PopupCloseButton onClick={onClose}>
                  <Icon20.Close />
                </PopupCloseButton>
              </HeaderContainer>
              <SuggestionsContainer>{content}</SuggestionsContainer>
            </PopupContent>
            <PointerContainer data-popper-arrow>
              <Pointer src={pointerImageUrl} />
            </PointerContainer>
          </PopupContainer>
        )
      }}
      visible={isVisible}
      interactive
      animation
      offset={[0, 16]}
      popperOptions={{
        modifiers: [{ name: 'flip', enabled: false }],
      }}
      onClickOutside={onClickOutside}
      onMount={onMount}
      onHide={onHide}
      // default value is between z-indexes of 'isPageScrolled' states of ResumeScore component
      // to correctly handle popup position if ImproveResumePanel is opened
      zIndex={zIndex ?? 3}
    >
      {children}
    </Tippy>
  )
})
