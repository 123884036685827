import { Icon20 } from 'builder/components/Icon/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { usePermission } from '../hooks/usePermissions'
import {
  IconContainer,
  Spinner,
  StatusText,
  Container,
  BackgroundContainer,
  RevokePermissionHintText,
  HowToDoThat,
} from './styles'
import { PulsingCircle } from './PulsingCircle'

export enum VoiceIndicatorStatusEnum {
  listening = 'listening',
  processing = 'processing',
  muted = 'muted',
}

interface Props {
  audioStream: MediaStream | undefined
  status: VoiceIndicatorStatusEnum
  isRecording: boolean
  onShowHelp: () => void
}
export const VoiceIndicator = ({ audioStream, status, isRecording, onShowHelp }: Props) => {
  const { i18n } = useI18n()
  const microphonePermission = usePermission('microphone')
  const isNotCapturingAudio = !audioStream?.active || microphonePermission !== 'granted'
  function renderIcon() {
    if (isNotCapturingAudio) {
      return (
        <>
          <Icon20.WarningCircle />
        </>
      )
    }
    switch (status) {
      case VoiceIndicatorStatusEnum.listening:
        return <PulsingCircle audioStream={audioStream} />
      case VoiceIndicatorStatusEnum.processing:
        return (
          <Spinner>
            <Icon20.Spinner />
          </Spinner>
        )
      case VoiceIndicatorStatusEnum.muted:
        return <Icon20.Play />
    }
  }
  const isMicON = !!audioStream?.getAudioTracks()[0].enabled && isRecording

  return (
    <Container>
      <BackgroundContainer
        $animationOn={status === VoiceIndicatorStatusEnum.listening && isMicON}
        $warnning={isNotCapturingAudio}
      >
        <IconContainer>{renderIcon()}</IconContainer>
        <StatusText>
          {i18n.t(
            `builder.interview_prep_view.interview_player.${
              isNotCapturingAudio ? `trouble_hearing_you` : status
            }`,
          )}
        </StatusText>
      </BackgroundContainer>
      {isNotCapturingAudio && (
        <>
          <RevokePermissionHintText>
            {i18n.t(`builder.interview_prep_view.interview_player.trouble_hearing_you_hint`)}
          </RevokePermissionHintText>
          <HowToDoThat onClick={onShowHelp}>
            {i18n.t(`builder.interview_prep_view.interview_player.how_to_do_that`)}
          </HowToDoThat>
        </>
      )}
    </Container>
  )
}
