import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import FocusManager from 'builder/services/FocusManager'
import { SectionNames, selectors, actions } from 'builder/modules/resumeEditor'
import RichTextArea from 'builder/components/RichTextArea'
import { SectionHeader, SectionHint } from 'builder/components/Section'
import { TunerSectionTooltips } from 'builder/components/Tuner'
import SectionTitle from 'builder/components/SectionTitle'
import { useUpdateSimpleField } from 'builder/hooks/useUpdateSimpleField'
import { useRenameSection } from 'builder/hooks/useRenameSection'
import { SectionContainer, SectionContent } from './styles'
import { isEmptyField } from 'builder/modules/resumeEditor/resumeScoreRules/sectionRule'
import { AiKeywordsToggler, useKeywords } from 'builder/components/AiFeatures/Keywords'

const ProfileSummary = () => {
  const { i18n } = useI18n()
  const richEditor = useRef()
  const dispatch = useDispatch()
  const { updateSimpleField } = useUpdateSimpleField()
  const { renameSection } = useRenameSection()
  const suggestionLocales = useSelector(state => state.generalEditor.suggestionLocales)
  const { isKeywordsAvailable } = useKeywords()

  /** @type {import('@rio/types').Resume | null} */
  const resume = useSelector(selectors.resume)
  const { shouldAnimateEditorFieldContent } = useSelector(selectors.aiProfile)
  const { profile: value, sectionTitles, locale } = resume
  const customTitle = sectionTitles.profile
  const isProfileEmpty = resume && isEmptyField(SectionNames.profile, value)
  const textValue = value || ''

  useEffect(() => {
    FocusManager.subscribe(SectionNames.profile, () => {
      if (richEditor.current) richEditor.current.focusEditor()
    })

    return () => FocusManager.unsubscribe(SectionNames.profile)
  }, [])

  const handleDescriptionChange = useCallback(
    value => updateSimpleField({ name: 'profile', value, debounce: true }),
    [updateSimpleField],
  )

  const handleRename = useCallback(
    value => {
      renameSection({ id: SectionNames.profile, value })
    },
    [renameSection],
  )

  const suggestionProps = useMemo(() => {
    if (!suggestionLocales.profile || !suggestionLocales.profile.includes(locale)) return {}

    return {
      suggestionConfig: {
        mode: 'phrases',
        scope: 'profile',
        wide: true,
        onOpenAiProfileButtonClick: () => {
          trackInternalEvent('click_writing_help', {
            section: 'profile',
            profile_empty: isProfileEmpty,
          })
        },
        keywordsConfig: isKeywordsAvailable
          ? {
              section: SectionNames.profile,
              buttonText: i18n.t('builder.ai_profile_summary.assistance_modal.add_keywords'),
            }
          : null,
      },
    }
  }, [locale, suggestionLocales.profile, isProfileEmpty, i18n, isKeywordsAvailable])

  const recommendedRangeProps = {
    ranges: {
      bad: {
        start: 1,
        end: 100,
      },
      good: {
        start: 400,
        end: 600,
      },
    },
    recommendedRangeLabel: i18n.t('builder.resume_editor.professional_summary.length_suggestion', {
      count: '400-600',
    }),
  }

  const badgeComponents = useMemo(
    () => [
      props => {
        return isKeywordsAvailable ? (
          <AiKeywordsToggler section={SectionNames.profile} {...props} />
        ) : null
      },
    ],
    [isKeywordsAvailable],
  )

  const onAiProfileSummaryAnimationEnd = useCallback(() => {
    dispatch(actions.resetAIProfileSummaryFlow())
  }, [dispatch])

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle
          title={i18n.t('builder.resume_editor.professional_summary.title')}
          customTitle={customTitle}
          onRename={handleRename}
          renamable
          badgeComponents={badgeComponents}
        />
        <SectionHint>{i18n.t('builder.resume_editor.professional_summary.label')}</SectionHint>
      </SectionHeader>
      <SectionContent>
        {/* TODO: Add label to i18n if AB test succeeds */}
        <RichTextArea
          innerRef={ref => (richEditor.current = ref)}
          placeholder={i18n.t('builder.resume_editor.professional_summary.placeholder')}
          value={textValue}
          jobTitle={resume ? resume.position : null}
          onChange={handleDescriptionChange}
          locale={locale}
          isAnimating={shouldAnimateEditorFieldContent}
          onAnimationEnd={onAiProfileSummaryAnimationEnd}
          {...suggestionProps}
          {...recommendedRangeProps}
        />
      </SectionContent>

      <TunerSectionTooltips sectionId={SectionNames.profile} />
    </SectionContainer>
  )
}

export default memo(ProfileSummary)
