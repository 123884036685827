import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import 'slick-carousel/slick/slick.css'
import Slider from 'react-slick'
import throttle from 'lodash/throttle'
import { i18n as I18n } from 'builder/utils/i18n'
import Icon24 from 'builder/components/Icon'
import { withMediaQueries } from 'builder/components/MediaQueries'
import { DocumentTypes } from 'builder/modules/constants'
import { Spinner } from 'builder/components/Spinner'
import Tooltip from 'builder/components/Tooltip'
import SliderProgressBar from 'builder/components/SliderProgressBar'

import {
  Content,
  TemplatesContent,
  TemplatesContainer,
  Template,
  TemplatePreviewContainer,
  TemplatePreview,
  TemplateBadge,
  TemplatesLeftButton,
  TemplatesRightButton,
  PlanBadges,
  FormatBadges,
  Button,
  ClickableArea,
  TemplatePreviewWhite,
  TitleContainer,
  Title,
  Subtitle,
  SpinnerWrapper,
  TemplateNameContainer,
  TemplateName,
  TemplateNameText,
  TemplateUsageNumber,
  ProgressBarWrapper,
  ProgressBarContainer,
} from './styles'
import templateHeightFactorSelector from 'builder/components/Helper/utils/templateHeightFactorSelector'

const DEFAULT_WIDTH = 420
const DEFAULT_HEIGHT = 560

const SIDE_PADDING_PHONE = 32
const PHONE_MAX_SIZE = 560

const CURRENT_TEMPLATE_SCALE_FACTOR = 1.04

class Templates extends Component {
  static propTypes = {
    templates: PropTypes.array.isRequired,
    onChoose: PropTypes.func.isRequired,
    mediaQueries: PropTypes.object.isRequired,
    recommended: PropTypes.string,
    currentTemplate: PropTypes.string,
    documentType: PropTypes.oneOf(Object.values(DocumentTypes)),
  }

  static defaultProps = {
    recommended: 'stockholm',
    currentTemplate: '',
  }

  constructor(props) {
    super(props)
    this.templatesRef = createRef()
    this.templateRef = createRef()
    this.sliderRef = createRef()
    this.progressBarRef = createRef()
    this.swiping = false
    this.state = {
      templateWidth: DEFAULT_WIDTH,
      templateHeight: DEFAULT_HEIGHT,
      isLoaded: false,
      currentSlideIndex: this.currentSlideIndex,
      nextSlideIndex: this.currentSlideIndex,
      templateImages: {},
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.templates.length && this.props.templates.length) {
      this.loadTemplateImages()
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    document.addEventListener('keydown', this.handleKeyDown)

    if (this.props.templates.length) {
      this.loadTemplateImages()
    }

    trackInternalEvent(`visit_app_${this.props.documentType}_templates_page`)

    if (window.location.pathname === '/app/create-resume/templates') {
      trackMarketingEvent('Application', `visit_app_${this.props.documentType}_templates_page`)
    }

    // Hack to fix animation bug. See https://github.com/reactjs/react-transition-group/issues/10
    requestAnimationFrame(() => {
      this.handleResize()
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    document.removeEventListener('keydown', this.handleKeyDown)
    this.componentMounted = false
  }

  get currentSlideIndex() {
    const { templates, recommended, currentTemplate } = this.props
    const initialSlide = currentTemplate || recommended
    const index = templates.findIndex(item => item.id === initialSlide)
    return index >= 0 ? index : 0
  }

  get visibleSlideIndexes() {
    const { templates } = this.props
    const currentIndex = this.currentSlideIndex
    return [
      currentIndex > 0 ? currentIndex - 1 : templates.length - 1,
      currentIndex,
      currentIndex < templates.length - 1 ? currentIndex + 1 : 0,
    ]
  }

  get templateMargins() {
    return {
      top: 70,
      bottom: 98,
    }
  }

  loadTemplateImages() {
    const { templates } = this.props
    const visibleTemplates = this.visibleSlideIndexes.map(index => templates[index])
    const otherTemplates = templates.filter((template, i) => !this.visibleSlideIndexes.includes(i))
    this.componentMounted = true

    this.loadImagesFor(visibleTemplates, { lowRes: true })
      .then(() => this.setState({ isLoaded: true }))
      .then(() => this.loadImagesFor(visibleTemplates))
      .then(() => this.loadImagesFor(otherTemplates, { lowRes: true, immediate: true }))
      .then(() => this.loadImagesFor(otherTemplates))
  }

  loadImagesFor = (templates, { lowRes = false, immediate = false } = {}) => {
    const promises = templates.map(template => {
      const url = lowRes ? template.thumbnailLowRes : template.thumbnail

      // don't wait for the image to load if the `immediate` flag is given
      const imageLoadPromise = immediate ? Promise.resolve() : failsafeLoadImg(url)

      return imageLoadPromise.then(() => {
        if (!this.componentMounted) return
        this.setState({
          templateImages: {
            ...this.state.templateImages,
            [template.id]: url,
          },
        })
      })
    })

    return Promise.all(promises)
  }

  handleResize = throttle(() => {
    const templatesElt = this.templatesRef.current
    const { top } = templatesElt.getBoundingClientRect()
    let newWidth = 0
    let newHeight = 0

    const pdfHeightFactor = templateHeightFactorSelector(this.props.currentTemplate)
    if (window.innerWidth < PHONE_MAX_SIZE) {
      newWidth = window.innerWidth - SIDE_PADDING_PHONE * 2
      newHeight = newWidth / pdfHeightFactor
    } else {
      newHeight = Math.max(
        300,
        window.innerHeight - top - this.templateMargins.top - this.templateMargins.bottom,
      )
      newWidth = newHeight * pdfHeightFactor
    }

    this.setState({
      templateHeight: newHeight,
      templateWidth: newWidth,
    })
  }, 50)

  handleKeyDown = event => {
    const key = event.which || event.keyCode
    switch (key) {
      case 13:
        const template = this.props.templates[this.state.currentSlideIndex]
        if (template) {
          this.props.onChoose(template.id)
        }
        break
      case 37:
        this.handlePreviousSlideClick()
        break
      case 39:
        this.handleNextSlideClick()
        break
    }
  }

  handlePreviousSlideClick = () => {
    this.sliderRef.current.slickPrev()
  }

  handleNextSlideClick = () => {
    this.sliderRef.current.slickNext()
  }

  handleChooseClick = template => {
    this.props.onChoose(template.id)
    trackInternalEvent(`choose_sign_up_${this.props.documentType}_template`, {
      template: template.id,
      label: 'funnel',
    })
    trackMarketingEvent('Sign Up', template.name)
    if (localStorage.getItem('provisioning-premium-with-3-auto-apply-credits')) {
      trackMarketingEvent('TJSS Sign up', template.name)
    }
  }

  handleMouseDown = event => {
    event.preventDefault()
  }

  handleMouseUp = () => {
    this.swiping = this.sliderRef.current.innerSlider.state.swiping
  }

  handleClickCapture = event => {
    if (this.swiping) {
      event.stopPropagation()
    }
  }

  handleChangeSlide = (current, next) => {
    this.setState({ nextSlideIndex: next })
  }

  handleAfterChangeSlide = current => {
    trackInternalEvent('slide_sign_up_templates')
    this.setState({ currentSlideIndex: current })
  }

  render() {
    const { templates, recommended, mediaQueries } = this.props
    const { templateWidth, templateHeight, isLoaded } = this.state

    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 300,
      slidesToShow: mediaQueries.isDesktop ? 3 : 1,
      slidesToScroll: 1,
      centerMode: true,
      focusOnSelect: true,
      initialSlide: this.currentSlideIndex,
      variableWidth: true,
      adaptiveHeight: true,
      swipeToSlide: true,
      afterChange: this.handleAfterChangeSlide,
      beforeChange: this.handleChangeSlide,
    }

    const showHeader = mediaQueries.isTablet
    const badgesVerticalMargin =
      (templateHeight * CURRENT_TEMPLATE_SCALE_FACTOR - templateHeight) / 2
    const badgesHorizontalMargin =
      (templateWidth * CURRENT_TEMPLATE_SCALE_FACTOR - templateWidth) / 2

    return (
      <Content>
        {showHeader && (
          <TitleContainer>
            <Title>{I18n.t('builder.sign_up.templates_title')}</Title>
            <Subtitle>{I18n.t('builder.sign_up.templates_subtitle')}</Subtitle>
          </TitleContainer>
        )}
        <TemplatesContent ref={this.templatesRef}>
          {isLoaded ? (
            <TemplatesContainer
              badgesVerticalMargin={badgesVerticalMargin}
              badgesHorizontalMargin={badgesHorizontalMargin}
            >
              <TemplatesLeftButton onClick={this.handlePreviousSlideClick}>
                <Icon24.Chevron />
              </TemplatesLeftButton>
              <TemplatesRightButton onClick={this.handleNextSlideClick}>
                <Icon24.Chevron />
              </TemplatesRightButton>

              <Slider {...settings} ref={this.sliderRef}>
                {templates.map(template => {
                  return (
                    <div key={template.id}>
                      <Template
                        style={{ width: templateWidth }}
                        height={templateHeight}
                        key={template.id}
                        ref={this.templateRef}
                        onMouseDownCapture={this.handleMouseDown}
                        onMouseUpCapture={this.handleMouseUp}
                        onClickCapture={this.handleClickCapture}
                      >
                        <>
                          <TemplatePreviewContainer>
                            {this.state.templateImages[template.id] ? (
                              <TemplatePreview
                                alt={`${template.id} template`}
                                src={this.state.templateImages[template.id]}
                              />
                            ) : (
                              <TemplatePreviewWhite />
                            )}

                            {!template.premium && <PlanBadges badges={['Free']} />}

                            <FormatBadges badges={template.supportedFormats} />
                          </TemplatePreviewContainer>

                          <TemplateNameContainer>
                            <TemplateName>
                              <TemplateNameText>{template.name}</TemplateNameText>
                              {template.id === recommended && (
                                <Tooltip value={I18n.t('builder.sign_up.most_popular')}>
                                  <TemplateBadge />
                                </Tooltip>
                              )}
                            </TemplateName>
                            <TemplateUsageNumber>
                              {I18n.t('builder.sign_up.users_chose_this_template', {
                                number: template.usersChose,
                              })}
                            </TemplateUsageNumber>
                          </TemplateNameContainer>

                          <ClickableArea onClick={() => this.handleChooseClick(template)} />

                          <Button onClick={() => this.handleChooseClick(template)}>
                            {I18n.t('builder.sign_up.select')}
                          </Button>
                        </>
                      </Template>
                    </div>
                  )
                })}
              </Slider>

              <ProgressBarWrapper>
                <ProgressBarContainer>
                  <SliderProgressBar
                    currentStep={this.state.nextSlideIndex}
                    steps={templates.length}
                  />
                </ProgressBarContainer>
              </ProgressBarWrapper>
            </TemplatesContainer>
          ) : (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )}
        </TemplatesContent>
      </Content>
    )
  }
}

/*
 * Loads an image via its URL and returns a Promise.
 * The returned promise always resolves, even if the
 * image fails to load.
 *
 * @param {string} an image URL
 * @returns {Promise<string|null, error?>}
 */
function failsafeLoadImg(src) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => resolve(src)
    image.onerror = e => resolve(null, e)
    image.src = src
  })
}

export default withMediaQueries(Templates)
