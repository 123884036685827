import { i18n as I18n } from 'builder/utils/i18n'
import { ButtonSize } from 'builder/components/Button'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import {
  Title,
  Content,
  StartImprovingButton,
  ButtonWrapper,
  LoadingButton,
  LoadingIcon,
} from './styles'

interface BottomContainerprops {
  ButtonSize: ButtonSize
}

const BottomContainerLoadingView = ({ ButtonSize }: BottomContainerprops) => {
  const isClickedOnAiCVBanner = useTypedSelector(state => state.resumeEditor.isClickedOnAiCVBanner)
  const isOpenAICVOptimizerModal = useTypedSelector(
    state => state.resumeEditor.AICoverLetter.isOpenAICVOptimizerModal,
  )
  const AICVView = isClickedOnAiCVBanner || isOpenAICVOptimizerModal
  const loadingViewData = AICVView
    ? I18n.t('builder.ai_cover_letter.loading_screen.content')
    : I18n.t('builder.resume_optimizer.job_posting.loading.content')

  return (
    <>
      <Title>{I18n.t('builder.resume_optimizer.job_posting.loading.title')}</Title>
      <Content>{loadingViewData}</Content>

      <ButtonWrapper>
        {AICVView ? (
          <LoadingButton size={ButtonSize}>
            <LoadingIcon />
          </LoadingButton>
        ) : (
          <StartImprovingButton isDisabled={true} size={ButtonSize}>
            {I18n.t('builder.resume_optimizer.job_posting.start_improving')}
          </StartImprovingButton>
        )}
      </ButtonWrapper>
    </>
  )
}

export default BottomContainerLoadingView
