import { UseFormReturn, useFieldArray } from 'react-hook-form'
import * as SimpleForm from 'builder/components/SimpleForm'
import { TextField } from 'builder/components/TextField'
import { PhoneInput } from 'builder/components/PhoneInput/PhoneInput'
import { useI18n } from 'builder/hooks/useI18n'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { AutoApplyForm } from 'builder/modules/autoApply/types'
import {
  validateEmail as validateEmailRegex,
  validateUrl as validateUrlRegex,
} from 'builder/utils/regexValidations'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { createNewLocationSuggestionsApiFetcher } from 'builder/components/FindJob/utils/createNewSuggestionsApiFetcher'
import { SOCIAL_OPTIONS, SocialItemType } from '../../constants'
import {
  AddLink,
  SelectMultipleLabel,
  TitleAndLink,
  TitleAndLinkWrapper,
  TitleSelect,
} from './styles'

function findFirstNonSelected(
  socialArray: SocialItemType[],
  selectedList: string[],
): SocialItemType | undefined {
  const selectedIds = new Set(selectedList)
  return socialArray.find(item => !selectedIds.has(item.id))
}

type Props = {
  form: UseFormReturn<
    Pick<
      AutoApplyForm,
      'socialLinksAttributes' | 'location' | 'fullAddress' | 'email' | 'phoneNumber'
    >
  >
}

export const ContactInfoForm = (props: Props) => {
  const { form } = props

  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = form
  const { i18n } = useI18n()
  const { features } = useFeaturesConfig()

  const TRANSLATION = `builder.auto_apply.form.contact_information${
    features.careerProfileShortVersion ? '.short_version' : ''
  }`
  const required = i18n.t(`${TRANSLATION}.field_is_required`)
  const { fields, append } = useFieldArray({
    control,
    name: 'socialLinksAttributes',
  })

  const validateEmail = (val: string) => {
    if (!validateEmailRegex(val)) {
      return i18n.t(`${TRANSLATION}.invalid_email`)
    }
    return true
  }

  const validateUrl = (val: string) => {
    if (!val) return true
    if (!validateUrlRegex(val)) {
      return i18n.t(`${TRANSLATION}.invalid_url`)
    }
    return true
  }

  return (
    <>
      <SimpleForm.Row position="relative">
        <AsyncAutosuggest
          {...register('location', { required })}
          name="location"
          fetchItems={async (searchString: string) => {
            const result = await createNewLocationSuggestionsApiFetcher(searchString)
            return result.map(item => ({ text: item.formatted }))
          }}
          onChange={event => {
            setValue('location', event.target.value)
          }}
          value={watch('location')}
          onSuggestionSelected={(_, selected) => {
            setValue('location', selected?.suggestion?.text || '')
          }}
        >
          {inputProps => (
            <TextField
              {...inputProps}
              label={i18n.t(`${TRANSLATION}.location.label`)}
              placeholder={i18n.t(`${TRANSLATION}.location.placeholder`)}
              error={errors.location?.message}
            />
          )}
        </AsyncAutosuggest>
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <TextField
          {...register('fullAddress', { required })}
          label={i18n.t(`${TRANSLATION}.full_address.label`)}
          placeholder={i18n.t(`${TRANSLATION}.full_address.placeholder`)}
          error={errors.fullAddress?.message}
        />
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <TextField
          {...register('email', { validate: validateEmail, required })}
          name="email"
          label={i18n.t(`${TRANSLATION}.email.label`)}
          placeholder={i18n.t(`${TRANSLATION}.email.placeholder`)}
          error={errors.email?.message}
        />
      </SimpleForm.Row>
      {!features.careerProfileShortVersion && (
        <SimpleForm.Row position="relative">
          <PhoneInput
            {...register('phoneNumber', { required })}
            label={i18n.t(`${TRANSLATION}.phone.label`)}
            placeholder={i18n.t(`${TRANSLATION}.phone.placeholder`)}
            error={errors.phoneNumber?.message}
            value={watch('phoneNumber')}
            onChange={phone => setValue('phoneNumber', phone)}
          />
        </SimpleForm.Row>
      )}
      {!features.careerProfileShortVersion && (
        <SimpleForm.Row position="relative">
          <SelectMultipleLabel>{i18n.t(`${TRANSLATION}.public_profile.label`)}</SelectMultipleLabel>
          <TitleAndLinkWrapper>
            {fields.map((item, index) => {
              const title = watch(`socialLinksAttributes.${index}.title`)
              const selectedOne = SOCIAL_OPTIONS.find(s => s.id === title)
              const selectedSocialLinks = fields.map(f => f.title)
              const socialLinks = SOCIAL_OPTIONS.filter(
                s => !selectedSocialLinks.some(sel => sel === s.id) || s.id === 'others',
              )
              let options: Array<{ id: string; name: string }> = []

              if (!selectedOne || socialLinks.findIndex(s => s.id === selectedOne?.id) > -1) {
                options = socialLinks
              } else {
                options = [selectedOne, ...socialLinks]
              }

              return (
                <TitleAndLink key={`${item.id}_${index}`}>
                  <TitleSelect
                    {...register(`socialLinksAttributes.${index}.title`)}
                    error={errors.socialLinksAttributes?.[index]?.title?.message}
                    selected={title}
                    onSelect={val =>
                      setValue(`socialLinksAttributes.${index}.title`, val?.toString() || '')
                    }
                    options={options}
                  />
                  <TextField
                    {...register(`socialLinksAttributes.${index}.link`, {
                      validate: validateUrl,
                    })}
                    placeholder={i18n.t(`${TRANSLATION}.url`)}
                    error={errors.socialLinksAttributes?.[index]?.link?.message}
                  />
                </TitleAndLink>
              )
            })}
          </TitleAndLinkWrapper>
        </SimpleForm.Row>
      )}
      {!features.careerProfileShortVersion && (
        <AddLink
          onClick={() => {
            const courseAttr = getValues('socialLinksAttributes')
            const index = courseAttr.findIndex(social => !social.title)

            if (index === -1) {
              const nonSelected = findFirstNonSelected(
                SOCIAL_OPTIONS,
                courseAttr.map(c => c.title),
              )
              append({ title: nonSelected?.id, link: '' })
            }
          }}
        >
          {i18n.t(`${TRANSLATION}.add_link`)}
        </AddLink>
      )}
    </>
  )
}
