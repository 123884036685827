import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import Icon24 from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useMutationInterviewAnswerQuestion } from 'builder/views/Interview/hooks/useMutationInterviewAnswerQuestion'
import { InterviewQuestion } from 'builder/modules/interview/types'
import { CountDownOverlay } from 'builder/components/CountDownOverlay/CountDownOverlay'
import { useInterview } from 'builder/views/Interview/hooks/useInterview'
import {
  selectors,
  actions,
  setupVideoStream,
  setupAudioStream,
} from 'builder/modules/interview/interviewModule'

import { PerformanceLoggerWithMainDsn as PerformanceLogger } from 'builder/services/PerformanceLogger'
import ErrorLogger from 'builder/services/ErrorLogger'
import { useComponentWillMount } from 'builder/hooks/useComponentWillMount'
import { formatSecondsToMMSS } from '../../../../utils/formatTime'
import { useStartRecord } from '../../hooks/useStartRecord'

import { delay, invalidateCacheForInterviews } from '../../utils'
import { DELAY_FOR_FEEDBACK } from '../../constants'
import { PAGE, VideoMicModal } from './VideoMicModal/VideoMicModal'
import { MicVideoControls } from './MicVideoControls/MicVideoControls'
import {
  MidContainer,
  Container,
  FooterContainer,
  FooterContainerItem,
  InterviewDurationText,
  InterviewDurationTime,
  ListIcon,
  MobileSideBarButton,
  NextControlButtonContainer,
  Question,
  QuestionListText,
  QuestionStatementText,
  Title,
  TitleContainer,
  VideoSpeechTextContainer,
  YourAnswerTitle,
  BodyContainer,
  Top,
} from './styles'
import { Webcam } from './Webcam/Webcam'
import { VoiceIndicator, VoiceIndicatorStatusEnum } from './VoiceIndicator/VoiceIndicator'
import { QuestionListSideBar } from './QuestionListSideBar/QuestionListSideBar'
import { useTimer } from './hooks/useTimer'
import { ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog'
import { TimeTickingModal } from './TimeTickingModal/TimeTickingModal'
import { EndLoading } from './EndLoading/EndLoading'
import { TimeUpDialog } from './TimeUpDialog/TimeUpDialog'
import { StartLoading } from './StartLoading/StartLoading'
import { useTextToSpeechPlay } from './hooks/useTextToSpeechPlay'
import { ActionButtons } from './ActionButtons/ActionButtons'
import { Menu } from './Menu/Menu'
import { CompleteModal } from './CompleteModal/CompleteModal'
import { CloseModal } from './CloseModal/CloseModal'
import { NextControlButton } from './ControlButton'

export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player'

export const InterviewPlayerView = () => {
  const navigate = useNavigate()
  const interviewId = useSelector(selectors.interviewId)

  const videoStream = useSelector(selectors.videoStream)
  const audioStream = useSelector(selectors.audioStream)
  const presignedCalls = useSelector(selectors.presignedUrlCalls)
  const answerCompleteStatus = useSelector(selectors.answerCompleteStatus)
  const dispatch = useDispatch()
  const refQuestion = useRef<HTMLDivElement>(null)
  const { data: interview } = useInterview({ interviewId })
  const questions = interview?.questions

  const [enableConfirmationDialog, setEnableConfirmationDialog] = useState(false)
  const [stopButtonClicked, setStopButtonClicked] = useState(false)
  const [showSideMenu, setShowSideMenu] = useState(false)
  const [showCompleteModal, setShowCompleteModal] = useState(false)
  const [linkToGo, setLinkToGo] = useState('')

  const { i18n } = useI18n()
  const [showSidebar, setShowSidebar] = useState(false)
  const [showVideoCheckModal, setShowVideoCheckModal] = useState<{
    enabled: boolean
    initialPage: PAGE
  }>({
    enabled: true,
    initialPage: 'initial',
  })

  const [showCountDown, setShowCountDown] = useState(false)

  const { isPhone, isDesktop } = useMediaQueries()
  const { mutateAsync: startAnswer } = useMutationInterviewAnswerQuestion()
  const [answerId, setAnswerId] = useState<number>()
  const [question, setQuestion] = useState<InterviewQuestion>()
  const [showLoading, setShowLoading] = useState<'start' | 'end' | null>('start')
  const [enabledNextQuestionButton, setEnabledNextQuestionButton] = useState(false)
  const startQuestionIndex = useMemo(() => {
    const lastUnansweredQuestion = interview?.questions.findIndex(q => !q.answer?.audio) || -1
    return lastUnansweredQuestion === -1 ? 0 : lastUnansweredQuestion
  }, [interview])

  const {
    playQuestionAnnouncement,
    loading: isAnnouncementLoading,
    speaking,
  } = useTextToSpeechPlay(startQuestionIndex, interview)

  useEffect(() => {
    if (!interviewId) {
      navigate('/interview-preparation/dashboard')
    }
  }, [navigate])

  const isValidVideoStream = !!(videoStream && videoStream?.active)

  const {
    startRecord: startAudio,
    state: audioState,
    stop: stopAudio,
  } = useStartRecord('audio', isValidVideoStream, question?.id, answerId)

  const {
    startRecord: startVideo,
    state: videoState,
    stop: stopVideo,
  } = useStartRecord('video', isValidVideoStream, question?.id, answerId)

  const isRecording = audioState === 'recording' || videoState === 'recording'
  const isLastQuestion = !!(
    question &&
    Array.isArray(questions) &&
    questions[questions.length - 1] &&
    question.id === questions[questions.length - 1].id
  )

  const stopRecordings = (interruptedAnswer = false) => {
    const interrupted = interruptedAnswer || stopButtonClicked
    stopAudio(interrupted)
    stopVideo(interrupted)
  }

  const stopStream = () => {
    dispatch(actions.stopVideoStream())
    dispatch(actions.stopAudioStream())
  }

  const timer = useTimer(stopRecordings, isRecording)

  useEffect(() => {
    if (!question && interview?.questions) {
      const lastUnansweredQuestion = interview?.questions.findIndex(q => !q.answer?.audio)
      setQuestion(interview?.questions[lastUnansweredQuestion === -1 ? 0 : lastUnansweredQuestion])
    }
  }, [interview])

  useEffect(() => {
    const start = async () => {
      if (question) {
        setEnabledNextQuestionButton(false)
        const answerResponse = await startAnswer(question.id)
        setAnswerId(answerResponse.id)
        setEnabledNextQuestionButton(true)
      }
    }
    start()
  }, [question])

  const index = questions?.findIndex(q => q.id === question?.id)
  const currentIndex = index === -1 ? 0 : index

  useEffect(() => {
    const record = async () => {
      if (answerId && question?.id && !showVideoCheckModal.enabled && !showCountDown) {
        await playQuestionAnnouncement(question?.id)
        if (videoStream && videoStream.active) {
          startVideo(videoStream)
        }

        if (audioStream && audioStream.active) {
          startAudio(audioStream)
        }
      }
    }
    record()
  }, [answerId, showVideoCheckModal, showCountDown])

  useEffect(() => {
    dispatch(setupVideoStream())
    dispatch(setupAudioStream())

    return stopStream
  }, [])

  const jobTitle = interview?.job_title
    ? [interview?.job_title, i18n.t(`${TRANSLATION_PREFIX}.interview`)]
    : []

  const company = interview?.company_name
    ? [i18n.t(`${TRANSLATION_PREFIX}.at_preposition`), interview?.company_name]
    : []
  const title = interview?.job_title ? [jobTitle, company].flat().join(' ') : ''

  const renderVoiceIndicator = () => (
    <>
      <YourAnswerTitle>{i18n.t(`${TRANSLATION_PREFIX}.your_answer`)}</YourAnswerTitle>
      <VoiceIndicator
        audioStream={audioStream}
        isRecording={audioState === 'recording'}
        status={
          audioStream?.getAudioTracks()[0].enabled
            ? VoiceIndicatorStatusEnum.listening
            : VoiceIndicatorStatusEnum.muted
        }
        onShowHelp={() => setShowVideoCheckModal({ enabled: true, initialPage: 'help' })}
      />
    </>
  )

  const renderWebcam = () => {
    return (
      videoStream && (
        <Webcam
          srcObject={videoStream}
          isPhone={isPhone}
          isRecording={videoStream?.getVideoTracks()[0].enabled && videoState === 'recording'}
        />
      )
    )
  }

  const openSideBar = () => setShowSidebar(true)
  const closeSideBar = () => setShowSidebar(false)
  const onNextQuestionClick = () => {
    if (answerId && question) {
      stopRecordings()
      if (questions && currentIndex !== undefined) {
        if (isLastQuestion || stopButtonClicked) {
          stopStream()
          setShowLoading('end')
        }

        if (stopButtonClicked) {
          trackInternalEvent('pause_interview', {
            label: 'interview_prep',
            total_questions: interview.questions.length,
            answered_questions: currentIndex,
          })
          return
        }

        trackInternalEvent('submit_answer', {
          label: 'interview_prep',
        })

        if (isLastQuestion) {
          trackInternalEvent('complete_interview', {
            label: 'interview_prep',
          })
        } else {
          setQuestion(questions[currentIndex + 1])
          setEnableConfirmationDialog(false)
        }
      }
    }
  }

  const confirmationKey = useMemo(() => {
    if (stopButtonClicked) {
      return 'interrupted_interview'
    }

    if (isLastQuestion) {
      return 'complete_interview'
    }

    return 'next_question'
  }, [stopButtonClicked, isLastQuestion])

  useComponentWillMount(() => {
    PerformanceLogger.listen({
      name: 'interview-player-page',
    })
  })

  useEffect(() => {
    if (!interview) {
      PerformanceLogger.operation('start-interview', { tags: { interviewId } })
    } else {
      PerformanceLogger.operationEnd('start-interview')
    }
  }, [interview])

  useEffect(() => {
    // handle upload and wait complete to go to feedback page
    async function handleUploads() {
      if (showLoading === 'end') {
        const presignedCallCount = Object.values(presignedCalls).length
        const values = Object.values(answerCompleteStatus)
        const promises = values.map(i => i.completedPromise).filter(Boolean)

        if (
          promises.length !== presignedCallCount ||
          presignedCallCount === 0 ||
          promises.length === 0
        ) {
          return
        }
        try {
          // show the end loading
          await delay(DELAY_FOR_FEEDBACK)
          await Promise.all(promises)

          await PerformanceLogger.finish()

          invalidateCacheForInterviews()

          navigate(
            stopButtonClicked
              ? '/interview-preparation/dashboard'
              : '/interview-preparation/feedback',
          )
        } catch (error) {
          ErrorLogger.log(error)
        }
      }
    }

    handleUploads()
  }, [answerCompleteStatus, presignedCalls, showLoading])

  const location = useLocation() as unknown as { state?: { forceStartLoading?: boolean } }
  const forceStartLoading = location.state?.forceStartLoading

  useEffect(() => {
    if (showLoading === 'start' && interview && !isAnnouncementLoading && !forceStartLoading) {
      return setShowLoading(null)
    }
  }, [isAnnouncementLoading, interview, showLoading, forceStartLoading])

  if (showLoading === 'start') {
    return (
      <StartLoading
        forceStartLoading={Boolean(forceStartLoading)}
        onClose={() => forceStartLoading && setShowLoading(null)}
      />
    )
  }

  if (showLoading === 'end') {
    return <EndLoading stopButtonClicked={stopButtonClicked} />
  }

  return (
    <Container ref={refQuestion}>
      {timer.showTimeUpDialog && (
        <TimeUpDialog
          isLastQuestion={isLastQuestion}
          onClick={() => {
            onNextQuestionClick()
            timer.setShowTimeUpDialog(false)
          }}
        />
      )}
      {showVideoCheckModal.enabled && (
        <VideoMicModal
          initialPage={showVideoCheckModal.initialPage}
          onClose={() => {
            setShowVideoCheckModal({ enabled: false, initialPage: 'initial' })
            if (showVideoCheckModal.initialPage === 'initial') {
              setShowCountDown(true)
            }
          }}
        />
      )}
      {showCountDown && (
        <CountDownOverlay
          initialCounterValue={3}
          onCompleted={() => setShowCountDown(false)}
          onCancel={() => navigate('/interview-preparation/dashboard')}
          cancelTitle={i18n.t(`${TRANSLATION_PREFIX}.cancel`)}
        />
      )}
      {timer.showTimeTickingModal && (
        <TimeTickingModal
          isLastQuestion={isLastQuestion}
          remainingTime={formatSecondsToMMSS(timer.time)}
          onCancel={() => timer.setShowTimeTickingModal(false)}
          onConfirmation={onNextQuestionClick}
        />
      )}
      {showCompleteModal && (
        <CompleteModal
          onClose={() => setShowCompleteModal(false)}
          onComplete={onNextQuestionClick}
        />
      )}
      {showSideMenu && (
        <Menu
          onClose={() => setShowSideMenu(false)}
          onClick={(path: string) => {
            setLinkToGo(path)
          }}
        />
      )}
      {linkToGo && <CloseModal onClose={() => setLinkToGo('')} linkToGo={linkToGo} />}
      <ActionButtons
        onSideMenuClick={() => setShowSideMenu(true)}
        onCloseClick={() => {
          stopRecordings(true)
          setLinkToGo('/interview-preparation/dashboard')
        }}
        onDashboardClick={() => {
          stopRecordings(true)
          setLinkToGo('/interview-preparation/dashboard')
        }}
      />
      <BodyContainer>
        <Top>
          <TitleContainer>
            <Title>{title}</Title>
            {!isPhone && (
              <QuestionListText onClick={openSideBar}>
                <ListIcon />
                {i18n.t(`${TRANSLATION_PREFIX}.question_list`)}
              </QuestionListText>
            )}
          </TitleContainer>
          <QuestionListSideBar
            subtitle={interview?.job_title || ''}
            title={title || ''}
            onClose={closeSideBar}
            currentIndex={currentIndex}
            open={showSidebar}
            questions={questions}
          />
          {!isDesktop && <VideoSpeechTextContainer>{renderWebcam()}</VideoSpeechTextContainer>}
        </Top>
        <MidContainer>
          <>
            <QuestionStatementText>
              {i18n.t(`${TRANSLATION_PREFIX}.question`)} {(currentIndex || 0) + 1}
            </QuestionStatementText>
            {question && (
              <Question ref={refQuestion} $ref={refQuestion.current}>
                {question?.content}
              </Question>
            )}
          </>

          {!isDesktop && renderVoiceIndicator()}
        </MidContainer>
        {isDesktop && (
          <VideoSpeechTextContainer>
            {renderWebcam()}
            {renderVoiceIndicator()}
          </VideoSpeechTextContainer>
        )}
      </BodyContainer>

      <NextControlButtonContainer $show={!speaking && enabledNextQuestionButton}>
        <NextControlButton
          onClick={() => {
            if (isLastQuestion) {
              setShowCompleteModal(true)
            } else {
              setEnableConfirmationDialog(true)
            }
          }}
        >
          {i18n.t(
            `${TRANSLATION_PREFIX}.${isLastQuestion ? 'complete_interview' : 'next_question'}`,
          )}
        </NextControlButton>
        {enableConfirmationDialog && (
          <ConfirmationDialog
            text={i18n.t(`${TRANSLATION_PREFIX}.confirmation_${confirmationKey}`)}
            onNoClick={() => {
              setEnableConfirmationDialog(false)
              setStopButtonClicked(false)
            }}
            onYesClick={onNextQuestionClick}
          />
        )}
      </NextControlButtonContainer>

      <FooterContainer>
        <FooterContainerItem>
          <InterviewDurationText>
            {!isPhone && i18n.t(`${TRANSLATION_PREFIX}.interview_duration`)}
            <InterviewDurationTime>{formatSecondsToMMSS(timer.time)}</InterviewDurationTime>
          </InterviewDurationText>
        </FooterContainerItem>
        <MicVideoControls />
        <FooterContainerItem>
          {isPhone && (
            <MobileSideBarButton onClick={openSideBar}>
              <Icon24.ListIcon />
            </MobileSideBarButton>
          )}
        </FooterContainerItem>
      </FooterContainer>
    </Container>
  )
}
