import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Button, { ButtonTheme } from 'builder/components/Button'
import NO_RESULTS from './assets/no_results_recommandations.png'
import { EmptyResultsContainer, Title, Subtitle, NoResultsImage, ButtonsContainer } from './styles'

export const NoResultsRecommendation = ({ handleSearch }: { handleSearch: () => void }) => {
  const { i18n } = useI18n()
  const navigate = useNavigate()

  return (
    <EmptyResultsContainer>
      <NoResultsImage src={NO_RESULTS} />
      <Title>{i18n.t(`builder.job_search.no_results_recommendations.title`)}</Title>
      <Subtitle>
        {i18n.t(`builder.job_search.no_results_recommendations.subtitle_line_one`)}
      </Subtitle>
      <Subtitle>
        {i18n.t(`builder.job_search.no_results_recommendations.subtitle_line_two`)}
      </Subtitle>
      <ButtonsContainer>
        <Button
          onClick={() => {
            trackInternalEvent('view_profile_editor', { label: 'recommended_jobs' })
            navigate(
              '/career-profile/edit?section=workExperiences&returnUrl=/job-search?view=recommendation',
            )
          }}
        >
          {i18n.t(`builder.job_search.no_results_recommendations.change_work_button`)}
        </Button>
        <Button theme={ButtonTheme.ghost} onClick={handleSearch}>
          {i18n.t(`builder.job_search.no_results_recommendations.go_search_button`)}
        </Button>
      </ButtonsContainer>
    </EmptyResultsContainer>
  )
}
