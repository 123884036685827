import { useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/user'
import { Icon20 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { InterviewTemplate } from 'builder/modules/interview/types'
import Button from 'builder/components/Button'
import Colors from 'builder/styles/colors'
import { useInterviews } from 'builder/views/Interview/hooks/useInterviews'
import { QUESTION_DURATION } from 'builder/views/Interview/constants'
import { useNewInterview } from 'builder/views/Interview/hooks/useNewInterview'
import { actions } from 'builder/modules/interview/interviewModule'
import { PremiumDialog } from '../../../PremiumDialog/PremiumDialog'
import { Logo } from '../../Logo/Logo'
import { Modal } from '../../../Modal/Modal'
import { Badge } from '../../../Badge/Badge'
import {
  Title,
  TopLeft,
  SubInfo,
  PremiumWarning,
  Bottom,
  InterviewDescription,
  Duration,
  InterviewTop,
  InterviewContainer,
  ContainerTop,
} from './styles'

const TRANSLATION = 'builder.interview_prep_view.interview_dashboard.practice_this'

type PracticeThisInterviewModalProps = {
  trackingParameter: 'interview_dash_recommendation' | 'interview_dash_questions' | 'library'
  onClose: () => void
  onConfirmation?: () => void
  interview: InterviewTemplate
  isTestInterview: boolean
  interviewId?: number
}

export const PracticeThisInterviewModal = (props: PracticeThisInterviewModalProps) => {
  const { onClose, interview, onConfirmation, trackingParameter, isTestInterview, interviewId } =
    props
  const { data: allInterviews } = useInterviews()
  const { i18n } = useI18n()
  const [showPremiumDialog, setShowPremiumDialog] = useState(false)
  const { mutateAsync: postInterview, status: interviewStatus } = useNewInterview()

  const isPremiumUser = useTypedSelector(selectors.premiumAccess)
  const isFreeUserCapableStartInterview =
    !isPremiumUser && allInterviews?.length === 0 && interview?.questions_count === 1
  const isEligible = isPremiumUser || isFreeUserCapableStartInterview

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isLoading = interviewStatus === 'loading'

  const onCreateInterviewClick = async () => {
    onConfirmation && onConfirmation()
    if (!isEligible) {
      setShowPremiumDialog(true)
      return
    }
    trackInternalEvent('start_interview', {
      label: 'interview_prep',
      parameter: trackingParameter,
    })
    if (interviewId) {
      dispatch(actions.setInterviewId(interviewId))
      navigate('/interview-preparation/interview-player')
      return
    }
    if (interview) {
      await postInterview({
        jobTitleCode: interview.data_api_job_title_id,
        company: interview.company_name,
      })
      navigate('/interview-preparation/interview-player')
    }
  }

  if (showPremiumDialog) {
    return (
      <PremiumDialog
        trackingParameter={trackingParameter}
        onClose={() => setShowPremiumDialog(false)}
      />
    )
  }

  return (
    <Modal
      onClose={onClose}
      title={
        <InterviewTop>
          {interview.logo_url && <Logo src={interview.logo_url} />}
          <TopLeft>
            <Badge $question={false} $status="initiated">
              {i18n.t(`${TRANSLATION}.interview`)}
            </Badge>
            <Duration>
              <Icon20.Clock stroke={Colors.Green80} />
              {i18n.t(`${TRANSLATION}.min`, {
                minutes: interview.questions_count * QUESTION_DURATION,
              })}
            </Duration>
          </TopLeft>
        </InterviewTop>
      }
    >
      <InterviewContainer>
        <ContainerTop>
          <Title>{interview.job_title}</Title>
          <SubInfo>
            {interview.company_name
              ? i18n.t(`${TRANSLATION}.at`, {
                  company: interview.company_name,
                })
              : i18n.t(`${TRANSLATION}.test_interview`)}{' '}
            •{' '}
            {interview.questions_count === 1
              ? i18n.t(`${TRANSLATION}.single_question`)
              : i18n.t(`${TRANSLATION}.questions`, { count: interview.questions_count })}
          </SubInfo>
        </ContainerTop>
        <InterviewDescription>
          {isTestInterview
            ? i18n.t(`${TRANSLATION}.test_interview_description`)
            : i18n.t(`${TRANSLATION}.description`)}
        </InterviewDescription>
        <Bottom>
          <Button isLoading={isLoading} onClick={onCreateInterviewClick}>
            {i18n.t(`${TRANSLATION}.practice_interview`)}
          </Button>
          {!isEligible && (
            <PremiumWarning>
              <Icon20.Crown color={Colors.Indigo70} /> {i18n.t(`${TRANSLATION}.available_premium`)}
            </PremiumWarning>
          )}
        </Bottom>
      </InterviewContainer>
    </Modal>
  )
}
