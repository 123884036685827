export enum CreditabilityCheckStatus {
  empty = 'empty',
  ok = 'ok',
  fake = 'fake',
}

export const checkTextCreditability = (text: string): CreditabilityCheckStatus => {
  if (!text) return CreditabilityCheckStatus.empty

  if (
    text.length >= 50 &&
    // text must have more than 9 unique symbols
    new Set(text).size >= 10
  ) {
    return CreditabilityCheckStatus.ok
  } else {
    return CreditabilityCheckStatus.fake
  }
}
