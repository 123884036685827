import { Resume } from 'packages/types'
import { ResumeDistributionPersonalForm } from 'builder/views/ResumeDistributionView/types'
import { BriefExpertDoc, BriefResume } from '../panel/types'
import { FetchStatuses } from '../constants'

export type Currency = 'USD' | 'EUR' | 'GBP' | 'CAD' | 'AUD'
export type ResumeDetails = BriefResume | BriefExpertDoc | File | null
export type RecruiterList<T> = Record<number, T>
export type RecruiterStatus<T> = Record<number, T>
export enum SubscriptionStatus {
  paused = 'paused',
  cancelled = 'cancelled',
  active = 'active',
}

export interface ResumeDistributionStore {
  isEdit: boolean
  isResumeSelected: boolean
  selectedResumeDetails: ResumeDetails
  formResumeFetchDetails: Resume | null
  isParsing: boolean
  form: Form

  // Candidate
  candidate?: Candidate
  candidateFetchStatus: FetchStatuses
  candidatePostStatus: FetchStatuses
  candidatePatchStatus: FetchStatuses
  candidateResumeFailed: boolean
  serverFailed: boolean

  // Disitribution dates
  distributionDates: DistributionDates
  distributionDatesFetchStatus: FetchStatuses

  // Recruiters
  recruiters: RecruiterList<RecruiterData>
  recruitersFetchStatus: RecruiterStatus<FetchStatuses>
  recruitersCount: number | string
  distributedRecruiterCount: number

  // Candidate Resume
  candidateResumePostStatus: FetchStatuses

  // Subscriptions
  subscriptionPostPauseStatus: FetchStatuses
  subscriptionPostResumeStatus: FetchStatuses
  subscriptionPostCreateStatus: FetchStatuses
}

export interface RecruiterPayload {
  count?: boolean
  date?: number
  page?: number
}

export interface RecruiterCountPayload {
  countryId?: string
  stateId?: string
  industryId?: string | number
}

export interface Recruiter {
  name: string
  jobTitle: string
  liUrl: string
  company: string
  industry: string
  location: {
    city: string
    state: string
    country: string
  }
  distribution: {
    date: number
    resumeFile: string
  }
}

export interface RecruiterData {
  data: Recruiter[]
  pagination: {
    count: number
    totalRecords: number
    currentPage: number
    totalPages: number
    nextPage: number
    prevPage: number
  }
}

export type Distribution = {
  day: number
  month: string
  year: number
  unixFormat: number
}

export type DistributionDates = {
  data: Array<Distribution>
  nextDistribution: Distribution
}

export enum Weeks {
  not_started = 0,
  first = 1,
  second = 2,
  third = 3,
  fourth = 4,
}

export enum FileUploadStatus {
  pending = 'pending',
  completed = 'completed',
}

export interface Subscription {
  subscriptionId: number
  subscriptionStatus: SubscriptionStatus
  purchaseDate: number
  endDate: number
  productId: number
  productName: string
  coupon: string
}
export interface Candidate {
  candidateId: string
  email: string
  firstName: string
  lastName: string
  jobTitle: string
  linkedinUrl: string
  distributionStatus: SubscriptionStatus
  distributionWeek: Weeks
  fileUploadId: string
  fileUploadStatus: FileUploadStatus | null
  resumeExists: boolean
  resumeId: number
  documentId: number
  fileName: string
  dateUploaded: number
  startedAt: Date
  pausedAt: Date | null
  location: {
    cityId: string
    cityName: string
    stateId: string
    stateName: string
    countryId: string
    countryName: string
  }
  preferences: {
    countryId: string
    countryName: string
    stateId: string
    stateName: string
    industryId: number
    industryName: string
    remote: string
    desiredSalary: {
      currency: Currency
      amount: string
      per: string
    }
  }
  subscriptions: Subscription[]
}

export type AutoSuggestItem = {
  id: string
  text: string
}

export interface IJobPreferencesForm {
  country?: AutoSuggestItem
  state?: AutoSuggestItem
  industry: number | string
  remote: string
  salaryCurrency: Currency
  salaryAmount: string
  salaryPeriod: string
}

export interface LocationForm {
  country?: AutoSuggestItem
  state?: AutoSuggestItem
  city?: AutoSuggestItem
}

export interface Form {
  jobPreferences: IJobPreferencesForm
  personalDetails: ResumeDistributionPersonalForm
  locationDetails: LocationForm
}

export interface CandidatePayload {
  email?: string
  first_name?: string
  last_name?: string
  job_title?: string
  linkedin_url?: string
  location?: {
    country_id?: number
    state_id?: number
    city_id?: number
  }
  preferences?: {
    country_id?: number
    state_id?: number
    industry_id?: number
    remote?: string
    desired_salary?: {
      currency?: string
      amount?: number
      per?: string
    }
  }
}
