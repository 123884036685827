import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as userSelectors } from 'builder/modules/user'
import Colors from 'builder/styles/colors'

import { Step } from '../Step'
import {
  EllipseOne,
  EllipseThree,
  EllipseTwo,
  EllipsisContainer,
  AddIconContainer,
  AddIcon,
  Score,
  AvatarIcon,
  ScoreWrapper,
  Duration,
  ClockIcon,
  DurationInfo,
  Dot,
  DurationOpen,
  Container,
  Avatar,
} from './styles'

export type ColorsProp = {
  start: string
  end: string
}

interface Props {
  isCompleted: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  score: number
  resumeId?: number
}

const getProgressColor = (completenessPercent: number): ColorsProp => {
  if (completenessPercent < 10) {
    return { start: Colors.Orange50, end: Colors.Orange30 }
  } else if (completenessPercent < 70) {
    return { start: Colors.Amber50, end: Colors.Amber30 }
  } else {
    return { start: Colors.Green50, end: Colors.Green30 }
  }
}

export const CareerProfileStep = ({ isCompleted, isOpen, setIsOpen, score }: Props) => {
  const [showHover, setShowHover] = useState(false)
  const navigate = useNavigate()
  const { i18n } = useI18n()
  const photoUrl = useTypedSelector(userSelectors.photoUrl)

  return (
    <Step
      customHeight={72}
      title={i18n.t('builder.dashboard_v2.steps.career_profile.title')}
      subtitle={i18n.t('builder.dashboard_v2.steps.career_profile.subtitle')}
      isCompleted={isCompleted}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleOnClick={() => navigate('/career-profile/insights')}
      buttonText={i18n.t('builder.dashboard_v2.steps.career_profile.button_text')}
      analyticsEventsParam="career_profile"
      useSecondaryTheme
      CustomText={
        <Duration>
          <ClockIcon isOpen={false} />
          <DurationInfo>
            15 {i18n.t('builder.dashboard_v2.steps.career_profile.minutes')}
          </DurationInfo>
          <Dot isOpen={false} />
          <DurationInfo>
            11 {i18n.t('builder.dashboard_v2.steps.career_profile.questions')}
          </DurationInfo>
        </Duration>
      }
      Images={
        <>
          <Container>
            <Avatar>
              <ScoreWrapper percentageColor={getProgressColor(score)}>
                <AvatarIcon src={photoUrl} />
                <Score percentageColor={getProgressColor(score)}>{score}%</Score>
              </ScoreWrapper>
              <AddIconContainer className={showHover ? 'hover' : ''}>
                <AddIcon className={showHover ? 'hover' : ''} />
              </AddIconContainer>
            </Avatar>

            <EllipsisContainer>
              <EllipseOne className={showHover ? 'hover' : ''} />
              <EllipseTwo className={showHover ? 'hover' : ''} />
              <EllipseThree className={showHover ? 'hover' : ''} />
            </EllipsisContainer>
          </Container>

          <DurationOpen>
            <ClockIcon isOpen />
            <DurationInfo>
              15 {i18n.t('builder.dashboard_v2.steps.career_profile.minutes')}
            </DurationInfo>
            <Dot isOpen />
            <DurationInfo>
              11 {i18n.t('builder.dashboard_v2.steps.career_profile.questions')}
            </DurationInfo>
          </DurationOpen>
        </>
      }
      setShowHover={setShowHover}
    />
  )
}
