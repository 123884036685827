import { ReactElement } from 'react'
import { Item } from './styles'

interface ListItemProps {
  padding?: string
  children: ReactElement
}

export const ListItem = ({ padding, children }: ListItemProps) => {
  return <Item padding={padding}> {children} </Item>
}
