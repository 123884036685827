import styled from 'styled-components'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Typography from 'builder/styles/typography'
import { rotation } from 'builder/styles/keyframes'

export const Container = styled.div`
  padding-top: 14px;
  user-select: none;
`

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
`

export const Photo = styled.div`
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: ${Colors.Neutral10};
  background-image: ${props => (props.imageUrl ? `url(${props.imageUrl})` : 'none')};
  background-size: cover;
`

export const PhotoPlaceholder = styled(Photo)`
  color: ${Colors.Neutral30};
  transition: background-color 0.1s ease, color 0.1s ease;

  ${Wrapper}:hover & {
    background-color: ${Colors.Blue10};
    color: ${Colors.Blue50};
  }
`

export const PhotoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => hexToRgba(Colors.Neutral100, props.enable ? 0.3 : 0)};
`

export const Lock = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${props => (props.dark ? Colors.Neutral100 : Colors.Neutral10)};
  color: ${props => (props.dark ? Colors.White : Colors.Neutral30)};
  border-color: ${Colors.Neutral30};
  border-style: solid;
  border-width: ${props => (props.dark ? 0 : '1px')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Spinner = styled(Lock)`
  animation: ${rotation} 0.5s infinite linear;
`

export const ButtonBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`

export const Caption = styled.div`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  max-width: 180px;
`

export const UploadButton = styled.div`
  ${Typography.Caption};
  color: ${Colors.Blue50};
  transition: color 0.1s ease;

  ${Wrapper}:hover & {
    color: ${Colors.Blue70};
  }
`

export const EditButton = styled.div`
  ${Typography.Caption};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${Colors.Blue50};
  transition: color 0.1s ease;
  padding: 4px 0;
  cursor: pointer;

  &:hover {
    color: ${Colors.Blue70};
  }
`

export const DeleteButton = styled(EditButton)`
  color: ${Colors.Neutral50};

  &:hover {
    color: ${Colors.Red50};
  }
`

export const IconBlock = styled.div`
  margin-right: 4px;
  display: flex;

  ${DeleteButton} & {
    color: ${Colors.Neutral30};
  }

  ${DeleteButton}:hover & {
    color: ${Colors.Red50};
  }
`

export const FileInput = styled.input`
  visibility: hidden;
  position: absolute;
  width: 0px;
  height: 0px;
  pointer-events: none;
`
