import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

const { Caption, Body, Tiny } = Typography
const { Neutral50, Neutral5, Blue10, Blue50, Indigo10, Indigo50, Indigo60 } = Colors

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 520px;
  gap: 16px;
  ${Media.Phone`
    width: 311px;
    gap: 8px;
  `}
`

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${Neutral5};
  border-radius: 6px;
  padding: 15px 16px 15px 21px;
  ${Media.Phone`
  padding: 16px;
`}

  ${Media.AboveTablet`
  &:hover {
    cursor: pointer;
    background-color: ${Blue10};
  }
`}
`

export const CardDescription = styled.div`
  margin-left: 20px;
  text-align: left;
  ${Media.Phone`
    margin-left: 8px;
  `}
`

export const CardTitle = styled.p`
  margin-bottom: 2px;
  ${FontWeights.DemiBold}
  ${Body}
  ${Media.AboveTablet`
    ${Card}:hover & {
      color: ${Blue50};
    }
  `}
  ${Media.Phone`
    margin-bottom: 4px;
  `}
`

export const CardSubtitle = styled.p<{ $fixedWidth?: number }>`
  color: ${Neutral50};
  ${FontWeights.Regular};
  ${Caption};
  ${props => props.$fixedWidth && `max-width: ${props.$fixedWidth}px;`}
  width: 100%;
`

export const CreateNewResumeCard = styled(Card)`
  // To make Create new resume card same height of the rest of cards in Desktop
  padding: 23px 16px 23px 21px;
  ${Media.Phone`
    padding: 16px;
  `}
`

export const UseExampleCard = styled(CreateNewResumeCard)``

export const CreateWithAICard = styled(Card)`
  ${Media.AboveTablet`
    &:hover {
      background-color: ${Indigo10}; // Different background color for AI resume card hover
    }
  `}
`

export const SpinnerWrapper = styled.div`
  margin-left: auto;
  height: 24px;
  width: 24px;
  & > div {
    width: inherit;
    height: inherit;
  }
`

export const IconWrapper = styled.div`
  margin-left: auto;
  ${Media.AboveTablet`
    ${Card}:hover & {
      svg path {
        fill: ${Blue50};
      }
    }
  `}
`

export const CreateWithAIArrowIcon = styled(IconWrapper)`
  ${Media.AboveTablet`
    ${Card}:hover & {
      svg path {
        fill: ${Indigo50};
      }
    }
  `}
`

export const BaseImage = styled.img`
  width: 50px;
  height: 50px;
  ${Media.Phone`
    width: 40px;
    height: 40px;
  `}
`

export const CreateResumeImage = styled(BaseImage)``
export const CreateWithAIImage = styled.img`
  padding: 6.5px 7.5px;
  ${Media.Phone`
    width: 40px;
    height: 32px;
    padding: 0px;
  `}
`
export const UploadResumeImage = styled(BaseImage)``
export const UseExampleImg = styled.img`
  width: 33px;
  height: 34px;
`

export const CreateWithAICardTitle = styled(CardTitle)`
  margin-bottom: 0px;
  ${Media.AboveTablet`
    ${Card}:hover & {
      color: ${Indigo60}; // Different text color for AI resume hover
    }
  `}
`

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2px;
  ${Media.Phone`
    margin-bottom: 4px;
  `}
`
export const AIPoweredBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  border-radius: 4px;
  background: rgba(219, 222, 255, 0.5);
  ${Tiny};
  color: ${Colors.Indigo60};
  margin-left: 8px;
  ${FontWeights.DemiBold};
`

export const AIStarImg = styled.img`
  margin: 0px -4px;
`

export const ImageWrapper = styled.div``
export const UseExampleImgWrapper = styled(ImageWrapper)`
  padding: 8px 8.5px;
  ${Media.Phone`
  padding: 3px 3.5px;;
  `}
`
