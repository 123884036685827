import { apiClient, baseClient } from 'builder/modules/apiClient'
import {
  Job,
  PrefillApiResponse,
  ResultStats,
  SearchApiResponse,
  SearchQuery,
  RecommendJobsApiResponse,
} from './JobSearchResult/types'
import { AVAILABLE_COUNTRY } from './types'
import { LocationResponse } from './SearchBar/constants'
import { SearchSuggestionType } from './AutoSuggestField/types'

type PerformSearch = {
  jobs: Job[]
  stats: ResultStats
  correctedTerm: string | null
}
export const performApiSearch = async (params: SearchQuery): Promise<PerformSearch> => {
  const result = await baseClient.get<SearchApiResponse>('jobs/v1/search', { params })

  const { jobs, corrected_term: correctedTerm, ...stats } = result.data

  return {
    jobs,
    stats,
    correctedTerm,
  }
}

export const performSimilarJobsApi = async (
  params: Omit<SearchQuery, 'correct_term' | 'view'>,
): Promise<Omit<PerformSearch, 'correctedTerm'>> => {
  const result = await baseClient.get<Omit<SearchApiResponse, 'corrected_term'>>(
    'jobs/v1/search/similar-jobs',
    { params },
  )

  const { jobs, ...stats } = result.data

  return {
    jobs,
    stats,
  }
}

export const fetchRecommendations = async (
  page: number,
  latitude?: number,
  longitude?: number,
): Promise<Omit<PerformSearch, 'correctedTerm'>> => {
  const url =
    latitude && longitude
      ? `career/v1/recommended-jobs?page=${page}&location_latitude=${latitude}&location_longitude=${longitude}`
      : `career/v1/recommended-jobs?page=${page}`

  const result = await baseClient.get<RecommendJobsApiResponse>(url)

  const { jobs, ...stats } = result.data

  return {
    jobs,
    stats,
  }
}

type PrefillSearchData = {
  jobTitle: string | undefined
  prefillLocation: string | undefined
}

export const prefillJobSearchData = async (): Promise<
  PrefillSearchData & { available: boolean }
> => {
  const response = await apiClient.get<PrefillApiResponse>('/jobs/prefill')

  const { jobTitle, location: currentLocation } = response.data
  const prefillLocation: string | undefined = [currentLocation?.city, currentLocation?.region]
    .filter(Boolean)
    .join(', ')

  return {
    jobTitle,
    prefillLocation: prefillLocation?.length ? prefillLocation : currentLocation?.country,
    available: currentLocation?.country === AVAILABLE_COUNTRY,
  }
}

export const getCurrentPosition = (): Promise<GeolocationPosition> =>
  new Promise((resolve, reject) => {
    const rejectionCallBackId = setTimeout(
      () => reject(new Error('Timeout on getCurrentPosition')),
      5000,
    )
    navigator.geolocation.getCurrentPosition(
      r => {
        clearInterval(rejectionCallBackId)
        resolve(r)
      },
      reject,
      { timeout: 5000 },
    )
  })

export const getLocationFromServer = async () => {
  const geoLocation = await getCurrentPosition()
  let response
  if (geoLocation?.coords) {
    response = await baseClient.get<LocationResponse>(`/jobs/v1/search/location`, {
      params: { lat: geoLocation?.coords?.latitude, lng: geoLocation?.coords?.longitude },
    })
    const state = response.data
    const suggestionObj: SearchSuggestionType = {
      value: String(state.id),
      text: state.name,
      formatted: state.formatted_name,
      locationType: 'near_me',
      lat: String(geoLocation?.coords?.latitude),
      lng: String(geoLocation?.coords?.longitude),
    }
    return suggestionObj
  }
}
