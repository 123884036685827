import { trackInternalEvent } from '@rio/tracking'
import { useNavigate } from 'react-router-dom'
import Button from 'builder/components/Button'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import * as Styles from './styles'
const InboxIcon = require('./assets/InboxIcon.svg')

const ResumeDistributionBanner = () => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const { getHost } = useWebsiteHost()
  const navigate = useNavigate()
  const features = useFeaturesConfig()

  const handleBannerCTA = () => {
    trackInternalEvent('click_documents_resume_distribution_banner', {
      website_host: getHost,
    })

    navigate('/resume-distribution')
  }

  if (!features?.showResumeDistribution()) {
    return null
  }

  return (
    <Styles.Container>
      <Styles.InboxImage alt="Inbox" src={InboxIcon} />

      <Styles.Title data-testid="title">
        {isPhone
          ? i18n.t('builder.panel.documentSectionResumeDistributionBanner.isPhoneTitle')
          : i18n.t('builder.panel.documentSectionResumeDistributionBanner.title')}
      </Styles.Title>

      <Styles.Text data-testid="text">
        {isPhone
          ? i18n.t('builder.panel.documentSectionResumeDistributionBanner.isPhoneText')
          : i18n.t('builder.panel.documentSectionResumeDistributionBanner.text')}
      </Styles.Text>

      <Styles.ButtonContainer data-testid="button" onClick={handleBannerCTA}>
        <Button theme="ghost" size="small">
          {isPhone
            ? i18n.t('builder.panel.documentSectionResumeDistributionBanner.isPhoneButton')
            : i18n.t('builder.panel.documentSectionResumeDistributionBanner.button')}
        </Button>
      </Styles.ButtonContainer>
    </Styles.Container>
  )
}

export default ResumeDistributionBanner
