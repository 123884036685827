import * as React from 'react'
import mapValues from 'lodash/mapValues'
import * as IconSource16 from './16x16'
import * as IconSource20 from './20x20'
import * as IconSource32 from './32x32'
import * as IconSource24 from './24x24'
import * as IconSource48 from './48x48'
import * as IconSource56 from './56x56'

interface SvgContainerProps extends React.SVGAttributes<SVGElement> {
  size: number
}

const SvgContainer = ({ size, ...props }: SvgContainerProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  />
)

const withContainer = (Icon: () => JSX.Element, size: number) => {
  const IconWithContainer = (props: React.SVGAttributes<SVGElement>) => (
    <SvgContainer size={size} {...props}>
      <Icon />
    </SvgContainer>
  )
  return IconWithContainer
}

export const Icon16 = mapValues(IconSource16, Icon => withContainer(Icon, 16))
export const Icon20 = mapValues(IconSource20, Icon => withContainer(Icon, 20))
export const Icon24 = mapValues(IconSource24, Icon => withContainer(Icon, 24))
export const Icon32 = mapValues(IconSource32, Icon => withContainer(Icon, 32))
export const Icon48 = mapValues(IconSource48, Icon => withContainer(Icon, 48))
export const Icon56 = mapValues(IconSource56, Icon => withContainer(Icon, 56))
