import styled from 'styled-components'
import Icon from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import { DashboardBuilder } from 'builder/components/Icon/24x24'
import Button, { ButtonTheme } from 'builder/components/Button'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  padding: 12px 0 0;
  top: 0;
  position: fixed;
  background-color: white;
  z-index: 5;
  gap: 5px;
  margin-left: 5px;
  left: 0;
`

export const ToggleButton = styled.div`
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: ${Colors.Blue10};
    svg {
      color: ${Colors.Blue50};
      fill: ${Colors.Blue50};
    }
  }

  ${Media.Phone`
    display: none;
  `}
`

export const CloseIcon = styled(Icon.CloseLarge)``
export const OpenIcon = styled(DashboardBuilder)``

export const ButtonContainer = styled.div`
  padding: 14px 0 0;
  position: absolute;
  left: 50px;
  top: 0;

  ${Media.Phone`
    position: relative;
    left: 0;
    margin-left: 20px;
    margin-top: 2px;
  `}
`

export const DashboardButton = styled(Button).attrs({ theme: ButtonTheme.secondary })`
  border-radius: 32px;
  padding: 8px 16px;
  color: ${Colors.Neutral80};
  background-color: ${Colors.Neutral10};

  &:hover {
    color: ${Colors.Blue50};
  }
`

export const SettingsContainer = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
  margin-left: 0;
  z-index: 50;

  ${Media.Laptop`
    position: absolute
  `}
`

export const Profile = styled.div`
  right: 20px;
  cursor: pointer;
  position: fixed;
  top: 12px;
  margin-left: 0;
  z-index: 50;

  ${Media.Phone`
    position: absolute;
  `}
`
