import { useCallback, useState } from 'react'
import { trackInternalEvent, trackMarketingEvent } from '@rio/tracking'
import { FetchStatuses } from 'builder/modules/constants'
import { apiClient } from 'builder/modules/apiClient'
import { authRedirectService } from 'builder/services/AuthRedirectService'

type AccountCreationPayload = {
  firstName: string
  lastName: string
  email: string
  gclid: string | null
}

export const useAccountCreation = () => {
  const [status, setStatus] = useState(FetchStatuses.notAsked)

  const handleSubmit = useCallback(async (payload: AccountCreationPayload) => {
    setStatus(FetchStatuses.loading)
    trackInternalEvent('submit_sign_up_email')

    try {
      // Request user creation
      const careerIOUserProvisioningTimeStamp = localStorage.getItem(
        'provisioning-premium-with-3-auto-apply-credits',
      )
      const accountCreatePayload = careerIOUserProvisioningTimeStamp
        ? { ...payload, career_io_user_free_trial: true }
        : payload

      await apiClient.post(`/account`, accountCreatePayload)

      // record clickhouse event
      trackInternalEvent('complete_email_sign_up')

      // Record sign-up for our marketing department
      trackMarketingEvent('Sign up', 'Complete Registration')
      if (localStorage.getItem('provisioning-premium-with-3-auto-apply-credits')) {
        trackMarketingEvent('TJSS Sign up', 'Complete Registration')
      }
      // Open the app's dashboard or the page user was redirected from before
      authRedirectService.enterApp()
    } catch (error) {
      setStatus(FetchStatuses.failed)
      // TODO: Add edge-case error handling in a separate PR
    }
  }, [])

  return { status, handleSubmit }
}
