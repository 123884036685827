import React from 'react'
import { Icon20, Icon24 } from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { Interview, InterviewAnswer } from 'builder/modules/interview/types'
import { HorizontalPosition, VerticalPosition } from 'builder/components/Tooltip/types'
import { TRANSLATION_KEY } from '../DashboardView/ActiveInterviews/ActiveInterviews'
import { CustomBadge, CustomTooltip, Label, Spinner } from './styles'

type BaseProps = {
  tooltipWidth?: number
  position?: HorizontalPosition | VerticalPosition
  disableTooltip?: boolean
  isLoading?: boolean
}
type PropsWithInterview = BaseProps & {
  interview: Interview
  answer?: never
}
type PropsWithAnswer = BaseProps & {
  interview?: never
  answer?: InterviewAnswer
}
type Props = PropsWithInterview | PropsWithAnswer

type WrapperToolTip = {
  hintText: string
  children: React.ReactNode
}
export const InterviewStatus = ({
  position = 'top',
  tooltipWidth,
  disableTooltip = false,
  answer,
  isLoading,
  interview,
}: Props) => {
  const { i18n } = useI18n()
  const WrapperTooltip = ({ hintText, children }: WrapperToolTip) =>
    disableTooltip ? (
      <>{children}</>
    ) : (
      <CustomTooltip
        value={hintText}
        position={position}
        align="left"
        multiline
        width={tooltipWidth}
      >
        {children}
      </CustomTooltip>
    )

  if (isLoading) {
    return (
      <CustomBadge variant="neutral">
        <Spinner /> <Label>{i18n.t(`${TRANSLATION_KEY}.loading_score`)}</Label>
      </CustomBadge>
    )
  }

  const grade = interview?.grade || answer?.feedback.grade

  switch (grade) {
    case 'average':
      return (
        <WrapperTooltip hintText={i18n.t(`${TRANSLATION_KEY}.average_hint`)}>
          <CustomBadge variant="amber">
            <Icon20.Star />
            <Label>{i18n.t(`${TRANSLATION_KEY}.average`)}</Label>
          </CustomBadge>
        </WrapperTooltip>
      )
    case 'perfect':
      return (
        <WrapperTooltip hintText={i18n.t(`${TRANSLATION_KEY}.perfect_hint`)}>
          <CustomBadge variant="green">
            <Icon20.WarningCircle />
            <Label>{i18n.t(`${TRANSLATION_KEY}.perfect`)}</Label>
          </CustomBadge>
        </WrapperTooltip>
      )
    case 'good':
      return (
        <WrapperTooltip hintText={i18n.t(`${TRANSLATION_KEY}.good_hint`)}>
          <CustomBadge variant="green">
            <Icon20.WarningCircle />
            <Label>{i18n.t(`${TRANSLATION_KEY}.good`)}</Label>
          </CustomBadge>
        </WrapperTooltip>
      )
    default:
      return (
        <WrapperTooltip hintText={i18n.t(`${TRANSLATION_KEY}.evaluating_hint`)}>
          <CustomBadge variant="indigo">
            <Icon24.Simple />
            <Label>{i18n.t(`${TRANSLATION_KEY}.evaluating`)}</Label>
          </CustomBadge>
        </WrapperTooltip>
      )
  }
}
