import { useMutation } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'

export type InterviewPresignedUrlParams = {
  partNumber: number
  questionId: number
  answerId: number
}

export const useInterviewPresignedUrl = () => {
  return useMutation({
    mutationFn: async (params: InterviewPresignedUrlParams) => {
      const { partNumber, questionId } = params
      const response = await baseClient.get(
        `/interview_prep/questions/${questionId}/answer?part_number=${partNumber}`,
      )
      return response.data
    },
  })
}
