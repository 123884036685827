import { trackInternalEvent } from '@rio/tracking'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { i18n } from 'builder/utils/i18n'
import { actions } from 'builder/modules/resumeEditor'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import starIndigo from './StarIndigo.svg'
import starWhite from './StarWhite.svg'
import {
  BadgeBanner as Banner,
  BadgeInfoContainer,
  HeadingContainer,
  Heading,
  AIPoweredBadge,
  AIPoweredText,
  Star,
  SubHeading,
  GenerateButton,
} from './styles'

interface AiCoverletterBannerprops {
  onBannerGenerateClick: () => void
}

const BadgeBanner = ({ onBannerGenerateClick }: AiCoverletterBannerprops) => {
  const targetRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const { viewGenerateCLBannerEventTracked } = useTypedSelector(state => state.resumeEditor)

  const handleIntersection: IntersectionObserverCallback = useCallback(
    entries => {
      entries.forEach(entry => {
        // if the banner is in the viewport, it will trigger the event, if it is not tracked already
        if (entry.isIntersecting) {
          trackInternalEvent('see_ai_cover_letter_banner')
          dispatch(actions.setGenerateCLBannerEventTracked(true))
        }
      })
    },
    [dispatch],
  )

  useEffect(() => {
    // Create an observer with a callback function to trigger the analytics event
    if (viewGenerateCLBannerEventTracked) return

    const observer = new IntersectionObserver(handleIntersection)
    const bannerRef = targetRef.current
    if (bannerRef) {
      observer.observe(bannerRef)
    }

    return () => {
      if (bannerRef) {
        observer.unobserve(bannerRef)
      }
    }
  }, [handleIntersection, viewGenerateCLBannerEventTracked, targetRef])

  return (
    <Banner ref={targetRef}>
      <BadgeInfoContainer>
        <HeadingContainer>
          <Heading>{i18n.t('builder.ai_cover_letter.cover_letter')}</Heading>
          <AIPoweredBadge>
            <Star showAnimation={false} src={starIndigo} alt="Star Image" />
            <AIPoweredText>{i18n.t('builder.ai_cover_letter.ai_powered')}</AIPoweredText>
          </AIPoweredBadge>
        </HeadingContainer>
        <SubHeading>{i18n.t('builder.ai_cover_letter.badge_banner.content')}</SubHeading>
      </BadgeInfoContainer>
      <GenerateButton onClick={onBannerGenerateClick}>
        <Star showAnimation={false} src={starWhite} alt="Star Image" />
        {i18n.t('builder.ai_cover_letter.generate')}
      </GenerateButton>
    </Banner>
  )
}

export default BadgeBanner
