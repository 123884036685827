import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
import Typography from 'builder/styles/typography'
import checkmark from './assets/checkmark.svg'
import target from './assets/target.svg'

interface FeatureSlotProps {
  variant: string
  useBiggerPadding: boolean
  isControl: boolean
  isHitesh: boolean
}

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: #000000ba;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
`

export const RootModalContainer = styled.div<{ $isControl: boolean; $isNath: boolean }>`
  background-color: ${Colors.White};
  border-radius: 8px;
  display: flex;
  width: fit-content;
  position: relative;
  ${props => props.$isControl && 'align-items: center;'}
  ${props => props.$isNath && 'flex-direction: column;'}

  ${Media.Tablet`
    max-width: 552px;
    width: 100%;
  `}

  ${props =>
    !props.$isNath &&
    css`
      ${Media.Tablet`
          flex-direction: column-reverse;
        `}
    `}

  ${Media.Phone`
    width: 95%;
  `}

   ${props =>
    !props.$isNath &&
    css`
      ${Media.Phone`
          flex-direction: column-reverse;
        `}
    `}
`

export const ModalSection = styled.div<{
  $isControl: boolean
  $maxWidth: number
  $isNath: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: ${props => `${props.$maxWidth}px`};
  height: 100%;
  padding: ${props => (props.$isNath ? '32px 12px' : '40px 40px 30px')};
  margin: auto;

  ${props =>
    props.$isControl
      ? css`
          ${Media.Tablet`
        padding: 0 24px 24px 24px;
      `}
        `
      : css`
          ${Media.Tablet`
        padding: 0 ${Sizes.L} ${Sizes.L};
      `}
        `}

  ${Media.Tablet`
    max-width: unset;
  `}

  ${Media.Phone`
    width: 100%;
    padding: 24px;
  `}
`

export const ModalImageSection = styled.div<{ $isControl: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-radius: 0 8px 8px 0;
  align-items: center;
  height: 100%;

  ${Media.Tablet`
    border-radius: 0;
    width: 100%;
    height: fit-content;
    padding: 0;
  `}

  ${props =>
    props.$isControl
      ? css`
          ${Media.Tablet`
            margin-top: 20px;
          `}
        `
      : css`
          ${Media.Tablet`
            margin-top: 12px;
          `}
        `}
`

export const ModalImageSectionContent = styled.div<{ $isControl: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: ${props => (props.$isControl ? '0 40px 0 0' : ' 8px 8px 8px 0')};
  gap: ${props => (props.$isControl ? '16px' : '8px')};

  ${Media.Tablet`
    width: 100%;
    gap: 10px;
    padding: 0 20px;
    height: fit-content;
  `}

  ${props =>
    props.$isControl
      ? css`
          ${Media.Tablet`
            padding: 0 20px;
          `}
        `
      : css`
          ${Media.Tablet`
            padding: 0 12px;
      `}

          ${Media.Phone`
            padding: 0 8px;
      `}
        `}
`

export const CloseIcon = styled(Icon24.Close)`
  display: block;
  color: ${Colors.Neutral50};
  transition: color 0.3s;
  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Close = styled.div`
  display: flex;
  justify-self: flex-end;
  right: 0;
  height: fit-content;
  width: fit-content;
  margin: 32px 32px 0 32px;
  background-color: ${Colors.Neutral5};
  border-radius: 32px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${Colors.Blue10};
  }

  ${Media.Tablet`
    margin: 20px;
  `}
`

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
`

export const GetStartedHeaderContainer = styled.div<{ $isNath: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => !props.$isNath && 'gap: 12px;'}

  ${Media.Phone`
    gap: 8px;
  `}
`

export const GetStartedTitle = styled.h2<{
  $maxWidth?: number
  $isControl: boolean
  $isNath: boolean
}>`
  font-size: 33px;
  font-weight: 600;
  color: ${Colors.Neutral90};
  line-height: 36px;
  ${props => props.$maxWidth && `max-width: ${props.$maxWidth}px`};

  ${Media.Tablet`
    font-size: 26px;
    max-width: unset;
  `}

  ${props =>
    props.$isControl
      ? css`
          ${Media.Tablet`
            margin-top: 24px;
          `}

          ${Media.Phone`
            font-size: 19px;
          `}
        `
      : css`
          ${Media.Tablet`
            margin-top: 32px;
            font-size: 33px;
          `}

          ${Media.Phone`
            font-size: 27px;
          `}
        `}

  ${Media.Phone`
    margin-top: 0;
    line-height: 24px;
  `}

   ${props =>
    props.$isNath &&
    css`
      ${Media.Phone`
            max-width: 206px;
          `}
    `}
`

export const GetStartedDescription = styled.p<{ $isControl: boolean; $isNath: boolean }>`
  color: ${Colors.Neutral50};
  font-size: 16px;
  line-height: 20px;

  ${props =>
    props.$isControl
      ? css`
          ${Media.Tablet`
            font-size: 19px;
          `}
        `
      : css`
          ${Media.Tablet`
            line-height: 24px;
          `}
        `}

  ${props =>
    props.$isNath &&
    css`
      ${Media.Tablet`
            font-size: 19px;
          `}
    `}

  ${Media.Phone`
    font-size: 16px;
    line-height: 20px;
  `}
`

export const GetStartedFeaturesContainer = styled.div<{ $isNath: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;

  ${props =>
    props.$isNath &&
    css`
      ${Media.Tablet`
            margin-top: 16px;
          `}
    `}
`

export const GetStartedFeatureTitle = styled.h3`
  color: ${Colors.Neutral80};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1.8px;
  text-transform: uppercase;

  ${Media.Tablet`
    font-size: 16px;
  `}

  ${Media.Phone`
    font-size: 12px;
    line-height: 14px;
  `}
`

export const GetStartedFeatures = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
`

export const GetStartedFeature = styled.li<{ $isNath: boolean }>`
  color: ${Colors.Neutral80};
  line-height: 20px;
  font-size: 16px;
  align-items: center;
  display: flex;

  &:before {
    content: '';
    display: inline-block;
    background-image: url(${checkmark});
    width: 20px;
    height: 22px;
    margin-right: 4px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  ${Media.Tablet`
    font-size: 18px;
    line-height: 20px;
  `}

  ${Media.Phone`
    font-size: 15px;
    line-height: 14px;
  `}

  ${props =>
    props.$isNath &&
    css`
      ${Media.Phone`
        font-size: 16px;
      `}
    `}
`

export const AlternativeWideFeatureSlotImage = styled.img`
  width: 150px;
  height: 104px;
`

export const WideFeatureSlotImage = styled.svg`
  background-image: url(${target});
  background-size: contain;
  background-repeat: no-repeat;
  width: 120px;
  height: 112px;

  ${Media.Tablet`
    height: 120px;
    width: 200px;
  `}

  ${Media.Phone`
    height: 80px;
    width: 112px;
    margin-right: 20px;
  `}
`

export const WideFeatureSlot = styled.div<{ $isControl: boolean; $isHitesh: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${Colors.Blue10};
  padding: ${props => (props.$isControl ? '20px' : `${Sizes.XL} ${Sizes.L}`)};
  ${props => props.$isHitesh && 'gap: 16px;'}

  ${props =>
    props.$isControl &&
    css`
      ${Media.Tablet`
            gap: 92px;
            padding: 20px 0 20px 20px;
          `}

      ${Media.Phone`
            gap: 17px;
          `}
    `}

  ${props =>
    !props.$isControl &&
    css`
      ${Media.Tablet`
            gap: 62px;
            padding: ${Sizes.XL} ${Sizes['2XL']} ${Sizes.XL} ${Sizes.L};
          `}

      ${Media.Phone`
        gap: 16px;
      `}
    `}

  ${Media.Phone`
    padding: 16px;
  `}
`

export const WideFeatureSlotTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.Blue80};
`

export const GetStartedTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FeatureSlot = styled.div<FeatureSlotProps>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: none;
  padding: ${props => (props.useBiggerPadding ? `${Sizes.XL} ${Sizes.L}` : '25px 20px')};
  background-color: ${props => (props.variant === 'green' ? Colors.Green10 : Colors.Indigo10)};
  color: ${props => (props.variant === 'green' ? Colors.Green80 : Colors.Indigo80)};

  ${props =>
    props.isControl
      ? css`
          ${Media.Tablet`
        padding: 20px 60px 20px 20px;
      `}
        `
      : css`
          ${Media.Tablet`
      border-radius: 12px;
    `}
        `}

  ${Media.Phone`
    padding: 16px;
    border-radius: 8px;
  `}

    ${props =>
    !props.isControl &&
    css`
      ${Media.Phone`
        border-radius: 4px;
      `}
    `}

  ${props =>
    props.isHitesh &&
    css`
      ${Media.Phone`
            padding: 26px;
          `}
    `}
`

export const NarrowFeatureSlotsContainer = styled.div<{ $isControl: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${props => (props.$isControl ? '16px' : '8px')};

  ${Media.Phone`
    gap: 10px;
  `}
`

export const FeatureSlotTitle = styled.h3`
  font-size: 2.5em;
  margin-bottom: 25px;

  ${Media.Tablet`
    font-size: 40px;
    margin-bottom: 15px;
  `}

  ${Media.Phone`
    font-size: 30px;
    margin-bottom: 15px;
  `}
`

export const FeatureSlotDescription = styled.p<{
  $maxWidth?: number
  $maxWidthTablet?: number
  $maxWidthPhone?: number
}>`
  ${props => props.$maxWidth && `max-width: ${props.$maxWidth}px`};
  font-size: 1.2em;

  ${Media.Tablet`
    font-size: 20px;
  `}

  ${props =>
    props.$maxWidthTablet
      ? css`
          ${Media.Tablet`
            max-width: ${props.$maxWidthTablet}px;
          `}
        `
      : css`
          ${Media.Tablet`
           max-width: unset;
        `}
        `}

  ${Media.Phone`
    font-size: 16px;
    line-height: 18px:
  `}

  ${props =>
    props.$maxWidthPhone
      ? css`
          ${Media.Phone`
            max-width: ${props.$maxWidthPhone}px;
          `}
        `
      : css`
          ${Media.Phone`
           max-width: unset;
        `}
        `}
`

export const CTAContainer = styled.div<{ $isNath: boolean }>`
  display: flex;
  align-items: ${props => (props.$isNath ? 'flex-end' : 'flex-start')};
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1.5em;
  gap: 0.5em;

  ${Media.Tablet`
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    text-align: end;
    gap: 1em;
  `}

  ${props =>
    props.$isNath &&
    css`
      ${Media.Phone`
            justify-content: flex-start;
            margin-top: 16px;
          `}
    `}
`

export const CTAPrimaryContainer = styled.div`
  display: flex;
  gap: ${Sizes.S};
  align-items: center;

  ${Media.Tablet`
    flex-direction: row-reverse;
  `}
`

export const CTADescription = styled.span`
  color: ${Colors.Neutral50};
  ${Typography.Caption};
  max-width: 210px;

  ${Media.Tablet`
    max-width: unset;
  `}

  ${Media.Phone`
    max-width: 127px;
  `}
`

export const CTATopLabel = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.Neutral80};

  ${Media.Tablet`
    max-width: 152px;
    text-align: end;
  `}

  ${Media.Phone`
    font-size: 15px;
    line-height: 18px;
  `}
`

export const TopBlueBadgeContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: -55px;

  ${Media.Tablet`
    margin-top: 10px;
  `}

  ${Media.Phone`
    margin-top: 0;
  `}
`
export const TopBlueBadgeText = styled.div`
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.8px;
  border-radius: var(--2XS, 8px);
  text-transform: uppercase;
  background: ${Colors.Blue10};
  color: ${Colors.Blue90};
  font-weight: 600;
`

export const CTAPrimary = styled.button`
  border-radius: 4px;
  border: none;
  background: ${Colors.Blue50};
  color: ${Colors.White};
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  display: inline-flex;
  padding: ${Sizes.XS} ${Sizes.L};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: ${Colors.Blue60};
  }

  &:active {
    background: ${Colors.Blue70};
  }

  &:disabled {
    color: ${Colors.Neutral50};
    background-color: transparent;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }

  ${Media.Phone`
    font-size: 16px;
  `}
`

export const NathImage = styled.img`
  width: 208px;
  height: 300px;

  ${Media.Phone`
    width: 121px;
    height: 178px;
  `}
`

export const NathImageContainer = styled.div`
  padding: 0px 168.308px 0px 168px;

  ${Media.Phone`
    padding: unset;
    margin: auto;
  `}
`
