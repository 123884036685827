import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'
import Colors from 'builder/styles/colors'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 104px;
  max-width: 100vw;
  overflow-x: hidden;
`

export const Image = styled.img`
  width: 126.5px;
  height: 88px;
  flex-shrink: 0;
`

export const Text = styled.div`
  ${Typography.Body}
  text-align: center;
  width: 544px;
  margin-bottom: 24px;

  ${Media.Tablet`
    width: 540px;
  `}

  ${Media.Phone`
    width: 335px;
  `}
`

export const Title = styled.div`
  ${Typography.M}
  ${FontWeights.Medium}
  text-align: center;
  width: 544px;
  margin-bottom: 12px;

  ${Media.Tablet`
    width: 552px;
  `}

  ${Media.Phone`
    width: 335px;
    margin-bottom: 24px;
  `};
`

export const Anchor = styled.a`
  display: flex;
  align-items: center;
  position: absolute;
  top: 24px;
  left: 32px;
  z-index: 1;

  ${Media.Tablet`
    top: 20px;
    left: 20px;
  `}
`

export const Logo = styled.img`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  height: 32px;
`

export const PremiumTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  margin-top: 45px;

  ${Media.Tablet`
    margin-top: 61px;
    margin-bottom: 27px;
  `}

  ${Media.Phone`
    margin-top: 74px;
    margin-bottom: 16px;
  `}
`

export const PremiumText = styled.div`
  ${Typography.Caps}
  ${FontWeights.DemiBold}
  text-align: center;
`

export const Icon = styled.div`
  color: ${Colors.Indigo70};
`

export const CardsAutoSliderContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 20px;

  ${Media.Phone`
    padding: 11.4px;
  `}
`

export const WhiteShadowBottom = styled.div`
  height: 56px;
  position: absolute;
  bottom: 20px;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${Colors.White} 100%);

  ${Media.Phone`
    bottom: 11.4px;
  `}
`

export const FooterTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  margin-top: 16px;
  margin-bottom: 45px;

  ${Media.Tablet`
    margin: 56px 0;
  `}

  ${Media.Phone`
    margin: 41.43px 0;
  `}
`

export const FooterText = styled.div`
  ${Typography.Body}
  color: ${Colors.Neutral50};

  ${Media.Phone`
    ${Typography.Caption}
  `}
`

export const FooterLink = styled.a`
  ${Typography.Body}
  color: ${Colors.Blue50};

  ${Media.Tablet`
    ${Typography.Caption}
  `}
`
