import { useCallback, useEffect, useState } from 'react'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import type { UserDocumentType } from '@rio/types'
import { typeToSlug } from 'builder/modules/panel'
import { DocumentTypes } from 'builder/modules/constants'
import { Dashboard } from './components/Dashboard'

type Props = {
  isDocumentsPage?: boolean
  defaultType?: DocumentTypes
}

export const PanelView: React.FC<Props> = ({
  defaultType = window?.location?.host === 'career.io' ||
  window?.location?.host === 'staging.career.io'
    ? DocumentTypes.trDocument
    : DocumentTypes.resume,
  ...props
}) => {
  const [documentType, setDocumentType] = useState(defaultType)
  const navigate = useNavigate()
  const location = useLocation()
  const isDocumentsPage = props.isDocumentsPage

  useEffect(() => {
    setDocumentType(defaultType)
  }, [location.pathname, defaultType])

  const handleDocumentTypeChange = useCallback(
    (type: UserDocumentType) => {
      try {
        const newSlug = typeToSlug(type)
        if (isDocumentsPage) {
          navigate(`/${newSlug}`, { replace: true, state: { preventScrollToTop: true } })
        }
        setDocumentType(type as DocumentTypes)
      } catch (e) {
        console.error('error', e)
      }
    },
    [isDocumentsPage, navigate],
  )

  useEffect(() => {
    trackInternalEvent('visit_dashboard')
  }, [])

  return (
    <Dashboard
      {...props}
      documentType={documentType}
      onDocumentTypeChange={handleDocumentTypeChange}
      isDocumentsPage={isDocumentsPage}
    />
  )
}
