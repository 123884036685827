import styled, { css } from 'styled-components'
import { Colors, hexToRgba } from 'builder/styles/colors'
import { Media } from 'builder/styles/media'
import { Typography } from 'builder/styles/typography'
import { Badge } from 'builder/components/Badge'
import { Align, Position } from './types'

export const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 32px 20px;
  background-color: ${hexToRgba(Colors.Neutral100, 0.8)};

  transition: opacity 0.2s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
`

export const CancelButton = styled.div<{ isVisible: boolean }>`
  padding: 12px;
  margin-top: 16px;
  border-radius: 6px;
  background-color: ${Colors.White};
  color: ${Colors.Blue50};
  text-align: center;
  font-weight: 600;
  cursor: pointer;

  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  transition-delay: 0.05s;
  transition-duration: 0.3s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: translateY(${props => (props.isVisible ? 0 : 28)}px) translateZ(0);
`

const getTransformOrigin = ({ align, position }: { align: Align; position: Position }) => {
  const x = align === 'left' ? '0' : '100%'
  const y = position === 'bottom' ? '0' : '100%'

  return [x, y].join(' ')
}

export const Container = styled.div<{ isVisible: boolean; align: Align; position: Position }>`
  padding: 10px 0;
  border-radius: 4px;
  background-color: ${Colors.White};

  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition-property: opacity, transform;
  transition-timing-function: ease-out;
  transition-duration: 0.15s;

  ${props => Media.AbovePhone`
    position: absolute;
    z-index: 5;
    left: ${props.align === 'left' ? 0 : 'auto'};
    right: ${props.align === 'right' ? 0 : 'auto'};
    box-shadow: 0 0 1px rgba(23, 68, 130, 0.2), 0 2px 14px rgba(23, 68, 130, 0.15);
    max-width: 294px;

    transform-origin: ${getTransformOrigin(props)};
    transform: scale(0.5) translateZ(0);

    ${
      props.position === 'top' &&
      css`
        bottom: 100%;
        margin-bottom: 8px;
      `
    }

    ${
      props.position === 'bottom' &&
      css`
        top: 100%;
        margin-top: 8px;
      `
    }

    ${
      props.isVisible &&
      css`
        transform: scale(1) translateZ(0);
      `
    }
  `};

  ${props => Media.Phone`
    border-radius: 6px;
    transition-delay: 0.05s;
    transition-duration: 0.25s;
    transform: translateY(${props.isVisible ? 0 : 28}px) translateZ(0);
  `};
`

export const Item = styled.div<{ hasDivider: boolean }>`
  padding: 6px 16px;
  cursor: pointer;

  ${props =>
    props.hasDivider &&
    css`
      padding-top: 16px;
      margin-top: 10px;
      border-top: 1px solid ${Colors.Neutral15};
    `}

  &:last-child {
    margin-bottom: 0;
  }
`

export const ItemContent = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const ItemIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  color: ${Colors.Blue50};
  margin-right: 8px;
`

export const ItemText = styled.div`
  transition: color 0.15s;
  color: ${Colors.Neutral90};

  ${Item}:hover && {
    color: ${Colors.Blue50};
  }
`

export const ItemBadge = styled(Badge).attrs({ variant: 'blue' })`
  margin-left: 9px;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 6px;
  margin-top: -2px;
  text-transform: uppercase;
`

export const ItemDescription = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
  min-width: 262px;
  white-space: normal;
  padding-left: 32px;

  ${Media.Phone`
    width: 100%;
  `};
`

export const ItemMark = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: ${Colors.Red50};
  vertical-align: middle;
  margin-left: 8px;
  border-radius: 50%;
`
