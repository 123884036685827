import { useMemo } from 'react'
import { i18n as I18n } from 'builder/utils/i18n'
import { ButtonSize } from 'builder/components/Button/types'
import {
  Title,
  ContentWrapper,
  PercentWrapper,
  PercentNeeded,
  Content,
  StartImprovingButton,
  TryAgainButton,
  ButtonWrapper,
} from './styles'

interface BottomContainerprops {
  resumeScore: number
  onTryAgainClick: () => void
  onStartImproving: () => void
  ButtonSize: ButtonSize
  isSavingJobPostingData: boolean
}

const BottomContainerResultView = ({
  resumeScore,
  onTryAgainClick,
  onStartImproving,
  ButtonSize,
  isSavingJobPostingData,
}: BottomContainerprops) => {
  const requiredScore = 100 - resumeScore

  const matchTitle = useMemo(() => {
    if (resumeScore < 30) {
      return I18n.t('builder.resume_optimizer.job_posting.poor_match.title')
    } else if (resumeScore > 70) {
      return I18n.t('builder.resume_optimizer.job_posting.good_match.title')
    } else {
      return I18n.t('builder.resume_optimizer.job_posting.normal_match.title')
    }
  }, [resumeScore])

  return (
    <>
      <Title>{matchTitle}</Title>
      <ContentWrapper>
        <PercentWrapper>
          <PercentNeeded>{requiredScore}</PercentNeeded>
        </PercentWrapper>
        <Content>{I18n.t('builder.resume_optimizer.job_posting.poor_match.content')}</Content>
      </ContentWrapper>
      <ButtonWrapper>
        <TryAgainButton
          size={ButtonSize}
          isTryAgain={true}
          onClick={onTryAgainClick}
          isDisabled={isSavingJobPostingData}
        >
          {I18n.t('builder.resume_optimizer.job_posting.try_again')}
        </TryAgainButton>
        <StartImprovingButton
          size={ButtonSize}
          onClick={onStartImproving}
          isLoading={isSavingJobPostingData}
          isDisabled={isSavingJobPostingData}
        >
          {I18n.t('builder.resume_optimizer.job_posting.start_improving')}
        </StartImprovingButton>
      </ButtonWrapper>
    </>
  )
}

export default BottomContainerResultView
