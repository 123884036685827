import range from 'lodash/range'
import { EnumerableResumeSectionType, Resume, ResumeField, ResumeSection } from 'packages/types'
import { i18n as I18n } from 'builder/utils/i18n'
import { isTruthy } from 'builder/utils/richTextUtils'
import { SectionNames } from '../sectionNames'
import { ResumeFieldSuggest, SectionPoints, SectionRule } from './types'

// ---
// Sum of N numbers of following row:
//   first, others, others-1, ..., 2, 1, 1, ...
//   if n === 0 returns 0
// ---
export const getTotalPoints = (n: number, first: number, others: number) => {
  if (n <= 0) return 0
  if (n === 1 || others === 0) return first
  const limit = Math.min(n - 1, 4) // limit range to 5
  return range(limit).reduce(acc => acc + others, first)
}

// returns 5 for first 5 times
// then returns 0
export const getNextSuggestPoints = (n: number, first: number, others: number) => {
  if (n <= 0) return 0
  if (n === 1) return first
  if (others === 0) return 0
  if (n > 5) return 0
  return Math.max(others, 0)
}

// ---
// Score
// ---

const hasData = <K extends keyof Resume>(key: K, section: ResumeSection) => {
  switch (key) {
    case 'workExperiences':
    case 'activities':
    case 'internships':
      return (
        section as Resume['workExperiences'] | Resume['activities'] | Resume['internships']
      ).some(card =>
        isTruthy(card.title || card.employer || card.city || card.dateFrom || card.description),
      )
    case 'educations':
      return (section as Resume['educations']).some(card =>
        isTruthy(card.school || card.degree || card.city || card.dateFrom || card.description),
      )
    case 'languages':
      return (section as Resume['languages']).some(card => isTruthy(card.language))
    case 'courses':
      return (section as Resume['courses']).some(card =>
        isTruthy(card.course || card.institution || card.dateFrom),
      )
  }
  return false
}

const getSectionScore = <K extends keyof Resume>(
  key: K,
  section: ResumeSection,
  points: SectionPoints,
) => {
  if (key !== 'skills' && !hasData(key, section)) return 0

  return getTotalPoints(section.length, points.first, points.others)
}

const getScore = <K extends keyof Resume>(resume: Resume, field: SectionRule<K>) => {
  let sectionData = resume[field.key] as ResumeSection
  // Only consider filled section for skills
  if (field.key === 'skills') {
    sectionData = (sectionData as Resume['skills']).filter(card => isTruthy(card.skill))
  }

  const totalPoints = getSectionScore(field.key, sectionData, field.points)
  const totalEntries = sectionData.length

  let suggest: ResumeFieldSuggest | null = null
  const suggestPoints = getNextSuggestPoints(
    totalEntries + 1,
    field.points.first,
    field.points.others,
  )

  if (field.includeSuggest && (totalPoints === 0 || suggestPoints > 0)) {
    suggest = {
      type: 'section',
      sectionName: field.key as EnumerableResumeSectionType,
      text: field.text,
      points: totalPoints === 0 ? field.points.first : suggestPoints,
      key: `${field.key}_${totalPoints}_${totalEntries}`,
    }
  }

  return { points: totalPoints, suggest }
}

// ---
// Fields
// ---
const getFields = <K extends keyof Resume>() =>
  [
    {
      key: SectionNames.workExperiences,
      points: {
        first: 25,
        others: 0,
      },
      text: I18n.t('builder.resume_score.add_employment'),
      includeSuggest: true,
    },
    {
      key: SectionNames.educations,
      points: {
        first: 15,
        others: 0,
      },
      text: I18n.t('builder.resume_score.add_educations'),
      includeSuggest: true,
    },
    {
      key: SectionNames.skills,
      points: {
        first: 4,
        others: 4,
      },
      text: I18n.t('builder.resume_score.add_skills'),
      includeSuggest: true,
    },
    {
      key: SectionNames.courses,
      points: {
        first: 5,
        others: 0,
      },
      text: I18n.t('builder.resume_score.add_courses'),
      includeSuggest: false,
    },
    {
      key: SectionNames.activities,
      points: {
        first: 5,
        others: 0,
      },
      text: I18n.t('builder.resume_score.add_activities'),
      includeSuggest: false,
    },
    {
      key: SectionNames.languages,
      points: {
        first: 3,
        others: 0,
      },
      text: I18n.t('builder.resume_score.add_languages'),
      includeSuggest: true,
    },
    {
      key: SectionNames.internships,
      points: {
        first: 2,
        others: 0,
      },
      text: I18n.t('builder.resume_score.add_internships'),
      includeSuggest: true,
    },
  ] as SectionRule<K>[]

export const getEmptyCard = (key: EnumerableResumeSectionType, resume: Resume) => {
  switch (key) {
    case 'workExperiences':
    case 'activities':
    case 'internships':
      return resume[key].find(
        card =>
          !isTruthy(card.title || card.employer || card.city || card.dateFrom || card.description),
      )
    case 'educations':
      return resume[key].find(
        card =>
          !isTruthy(card.school || card.degree || card.city || card.dateFrom || card.description),
      )
    case 'skills':
      return resume[key].find(card => !isTruthy(card.skill))
    case 'languages':
      return resume[key].find(card => !isTruthy(card.language))
    case 'courses':
      return resume[key].find(card => !isTruthy(card.course || card.institution || card.dateFrom))
  }
}

export const isEmptyField = <K extends keyof Resume>(key: K, field: ResumeField) => {
  switch (key) {
    case 'profile':
      return !isTruthy(field as Resume['profile'])
  }
  return false
}

export const sectionRule = {
  getScore,
  getFields,
}
