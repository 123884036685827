import React, { Fragment } from 'react'
import { UserDocumentType } from '@rio/types'
import { useI18n } from 'builder/hooks/useI18n'
import CritiqueReviewLoader from '../CIOCritiqueReview/CritiqueReviewLoader'
import { BriefResume, BriefLetter, BriefExpertDoc } from './../../../../builder/modules/panel'
import { ListDocument, LoaderContainer, PrefillText, Spinner } from './styles'

interface Props {
  document: BriefResume | BriefLetter | BriefExpertDoc
  critiqueReviewLoaderVisibility: boolean
  shouldShowLoaderContainer: boolean
  setIsHovering: (value: boolean) => void
  isSuperApp?: boolean | undefined
  documentType: UserDocumentType
  isPostPremium: boolean
  isFirstDocumentFree: boolean
}

type Ref = React.ForwardedRef<HTMLDivElement>

export const DocumentListItem = React.forwardRef(
  (
    {
      document,
      shouldShowLoaderContainer,
      setIsHovering,
      critiqueReviewLoaderVisibility,
      isSuperApp,
      documentType,
      isPostPremium,
      isFirstDocumentFree,
    }: Props,
    ref: Ref,
  ) => {
    const { i18n } = useI18n()

    const handleMouseOver = () => {
      setIsHovering(true)
    }

    const handleMouseOut = () => {
      setIsHovering(false)
    }

    const handleTouchEvent = () => {
      setIsHovering(true)
    }

    const handleTouchCloseEvent = () => {
      setIsHovering(false)
    }

    const cioCritiqueLoaderCheck =
      critiqueReviewLoaderVisibility && isSuperApp && documentType === 'resume'

    return (
      <Fragment>
        <ListDocument
          document={document}
          isPostPremium={isPostPremium}
          isFirstDocumentFree={isFirstDocumentFree}
          ref={ref}
        />
        {shouldShowLoaderContainer && (
          <LoaderContainer
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onTouchStart={handleTouchEvent}
            onTouchCancel={handleTouchCloseEvent}
          >
            <Spinner />
            <PrefillText>{i18n.t('builder.panel.loader.prefill_loader_text')}</PrefillText>
          </LoaderContainer>
        )}
        {cioCritiqueLoaderCheck && <CritiqueReviewLoader isGoalCardSection={false} />}
      </Fragment>
    )
  },
)
