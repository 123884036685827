import type {
  Optional,
  Resume,
  UserDocumentTemplate,
  ResumeAvatarTransform,
  DateRangePickerValue,
} from '@rio/types'
import { Step as JoyRideStep } from 'react-joyride'
import { TABS } from 'builder/components/FillResumeModal/constants'
import {
  AIResumeLinkedInSteps,
  AIResumeFlows,
  FetchStatuses,
  ResumeUploadStep,
  ResumeUploadStatus,
  ResumeValidationStatus,
  GenerateResumeStatus,
  SpeechToTextStatus,
  AIResumeWarningModalActions,
  AIResumeWarningModalTypes,
  VoiceInputErrors,
  GenerateAiProfileStatus,
  AIProfileGenerateType,
} from 'builder/modules/constants'
import { UseExample } from 'builder/components/FillResumeModal/UseExample/types'
import { LanguageLevelData, SkillLevelData } from '../generalEditor'
import { SectionNames } from './sectionNames'

export type Step = {
  id: string
  translationKey: string
  done: boolean
  simpleFieldNames?: string[]
  cardFieldNames?: string[]
}

export enum PrefillStatuses {
  /** initial value */
  notAsked = 'notAsked',
  /** resume doesn't need to prefill */
  skipped = 'skipped',
  /** resume is prefilling */
  scheduled = 'scheduled',
  /** resume prefilled and ready to merge */
  readyToMerge = 'readyToMerge',
  /** when accept of merge was failed */
  mergeFailed = 'mergeFailed',
  /** when reject of merge was failed */
  rejectFailed = 'rejectFailed',
  /** when resume was not be changed and was merged automatically */
  mergedAuto = 'mergedAuto',

  error = 'error',
  failed = 'failed',
  done = 'done',
  rejected = 'rejected',
  merged = 'merged',
}

export enum PrefillProviders {
  topresume = 'topresume',
  linkedin = 'linkedin',
}

export type ResumeScoreAPIData = {
  jobPostingId: number
  employerName: string
  suggestedJobTitle: string
  averageScore: number
}

export type JobPostingAPIData = {
  jobPostingLink: string
  jobPostingId: number
  isManagementJob: boolean
  employerName: Optional<string>
  recommendedJobTitle: Optional<string>
  visibleJobTitle: string
  recommendedSkills: string[]
  visibleSkills: string[]
  resumeScore: number
  apiScore: number
  averageScore: number
  keywords: string[]
  recommendedKeywords: string[]
}

export type JobPostingManualInputValues = {
  companyName: string
  jobTitle: string
  role: string
}

export type ResumeOptimizerSection = {
  jobPostingLinkForModal: string
  fetchScoreStatus: FetchStatuses
  saveJobPostingDataStatus: FetchStatuses
  fetchJobPostingDataStatus: FetchStatuses
  deleteJobPostingStatus: FetchStatuses
  editJobDetailsStatus: FetchStatuses
  showManualInputScreen: boolean
  temporaryJobPostingAPIData: JobPostingAPIData
  resumeScoreAPIData: ResumeScoreAPIData
  jobPostingAPIData: JobPostingAPIData
  manualInput: JobPostingManualInputValues
}

export type PrefillResumeSection = {
  resumeValidationStatus: ResumeValidationStatus
  selectedResume: File | null
  isSavingPrefilledResume: boolean
  validationError: string | null
  resumeUploadStatus: ResumeUploadStatus
  error: Error | string | null
  errorSource: ResumeUploadStep | null
}

export type AICoverLetter = {
  isEditJobDetailsFocused: boolean
  isClickedOnAiCVEditJobDetails: boolean
  isClickedOnAiCVEditModalIcon: boolean
  isOpenAICVOptimizerModal: boolean
  isShowUntailoreView: boolean
  generateAICoverLetterStatus: FetchStatuses
  aICVEditedJobTitle: string
  aICVEditedCompanyName: string
}

export type AIProfile = {
  isEditJobDetailsFocused: boolean
  isClickedOnAiCVEditJobDetails: boolean
  isClickedOnAiCVEditModalIcon: boolean
  generateAiProfileStatus: GenerateAiProfileStatus
  showPaywall: boolean
  summary: string | null
  actionType: AIProfileGenerateType | null
  shouldAnimateResultPopupContent: boolean
  shouldAnimateEditorFieldContent: boolean
}

export type AIResume = {
  showAIResumeModal: boolean
  aiResumeFlow: AIResumeFlows
  hideLinkedInFlow: boolean
  linkedInData: LinkedInData
  questionnaireData: QuestionnaireData
  generateResumeStatus: GenerateResumeStatus
  showWarningModal: boolean
  warningModalType: AIResumeWarningModalTypes | null
  warningModalAction: AIResumeWarningModalActions | null
}

export type LinkedInProfileData = {
  firstName: string
  headline: string
  lastName: string
}

export type LinkedInData = {
  currentStep: AIResumeLinkedInSteps
  linkedInProfileURL: string
  fetchingLinkedInProfile: boolean
  linkedInProfileData: LinkedInProfileData
}

export type QuestionnaireData = {
  step: number
  progress: number
  accomplishmentsVoiceProcessingStatus: SpeechToTextStatus
  accomplishmentsVoiceInputError: VoiceInputErrors | null
  activeAccomplishmentsTab: TABS
  accomplishmentsAudio: Blob[]
  accomplishmentsAudioDuration: number
  goalsVoiceProcessingStatus: SpeechToTextStatus
  goalsVoiceInputError: VoiceInputErrors | null
  activeGoalsTab: TABS
  goalsAudio: Blob[]
  goalsAudioDuration: number
  userInput: {
    workExperience: {
      cards: AIResumeWorkExpCardData[]
      confirmDeleteCardId: string
    }
    desiredPosition: string
    education: {
      cards: AIResumeEducationCardData[]
    }
    currentSkills: string[]
    suggestedSkillsSource: string[]
    suggestedSkills: string[]
    pastAccomplishments: string
    futureGoals: string
  }
}

export type AIResumeWorkExpCardDataError = {
  position: boolean
  company: boolean
  dateFrom: boolean
  dateUntil: boolean
}

export type AIResumeWorkExpCardData = {
  id: string
  position: string
  company: string
  date: DateRangePickerValue
  errors?: AIResumeWorkExpCardDataError
  isCollapsed: boolean
}

export type AIResumeEducationCardData = {
  id: string
  institute: string
  degree: string
  date: DateRangePickerValue
  isCollapsed: boolean
}

export type ResumeEditorStore = {
  isLoading: boolean
  isSyncing: boolean
  isUploadingPhoto: boolean
  resume: Resume | null
  currentWizardStepId: string
  wizardSteps: Step[]
  levels: {
    language: LanguageLevelData[]
    skill: SkillLevelData[]
  } | null
  templates: UserDocumentTemplate[]
  error: Error | null
  openCardId: Optional<number | string>
  prefill: {
    status: PrefillStatuses
    provider: PrefillProviders
    resumeId: number | null
  }
  isOptimizerModalOpen: boolean
  editJobPosting: boolean
  openedAISuggestionsPopup: Optional<AvailableAISuggestions>
  isResumeOptimizerBannerOpen: boolean
  optimizerSection: ResumeOptimizerSection
  prefillResumeSection: PrefillResumeSection
  isEditJobDetails: boolean
  showToolTip: boolean
  companyName: string
  jobTitle: string
  animatePersonalDetailsSection: boolean
  animateResumeOptimizerBanner: boolean
  isClickedOnDeleteJobPosting: boolean
  isClickedOnEditJobDetails: boolean
  changeJobPostingLink: boolean
  isFromJobTracker: boolean
  isOpenSavingInfoBanner: boolean
  isClickedOnAiCVBanner: boolean
  AICoverLetter: AICoverLetter
  aiResume: AIResume
  viewGenerateCLBannerEventTracked: boolean
  useExample: UseExample
  viewTailoringBannerEventTracked: boolean
  aiProfile: AIProfile
  highlightedElementData: HighlightedElementData | null
  openedKeywordsModalType: SectionNames | null
  isImproveResumePanelOpen: boolean
  isHelpWithWritingOpen: boolean
  isAIProfileSummaryResultOpen: boolean
}

export interface AvatarUploadSimplePayload {
  file: File
}

export interface AvatarUploadEditorPayload {
  file?: Optional<File>
  processedFile: File
  transform: ResumeAvatarTransform
}

export type AvatarUploadPayload = AvatarUploadSimplePayload | AvatarUploadEditorPayload

export interface VoiceInputTabProps {
  audioChunks: Array<Blob>
  duration: number
  errorWhileRecording: string
  isRecording: boolean
  onClearRecording: () => void
  onStopRecording: () => void
}

export type HighlightedElementData = Pick<
  JoyRideStep,
  'title' | 'content' | 'disableScrollParentFix' | 'styles'
> & {
  target: string
  buttonLabel?: string
}

export enum AvailableAISuggestions {
  jobTitle = 'jobTitle',
  skills = 'skills',
}
