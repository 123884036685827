import parseISO from 'date-fns/parseISO'
import sortBy from 'lodash/sortBy'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { Resume } from 'packages/types'
import { Store } from 'builder/modules/store'
import { DocumentTypes, FetchStatuses } from 'builder/modules/constants'
import {
  PanelStore,
  ReviewResumeID,
  BriefDocumentMap,
  ResumeCritique,
  ResumeReviews,
  ResumeCritiqueStatuses,
  ResumeCritiqueBase,
} from './types'

// ---
// Action creators
// ---
export const actions = {
  setCritiques: createAction<ResumeCritique>('builder/SET_CRITIQUES'),
  setResumeId: createAction<{ resumeId: number; checkSum: string }>(
    'builder/CRITIQUE_REVIEW_SET_RESUME_ID',
  ),
  setCritiqueStatus: createAction<ResumeCritiqueBase>('builder/CRITIQUE_REVIEW_STATUS'),
  fetchCritiqueReviewStatus: createAction<ReviewResumeID>('builder/FETCH_CRITIQUE_REVIEW_STATUS'),
  fetchCioCritiques: createAction<{ resumeId: number | null; checkSum: string | null }>(
    'builder/FETCH_RESUME_AVAILABLE_CRITIQUES',
  ),
  setReviews: createAction<ResumeReviews[]>('builder/SET_RESUME_REVIEWS'),
  setIsTopResumeUser: createAction<{ isTopResumeUser?: boolean }>('builder/SET_IS_TOP_RESUME_USER'),
  updateIsTopResumeUserFlag: createAction<{ resumeId: number; isTopResumeUser: boolean }>(
    'builder/UPDATE_IS_TOP_RESUME_USER_FLAG',
  ),
  setCritiquePageVisitedCount: createAction<boolean>('builder/CRITIQUE_PAGE_VISTITED_COUNT'),
  fetchPopupDate: createAction<{ resumeId: number | null }>('builder/FETCH_CRITIQUE_POPUP_DATE'),
  updateScoreForRemoteResume: createAction<{ resume: Resume; score: number }>(
    'builder/CRITIQUE_REVIEW_SET_RESUME_REMOTE_SCORE',
  ),
}

// ---
// Selectors
// ---
export const selectors = {
  lastCreated: <T extends DocumentTypes>(state: Store, type: T): BriefDocumentMap[T] | null => {
    const documents = state.panel.documents.filter((d): d is BriefDocumentMap[T] => d.type === type)
    const sortedDocuments = sortBy(documents, resume => -parseISO(resume.createdAt).getTime())
    return sortedDocuments.length ? sortedDocuments[0] : null
  },
  /** Detect a resume to tune (find the last created resume) */
  tunerResumeId: (state: Store): number | null => {
    return selectors.lastCreated(state, DocumentTypes.resume)?.id ?? null
  },
  critiqueStatus: (state: Store) => state.critiqueReview.critiqueStatus,
  triggeredCritiqueStatus: (state: Store) => state.critiqueReview.triggeredCritiqueStatus,
  resumeId: (state: Store) => state.panel.resumeId,
  critique: (state: Store, resumeId: number) => {
    return state.critiqueReview.critiques.find(critique => critique.resumeId === resumeId)
  },
  isTopResumeUser: (state: Store) => state.critiqueReview.isTopResumeUser,
  isCritiquePageCount: (state: Store) => state.critiqueReview.critiquePageCount,
  isPopupViewedAt: (state: Store) => state.critiqueReview.triggeredCritiqueStatus.popupViewedAt,
}

// ---
// Reducer
// ---

const initialState: PanelStore = {
  checkSum: '',
  critiqueStatus: ResumeCritiqueStatuses.pending,
  resumeId: 0,
  isTopResumeUser: false,
  documents: [],
  documentsStatus: FetchStatuses.notAsked,
  triggeredCritiqueStatus: {
    id: 0,
    resumeId: 0,
    status: ResumeCritiqueStatuses.pending,
    iframeUrl: '',
    popupViewedAt: null,
    reviewer: {
      name: null,
      photoUrl: '',
    },
    createdAt: '',
    reviewedAt: null,
  },
  critiques: [],
  critiquePageCount: false,
}

export const reducer = createReducer(initialState, reducer => {
  reducer.addCase(actions.setCritiqueStatus, (draft, action) => {
    draft.triggeredCritiqueStatus = action.payload
  })

  reducer.addCase(actions.setResumeId, (draft, action) => {
    draft.resumeId = action.payload.resumeId
    draft.checkSum = action.payload.checkSum
  })

  reducer.addCase(actions.setCritiques, (draft, action) => {
    draft.triggeredCritiqueStatus = action.payload
  })

  reducer.addCase(actions.setIsTopResumeUser, (draft, action) => {
    draft.isTopResumeUser = action.payload?.isTopResumeUser || false
  })

  reducer.addCase(actions.setCritiquePageVisitedCount, (draft, action) => {
    draft.critiquePageCount = action.payload
  })
})
