import { useNavigate } from 'react-router-dom'

import { useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import { ButtonSize } from 'builder/components/Button'
import { StartCompleteScreenRoute, Routes } from '../../constants'
import {
  Container,
  InfoBox,
  InfoHeader,
  InfoIcon,
  InfoHeadContiner,
  InfoSubHeader,
  InfoItems,
  InfoItem,
  InfoItem1Icon,
  InfoItemHeader,
  InfoItemDescription,
  InfoActions,
  InfoActionIcon,
  InfoActionDescription,
  InfoActionStart,
  InfoItem2Icon,
  InfoActionDescriptionBold,
  StartButton,
  NewInfoBox,
  InfoBoxContainer,
  InfoActionContainer,
  HorizontalDivider,
  VideoContainer,
} from './styles'
import starImage from './assets/star-image.png'
import { VideoPlayer } from './VideoPlayer'

export const StartScreenView = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { features } = useFeaturesConfig()
  const { isPhone, isTablet } = useMediaQueries()

  const TRANSLATION = `builder.auto_apply.start_screen${
    features.careerProfileShortVersion ? '.short_version' : ''
  }`

  const onStart = () => {
    const path = features.careerProfileShortVersion
      ? Routes.JOB_PREFERENCES_1
      : StartCompleteScreenRoute.RESUME_SELECT
    navigate(`/auto-apply/${path}`)
  }

  useEffect(() => {
    trackInternalEvent('enter_welcome_screen', {
      label: 'total_job_search_solution',
    })
  }, [])

  const InfoActionComponent = (
    <InfoActions $isNewCareerPath={features.careerProfileShortVersion}>
      <InfoActionDescription>
        <InfoActionIcon />
        {i18n.t(`${TRANSLATION}.time`)}
        <InfoActionDescriptionBold>
          {i18n.t(`${TRANSLATION}.time_minutes`)}
        </InfoActionDescriptionBold>
      </InfoActionDescription>
      {features.careerProfileShortVersion ? (
        (isPhone || isTablet) && (
          <InfoActionStart $isNewCareerPath={features.careerProfileShortVersion} onClick={onStart}>
            {i18n.t(`${TRANSLATION}.button`)}
          </InfoActionStart>
        )
      ) : (
        <InfoActionStart onClick={onStart}> {i18n.t(`${TRANSLATION}.button`)}</InfoActionStart>
      )}
    </InfoActions>
  )

  return (
    <Container $isNewCareerPath={features.careerProfileShortVersion}>
      {features.careerProfileShortVersion ? (
        <>
          <NewInfoBox>
            <InfoBoxContainer>
              <InfoIcon $width={isPhone ? 42 : 72} $height={isPhone ? 48 : 83} src={starImage} />

              <InfoHeadContiner>
                <InfoHeader>{i18n.t(`${TRANSLATION}.header`)}</InfoHeader>
                <InfoSubHeader>{i18n.t(`${TRANSLATION}.sub_header`)}</InfoSubHeader>
                {!isPhone && !isTablet && (
                  <StartButton
                    size={isPhone ? ButtonSize.small : ButtonSize.default}
                    onClick={onStart}
                  >
                    {i18n.t(`${TRANSLATION}.button`)}
                  </StartButton>
                )}
              </InfoHeadContiner>

              {(isPhone || isTablet) && (
                <VideoContainer>
                  <VideoPlayer />
                </VideoContainer>
              )}
            </InfoBoxContainer>
            {(isPhone || isTablet) && InfoActionComponent}

            {!isPhone && !isTablet && (
              <InfoActionContainer>
                <HorizontalDivider />
                {InfoActionComponent}
              </InfoActionContainer>
            )}
          </NewInfoBox>
          {!isPhone && !isTablet && <VideoPlayer />}
        </>
      ) : (
        <>
          <InfoBox>
            <InfoIcon src={starImage} />

            <InfoHeadContiner>
              <InfoHeader>{i18n.t(`${TRANSLATION}.header`)}</InfoHeader>
              <InfoSubHeader>{i18n.t(`${TRANSLATION}.sub_header`)}</InfoSubHeader>
            </InfoHeadContiner>

            <InfoItems>
              <InfoItem>
                <InfoItem1Icon />
                <InfoItemHeader>{i18n.t(`${TRANSLATION}.item_1.header`)}</InfoItemHeader>
                <InfoItemDescription>
                  {i18n.t(`${TRANSLATION}.item_1.sub_header`)}
                </InfoItemDescription>
              </InfoItem>
              <InfoItem>
                <InfoItem2Icon />
                <InfoItemHeader>{i18n.t(`${TRANSLATION}.item_2.header`)}</InfoItemHeader>
                <InfoItemDescription>
                  {i18n.t(`${TRANSLATION}.item_2.sub_header`)}
                </InfoItemDescription>
              </InfoItem>
            </InfoItems>

            {!isPhone && !isTablet && InfoActionComponent}
          </InfoBox>
          <VideoPlayer />
          {(isPhone || isTablet) && InfoActionComponent}
        </>
      )}
    </Container>
  )
}
