import { useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useI18n } from 'builder/hooks/useI18n'
import FieldIcon from './FieldIcon'

import { FieldContainer, FieldLabel, FieldInput, FieldBar } from './styles'

const Field = ({ name, value, multiline, onChange, onBlur }) => {
  const { i18n } = useI18n()
  const input = useRef()
  const [focused, setFocused] = useState(false)
  const label = i18n.t(`builder.job_tracking.edit_modal.${name}_label`)
  const placeholder = i18n.t(`builder.job_tracking.edit_modal.${name}_placeholder`)
  const visible = focused || (typeof value === 'string' && value.length > 0)
  const tagName = multiline ? 'textarea' : 'input'

  useLayoutEffect(() => {
    if (multiline) {
      input.current.style.height = 'auto'
      input.current.style.height = `${input.current.scrollHeight}px`
    }
  }, [multiline, value])

  return (
    <FieldContainer multiline={multiline}>
      <FieldLabel collapsed={visible}>{label}</FieldLabel>
      <FieldInput
        as={tagName}
        ref={input}
        name={name}
        value={value}
        placeholder={placeholder}
        visible={visible}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={onBlur}
      />
      {!multiline && <FieldBar />}
      <FieldIcon name={name} value={value} />
    </FieldContainer>
  )
}

Field.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

Field.defaultProps = {
  value: '',
}

export default Field
