export const RecordingIcon = () => (
  <>
    <circle cx="8" cy="8" r="7" stroke="#FB4458" strokeWidth="2" />
    <circle cx="8" cy="8" r="5" fill="#FB4458" />
  </>
)

export const Settings = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.87178 5.03883L6.99998 2.07806L12.1282 5.03883V10.9604L6.99998 13.9211L1.87178 10.9604V5.03883ZM6.49998 0.288271C6.80938 0.109639 7.19058 0.109639 7.49998 0.288271L13.4282 3.71092C13.7376 3.88955 13.9282 4.21968 13.9282 4.57695V11.4222C13.9282 11.7795 13.7376 12.1096 13.4282 12.2883L7.49998 15.7109C7.19058 15.8896 6.80938 15.8896 6.49998 15.7109L0.571777 12.2883C0.262376 12.1096 0.0717773 11.7795 0.0717773 11.4222V4.57695C0.0717773 4.21968 0.262376 3.88955 0.571777 3.71092L6.49998 0.288271ZM5.36793 6.94186L6.89998 6.05733C6.96186 6.02161 7.0381 6.02161 7.09998 6.05733L8.63203 6.94186C8.69391 6.97759 8.73203 7.04361 8.73203 7.11507V8.88413C8.73203 8.95558 8.69391 9.0216 8.63203 9.05733L7.09998 9.94186C7.0381 9.97759 6.96186 9.97759 6.89998 9.94186L5.36793 9.05733C5.30605 9.0216 5.26793 8.95558 5.26793 8.88413V7.11507C5.26793 7.04361 5.30605 6.97759 5.36793 6.94186Z"
      fill="currentColor"
    />
  </svg>
)

export const Columns = () => (
  <>
    <path
      stroke="#1E2532"
      fill="white"
      strokeWidth={1.4}
      d="M1 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3ZM8 15V1"
    />
  </>
)

export const SettingIcon = () => (
  <>
    <path
      stroke="#1A91F0"
      strokeLinecap="round"
      strokeWidth={1.4}
      d="M1 11h3M1 3h7M8 11h7M12 3h3"
    />
    <circle
      cx={6}
      cy={11}
      r={2}
      stroke="#1A91F0"
      fill="white"
      strokeLinecap="round"
      strokeWidth={1.4}
    />
    <circle
      cx={10}
      cy={3}
      r={2}
      stroke="#1A91F0"
      fill="white"
      strokeLinecap="round"
      strokeWidth={1.4}
    />
  </>
)
