import { useCallback, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { SuggestionSelectedEventData } from 'react-autosuggest'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { TextField } from 'builder/components/TextField'
import * as SimpleForm from 'builder/components/SimpleForm'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { AutoSuggestItem, IJobPreferencesForm } from 'builder/modules/resumeDistribution'

import Toggle from 'builder/components/Toggle'
import Select from 'builder/components/Select'

import { CURRENCY, CURRENCY_SIGN, PERIOD } from '../../JobPreferencesForm/constants'
import {
  fetchCountriesSuggestions,
  fetchIndustriesSuggestions,
  fetchStatesSuggestions,
  Item,
  TransformedItem,
} from '../../JobPreferencesForm/utils'

import { Column, ColumnSection, Label, TextFieldAmount, ToggleLable } from './styles'

const TRANSLATION = 'builder.resume_distribution.landing.job_preferences_form'

type Props = {
  form: UseFormReturn<IJobPreferencesForm>
  setStateFromField: (field: string, value?: number | string | AutoSuggestItem) => void
  isAutoCompleteForm?: boolean
}

export const JobPreferences = ({ form, setStateFromField, isAutoCompleteForm }: Props) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = form

  const { i18n } = useI18n()
  const [industries, setIndustries] = useState<Item[]>([])
  const [location, setLocation] = useState<{ country?: string; state?: string }>({
    country: watch('country')?.text,
    state: watch('state')?.text,
  })

  const required = i18n.t(`${TRANSLATION}.required_field`)

  const fetchIndustries = async (category?: 'top' | 'all') => {
    const industriesResponse = await fetchIndustriesSuggestions(category)
    setIndustries(industriesResponse)
  }

  const getSelectedIndustries = useCallback(
    async (industries: Item[]) => {
      const selectedIndustry = getValues('industry')
      if (selectedIndustry) {
        const isInTopCategories = industries.find(item => item.id === selectedIndustry)

        if (!isInTopCategories) {
          await fetchIndustries('all')
        }
      }
    },
    [getValues],
  )

  const onChangeLocation = (field: string, value: string) => {
    setLocation({ ...location, [field]: value })
  }

  const onSelectField = (field: keyof IJobPreferencesForm, value: string | number) => {
    setValue(field, value)
    setStateFromField(field, value)
  }

  const getError = (field: keyof IJobPreferencesForm) => {
    if (errors[field]) {
      const err = errors[field] as { message: string }
      return err?.message
    }
  }

  const onSuggestionSelected =
    (field: 'country' | 'state') =>
    (_: React.FormEvent<unknown>, { suggestion }: SuggestionSelectedEventData<TransformedItem>) => {
      setValue(field, suggestion)
      onChangeLocation(field, suggestion.text)
      setStateFromField(field, suggestion)
    }

  useEffect(() => {
    fetchIndustries('top')
  }, [])

  useEffect(() => {
    getSelectedIndustries(industries)
  }, [getSelectedIndustries, industries])

  return (
    <>
      <SimpleForm.Row position="relative">
        <AsyncAutosuggest<TransformedItem>
          highlightedQuery
          value={location.country}
          onChange={() => {
            // The filed need to be prefilled with USA only
          }}
          onSuggestionSelected={onSuggestionSelected('country')}
          // Show only USA at the moment
          fetchItems={() => fetchCountriesSuggestions('United States', true)}
        >
          {inputProps => (
            <TextField
              autoComplete="off"
              role="presentation"
              {...register('country', { required })}
              {...inputProps}
              error={getError('country')}
              name="country"
              label={i18n.t(`${TRANSLATION}.country`)}
              placeholder="United States"
            />
          )}
        </AsyncAutosuggest>
      </SimpleForm.Row>
      <SimpleForm.Row position="relative">
        <AsyncAutosuggest<TransformedItem>
          highlightedQuery
          value={location.state}
          onChange={e => onChangeLocation('state', e.target.value)}
          onSuggestionSelected={onSuggestionSelected('state')}
          fetchItems={query => fetchStatesSuggestions(getValues('country')?.id as string)(query)}
        >
          {inputProps => (
            <TextField
              autoComplete="off"
              role="presentation"
              {...register('state', { required })}
              {...inputProps}
              error={getError('state')}
              name="state"
              label={i18n.t(`${TRANSLATION}.state`)}
              placeholder="E.g. California (CA)"
            />
          )}
        </AsyncAutosuggest>
      </SimpleForm.Row>
      <SimpleForm.Row>
        <Toggle
          checked={watch('remote') === 'true'}
          {...register('remote')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onSelectField('remote', e.target.checked.toString())
            if (isAutoCompleteForm && e.target.checked) {
              trackInternalEvent('rd_open_to_remote', {
                label: 'total_job_search_solution',
              })
            }
          }}
          label={<ToggleLable>{i18n.t(`${TRANSLATION}.remote`)}</ToggleLable>}
        />
      </SimpleForm.Row>
      <SimpleForm.Row>
        <Select
          selected={watch('industry')}
          {...register('industry')}
          onSelect={value => onSelectField('industry', value)}
          options={industries}
          error={getError('industry')}
          label={i18n.t(`${TRANSLATION}.industry`)}
        />
      </SimpleForm.Row>
      <SimpleForm.Row>
        <Label>{i18n.t(`${TRANSLATION}.salary`)}</Label>
        <ColumnSection>
          <Column size={0.81}>
            <Select
              selected={watch('salaryCurrency')}
              {...register('salaryCurrency')}
              onSelect={value => onSelectField('salaryCurrency', value)}
              options={CURRENCY}
            />
          </Column>
          <Column size={1.3} position="center">
            <TextFieldAmount
              inputIcon={CURRENCY_SIGN[getValues('salaryCurrency')]}
              value={watch('salaryAmount') || ''}
              {...register('salaryAmount', {
                valueAsNumber: true,
                required,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  onSelectField('salaryAmount', e.target.value)
                },
                validate: val => (isNaN(Number(val)) ? 'The amount should be number' : true),
              })}
              error={getError('salaryAmount')}
              placeholder="100,000"
            />
          </Column>
          <Column position="end">
            <Select
              selected={watch('salaryPeriod')}
              {...register('salaryPeriod')}
              onSelect={value => onSelectField('salaryPeriod', value)}
              options={PERIOD}
            />
          </Column>
        </ColumnSection>
      </SimpleForm.Row>
    </>
  )
}
