import { useI18n } from 'builder/hooks/useI18n'
import { TooltipQuestion } from 'builder/components/Tooltip/variations'

import { Weeks } from 'builder/modules/resumeDistribution'
import { Box, Header, Section, SubHeader, TooltipWrapper } from '../styles'
import { DistributionCycle } from '../DistributionCycle/DistributionCycle'
import { TRANSLATION } from '../constants'

type IProps = {
  totalRecruiters: number
  distributionWeek: Weeks
}

export const RecruitersInfo = ({ totalRecruiters, distributionWeek }: IProps) => {
  const { i18n } = useI18n()

  return (
    <Section>
      <Box>
        <Header>{totalRecruiters}</Header>
        <SubHeader>
          {i18n.t(`${TRANSLATION}.recruiters_review`)}
          <TooltipWrapper>
            <TooltipQuestion textAlign="left" multiline width={300}>
              {i18n.t(`${TRANSLATION}.recruiters_review_tooltip`, { number: totalRecruiters })}
            </TooltipQuestion>
          </TooltipWrapper>
        </SubHeader>
      </Box>
      <DistributionCycle distributionWeek={distributionWeek} />
    </Section>
  )
}
