import { useMutation } from '@tanstack/react-query'

export type UploadInterviewVideoParams = {
  url: string
  blob: Blob
}

export const useUploadInterviewVideo = () => {
  return useMutation({
    mutationFn: (params: UploadInterviewVideoParams) => {
      const { url, blob } = params
      return fetch(`${url}`, {
        method: 'PUT',
        body: blob,
      })
    },
  })
}
