import { ChangeEvent, KeyboardEventHandler, useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import { i18n } from 'builder/utils/i18n'

import { TextField } from 'builder/components/TextField'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import {
  guessMonth,
  guessYear,
  convertToInternal,
  convertToExternal,
  convertToFieldValue,
  isPresent,
  isMonthHidden,
  isDateHidden,
} from './utils'

import PickerPanel from './PickerPanel'

import { Container, Row, FieldWrapper, Label, LabelContent, LabelHint } from './styles'
import { DateRangePickerProps } from './types'

const DesktopPicker = (props: DateRangePickerProps) => {
  const {
    currentlyLabel,
    dateFromHighlightId,
    dateUntilHighlightId,
    onChange = () => {},
    errors: { startError, endError } = {
      startError: false,
      endError: false,
    },
    isUntilToday = false,
  } = props

  const container = useRef<HTMLDivElement>(null)
  const [isStartPickerOpen, setIsStartPickerOpen] = useState(false)
  const [isEndPickerOpen, setIsEndPickerOpen] = useState(false)
  const [startValue, setStartValue] = useState<string | null>(null)
  const [startMonth, setStartMonth] = useState<number | null>(null)
  const [startYear, setStartYear] = useState(0)

  const [endValue, setEndValue] = useState<string | null>(null)
  const [endMonth, setEndMonth] = useState<number | null>(null)
  const [endYear, setEndYear] = useState(0)

  const [maxMonth, setMaxMonth] = useState<number | null>(null)
  const [maxYear, setMaxYear] = useState(0)

  const isPresentEndValue = isPresent(endValue)

  useEffectOnMount(() => {
    const [startValue, startMonth, startYear] = convertToInternal(props.value.dateFrom, {
      isMonthHidden: props.value.isMonthFromHidden,
    })
    const [endValue, endMonth, endYear] = convertToInternal(props.value.dateUntil, {
      isDatePresent: props.value.isDateUntilPresent,
      isMonthHidden: props.value.isMonthUntilHidden,
    })

    setStartValue(startValue)
    setStartMonth(startMonth)
    setStartYear(startYear)

    setEndValue(endValue)
    setEndMonth(endMonth)
    setEndYear(endYear)
  })

  useEffect(() => {
    if (isPresentEndValue || isUntilToday) {
      const [, maxMonth, maxYear] = convertToInternal(new Date().toISOString())

      setMaxMonth(maxMonth)
      setMaxYear(maxYear)
    } else {
      setMaxMonth(null)
      setMaxYear(0)
    }
  }, [isPresentEndValue, isUntilToday])

  useClickOutside(container, () => {
    setIsStartPickerOpen(false)
    setIsEndPickerOpen(false)
  })

  const handleEnter = () => {}

  const handleEntering = () => {}

  const handleExiting = () => {}

  const openStartPicker = () => {
    setIsStartPickerOpen(true)
    setIsEndPickerOpen(false)
  }

  const openEndPicker = () => {
    setIsStartPickerOpen(false)
    setIsEndPickerOpen(true)
  }

  const closeStartPicker = () => setIsStartPickerOpen(false)

  const closeEndPicker = () => setIsEndPickerOpen(false)

  const changeStartYear = (startYear: number) => {
    const startValue = convertToFieldValue(startMonth, startYear)

    setStartYear(startYear)
    setStartValue(startValue)

    onChange({
      dateFrom: convertToExternal(startMonth, startYear, {
        isDateHidden: isDateHidden(startValue),
      }),
    })
  }

  const changeEndYear = (endYear: number) => {
    const endValue = convertToFieldValue(endMonth, endYear)

    setEndYear(endYear)
    setEndValue(endValue)

    onChange({
      dateUntil: convertToExternal(endMonth, endYear, {
        isDateHidden: isDateHidden(endValue),
      }),
    })
  }

  const updateStartPicker = (e: ChangeEvent) => {
    const newValue = (e.target as HTMLInputElement)?.value
    const newState = {
      startValue: newValue,
      startMonth: guessMonth(newValue),
      startYear: guessYear(newValue),
    }

    setStartValue(newState.startValue)
    setStartMonth(newState.startMonth)
    setStartYear(newState.startYear)

    onChange({
      dateFrom: convertToExternal(newState.startMonth, newState.startYear, {
        isDateHidden: isDateHidden(newState.startValue),
      }),
      isMonthFromHidden: isMonthHidden(newState.startValue),
    })
  }

  const updateEndPicker = (e: ChangeEvent) => {
    const newValue = (e.target as HTMLInputElement)?.value

    const newState = {
      endValue: newValue,
      endMonth: guessMonth(newValue),
      endYear: guessYear(newValue),
    }

    setEndValue(newState.endValue)
    setEndMonth(newState.endMonth)
    setEndYear(newState.endYear)

    onChange({
      dateUntil: convertToExternal(newState.endMonth, newState.endYear, {
        isDateHidden: isDateHidden(newState.endValue),
      }),
      isDateUntilPresent: isPresent(newState.endValue),
      isMonthUntilHidden: isMonthHidden(newState.endValue),
    })
  }

  const handleStartChoose = (month: number | null, year: number, shouldClosePanel = true) => {
    setStartMonth(month)
    setStartValue(convertToFieldValue(month, year))

    if (shouldClosePanel) {
      setIsEndPickerOpen(false)
    }

    onChange({
      dateFrom: convertToExternal(month, year),
      isMonthFromHidden: !month,
    })
  }

  const handleEndChoose = (month: number | null, year: number, shouldClosePanel = true) => {
    setEndMonth(month)
    setEndValue(convertToFieldValue(month, year))

    if (shouldClosePanel) {
      setIsEndPickerOpen(false)
    }

    onChange({
      dateUntil: convertToExternal(month, year),
      isMonthUntilHidden: !month,
    })
  }

  const handlePresentToggle = (value: boolean) => {
    setEndValue(
      convertToFieldValue(endMonth, endYear, {
        isDatePresent: value,
      }),
    )

    onChange({
      isDateUntilPresent: value,
    })
  }

  const handleStartBlur = () => {
    const convertedValue = convertToFieldValue(startMonth, startYear, { prevValue: startValue })

    if ((!convertedValue && !startValue) || convertedValue === startValue) {
      return
    }

    setStartValue(convertedValue)

    onChange({
      dateFrom: convertToExternal(startMonth, startYear, {
        isDateHidden: isDateHidden(startValue),
      }),
      isMonthFromHidden: isMonthHidden(startValue),
    })
  }

  const handleEndBlur = () => {
    const convertedValue = convertToFieldValue(endMonth, endYear, { prevValue: endValue })

    if ((!convertedValue && !endValue) || convertedValue === endValue) {
      return
    }

    setEndValue(convertedValue)

    onChange({
      dateUntil: convertToExternal(endMonth, endYear, {
        isDateHidden: isDateHidden(endValue),
      }),
      isDateUntilPresent: isPresent(endValue),
      isMonthUntilHidden: isMonthHidden(endValue),
    })
  }

  const handleTab = () => {
    if (isEndPickerOpen) closeEndPicker()
    if (isStartPickerOpen) closeStartPicker()
  }

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    let keyCode = e.keyCode

    // tab
    if (keyCode === 9) {
      handleTab()
    }
  }

  return (
    <Container onKeyDown={handleKeyDown} ref={container}>
      <Label>
        <LabelContent $hasError={startError || endError}>
          {i18n.t('builder.date_range_picker.title')}
        </LabelContent>
        <LabelHint multiline>{i18n.t('builder.date_range_picker.hint')}</LabelHint>
      </Label>
      <Row>
        <FieldWrapper>
          <TextField
            name="startDate"
            onFocus={openStartPicker}
            onBlur={handleStartBlur}
            onChange={updateStartPicker}
            value={startValue || ''}
            active={isStartPickerOpen}
            placeholder={i18n.t('builder.date_range_picker.placeholder')}
            autoComplete="off"
            errorNoMsg={startError}
            data-highlight-id={dateFromHighlightId}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            name="endDate"
            onFocus={openEndPicker}
            onBlur={handleEndBlur}
            onChange={updateEndPicker}
            value={endValue || ''}
            active={isEndPickerOpen}
            placeholder={i18n.t('builder.date_range_picker.placeholder')}
            autoComplete="off"
            errorNoMsg={endError}
            data-highlight-id={dateUntilHighlightId}
          />
        </FieldWrapper>
      </Row>

      <Transition
        in={isStartPickerOpen}
        timeout={0}
        onEnter={handleEnter}
        onEntering={handleEntering}
        onExiting={handleExiting}
        unmountOnExit
        appear
      >
        <PickerPanel
          month={startMonth}
          year={startYear}
          maxMonth={(endValue && !isPresentEndValue && endMonth) || maxMonth}
          maxYear={(endValue && !isPresentEndValue && endYear) || maxYear}
          onChangeYear={changeStartYear}
          onChoose={handleStartChoose}
          noActiveMonth={isDateHidden(startValue) || isMonthHidden(startValue)}
        />
      </Transition>

      <Transition
        in={isEndPickerOpen}
        timeout={0}
        onEnter={handleEnter}
        onEntering={handleEntering}
        onExiting={handleExiting}
        unmountOnExit
        appear
      >
        <PickerPanel
          month={endMonth}
          year={endYear}
          maxMonth={maxMonth}
          maxYear={maxYear}
          onChangeYear={changeEndYear}
          onChoose={handleEndChoose}
          showIsPresent
          isPresent={isPresentEndValue}
          onIsPresentToggle={handlePresentToggle}
          alignRight
          currentlyLabel={currentlyLabel}
          noActiveMonth={isDateHidden(endValue) || isMonthHidden(endValue)}
          {...(startValue ? { minMonth: startMonth, minYear: startYear } : {})}
        />
      </Transition>
    </Container>
  )
}

export default DesktopPicker
