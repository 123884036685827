import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import Colors, { hexToRgba } from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Typography from 'builder/styles/typography'
import DocumentImagePreview from 'builder/components/DocumentImagePreview'
import Tooltip from 'builder/components/Tooltip'
import { Icon20 } from 'builder/components/Icon'

import { A4_FACTOR } from 'builder/modules/constants'
import { ResumeCritiqueStatuses as STATUSES } from 'builder/modules/resumeReview'
import { DESKTOP_PREVIEW_WIDTH, PHONE_PREVIEW_WIDTH } from './constants'

export const DocumentContainer = styled.article`
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`

export const DocumentPreviewContainer = styled.div<{ $miniature?: boolean }>`
  flex-shrink: 0;
  width: ${DESKTOP_PREVIEW_WIDTH}px;
  margin-right: 32px;

  ${Media.Phone`
    margin-right: 24px;
    width: ${PHONE_PREVIEW_WIDTH}px;
  `}

  ${props => (props.$miniature ? `width: ${PHONE_PREVIEW_WIDTH - 50}px;` : null)}
`

const documentPreviewStyles = css`
  border-radius: 6px;
  border: 1px solid ${hexToRgba(Colors.Neutral60, 0.16)};
`

export const DocumentPreview = styled(DocumentImagePreview)`
  ${documentPreviewStyles};
`

export const DocumentCreatePreview = styled.div`
  ${documentPreviewStyles};
  position: relative;
  padding-bottom: ${100 / A4_FACTOR}%;
`

export const DocumentCreateButton = styled.div`
  position: absolute;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  color: ${Colors.Neutral30};
  background-color: ${Colors.Neutral10};
  transition: background-color 0.15s, color 0.15s;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 12px);
    width: 24px;
    height: 2px;
    background-color: currentColor;
  }

  &:after {
    transform: rotate(90deg);
  }
`

export const DocumentCreateContainer = styled.article<{ isClicked: boolean }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};

  ${DocumentCreateButton} {
    transform: ${props => (props.isClicked ? 'rotate(45deg)' : 'rotate(90deg)')};
    color: ${props => (props.isClicked ? Colors.Blue50 : Colors.Neutral30)};
    background: ${props => (props.isClicked ? Colors.Blue10 : Colors.Neutral10)};
  }

  &:hover ${DocumentCreateButton} {
    transform: ${props => (props.isClicked ? 'rotate(45deg)' : 'rotate(90deg)')};
    color: ${props => (props.isClicked ? Colors.Blue50 : Colors.White)};
    background: ${props => (props.isClicked ? Colors.Blue10 : Colors.Blue50)};
  }
`

export const DocumentContent = styled.div<{ isDisabled?: boolean }>`
  flex-grow: 1;
  min-width: 0;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'all')};
`

export const DocumentCreateTitle = styled.div`
  ${Typography.Subhead};
  color: ${Colors.Neutral50};
  transition: color 0.15s;
  ${DocumentContainer}:hover & {
    color: ${Colors.Blue50};
  }
`

export const DocumentNote = styled.div`
  ${Typography.Tiny};
  color: ${Colors.Neutral50};
  margin-top: 2px;
  margin-bottom: 24px;
  max-width: 240px;

  ${Media.Phone`
    margin-bottom: 20px;
  `}
`

export const DocumentButtonIcon = styled.svg`
  margin-right: 8px;
  transition: color 0.15s;
`

type ButtonProps = { isDisabled?: boolean; isActive?: boolean }

const getButtonColor = ({ isDisabled, isActive }: ButtonProps) => {
  if (isDisabled) return Colors.Neutral50
  if (isActive) return Colors.Blue70
  return Colors.Neutral90
}

export const DocumentButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  user-select: none;
  color: ${getButtonColor};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'all')};
  transition: color 0.15s;

  &:hover {
    color: ${Colors.Blue50};
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  & ${DocumentButtonIcon} {
    color: ${props => (props.isDisabled ? Colors.Neutral50 : Colors.Blue50)};
  }
`

export const DocumentSecondaryActions = styled.div`
  position: relative;
`

export const DocumentReviewStatusTooltip = styled(Tooltip)`
  position: absolute;
  top: 12px;
  left: 12px;

  ${Media.Phone`
    top: 4px;
    left: 4px;
  `};
`

export const DocumentReviewStatusLink = styled(Link)<{ status: STATUSES }>`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 14px;
  background-color: ${Colors.White};
  box-shadow: 0 0 1px rgba(15, 56, 113, 0.32), 0 1px 2px rgba(15, 56, 113, 0.12),
    0 2px 4px rgba(15, 56, 113, 0.08);
  pointer-events: ${props => (props.status === STATUSES.finished ? 'auto' : 'none')};

  ${props =>
    props.status === STATUSES.pending &&
    css`
      padding-left: 12px;
      padding-right: 12px;
    `}

  ${props =>
    props.status === STATUSES.failed &&
    css`
      padding-right: 12px;
    `}
`

export const DocumentReviewStatusVisual = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-right: 6px;
  align-items: center;
`

export const DocumentReviewStatusIconWrapper = styled.div<{ status: STATUSES }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.status === STATUSES.failed ? Colors.Red50 : Colors.Indigo80)};
  color: ${Colors.White};
  position: relative;
  z-index: 1;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 0 2px ${Colors.White};
  border-radius: 50%;
`

export const DocumentReviewStatusPhoto = styled.img`
  margin-left: -4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${Colors.Neutral15};
`

export const DocumentReviewStatusIcon = styled.svg`
  width: 14px;
  height: 14px;
`

export const DocumentReviewStatusText = styled.div`
  ${Typography.Tiny};
  white-space: nowrap;
`

export const DocumentReviewStatusArrow = styled(Icon20.ChevronDown)`
  color: ${Colors.Neutral40};
  transition: color 0.15s;
  transform: rotate(-90deg);

  ${DocumentReviewStatusLink}:hover & {
    color: ${Colors.Indigo60};
  }
`
