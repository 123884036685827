import { createAction, createReducer } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { AppConfig, AppStore, ConfigScopesEnum, ConfigTypes, FeaturesValueType } from './types'
import { overrideFeatureFlags } from './utils'

// ---
// Selectors
// ---
export const selectors = {
  config: <S extends ConfigScopesEnum = ConfigScopesEnum.app>(
    state: Store,
    scope?: S,
  ): AppStore['configs'][S] => {
    const type = scope || (ConfigScopesEnum.app as S)
    return state.application.configs[type]
  },
  feature: <
    N extends keyof NonNullable<AppStore['configs'][S]>['features'],
    S extends ConfigScopesEnum.app | ConfigScopesEnum.signUp = ConfigScopesEnum.app,
  >(
    state: Store,
    name: N,
    scope?: S,
  ): NonNullable<AppStore['configs'][S]>['features'][N] | undefined => {
    // @ts-expect-error TS can't properly connect to the keys of given config
    return selectors.config(state, scope)?.features[name]
  },
  logo: (state: Store) => selectors.config(state, ConfigScopesEnum.country)?.logo ?? null,
  locale: (state: Store) => selectors.config(state, ConfigScopesEnum.country)?.locale ?? null,
  returnButton: (state: Store) =>
    selectors.config(state, ConfigScopesEnum.country)?.returnButton ?? null,
  isOnline: (state: Store) => state.application.isOnline,
  resumeLocale: (state: Store) => state.resumeEditor.resume?.locale ?? null,
  coverLetterLocale: (state: Store) => state.coverLetterEditor.coverLetter?.locale ?? null,
}

// ---
// Action creators
// ---

export const actions = {
  fetchConfig: createAction<{ scope: ConfigScopesEnum }>('builder/GET_APPLICATION_SETTINGS'),
  updateConfig: createAction<ConfigTypes & { scope: ConfigScopesEnum }>(
    'builder/SET_APPLICATION_SETTINGS',
  ),
  setFeatureValue: createAction<{
    name: keyof AppConfig['features']
    value: FeaturesValueType
  }>('builder/SET_FEATURE_VALUE'),
  setInternetConnectionStatus: createAction<{ isOnline: boolean }>(
    'builder/SET_INTERNET_CONNECTION_STATUS',
  ),
}

// ---
// Reducer
// ---
const initialState: AppStore = {
  configs: {
    [ConfigScopesEnum.country]: null,
    [ConfigScopesEnum.signUp]: null,
    [ConfigScopesEnum.app]: null,
  },
  isOnline: true,
}

export const reducer = createReducer(initialState, builder => {
  builder.addCase(actions.updateConfig, (draft, action) => {
    const { scope, ...config } = action.payload
    // @ts-expect-error Hard to narrow type in reducers
    draft.configs[scope] = config
    // @ts-expect-error Same here
    if ('features' in config) draft.configs[scope].features = overrideFeatureFlags(config.features)
  })
  builder.addCase(actions.setFeatureValue, (draft, action) => {
    if (draft.configs.app) {
      draft.configs.app.features[action.payload.name] = action.payload.value as never
    }
  })
  builder.addCase(actions.setInternetConnectionStatus, (draft, action) => {
    draft.isOnline = action.payload.isOnline
  })
})
