import styled from 'styled-components'

import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import Icon from 'builder/components/Icon'
import Menu from 'builder/features/navigation/components/SideMenu/Menu'

export const Container = styled.div`
  background-color: ${Colors.White};
  position: fixed;
  top: 64px;
  z-index: 600;
  display: flex;
  z-index: 3;

  ${Media.Tablet`
    position: fixed;
    align-items: center;
  `}

  ${Media.Phone`
    display: none;
  `};
`

export const MenuComponent = styled(Menu)`
  height: calc(100vh - 64px);
  border-right: 1px solid ${Colors.Neutral15};
`

export const CloseButtonContainer = styled.div<{ width: string }>`
  position: fixed;
  height: 64px;
  width: ${props => props.width};
`

export const CloseButton = styled.div`
  margin: 12px 10px;
  padding: 8px;
  cursor: pointer;
`
export const CloseIcon = styled(Icon.CloseLarge)``
