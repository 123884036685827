import styled from 'styled-components'
import { Badge } from 'builder/components/Badge'
import { Icon20 } from 'builder/components/Icon'
import { rotation } from 'builder/styles/keyframes'
import Typography, { FontWeights } from 'builder/styles/typography'
import Tooltip from 'builder/components/Tooltip/Tooltip'

export const CustomBadge = styled(Badge)`
  height: 24px;
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  box-sizing: content-box;
  width: fit-content;
  padding: 1px var(--3XS, 4px);
  align-items: center;
`

export const Spinner = styled(Icon20.Spinner)`
  animation: ${rotation} ease-in-out 1s infinite;
`

export const Label = styled.div`
  ${FontWeights.DemiBold};
  ${Typography.Tiny}

  display: flex;
  justify-content: center;
  align-items: center;
`

export const CustomTooltip = styled(Tooltip)`
  width: fit-content;
`
