import axios, { AxiosError, AxiosResponse } from 'axios'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import type { UserDocumentType } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import { APP_BASE_PATH, FetchStatuses } from 'builder/modules/constants'
import { navigate } from 'builder/modules/navigate'
import { apiClient, baseClient } from 'builder/modules/apiClient'
import { selectors as userSelectors } from 'builder/modules/user'
import { actions as uiActions } from 'builder/modules/ui'
import ErrorLogger from 'builder/services/ErrorLogger'
import { cioDomainList } from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { actions, selectors } from './panelModule'
import { typeToSlug } from './utils'
import {
  BriefLetter,
  BriefResume,
  EWidgetsName,
  PanelSessionStorageKeys,
  ResumeUpdateStatus,
  BriefExpertDoc,
  ResumeEditorQueryParams,
} from './types'

type FetchDocumentsResponses = [
  AxiosResponse<{ resumes: BriefResume[] }>,
  AxiosResponse<{ coverLetters: BriefLetter[] }>,
  AxiosResponse<{ data: BriefExpertDoc[] }>,
]

type CreateDocumentResponse = AxiosResponse<BriefResume | BriefLetter | BriefExpertDoc>

/** Loads user documents */
function* fetchDocumentsSaga() {
  yield put(actions.setDocumentsStatus(FetchStatuses.loading))

  const [resumesResponse, lettersResponse, expertDocResponse]: FetchDocumentsResponses = yield all([
    call(apiClient.get, `/resumes`),
    call(apiClient.get, `/cover-letters`),
    call(baseClient.get, `/documents`),
  ])
  let documents = []
  if (expertDocResponse.data && Array.isArray(expertDocResponse.data)) {
    expertDocResponse.data.forEach(elm => {
      elm.type = 'tr_document'
      elm.createdAt = elm.created_at
    })
    yield all(
      expertDocResponse.data.map(trDocument =>
        put(actions.setTRDocument({ document: trDocument })),
      ),
    )
    documents = [
      ...resumesResponse.data.resumes,
      ...lettersResponse.data.coverLetters,
      ...expertDocResponse.data,
    ]
  } else {
    documents = [...resumesResponse.data.resumes, ...lettersResponse.data.coverLetters]
  }

  yield put(actions.setDocuments(documents))
  yield put(actions.setDocumentsStatus(FetchStatuses.loaded))
}

/** Performs document renaming on the server */
function* renameDocumentSaga(action: ReturnType<typeof actions.renameDocument>) {
  const { id, name, type } = action.payload
  yield call(apiClient.post, `/${typeToSlug(type)}/${id}/rename`, { name })
}

/** Performs TR-document renaming on the server */
function* renameTrDocumentSaga(action: ReturnType<typeof actions.renameTrDocument>) {
  const { id, title, slug } = action.payload
  yield call(baseClient.put, `/documents/${id}`, { title, slug })
}

/** Performs document renaming on the server */
function* duplicateTRDocumentSaga(action: ReturnType<typeof actions.duplicateTRDocument>) {
  yield put(actions.setIsDuplicationLoading(true))
  const { id } = action.payload
  try {
    const {
      data: { redirect_to: redirecTo },
    } = yield call(baseClient.post, `/documents/${id}/duplicate`, { id: id })
    yield put(actions.setDuplicationUrl(redirecTo))
  } catch (error) {
    yield put(uiActions.closeTREditModal(id))
  }
  yield put(actions.setIsDuplicationLoading(false))
}

/** Check that user is allowed to create a new document */
function* canUserCreate(
  type: UserDocumentType,
): Generator<object, boolean, Array<BriefResume | BriefLetter | BriefExpertDoc>> {
  // does user have premium access
  const userHasPremiumAccess = yield select(userSelectors.premiumAccess)
  if (userHasPremiumAccess) return true
  // check that documents were loaded before (`unknown` fixed saga's select types bug)
  const fetchStatus: unknown = yield select(selectors.documentsStatus)
  if (fetchStatus === FetchStatuses.notAsked) yield call(fetchDocumentsSaga)
  // does user already have documents of this type
  const documents: Array<BriefResume | BriefLetter | BriefExpertDoc> = yield select(
    selectors.documents,
  )
  return documents.filter(d => d.type === type).length === 0
}

/** Create a new document */
function* createDocumentSaga(
  action: ReturnType<typeof actions.createDocument>,
): Generator<object, void, AxiosResponse> {
  const { type, name = 'Untitled', target } = action.payload
  const hostType = window?.location?.host
  const isUserFromRio = yield select(userSelectors.isRioUser)
  const isCareerIoDomain = cioDomainList.includes(hostType) && isUserFromRio
  let BASE_URL = APP_BASE_PATH

  if (yield call(canUserCreate, type)) {
    // create document in the database
    const url = `/${typeToSlug(type)}`
    const details = 'details' in action.payload ? action.payload.details : {}
    const { data: document }: CreateDocumentResponse = yield call(apiClient.post, url, {
      name,
      ...details,
    })
    // open the editor new flow
    const editorUrl = `/${typeToSlug(type)}/${document.id}/edit`

    if (isCareerIoDomain) {
      yield put(actions.navigateBackToRIO({ id: document.id, type, feature: 'createNew' }))
    } else {
      target ? window.open(BASE_URL + editorUrl, target) : navigate(editorUrl)
    }
  } else {
    sessionStorage.setItem(PanelSessionStorageKeys.ACTION_AFTER_UPGRADING_PLAN, 'createDocument')
    sessionStorage.setItem(PanelSessionStorageKeys.DOCUMENT_TYPE, type)
    yield put(uiActions.openLimitModal(type))
  }
}

/** Makes a copy of the document */
function* copyDocumentSaga(
  action: ReturnType<typeof actions.copyDocument>,
): Generator<object, void, AxiosResponse> {
  const { id, type } = action.payload

  if (yield call(canUserCreate, type)) {
    const url = `/${typeToSlug(type)}/${id}/duplicate`
    const response: AxiosResponse<BriefResume | BriefLetter | BriefExpertDoc> = yield call(
      apiClient.post,
      url,
    )
    yield put(actions.addDocument(response.data))
  } else {
    sessionStorage.setItem(PanelSessionStorageKeys.ACTION_AFTER_UPGRADING_PLAN, 'copyDocument')
    sessionStorage.setItem(PanelSessionStorageKeys.DOCUMENT_TYPE, type)
    sessionStorage.setItem(PanelSessionStorageKeys.DOCUMENT_TO_COPY_ID, `${id}`)
    yield put(uiActions.openLimitModal(type))
  }
}

/** Removes the document from the database */
function* deleteDocumentSaga(action: ReturnType<typeof actions.deleteDocument>) {
  yield put(actions.updateResumeStatus(ResumeUpdateStatus.Started))
  const { id, type } = action.payload
  yield call(apiClient.delete, `/${typeToSlug(type)}/${id}`)
  yield put(actions.updateResumeStatus(ResumeUpdateStatus.Finished))
}

function* fetchResumeStatusSaga(action: ReturnType<typeof actions.setResumeId>) {
  try {
    const { resumeId } = action.payload
    if (resumeId) {
      const { data } = yield call(apiClient.get, `/resumes/${resumeId}/prefill/status`)
      if (data) {
        yield put(actions.setResumeStatus(data.status))
      }
    }
  } catch (error) {
    ErrorLogger.log(error)
    trackInternalEvent('tr_prefill_error', {
      label: 'tr_leads_to_cio',
    })
  }
}

/** Navigates from CIO to RIO for resume/cover letter related services */
function* navigateBackToRioSaga(
  action: ReturnType<typeof actions.navigateBackToRIO>,
): Generator<object, void, AxiosResponse> {
  const { type, id, feature, extraInfo = 'none' } = action.payload

  const hostType = window?.location?.host
  const createValidation = yield call(canUserCreate, type)
  const isCareerIoDomain = true
  const isStagingCareerEnv = hostType === 'staging.career.io'
  try {
    if (createValidation || extraInfo === 'skipValidation') {
      // open the editor new flow
      let editorUrl = `/${typeToSlug(type)}/${id}/edit`

      if (feature === 'share') {
        editorUrl = `?isSharing=true&docId=${id}`
      }
      if (feature === 'tailorResume') {
        editorUrl = editorUrl + `?${ResumeEditorQueryParams.isResumeTailor}=true`
      }

      if (isCareerIoDomain) {
        const { data } = yield call(apiClient.post, '/rio/magic_link', {
          redirect_to: APP_BASE_PATH + editorUrl,
          http_method: 'post',
        })
        if (data && data.success && data.token) {
          const magicLinkToken = data.token
          const { data: dataRedirect } = yield call(
            apiClient.post,
            '/login/magic-auth-of-rio',
            {
              token: magicLinkToken,
            },
            {
              baseURL: isStagingCareerEnv
                ? 'https://staging.resume.io/api/app'
                : 'https://resume.io/api/app',
            },
          )
          if (dataRedirect.domain && dataRedirect.redirectTo) {
            window.open('http://' + dataRedirect.domain + dataRedirect.redirectTo, '_self')
          }
        }
      }
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      const axiosError = error as AxiosError
      axiosError.message = `${axiosError.message}. Requested redirection cannot be done.`
      ErrorLogger.log(axiosError)
    } else {
      ErrorLogger.log(error)
    }
  }
}

function* updateHelperWidgetsSaga(action: ReturnType<typeof actions.updateHelperWidgets>) {
  try {
    const { stepId, status } = action.payload

    yield call(apiClient.post, `/widget-steps/${stepId}`, { status })
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* fetchHelperWidgetsSaga(action: ReturnType<typeof actions.fetchHelperWidgets>) {
  try {
    const { widgetName } = action.payload
    if (widgetName) {
      const { data } = yield call(apiClient.get, `/widget-steps?name=${widgetName}`)
      if (data) {
        yield put(
          actions.setHelperWidgets({
            widgetCollectionName: widgetName as EWidgetsName,
            widgetsList: data,
          }),
        )
      }
    }
  } catch (error) {
    ErrorLogger.log(error)
  }
}

function* fetchTRDocumentSaga(action: ReturnType<typeof actions.fetchTRDocument>) {
  const { id } = action.payload
  try {
    yield put(actions.setTRDocumentStatus({ status: FetchStatuses.loading, id }))

    const { status, data } = yield call(baseClient.get, `/documents/${id}`)
    if (status === 200) {
      yield put(actions.setTRDocument({ document: data as BriefExpertDoc }))
      yield put(actions.setTRDocumentStatus({ status: FetchStatuses.loaded, id }))
    }
  } catch (error) {
    yield put(actions.setTRDocumentStatus({ status: FetchStatuses.failed, id }))
  }
}

function* setUIStatesConfigStatusSaga(action: ReturnType<typeof actions.setUIStatesConfigStatus>) {
  try {
    const newUIStateConfig = action.payload

    yield call(apiClient.patch, `/account/ui-states`, { ...newUIStateConfig })
  } catch (error) {
    ErrorLogger.log(error)
  }
}

/** Bind side-effect handlers */
export const sagas = function* saga() {
  yield all([
    takeLatest(actions.fetchDocuments, fetchDocumentsSaga),
    takeLatest(actions.createDocument, createDocumentSaga),
    takeLatest(actions.copyDocument, copyDocumentSaga),
    takeLatest(actions.renameDocument, renameDocumentSaga),
    takeLatest(actions.renameTrDocument, renameTrDocumentSaga),
    takeEvery(actions.deleteDocument, deleteDocumentSaga),
    takeLatest(actions.fetchResumeStatus, fetchResumeStatusSaga),
    takeLatest(actions.navigateBackToRIO, navigateBackToRioSaga),
    takeLatest(actions.fetchHelperWidgets, fetchHelperWidgetsSaga),
    takeLatest(actions.updateHelperWidgets, updateHelperWidgetsSaga),
    takeLatest(actions.fetchTRDocument, fetchTRDocumentSaga),
    takeLatest(actions.duplicateTRDocument, duplicateTRDocumentSaga),
    takeLatest(actions.setUIStatesConfigStatus, setUIStatesConfigStatusSaga),
  ])
}
